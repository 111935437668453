import {
  KlCompetitionScriptFilterGetScriptsUsingGetParams,
} from '@/api/generated/data-contracts';
import { useApi } from '@/hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { Public } from '@/api/generated/Public';
import { staleTime } from '@/const/httpQuery';

export const useGetPublicScriptsSearch = (
  query: KlCompetitionScriptFilterGetScriptsUsingGetParams,
  isArchived: boolean,
) => {
  const httpClient = useApi();
  const queryKey = isArchived
    ? 'get:/api/v2/public/script-short-filter'
    : 'get:/api/v1/public/competition-script-filter';

  return useQuery({
    queryKey: [
      queryKey,
      query.pageNumber,
      JSON.stringify(query),
    ],
    queryFn: async () => {
      if (isArchived) {
        return new Public(httpClient).scriptShortPublicFilterGetPublicScriptsUsingGet({
          ...query,
          isArchived,
        });
      }
      return new Public(httpClient).competitionScriptFilterGetScriptsUsingGet(query);
    },
    staleTime: staleTime(),
    enabled: !!query?.competitionIds?.length || !!query?.competitionReference,
  });
};

export const useGetArchiveSummary = (id: number) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: [`/api/v1/public/competition/${id}/summary`],
    queryFn: async () => {
      return new Public(httpClient).competitionScriptSummaryGetCompetitionScriptSummaryUsingGet(id);
    },
    staleTime: staleTime(),
    enabled: !!id,
  });
};
