const Instagram = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_12012_37501)">
      <path
        d="M18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0ZM18 34.3636C8.96259 34.3636 1.63638 27.0374 1.63638 18C1.63638 8.96259 8.96259 1.63638 18 1.63638C27.0374 1.63638 34.3636 8.96259 34.3636 18C34.3636 27.0374 27.0374 34.3636 18 34.3636Z"
        fill="currentColor"
      />
      <path
        d="M22.8756 9.81819H13.1577C11.3687 9.81819 9.91837 11.2834 9.91837 13.0909V22.909C9.91837 24.7165 11.3687 26.1817 13.1577 26.1817H22.8756C24.6646 26.1817 26.1149 24.7165 26.1149 22.909V13.091C26.115 11.2834 24.6647 9.81819 22.8756 9.81819ZM21.256 12.2727H23.6854V14.7273H21.256V12.2727ZM18.0167 14.7273C19.8057 14.7273 21.256 16.1926 21.256 18C21.256 19.8075 19.8057 21.2727 18.0167 21.2727C16.2276 21.2727 14.7774 19.8075 14.7774 18C14.7774 16.1926 16.2276 14.7273 18.0167 14.7273ZM24.4953 22.9091C24.4953 23.8128 23.7701 24.5455 22.8756 24.5455H13.1577C12.2632 24.5455 11.538 23.8128 11.538 22.9091V17.1818H13.2386C12.7523 19.8481 14.4974 22.4079 17.1365 22.8993C19.7756 23.3908 22.3093 21.6276 22.7957 18.9613C22.8535 18.6443 22.8803 18.3223 22.8756 18C22.8716 17.7255 22.8445 17.4518 22.7946 17.1819H24.4953L24.4953 22.9091Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_12012_37501">
        <rect width="100%" height="100%" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Instagram;
