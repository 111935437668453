import { useNavigate } from 'react-router-dom';
import { AppStateInterface, useGetAppState, useUpdateAppState } from '@/api/local/app';
import { DashboardPaths, SystemPaths } from '@/const/routes';
import first from 'lodash/first';
import { StrapiBlock, StrapiPage, StrapiSection } from '@/const/cmsInterface';
import { getPages } from '@/const/pages';

export const useNavigateBack = () => {
  const navigate = useNavigate();
  const { data: appState } = useGetAppState();
  const appStateMutation = useUpdateAppState();

  const navigateBack = (defaultUrl: string) => {
    const isAfterAuth = appState?.lastVisited?.find((url) => {
      return [`${SystemPaths.DASHBOARD}${DashboardPaths.LOGIN}`].includes(url);
    });
    const currentPath = window.location.pathname;
    const isTheSameUrl = first(appState?.lastVisited) === currentPath;
    if (isTheSameUrl) {
      navigate(defaultUrl);
      return;
    }

    // redirect to default url if user is after login
    if (isAfterAuth) {
      appStateMutation.mutate({
        lastVisited: [],
      } as Partial<AppStateInterface>);
      navigate(defaultUrl);
      return;
    }

    if (!window.history.length) {
      navigate(defaultUrl);
      return;
    }

    const lastVisited = first(appState?.lastVisited);
    if (lastVisited) {
      navigate(lastVisited);
      return;
    }
    navigate(-1);
  };

  return {
    navigateBack,
  };
};

export const useNavigateWithHistory = () => {
  const navigate = useNavigate();
  const { data: appState } = useGetAppState();
  const appStateMutation = useUpdateAppState();

  const navigateWithHistory = (url: number | string) => {
    if (typeof url === 'number') {
      navigate(url);
      return;
    }
    navigate(url);
    const currentPath = window.location.pathname;

    const historyArray = [
      ...new Set([currentPath, ...(appState?.lastVisited || [])]
        ?.splice(0, 3)),
    ];
    appStateMutation.mutate({
      lastVisited: historyArray,
    } as Partial<AppStateInterface>);
  };

  return navigateWithHistory;
};

export const useNavigateWithAnchor = () => {
  const navigate = useNavigate();

  const navigateWithAnchor = (baseUrl: string, strapiBlock: StrapiBlock['type']) => {
    const pages = getPages() as StrapiPage[];
    const foundBlock = pages
      ?.find((page) => page.slug === baseUrl)
      ?.sections?.find((section) => {
        return section.blocks?.find((block) => block.type === strapiBlock);
      }) as StrapiSection;

    if (!foundBlock) {
      return navigate(baseUrl);
    }
    return navigate(`${baseUrl}/#anchor-${foundBlock?.id}`);
  };

  return navigateWithAnchor;
};
