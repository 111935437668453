import {
  useMutation, useQuery, useQueryClient, UseQueryResult,
} from '@tanstack/react-query';

export interface AppStateInterface {
  search: {
    isLoglineVisible: boolean;
  };
  modals: {
    notifyMeModal: {
      isOpen: boolean;
    };
    videoModal: {
      isOpen: boolean;
      filter: 'HOME_PAGE' | 'SCREENPLAYS' | 'SCRIPT_ANALYSIS' | 'YOUTUBE';
      embeddedUrl?: string;
    };
  };
  tours: {
    id: string;
    isOpen: boolean;
  }[],
  messageAnnounce: {
    hiddenByUser: boolean | string;
  };
  headerAnnounce: {
    hiddenByUser: number[];
  };
  stargazer: {
    announcementShown: boolean,
    winnerAnnouncementShown: boolean,
  };
  contactUs: {
    isSent: boolean;
  };
  screenplayUpload: {
    id: number | undefined;
  };
  screenplay: {
    activity: {
      listen?: {
        id: number | undefined;
        currentTimeSeconds: number;
        isOpen: boolean;
      }[];
      read?: {
        id: number | undefined;
        currentPage: number;
      }[];
    };
  };

  lastVisited: string[];
}

const getStarageKey = () => {
  return 'local:app';
};

const getStateFromLocalStorage = (): AppStateInterface | null => {
  const itemString = localStorage.getItem(getStarageKey());
  if (!itemString) {
    return {} as AppStateInterface;
  }

  let savedDocument = {} as AppStateInterface;
  try {
    savedDocument = JSON.parse(itemString);
  } catch {
    return {} as AppStateInterface;
    // console.log(error);
  }

  return savedDocument;
};

export const useGetAppState = (): UseQueryResult<AppStateInterface> => {
  return useQuery({
    queryKey: ['local:app'],
    queryFn: async () => {
      return getStateFromLocalStorage();
    }
    ,
  });
};

export const useUpdateAppState = () => {
  const queryClient = useQueryClient();
  const save = async (parameters: Partial<AppStateInterface>) => {
    const oldState = getStateFromLocalStorage();
    const state = { ...oldState, ...parameters };
    localStorage.setItem(getStarageKey(), JSON.stringify(state));
    return state;
  };

  return useMutation({
    mutationKey: ['local:app'],
    mutationFn: save,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['local:app'],
      });
    },
  });
};
