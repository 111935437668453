import {
  useEffect,
} from 'react';
import { AppStateInterface, useGetAppState, useUpdateAppState } from '@/api/local/app';

// store the last 3 visited locations in local storage, not soft navigation
// this is used to determine the back button behavior, to detect if the user is coming from a keyclock
export const LocationHistory = () => {
  const { data: appState, isLoading } = useGetAppState();
  const appStateMutation = useUpdateAppState();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const historyArray = [
      ...new Set([window.location.pathname, ...(appState?.lastVisited || [])]
        ?.splice(0, 3)),
    ];
    appStateMutation.mutate({
      lastVisited: historyArray,
    } as Partial<AppStateInterface>);
  }, [isLoading]);

  return null;
};
