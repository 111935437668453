import Button from '@/ui-kit/Base/Button/Button';
import React from 'react';
import { useGetUser } from '@/api/http/user';
import { DashboardPaths, SystemPaths } from '@/const/routes';
import { useMyLabAddToMyLabUsingPost } from '@/api/http/mylab';
import { MyLabCollectionAddedIcon, MyLabCollectionAddIcon } from '@/ui-kit/Base/Iconography/Icons';
import {
  AddedToMyLabNotificationWithCompetition,
} from '@/ui-kit/Components/ScreenplayCarouselSection/AddedToMyLabNotificationWithCompetition';
import If from '@/ui-kit/Base/If/If';

export interface ScreenplayCarouselItemProperties {
  disabled?: boolean;
  screenplayId?: number;
  screenplayTitle: string;
}

export const AddToMyLabButton: React.FC<ScreenplayCarouselItemProperties> = ({
  disabled = false,
  screenplayId,
  screenplayTitle,
}) => {
  const { data: user } = useGetUser();
  const addToMyLabUsingPost = useMyLabAddToMyLabUsingPost();
  const [isInMyLab, setIsInMyLab] = React.useState(disabled);
  const [isAddedToMyLab, setIsAddedToMyLab] = React.useState(false);

  function renderNotificationWithCompetition() {
    if (!isAddedToMyLab) {
      return null;
    }
    // render notification with competition only if the screenplay is added to my lab to prevent unnecessary requests
    return (
      <AddedToMyLabNotificationWithCompetition screenplayTitle={screenplayTitle} />
    );
  }

  return (
    <>
      {renderNotificationWithCompetition()}
      <Button
        style={{ height: 14, width: 14 }}
        disabled={isInMyLab}
        variant="light"
        loading={addToMyLabUsingPost.isPending}
        type="text"
        icon={(
          <>
            <If truthy={!isInMyLab}>
              <MyLabCollectionAddIcon className="lime" size={14} />
            </If>
            <If truthy={isInMyLab}>
              <MyLabCollectionAddedIcon className="lime" size={14} />
            </If>
          </>
      )}
        onClick={async (event) => {
          event.preventDefault();
          event.stopPropagation();
          if (!user?.id) {
            const redirect = `${window.location.origin}${window.location.pathname}`;
            window.location.href = `${SystemPaths.DASHBOARD}${DashboardPaths.LOGIN}?redirect=${redirect}`;
            return false;
          }

          await addToMyLabUsingPost.mutateAsync({
            scriptId: screenplayId,
            type: 'SCRIPT_READ',
          });

          setIsInMyLab(true);
          setIsAddedToMyLab(true);

          return false;
        }}
      />
    </>
  );
};

export default AddToMyLabButton;
