import React, {
  createContext, useMemo, Suspense,
} from 'react';
import Keycloak, { KeycloakConfig } from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AuthClientError, AuthClientEvent, AuthClientTokens } from '@react-keycloak/core';
import GeneralLoader from '@/components/GeneralLoader';

export interface IAuthContext {
  initialized?: boolean;
}

const initialState = {
  initialized: false,
} as IAuthContext;

export const AuthContext = createContext<IAuthContext>(initialState);

interface AuthContextProviderProps {
  children?: JSX.Element | JSX.Element[];
  config: KeycloakConfig;
}

interface ProviderProps {
  children?: JSX.Element | JSX.Element[];
  config: KeycloakConfig;
  onEvent: (eventType: AuthClientEvent, error?: AuthClientError) => void;
  onTokens: (tokens: AuthClientTokens) => void;
}

const Provider: React.FC<ProviderProps> = ({
  children,
  config,
  onEvent,
  onTokens,
}) => {
  const keycloakInstance = useMemo(() => {
    return new Keycloak(config);
  }, [config]);

  const initOptions = {
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
    checkLoginIframe: true,
    silentCheckSsoFallback: false,
  } as Keycloak.KeycloakInitOptions;

  return (
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      onEvent={onEvent}
      onTokens={onTokens}
      initOptions={initOptions}
      isLoadingCheck={() => {
        return false;
      }}
      LoadingComponent={(
        <GeneralLoader />
      )}
    >
      { children }
    </ReactKeycloakProvider>
  );
};

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({
  children,
  config,
}) => {
  // onReady
  // onInitError
  // onAuthSuccess
  // onAuthError
  // onAuthRefreshSuccess
  // onAuthRefreshError
  // onTokenExpired
  // onAuthLogout
  const eventLogger = () => {};

  const tokenLogger = async (tokens: AuthClientTokens) => {
    if (tokens?.token) {
      localStorage.setItem('session', tokens?.token);
    }
  };

  if (window.localStorage.getItem('isTest')) {
    return (
      <Suspense fallback={<span />}>
        { children }
      </Suspense>
    );
  }
  return (
    <Provider
      onEvent={eventLogger}
      onTokens={tokenLogger}
      config={config}
    >
      <Suspense fallback={<span />}>
        { children }
      </Suspense>
    </Provider>
  );
};
