const MyLabCollectionAdd = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_181370_3304)">
      <path d="M30.8562 0H9.32045C9.14367 0 8.99902 0.144643 8.99902 0.321429V2.57143C8.99902 2.74821 9.14367 2.89286 9.32045 2.89286H29.249V30.5357C29.249 30.7125 29.3937 30.8571 29.5705 30.8571H31.8205C31.9972 30.8571 32.1419 30.7125 32.1419 30.5357V1.28571C32.1419 0.574554 31.5673 0 30.8562 0Z" fill="currentColor" />
      <path d="M7.11003 13.3899V11.6835C7.11003 11.5271 7.23802 11.3991 7.39444 11.3991H21.0458C21.2022 11.3991 21.3302 11.5271 21.3302 11.6835V13.3899C21.3302 13.5463 21.2022 13.6743 21.0458 13.6743H7.39444C7.23802 13.6743 7.11003 13.5463 7.11003 13.3899ZM21.0458 16.5183C21.2022 16.5183 21.3302 16.6463 21.3302 16.8028V18.5092C21.3302 18.6656 21.2022 18.7936 21.0458 18.7936H14.5045C14.3481 18.7936 14.2201 18.6656 14.2201 18.5092V16.8028C14.2201 16.6463 14.3481 16.5183 14.5045 16.5183H21.0458ZM7.67884 20.6422C11.92 20.6422 15.3577 24.0799 15.3577 28.3211C15.3577 32.5623 11.92 36 7.67884 36C3.43767 36 -5.72205e-05 32.5623 -5.72205e-05 28.3211C-5.72205e-05 24.0799 3.43767 20.6422 7.67884 20.6422ZM3.85717 32.1428C4.87747 33.1631 6.23549 33.7248 7.67884 33.7248C9.12219 33.7248 10.4802 33.1631 11.5005 32.1428C12.5208 31.1225 13.0825 29.7645 13.0825 28.3211C13.0825 26.8778 12.5208 25.5197 11.5005 24.4994C10.4802 23.4791 9.12219 22.9174 7.67884 22.9174C6.23549 22.9174 4.87747 23.4791 3.85717 24.4994C2.83687 25.5197 2.27517 26.8778 2.27517 28.3211C2.27517 29.7645 2.83687 31.1225 3.85717 32.1428ZM15.9265 32.5872H24.1743V7.55963H4.266V19.789C4.266 19.9454 4.13802 20.0734 3.98159 20.0734H1.99077C1.83435 20.0734 1.70637 19.9454 1.70637 19.789V6.13761C1.70637 5.50837 2.21474 5 2.84398 5H25.5963C26.2255 5 26.7339 5.50837 26.7339 6.13761V34.0092C26.7339 34.6384 26.2255 35.1468 25.5963 35.1468H15.9265C15.7701 35.1468 15.6421 35.0188 15.6421 34.8624V32.8716C15.6421 32.7151 15.7701 32.5872 15.9265 32.5872Z" fill="currentColor" fillOpacity="0.85" />
      <path d="M10.8429 25.6193H9.26797C9.17553 25.6193 9.09021 25.6619 9.03689 25.7366L6.77943 28.8579L5.95822 27.7239C5.93191 27.6872 5.89719 27.6574 5.85698 27.637C5.81677 27.6166 5.77224 27.6062 5.72714 27.6065H4.15936C3.92829 27.6065 3.7932 27.8696 3.92829 28.058L6.55191 31.6877C6.66567 31.8441 6.89675 31.8441 7.01051 31.6877L11.0704 26.0708C11.209 25.8823 11.0739 25.6193 10.8429 25.6193Z" fill="currentColor" fillOpacity="0.85" />
    </g>
    <defs>
      <clipPath id="clip0_181370_3304">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>

);
export default MyLabCollectionAdd;
