import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { PropsWithChildren } from 'react';
import type React from 'react';
import { DashboardPaths, SystemPaths } from '@/const/routes';
import { KlApiError } from '@/api/generated/data-contracts';

const UNAUTHORIZED = 401;
const SERVER_ERROR = 500;
const SERVER_UNAVAILABLE_ERROR = 503;
const NOT_FOUND = 404;
const BAD_REQUEST = 400;

export interface ApiError {
  message: string;
  description: string;
  status: number | string;
  statusText: string;
  error: KlApiError
}

export const ReactQueryProvider: React.FC<PropsWithChildren> = ({ children = null }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 0,
        retry: false,
        throwOnError: (error: Error) => {
          const apiError = error as unknown as ApiError;
          const { status } = apiError;

          // Temporary disabled due to Discourse issues
          if (status >= SERVER_ERROR && status <= SERVER_UNAVAILABLE_ERROR) {
            if (!window.location.href.includes(SystemPaths.UNAVAILABLE)) {
              window.location.href = SystemPaths.UNAVAILABLE;
            }
            console.error('Error', apiError?.error?.message);
            return false;
          }

          // first we have to check if user got 401 error to redirect him, then to handle all other types of errors
          if (status === UNAUTHORIZED) {
            localStorage.setItem('session', '');
            const redirect = `${window.location.origin}${window.location.pathname}`;
            window.location.href = `${SystemPaths.DASHBOARD}${DashboardPaths.LOGIN}?redirect=${redirect}`;
            return false;
          }

          if (apiError?.error?.message === 'Unexpected end of JSON input') {
            return false;
          }

          if (status === NOT_FOUND) {
            return false;
          }

          // handle bad request, but we should check validation errors behavior
          if (status === BAD_REQUEST) {
            return false;
          }

          return true;
        },
      },
    },

    queryCache: new QueryCache({
      onError: async (error: Error) => {
        const apiError = error as unknown as ApiError;
        const { status } = apiError;
        if (status === UNAUTHORIZED) {
          localStorage.setItem('session', '');
          const redirect = `${window.location.origin}${window.location.pathname}`;
          window.location.href = `${SystemPaths.DASHBOARD}${DashboardPaths.LOGIN}?redirect=${redirect}`;
        }

        return true;
      },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  );
};
