const Comments = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_12260_20622)">
      <path
        d="M8.69754 27.2569C9.60434 26.5961 10.7783 26.4274 11.8327 26.807C13.6955 27.4818 15.7832 27.8685 18.0046 27.8685C26.7703 27.8685 32.6259 22.2097 32.6259 16.6213C32.6259 11.0329 26.7703 5.37415 18.0046 5.37415C9.23881 5.37415 3.38326 11.0329 3.38326 16.6213C3.38326 18.8707 4.25491 21.0358 5.89278 22.8916C6.49731 23.5734 6.79255 24.4732 6.72226 25.387C6.62385 26.6594 6.32158 27.8263 5.92793 28.8596C7.12294 28.3043 8.11409 27.6857 8.69754 27.2639V27.2569ZM1.49936 30.1108C1.62589 29.9211 1.74539 29.7313 1.85786 29.5415C2.56081 28.3746 3.22861 26.8421 3.36217 25.1199C1.25333 22.7229 0.00911407 19.7916 0.00911407 16.6213C0.00911407 8.54444 8.06489 2 18.0046 2C27.9442 2 36 8.54444 36 16.6213C36 24.6982 27.9442 31.2426 18.0046 31.2426C15.3966 31.2426 12.9222 30.7927 10.6869 29.9843C9.85037 30.5959 8.48666 31.4324 6.86988 32.1353C5.80843 32.5993 4.59936 33.0211 3.34811 33.2671C3.29188 33.2811 3.23564 33.2882 3.1794 33.3022C2.87011 33.3585 2.56784 33.4077 2.25152 33.4358C2.23746 33.4358 2.21637 33.4428 2.20231 33.4428C1.84381 33.478 1.4853 33.4991 1.1268 33.4991C0.669884 33.4991 0.262175 33.2249 0.0864382 32.8031C-0.0892985 32.3814 0.00911405 31.9034 0.32544 31.58C0.613648 31.2848 0.873739 30.9684 1.11977 30.631C1.23927 30.4694 1.35174 30.3077 1.45719 30.146C1.46421 30.1319 1.47124 30.1249 1.47827 30.1108H1.49936Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_12260_20622">
        <rect width="100%" height="100%" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default Comments;
