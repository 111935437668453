export const VideoSettings = {
  streaming: {
    abr: {
      maxBitrate: {
        audio: 100,
        video: 100_000,
      },
    },
    scheduling: {
      scheduleWhilePaused: false,
    },
    buffer: {
      fastSwitchEnabled: true,
      stableBufferTime: 8,
      bufferTimeAtTopQualityLongForm: 4,
    },
  },
};
