const FileLines = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <path
      d="M9.5 32.625C8.88125 32.625 8.375 32.1188 8.375 31.5V4.5C8.375 3.88125 8.88125 3.375 9.5 3.375H20.75V9C20.75 10.2445 21.7555 11.25 23 11.25H28.625V31.5C28.625 32.1188 28.1187 32.625 27.5 32.625H9.5ZM9.5 0C7.01797 0 5 2.01797 5 4.5V31.5C5 33.982 7.01797 36 9.5 36H27.5C29.982 36 32 33.982 32 31.5V10.8633C32 9.66797 31.5289 8.52187 30.6852 7.67812L24.3148 1.31484C23.4711 0.471094 22.332 0 21.1367 0H9.5ZM13.4375 18C12.5023 18 11.75 18.7523 11.75 19.6875C11.75 20.6227 12.5023 21.375 13.4375 21.375H23.5625C24.4977 21.375 25.25 20.6227 25.25 19.6875C25.25 18.7523 24.4977 18 23.5625 18H13.4375ZM13.4375 24.75C12.5023 24.75 11.75 25.5023 11.75 26.4375C11.75 27.3727 12.5023 28.125 13.4375 28.125H23.5625C24.4977 28.125 25.25 27.3727 25.25 26.4375C25.25 25.5023 24.4977 24.75 23.5625 24.75H13.4375Z"
      fill="currentColor"
    />
  </svg>
);

export default FileLines;
