const Copy = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_179722_86840)">
      <path d="M31.7812 36H12.6562C10.3298 36 8.4375 34.1077 8.4375 31.7812V12.6562C8.4375 10.3298 10.3298 8.4375 12.6562 8.4375H31.7812C34.1077 8.4375 36 10.3298 36 12.6562V31.7812C36 34.1077 34.1077 36 31.7812 36ZM12.6562 11.25C11.8808 11.25 11.25 11.8808 11.25 12.6562V31.7812C11.25 32.5567 11.8808 33.1875 12.6562 33.1875H31.7812C32.5567 33.1875 33.1875 32.5567 33.1875 31.7812V12.6562C33.1875 11.8808 32.5567 11.25 31.7812 11.25H12.6562ZM5.625 24.75H4.21875C3.44334 24.75 2.8125 24.1192 2.8125 23.3438V4.21875C2.8125 3.44334 3.44334 2.8125 4.21875 2.8125H23.3438C24.1192 2.8125 24.75 3.44334 24.75 4.21875V5.55469H27.5625V4.21875C27.5625 1.89225 25.6702 0 23.3438 0H4.21875C1.89225 0 0 1.89225 0 4.21875V23.3438C0 25.6702 1.89225 27.5625 4.21875 27.5625H5.625V24.75Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_179722_86840">
        <rect width="100%" height="100%" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>

);

export default Copy;
