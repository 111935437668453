import { useApi } from '@/hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { Wallet } from '@/api/generated/Wallet';
import { getToken } from '@/utils/getToken';
import { staleTime } from '@/const/httpQuery';
import { Payment } from '@/api/generated/Payment';
import { Reward } from '@/api/generated/Reward';
import { KlTypesEnum } from '@/api/generated/data-contracts';

export const useWalletBalance = () => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/wallet'],
    queryFn: async () => new Wallet(httpClient).walletListUsingGet(),
    enabled: !!getToken(),
    staleTime: staleTime(),
  });
};

export const useGetDiscounts = (productIds: number[] | undefined[], type?: KlTypesEnum) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/payment/coupon-discount', productIds],
    queryFn: async () => {
      return new Payment(httpClient).couponDiscountGetCouponDiscountsUsingGet({
        ...(type && { types: type }),
        productIds: productIds as number[],
      });
    },
    enabled: !productIds?.some((product) => !product),
  });
};

export const useGetRewardHistory = (
  pageNumber: number,
  pageSize: number,
  sort: string[],
  code: 'SCRIPTLIME',
  // eslint-disable-next-line @typescript-eslint/max-params
) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/reward/history', pageNumber],
    queryFn: async () => {
      return new Reward(httpClient).rewardHistoryGetHistoryUsingGet({
        code,
        pageNumber,
        pageSize,
        sort,
      });
    },
  });
};
