const NotificationAdded = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <path
      d="M27.0366 13.6147C30.5353 13.6147 33.3716 10.7785 33.3716 7.27979C33.3716 3.78108 30.5353 0.944824 27.0366 0.944824C23.5379 0.944824 20.7017 3.78108 20.7017 7.27979C20.7017 10.7785 23.5379 13.6147 27.0366 13.6147Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.495 14.0747C6.495 7.37843 12.6905 2.45608 19.0404 3.01664C19.5906 3.0652 19.9972 3.55055 19.9486 4.1007C19.9 4.65084 19.4147 5.05745 18.8645 5.00889C13.5472 4.53948 8.495 8.68558 8.495 14.0747V18.1032C8.49558 19.6681 8.1407 21.2134 7.45676 22.6294C6.77381 24.0434 5.77997 25.2934 4.54726 26.2938C4.29949 26.5009 4.12817 26.7709 4.04936 27.0649C3.97025 27.36 3.9864 27.6711 4.09664 27.9584C4.20707 28.2462 4.40869 28.5011 4.68108 28.6845C4.95347 28.8679 5.28155 28.9693 5.62135 28.9707H30.3745C31.3056 28.9707 32 28.249 32 27.4418C32 27.0131 31.8088 26.5933 31.4343 26.2831C30.2044 25.2828 29.2132 24.0336 28.5324 22.621C27.4951 20.4686 27.4971 18.2448 27.499 16.2044L27.4991 15.9478C27.4991 15.3955 27.9468 14.9478 28.4991 14.9478C29.0514 14.9478 29.4991 15.3955 29.4991 15.9478L29.4991 16.0449C29.4989 18.2092 29.4988 20.0194 30.3341 21.7528C30.8858 22.8975 31.692 23.9154 32.699 24.7336L32.7048 24.7384C33.5229 25.4135 34 26.3917 34 27.4418C34 29.4236 32.3389 30.9707 30.3745 30.9707H24.3581C24.3443 30.9989 24.3304 31.0271 24.3162 31.0552C23.8722 31.9363 23.2624 32.7223 22.5164 33.3618C21.2413 34.4548 19.6462 35.0528 18 35.0552C16.3538 35.0528 14.7587 34.4548 13.4836 33.3618C12.7376 32.7223 12.1278 31.9363 11.6838 31.0552C11.6696 31.0271 11.6557 30.9989 11.6419 30.9707H5.61964L5.61638 30.9707C4.88537 30.9683 4.16935 30.751 3.56406 30.3435C2.95835 29.9357 2.49037 29.355 2.22939 28.6749C1.96821 27.9942 1.92884 27.2511 2.11756 26.5471C2.30616 25.8434 2.71187 25.2182 3.27349 24.7519L3.2826 24.7443C4.29277 23.9256 5.10195 22.9063 5.65582 21.7596C6.20962 20.613 6.49552 19.3654 6.495 18.104V14.0747ZM18 33.0552C19.1583 33.0528 20.2942 32.6324 21.2148 31.8433C21.4895 31.6078 21.7402 31.3435 21.9633 31.0552H14.0367C14.2598 31.3435 14.5105 31.6078 14.7852 31.8433C15.7058 32.6324 16.8417 33.0528 18 33.0552Z"
      fill="currentColor"
    />
  </svg>
);

export default NotificationAdded;
