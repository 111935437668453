const Help = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_11934_215974)">
      <path
        d="M18 0C8.05141 0 0 8.0505 0 18C0 27.9484 8.0505 36 18 36C27.9486 36 36 27.9495 36 18C36 8.05141 27.9495 0 18 0ZM18 33.4884C9.4597 33.4884 2.51163 26.5404 2.51163 18C2.51163 9.45963 9.4597 2.51163 18 2.51163C26.5404 2.51163 33.4884 9.45963 33.4884 18C33.4884 26.5404 26.5404 33.4884 18 33.4884Z"
        fill="currentColor"
      />
      <path
        d="M17.4674 22.7759C16.4722 22.7759 15.6667 23.6052 15.6667 24.6002C15.6667 25.5717 16.4486 26.4247 17.4674 26.4247C18.4862 26.4247 19.2917 25.5717 19.2917 24.6002C19.2917 23.6052 18.4624 22.7759 17.4674 22.7759ZM17.7754 8.96266C14.5767 8.96266 13.1077 10.8582 13.1077 12.1376C13.1077 13.0617 13.8896 13.4882 14.5293 13.4882C15.8088 13.4882 15.2876 11.6637 17.7043 11.6637C18.8889 11.6637 19.8367 12.185 19.8367 13.2749C19.8367 14.5543 18.5098 15.2888 17.728 15.9522C17.0408 16.5445 16.1405 17.516 16.1405 19.5536C16.1405 20.7856 16.4722 21.1411 17.4436 21.1411C18.6045 21.1411 18.8415 20.6198 18.8415 20.1696C18.8415 18.9375 18.8652 18.2268 20.1684 17.2079C20.8081 16.7104 22.822 15.0992 22.822 12.872C22.822 10.6449 20.8081 8.96266 17.7754 8.96266Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_11934_215974">
        <rect width="100%" height="100%" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Help;
