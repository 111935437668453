/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	KlCompetitionFilterGetDataUsingGetData,
	KlCompetitionFilterGetDataUsingGetError,
	KlCompetitionFilterGetDataUsingGetParams,
	KlCompetitionPublicGetActiveCompetitionsUsingGetData,
	KlCompetitionPublicGetActiveCompetitionsUsingGetError,
	KlCompetitionPublicGetCompetitionUsingGetData,
	KlCompetitionPublicGetCompetitionUsingGetError,
	KlCompetitionRoundActionPublicListUsingGetData,
	KlCompetitionRoundActionPublicListUsingGetError,
	KlCompetitionRoundPublicListUsingGetData,
	KlCompetitionRoundPublicListUsingGetError,
	KlCompetitionRoundStepPublicListUsingGetData,
	KlCompetitionRoundStepPublicListUsingGetError,
	KlCompetitionScriptFilterGetScriptsUsingGetData,
	KlCompetitionScriptFilterGetScriptsUsingGetError,
	KlCompetitionScriptFilterGetScriptsUsingGetParams,
	KlCompetitionScriptSummaryGetCompetitionScriptSummaryUsingGetData,
	KlCompetitionScriptSummaryGetCompetitionScriptSummaryUsingGetError,
	KlComplexScriptFilterGetScriptsUsingGetData,
	KlComplexScriptFilterGetScriptsUsingGetError,
	KlComplexScriptFilterGetScriptsUsingGetParams,
	KlContactUsDto,
	KlContactUsSaveContactUsUsingPostData,
	KlContactUsSaveContactUsUsingPostError,
	KlConverterPreviewReportUsingPostData,
	KlConverterPreviewReportUsingPostError,
	KlConverterPreviewReportUsingPostParams,
	KlConverterPreviewReportUsingPostPayload,
	KlCurrencyEventRateGetRatesUsingGetData,
	KlCurrencyEventRateGetRatesUsingGetError,
	KlDictionaryGetDictionaryUsingGetData,
	KlDictionaryGetDictionaryUsingGetError,
	KlDictionaryGetDictionaryUsingGetParams,
	KlDictionaryGetEmailEventTypesUsingGetData,
	KlDictionaryGetEmailEventTypesUsingGetError,
	KlDictionaryGetRolesUsingGetData,
	KlDictionaryGetRolesUsingGetError,
	KlInterviewSsrGetHtmlUsingGetData,
	KlInterviewSsrGetHtmlUsingGetError,
	KlMediaPublicGetHomePageMediaUsingGetData,
	KlMediaPublicGetHomePageMediaUsingGetError,
	KlMediaPublicGetMediaUsingGetData,
	KlMediaPublicGetMediaUsingGetError,
	KlMediaPublicGetMediaUsingGetParamsEnum,
	KlMediaPublicGetScreenplaysUsingGetData,
	KlMediaPublicGetScreenplaysUsingGetError,
	KlMediaPublicGetScripAnalysisUsingGetData,
	KlMediaPublicGetScripAnalysisUsingGetError,
	KlNewsletterSubscriptionRequest,
	KlNewsletterSubscriptionSubscribeUsingPostData,
	KlNewsletterSubscriptionSubscribeUsingPostError,
	KlPaymentProductGetProductsUsingGetData,
	KlPaymentProductGetProductsUsingGetError,
	KlPaymentProductGetProductsUsingGetParams,
	KlPaymentProductSaveExternalProductsUsingPostData,
	KlPaymentProductSaveExternalProductsUsingPostError,
	KlPaymentReceiptGetPaymentReceiptUrlUsingGetData,
	KlPaymentReceiptGetPaymentReceiptUrlUsingGetError,
	KlPaymentWebhookCouponWebhookUsingPostData,
	KlPaymentWebhookCouponWebhookUsingPostError,
	KlPaymentWebhookInvoiceWebhookUsingPostData,
	KlPaymentWebhookInvoiceWebhookUsingPostError,
	KlPaymentWebhookProductWebhookUsingPostData,
	KlPaymentWebhookProductWebhookUsingPostError,
	KlPaymentWebhookSessionWebhookUsingPostData,
	KlPaymentWebhookSessionWebhookUsingPostError,
	KlPaymentWebhookSubscriptionWebhookUsingPostData,
	KlPaymentWebhookSubscriptionWebhookUsingPostError,
	KlPodcastSsrGetHtmlUsingGetData,
	KlPodcastSsrGetHtmlUsingGetError,
	KlRecaptchaDto,
	KlRecaptchaVerifyRecaptchaUsingPostData,
	KlRecaptchaVerifyRecaptchaUsingPostError,
	KlReelSsrGetHtmlUsingGetData,
	KlReelSsrGetHtmlUsingGetError,
	KlReelsFilterGetReelsUsingGetData,
	KlReelsFilterGetReelsUsingGetError,
	KlReelsFilterGetReelsUsingGetParams,
	KlReelsPublicGetByIdUsingGetData,
	KlReelsPublicGetByIdUsingGetError,
	KlScriptImageByGenreGetUrlsByGenreUsingGetData,
	KlScriptImageByGenreGetUrlsByGenreUsingGetError,
	KlScriptImageByGenreGetUrlsByGenreUsingGetParams,
	KlScriptImageGetScriptImageUsingGetData,
	KlScriptImageGetScriptImageUsingGetError,
	KlScriptPublicFilterGetPublicScriptsUsingGetData,
	KlScriptPublicFilterGetPublicScriptsUsingGetError,
	KlScriptPublicFilterGetPublicScriptsUsingGetParams,
	KlScriptPublicGetScriptPublicByReferenceUsingGetData,
	KlScriptPublicGetScriptPublicByReferenceUsingGetError,
	KlScriptPublicGetScriptPublicByReferenceUsingGetParams,
	KlScriptPublicGetScriptPublicUsingGetData,
	KlScriptPublicGetScriptPublicUsingGetError,
	KlScriptScreenwriterFilterGetScreenwriterScriptsUsingGetData,
	KlScriptScreenwriterFilterGetScreenwriterScriptsUsingGetError,
	KlScriptScreenwriterFilterGetScreenwriterScriptsUsingGetParams,
	KlScriptShortPublicFilterGetPublicScriptsUsingGetData,
	KlScriptShortPublicFilterGetPublicScriptsUsingGetError,
	KlScriptShortPublicFilterGetPublicScriptsUsingGetParams,
	KlScriptSsrGetHtmlUsingGetData,
	KlScriptSsrGetHtmlUsingGetError,
	KlScriptVipPublicGetScriptVipUsingGetData,
	KlScriptVipPublicGetScriptVipUsingGetError,
	KlSitemapGetSitemapUsingGetData,
	KlSitemapGetSitemapUsingGetError,
	KlUserPublicFilterFindByIdUsingGetData,
	KlUserPublicFilterFindByIdUsingGetError,
	KlUserPublicFilterGetUsersUsingGetData,
	KlUserPublicFilterGetUsersUsingGetError,
	KlUserPublicFilterGetUsersUsingGetParams
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Public<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http
	}

	/**
	 * No description
	 *
	 * @tags Competition
	 * @name CompetitionPublicGetActiveCompetitionsUsingGet
	 * @summary getActiveCompetitions
	 * @request GET:/api/v1/public/competition
	 * @deprecated
	 * @secure
	 * @response `200` `KlCompetitionPublicGetActiveCompetitionsUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	competitionPublicGetActiveCompetitionsUsingGet = (
		params: RequestParams = {}
	) =>
		this.http.request<
			KlCompetitionPublicGetActiveCompetitionsUsingGetData,
			KlCompetitionPublicGetActiveCompetitionsUsingGetError
		>({
			path: `/api/v1/public/competition`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Public filters
	 * @name CompetitionFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/public/competition-filter
	 * @secure
	 * @response `200` `KlCompetitionFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	competitionFilterGetDataUsingGet = (
		query: KlCompetitionFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlCompetitionFilterGetDataUsingGetData,
			KlCompetitionFilterGetDataUsingGetError
		>({
			path: `/api/v1/public/competition-filter`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Public filters
	 * @name CompetitionScriptFilterGetScriptsUsingGet
	 * @summary getScripts
	 * @request GET:/api/v1/public/competition-script-filter
	 * @secure
	 * @response `200` `KlCompetitionScriptFilterGetScriptsUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	competitionScriptFilterGetScriptsUsingGet = (
		query: KlCompetitionScriptFilterGetScriptsUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlCompetitionScriptFilterGetScriptsUsingGetData,
			KlCompetitionScriptFilterGetScriptsUsingGetError
		>({
			path: `/api/v1/public/competition-script-filter`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Competition
	 * @name CompetitionRoundPublicListUsingGet
	 * @summary list
	 * @request GET:/api/v1/public/competition/{competitionId}/round
	 * @secure
	 * @response `200` `KlCompetitionRoundPublicListUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	competitionRoundPublicListUsingGet = (
		competitionId: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlCompetitionRoundPublicListUsingGetData,
			KlCompetitionRoundPublicListUsingGetError
		>({
			path: `/api/v1/public/competition/${competitionId}/round`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Competition
	 * @name CompetitionRoundActionPublicListUsingGet
	 * @summary list
	 * @request GET:/api/v1/public/competition/{competitionId}/round/{competitionRoundId}/action
	 * @secure
	 * @response `200` `KlCompetitionRoundActionPublicListUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	competitionRoundActionPublicListUsingGet = (
		competitionId: number,
		competitionRoundId: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlCompetitionRoundActionPublicListUsingGetData,
			KlCompetitionRoundActionPublicListUsingGetError
		>({
			path: `/api/v1/public/competition/${competitionId}/round/${competitionRoundId}/action`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Competition
	 * @name CompetitionRoundStepPublicListUsingGet
	 * @summary list
	 * @request GET:/api/v1/public/competition/{competitionId}/round/{competitionRoundId}/step
	 * @secure
	 * @response `200` `KlCompetitionRoundStepPublicListUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	competitionRoundStepPublicListUsingGet = (
		competitionId: number,
		competitionRoundId: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlCompetitionRoundStepPublicListUsingGetData,
			KlCompetitionRoundStepPublicListUsingGetError
		>({
			path: `/api/v1/public/competition/${competitionId}/round/${competitionRoundId}/step`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Competition
	 * @name CompetitionPublicGetCompetitionUsingGet
	 * @summary getCompetition
	 * @request GET:/api/v1/public/competition/{id}
	 * @secure
	 * @response `200` `KlCompetitionPublicGetCompetitionUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	competitionPublicGetCompetitionUsingGet = (
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlCompetitionPublicGetCompetitionUsingGetData,
			KlCompetitionPublicGetCompetitionUsingGetError
		>({
			path: `/api/v1/public/competition/${id}`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Competition
	 * @name CompetitionScriptSummaryGetCompetitionScriptSummaryUsingGet
	 * @summary getCompetitionScriptSummary
	 * @request GET:/api/v1/public/competition/{id}/summary
	 * @secure
	 * @response `200` `KlCompetitionScriptSummaryGetCompetitionScriptSummaryUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	competitionScriptSummaryGetCompetitionScriptSummaryUsingGet = (
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlCompetitionScriptSummaryGetCompetitionScriptSummaryUsingGetData,
			KlCompetitionScriptSummaryGetCompetitionScriptSummaryUsingGetError
		>({
			path: `/api/v1/public/competition/${id}/summary`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Public filters
	 * @name ComplexScriptFilterGetScriptsUsingGet
	 * @summary getScripts
	 * @request GET:/api/v1/public/complex-script-filter
	 * @secure
	 * @response `200` `KlComplexScriptFilterGetScriptsUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	complexScriptFilterGetScriptsUsingGet = (
		query: KlComplexScriptFilterGetScriptsUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlComplexScriptFilterGetScriptsUsingGetData,
			KlComplexScriptFilterGetScriptsUsingGetError
		>({
			path: `/api/v1/public/complex-script-filter`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags contact-us-controller
	 * @name ContactUsSaveContactUsUsingPost
	 * @summary saveContactUs
	 * @request POST:/api/v1/public/contact-us
	 * @secure
	 * @response `200` `KlContactUsSaveContactUsUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	contactUsSaveContactUsUsingPost = (
		request: KlContactUsDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlContactUsSaveContactUsUsingPostData,
			KlContactUsSaveContactUsUsingPostError
		>({
			path: `/api/v1/public/contact-us`,
			method: 'POST',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags converter-controller
	 * @name ConverterPreviewReportUsingPost
	 * @summary previewReport
	 * @request POST:/api/v1/public/convert
	 * @secure
	 * @response `200` `KlConverterPreviewReportUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	converterPreviewReportUsingPost = (
		query: KlConverterPreviewReportUsingPostParams,
		htmlBody: KlConverterPreviewReportUsingPostPayload,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlConverterPreviewReportUsingPostData,
			KlConverterPreviewReportUsingPostError
		>({
			path: `/api/v1/public/convert`,
			method: 'POST',
			query: query,
			body: htmlBody,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags currency-event-rate-controller
	 * @name CurrencyEventRateGetRatesUsingGet
	 * @summary getRates
	 * @request GET:/api/v1/public/currency-event-rate
	 * @secure
	 * @response `200` `KlCurrencyEventRateGetRatesUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	currencyEventRateGetRatesUsingGet = (params: RequestParams = {}) =>
		this.http.request<
			KlCurrencyEventRateGetRatesUsingGetData,
			KlCurrencyEventRateGetRatesUsingGetError
		>({
			path: `/api/v1/public/currency-event-rate`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Dictionary
	 * @name DictionaryGetDictionaryUsingGet
	 * @summary getDictionary
	 * @request GET:/api/v1/public/dictionary
	 * @secure
	 * @response `200` `KlDictionaryGetDictionaryUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	dictionaryGetDictionaryUsingGet = (
		query: KlDictionaryGetDictionaryUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlDictionaryGetDictionaryUsingGetData,
			KlDictionaryGetDictionaryUsingGetError
		>({
			path: `/api/v1/public/dictionary`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Dictionary
	 * @name DictionaryGetEmailEventTypesUsingGet
	 * @summary getEmailEventTypes
	 * @request GET:/api/v1/public/dictionary/event-types
	 * @secure
	 * @response `200` `KlDictionaryGetEmailEventTypesUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	dictionaryGetEmailEventTypesUsingGet = (params: RequestParams = {}) =>
		this.http.request<
			KlDictionaryGetEmailEventTypesUsingGetData,
			KlDictionaryGetEmailEventTypesUsingGetError
		>({
			path: `/api/v1/public/dictionary/event-types`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Dictionary
	 * @name DictionaryGetRolesUsingGet
	 * @summary getRoles
	 * @request GET:/api/v1/public/dictionary/roles
	 * @secure
	 * @response `200` `KlDictionaryGetRolesUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	dictionaryGetRolesUsingGet = (params: RequestParams = {}) =>
		this.http.request<
			KlDictionaryGetRolesUsingGetData,
			KlDictionaryGetRolesUsingGetError
		>({
			path: `/api/v1/public/dictionary/roles`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Interview
	 * @name InterviewSsrGetHtmlUsingGet
	 * @summary getHtml
	 * @request GET:/api/v1/public/interview/{id}/ssr
	 * @secure
	 * @response `200` `KlInterviewSsrGetHtmlUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	interviewSsrGetHtmlUsingGet = (id: number, params: RequestParams = {}) =>
		this.http.request<
			KlInterviewSsrGetHtmlUsingGetData,
			KlInterviewSsrGetHtmlUsingGetError
		>({
			path: `/api/v1/public/interview/${id}/ssr`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags media-public-controller
	 * @name MediaPublicGetHomePageMediaUsingGet
	 * @summary getHomePageMedia
	 * @request GET:/api/v1/public/media/home-page
	 * @deprecated
	 * @secure
	 * @response `200` `KlMediaPublicGetHomePageMediaUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	mediaPublicGetHomePageMediaUsingGet = (params: RequestParams = {}) =>
		this.http.request<
			KlMediaPublicGetHomePageMediaUsingGetData,
			KlMediaPublicGetHomePageMediaUsingGetError
		>({
			path: `/api/v1/public/media/home-page`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags media-public-controller
	 * @name MediaPublicGetScreenplaysUsingGet
	 * @summary getScreenplays
	 * @request GET:/api/v1/public/media/screenplays
	 * @deprecated
	 * @secure
	 * @response `200` `KlMediaPublicGetScreenplaysUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	mediaPublicGetScreenplaysUsingGet = (params: RequestParams = {}) =>
		this.http.request<
			KlMediaPublicGetScreenplaysUsingGetData,
			KlMediaPublicGetScreenplaysUsingGetError
		>({
			path: `/api/v1/public/media/screenplays`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags media-public-controller
	 * @name MediaPublicGetScripAnalysisUsingGet
	 * @summary getScripAnalysis
	 * @request GET:/api/v1/public/media/script-analysis
	 * @deprecated
	 * @secure
	 * @response `200` `KlMediaPublicGetScripAnalysisUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	mediaPublicGetScripAnalysisUsingGet = (params: RequestParams = {}) =>
		this.http.request<
			KlMediaPublicGetScripAnalysisUsingGetData,
			KlMediaPublicGetScripAnalysisUsingGetError
		>({
			path: `/api/v1/public/media/script-analysis`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags media-public-controller
	 * @name MediaPublicGetMediaUsingGet
	 * @summary getMedia
	 * @request GET:/api/v1/public/media/{media}
	 * @secure
	 * @response `200` `KlMediaPublicGetMediaUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	mediaPublicGetMediaUsingGet = (
		media: KlMediaPublicGetMediaUsingGetParamsEnum,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlMediaPublicGetMediaUsingGetData,
			KlMediaPublicGetMediaUsingGetError
		>({
			path: `/api/v1/public/media/${media}`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags newsletter-subscription-controller
	 * @name NewsletterSubscriptionSubscribeUsingPost
	 * @summary subscribe
	 * @request POST:/api/v1/public/newsletter/subscription
	 * @secure
	 * @response `200` `KlNewsletterSubscriptionSubscribeUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	newsletterSubscriptionSubscribeUsingPost = (
		request: KlNewsletterSubscriptionRequest,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlNewsletterSubscriptionSubscribeUsingPostData,
			KlNewsletterSubscriptionSubscribeUsingPostError
		>({
			path: `/api/v1/public/newsletter/subscription`,
			method: 'POST',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Payment
	 * @name PaymentProductGetProductsUsingGet
	 * @summary getProducts
	 * @request GET:/api/v1/public/payment/product
	 * @secure
	 * @response `200` `KlPaymentProductGetProductsUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	paymentProductGetProductsUsingGet = (
		query: KlPaymentProductGetProductsUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlPaymentProductGetProductsUsingGetData,
			KlPaymentProductGetProductsUsingGetError
		>({
			path: `/api/v1/public/payment/product`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Payment
	 * @name PaymentProductSaveExternalProductsUsingPost
	 * @summary saveExternalProducts
	 * @request POST:/api/v1/public/payment/product/external
	 * @secure
	 * @response `200` `KlPaymentProductSaveExternalProductsUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	paymentProductSaveExternalProductsUsingPost = (params: RequestParams = {}) =>
		this.http.request<
			KlPaymentProductSaveExternalProductsUsingPostData,
			KlPaymentProductSaveExternalProductsUsingPostError
		>({
			path: `/api/v1/public/payment/product/external`,
			method: 'POST',
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Payment
	 * @name PaymentReceiptGetPaymentReceiptUrlUsingGet
	 * @summary getPaymentReceiptUrl
	 * @request GET:/api/v1/public/payment/receipt/url/{recordId}
	 * @secure
	 * @response `200` `KlPaymentReceiptGetPaymentReceiptUrlUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	paymentReceiptGetPaymentReceiptUrlUsingGet = (
		recordId: string,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlPaymentReceiptGetPaymentReceiptUrlUsingGetData,
			KlPaymentReceiptGetPaymentReceiptUrlUsingGetError
		>({
			path: `/api/v1/public/payment/receipt/url/${recordId}`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags payment-webhook-controller
	 * @name PaymentWebhookCouponWebhookUsingPost
	 * @summary couponWebhook
	 * @request POST:/api/v1/public/payment/webhook/coupon
	 * @secure
	 * @response `200` `KlPaymentWebhookCouponWebhookUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	paymentWebhookCouponWebhookUsingPost = (
		payload: string,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlPaymentWebhookCouponWebhookUsingPostData,
			KlPaymentWebhookCouponWebhookUsingPostError
		>({
			path: `/api/v1/public/payment/webhook/coupon`,
			method: 'POST',
			body: payload,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags payment-webhook-controller
	 * @name PaymentWebhookInvoiceWebhookUsingPost
	 * @summary invoiceWebhook
	 * @request POST:/api/v1/public/payment/webhook/invoice
	 * @secure
	 * @response `200` `KlPaymentWebhookInvoiceWebhookUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	paymentWebhookInvoiceWebhookUsingPost = (
		payload: string,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlPaymentWebhookInvoiceWebhookUsingPostData,
			KlPaymentWebhookInvoiceWebhookUsingPostError
		>({
			path: `/api/v1/public/payment/webhook/invoice`,
			method: 'POST',
			body: payload,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags payment-webhook-controller
	 * @name PaymentWebhookProductWebhookUsingPost
	 * @summary productWebhook
	 * @request POST:/api/v1/public/payment/webhook/product
	 * @secure
	 * @response `200` `KlPaymentWebhookProductWebhookUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	paymentWebhookProductWebhookUsingPost = (
		payload: string,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlPaymentWebhookProductWebhookUsingPostData,
			KlPaymentWebhookProductWebhookUsingPostError
		>({
			path: `/api/v1/public/payment/webhook/product`,
			method: 'POST',
			body: payload,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags payment-webhook-controller
	 * @name PaymentWebhookSessionWebhookUsingPost
	 * @summary sessionWebhook
	 * @request POST:/api/v1/public/payment/webhook/session
	 * @secure
	 * @response `200` `KlPaymentWebhookSessionWebhookUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	paymentWebhookSessionWebhookUsingPost = (
		payload: string,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlPaymentWebhookSessionWebhookUsingPostData,
			KlPaymentWebhookSessionWebhookUsingPostError
		>({
			path: `/api/v1/public/payment/webhook/session`,
			method: 'POST',
			body: payload,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags payment-webhook-controller
	 * @name PaymentWebhookSubscriptionWebhookUsingPost
	 * @summary subscriptionWebhook
	 * @request POST:/api/v1/public/payment/webhook/subscription
	 * @secure
	 * @response `200` `KlPaymentWebhookSubscriptionWebhookUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	paymentWebhookSubscriptionWebhookUsingPost = (
		payload: string,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlPaymentWebhookSubscriptionWebhookUsingPostData,
			KlPaymentWebhookSubscriptionWebhookUsingPostError
		>({
			path: `/api/v1/public/payment/webhook/subscription`,
			method: 'POST',
			body: payload,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Podcast
	 * @name PodcastSsrGetHtmlUsingGet
	 * @summary getHtml
	 * @request GET:/api/v1/public/podcast/{id}/ssr
	 * @secure
	 * @response `200` `KlPodcastSsrGetHtmlUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	podcastSsrGetHtmlUsingGet = (id: number, params: RequestParams = {}) =>
		this.http.request<
			KlPodcastSsrGetHtmlUsingGetData,
			KlPodcastSsrGetHtmlUsingGetError
		>({
			path: `/api/v1/public/podcast/${id}/ssr`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags recaptcha-controller
	 * @name RecaptchaVerifyRecaptchaUsingPost
	 * @summary verifyRecaptcha
	 * @request POST:/api/v1/public/recaptcha
	 * @secure
	 * @response `200` `KlRecaptchaVerifyRecaptchaUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	recaptchaVerifyRecaptchaUsingPost = (
		recaptcha: KlRecaptchaDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlRecaptchaVerifyRecaptchaUsingPostData,
			KlRecaptchaVerifyRecaptchaUsingPostError
		>({
			path: `/api/v1/public/recaptcha`,
			method: 'POST',
			body: recaptcha,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelSsrGetHtmlUsingGet
	 * @summary getHtml
	 * @request GET:/api/v1/public/reel/{id}/ssr
	 * @secure
	 * @response `200` `KlReelSsrGetHtmlUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelSsrGetHtmlUsingGet = (id: number, params: RequestParams = {}) =>
		this.http.request<
			KlReelSsrGetHtmlUsingGetData,
			KlReelSsrGetHtmlUsingGetError
		>({
			path: `/api/v1/public/reel/${id}/ssr`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Public filters
	 * @name ReelsFilterGetReelsUsingGet
	 * @summary getReels
	 * @request GET:/api/v1/public/reels-filter
	 * @secure
	 * @response `200` `KlReelsFilterGetReelsUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelsFilterGetReelsUsingGet = (
		query: KlReelsFilterGetReelsUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlReelsFilterGetReelsUsingGetData,
			KlReelsFilterGetReelsUsingGetError
		>({
			path: `/api/v1/public/reels-filter`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelsPublicGetByIdUsingGet
	 * @summary getById
	 * @request GET:/api/v1/public/reels/{id}
	 * @secure
	 * @response `200` `KlReelsPublicGetByIdUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelsPublicGetByIdUsingGet = (id: number, params: RequestParams = {}) =>
		this.http.request<
			KlReelsPublicGetByIdUsingGetData,
			KlReelsPublicGetByIdUsingGetError
		>({
			path: `/api/v1/public/reels/${id}`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Public filters
	 * @name ScriptPublicFilterGetPublicScriptsUsingGet
	 * @summary getPublicScripts
	 * @request GET:/api/v1/public/script-filter
	 * @secure
	 * @response `200` `KlScriptPublicFilterGetPublicScriptsUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptPublicFilterGetPublicScriptsUsingGet = (
		query: KlScriptPublicFilterGetPublicScriptsUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptPublicFilterGetPublicScriptsUsingGetData,
			KlScriptPublicFilterGetPublicScriptsUsingGetError
		>({
			path: `/api/v1/public/script-filter`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script file
	 * @name ScriptImageGetScriptImageUsingGet
	 * @summary getScriptImage
	 * @request GET:/api/v1/public/script-image/{scriptId}
	 * @deprecated
	 * @secure
	 * @response `200` `KlScriptImageGetScriptImageUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptImageGetScriptImageUsingGet = (
		scriptId: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptImageGetScriptImageUsingGetData,
			KlScriptImageGetScriptImageUsingGetError
		>({
			path: `/api/v1/public/script-image/${scriptId}`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Public filters
	 * @name ScriptScreenwriterFilterGetScreenwriterScriptsUsingGet
	 * @summary getScreenwriterScripts
	 * @request GET:/api/v1/public/script-screenwriter-filter
	 * @secure
	 * @response `200` `KlScriptScreenwriterFilterGetScreenwriterScriptsUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptScreenwriterFilterGetScreenwriterScriptsUsingGet = (
		query: KlScriptScreenwriterFilterGetScreenwriterScriptsUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptScreenwriterFilterGetScreenwriterScriptsUsingGetData,
			KlScriptScreenwriterFilterGetScreenwriterScriptsUsingGetError
		>({
			path: `/api/v1/public/script-screenwriter-filter`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-image-by-genre-controller
	 * @name ScriptImageByGenreGetUrlsByGenreUsingGet
	 * @summary getUrlsByGenre
	 * @request GET:/api/v1/public/script/genre/{id}/image
	 * @secure
	 * @response `200` `KlScriptImageByGenreGetUrlsByGenreUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptImageByGenreGetUrlsByGenreUsingGet = (
		{ id, ...query }: KlScriptImageByGenreGetUrlsByGenreUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptImageByGenreGetUrlsByGenreUsingGetData,
			KlScriptImageByGenreGetUrlsByGenreUsingGetError
		>({
			path: `/api/v1/public/script/genre/${id}/image`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-public-controller
	 * @name ScriptPublicGetScriptPublicByReferenceUsingGet
	 * @summary getScriptPublicByReference
	 * @request GET:/api/v1/public/script/ref/{reference}
	 * @secure
	 * @response `200` `KlScriptPublicGetScriptPublicByReferenceUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptPublicGetScriptPublicByReferenceUsingGet = (
		{
			reference,
			...query
		}: KlScriptPublicGetScriptPublicByReferenceUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptPublicGetScriptPublicByReferenceUsingGetData,
			KlScriptPublicGetScriptPublicByReferenceUsingGetError
		>({
			path: `/api/v1/public/script/ref/${reference}`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-public-controller
	 * @name ScriptPublicGetScriptPublicUsingGet
	 * @summary getScriptPublic
	 * @request GET:/api/v1/public/script/{id}
	 * @secure
	 * @response `200` `KlScriptPublicGetScriptPublicUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptPublicGetScriptPublicUsingGet = (
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptPublicGetScriptPublicUsingGetData,
			KlScriptPublicGetScriptPublicUsingGetError
		>({
			path: `/api/v1/public/script/${id}`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-ssr-controller
	 * @name ScriptSsrGetHtmlUsingGet
	 * @summary getHtml
	 * @request GET:/api/v1/public/script/{id}/ssr
	 * @secure
	 * @response `200` `KlScriptSsrGetHtmlUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSsrGetHtmlUsingGet = (id: number, params: RequestParams = {}) =>
		this.http.request<
			KlScriptSsrGetHtmlUsingGetData,
			KlScriptSsrGetHtmlUsingGetError
		>({
			path: `/api/v1/public/script/${id}/ssr`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags sitemap-controller
	 * @name SitemapGetSitemapUsingGet
	 * @summary getSitemap
	 * @request GET:/api/v1/public/sitemap/sitemap-script.xml
	 * @secure
	 * @response `200` `KlSitemapGetSitemapUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	sitemapGetSitemapUsingGet = (params: RequestParams = {}) =>
		this.http.request<
			KlSitemapGetSitemapUsingGetData,
			KlSitemapGetSitemapUsingGetError
		>({
			path: `/api/v1/public/sitemap/sitemap-script.xml`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Public filters
	 * @name UserPublicFilterGetUsersUsingGet
	 * @summary getUsers
	 * @request GET:/api/v1/public/user-filter
	 * @secure
	 * @response `200` `KlUserPublicFilterGetUsersUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	userPublicFilterGetUsersUsingGet = (
		query: KlUserPublicFilterGetUsersUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlUserPublicFilterGetUsersUsingGetData,
			KlUserPublicFilterGetUsersUsingGetError
		>({
			path: `/api/v1/public/user-filter`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Public filters
	 * @name UserPublicFilterFindByIdUsingGet
	 * @summary findById
	 * @request GET:/api/v1/public/user-filter/{id}
	 * @secure
	 * @response `200` `KlUserPublicFilterFindByIdUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	userPublicFilterFindByIdUsingGet = (id: number, params: RequestParams = {}) =>
		this.http.request<
			KlUserPublicFilterFindByIdUsingGetData,
			KlUserPublicFilterFindByIdUsingGetError
		>({
			path: `/api/v1/public/user-filter/${id}`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-vip-public-controller
	 * @name ScriptVipPublicGetScriptVipUsingGet
	 * @summary getScriptVIP
	 * @request GET:/api/v1/public/vip/script/{id}
	 * @secure
	 * @response `200` `KlScriptVipPublicGetScriptVipUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptVipPublicGetScriptVipUsingGet = (
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptVipPublicGetScriptVipUsingGetData,
			KlScriptVipPublicGetScriptVipUsingGetError
		>({
			path: `/api/v1/public/vip/script/${id}`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Public filters
	 * @name ScriptShortPublicFilterGetPublicScriptsUsingGet
	 * @summary getPublicScripts
	 * @request GET:/api/v2/public/script-short-filter
	 * @secure
	 * @response `200` `KlScriptShortPublicFilterGetPublicScriptsUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptShortPublicFilterGetPublicScriptsUsingGet = (
		query: KlScriptShortPublicFilterGetPublicScriptsUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptShortPublicFilterGetPublicScriptsUsingGetData,
			KlScriptShortPublicFilterGetPublicScriptsUsingGetError
		>({
			path: `/api/v2/public/script-short-filter`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
}
