import type { KlUserData } from '@/api/generated/data-contracts';

const isUserProfileSet = (user: KlUserData | undefined): boolean => {
  if (!user) {
    return false;
  }
  // for public routes we should allow not authorized users
  if (!user.id) {
    return true;
  }

  return !!user.phoneNumber && !!user.firstName && !!user.lastName && !!user.username;
};

export default isUserProfileSet;
