import React, { ReactElement, Suspense } from 'react';
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import {
  DashboardPaths, Paths, ShopPaths, SystemPaths,
} from './const/routes';
import ProtectedRoute from '@/components/Routes/ProtectedRoute';

import { AuthContextProvider } from '@/api/AuthProvider';

import { ParallaxProvider } from 'react-scroll-parallax';
import { StrapiPage } from '@/const/cmsInterface';
import { IMenuItem } from '@/ui-kit/types';

import { LocationHistory } from '@/components/Routes/LocationHistoryContext';

// @todo do not import it using lazy load, refactor it to use as helper
import ScreenplayPageWrapper from '@/components/Wrappers/ScreenplayPageWrapper';
import StrapiCompetitionFilterWrapperComponent from '@/components/Cms/StrapiCompetitionFilterWrapperComponent';
import UserWrapper from '@/components/Wrappers/UserWrapper';
import ScreenplayFullPageWrapper from '@/components/Wrappers/ScreenplayFullPageWrapper';
import GeneralLoader from '@/components/GeneralLoader';

import Login from '@/pages/Login';
import Logout from '@/pages/Logout';
import SignUp from '@/pages/SignUp';
import NotFound from '@/pages/NotFound';
import Unavailable from '@/pages/Unavailable';
import UnderConstruction from '@/pages/UnderConstruction';
import { renderPage } from '@/ui-kit/Components/Layout/renderPage';
import { getPages } from '@/const/pages';

// use lazy load for that page to move pdf viewer lib to another chunk
const ScreenplayReadPage = React.lazy(async () => import('@/pages/Screenplays/ScreenplayReadPage'));
const ScreenplayListenPage = React.lazy(async () => import('@/pages/Screenplays/ScreenplayListenPage'));
const ScreenplayPodcastPage = React.lazy(async () => import('@/pages/Screenplays/ScreenplayPodcastPage'));
const ScreenplayInterviewPage = React.lazy(async () => import('@/pages/Screenplays/ScreenplayInterviewPage'));
const Page = React.lazy(async () => import('@/ui-kit/Components/Layout/Page'));
const CompetitionByReference = React.lazy(async () => import('@/pages/CompetitionByReference'));
const UploadScreenplayFinal = React.lazy(async () => import('@/pages/UploadScreenplay/UploadScreenplayFinal'));
const UploadScreenplay = React.lazy(async () => import('@/pages/UploadScreenplay/UploadScreenplay'));

const NewUserProfilePage = React.lazy(async () => import('@/pages/UserProfileSettings/NewUserProfilePage'));
const RestoreUserPage = React.lazy(async () => import('@/pages/UserProfileSettings/RestoreUserPage'));
const InviteCodePage = React.lazy(async () => import('@/pages/InviteFriends/InviteCodePage'));
const Dashboard = React.lazy(async () => import('@/pages/Dashboard/Dashboard'));
const UserProfilePage = React.lazy(async () => import('@/pages/UserProfileSettings/UserProfilePage'));
const MyLabsPage = React.lazy(async () => import('@/pages/MyLab/MyLabsPage'));
const AccountPage = React.lazy(async () => import('@/pages/Account/Account'));
const BillingPage = React.lazy(async () => import('@/pages/Billing/BillingPage'));
const EditScreenplayPage = React.lazy(async () => import('@/pages/EditScreenplay/EditScreenplayPage'));
const InviteFriendsPage = React.lazy(async () => import('@/pages/InviteFriends/InviteFriendsPage'));
const MyScreenplaysPage = React.lazy(async () => import('@/pages/MyScreenplays/MyScreenplaysPage'));
const NewOrderPage = React.lazy(async () => import('@/pages/ScreenplayCoverage/NewOrderPage'));
const ConsultationServicePage = React.lazy(async () => import('@/pages/ConsultationService/ConsultationServicePage'));
const ScreenplayAnalyticsPage = React.lazy(async () => import('@/pages/ScreenplayAnalyticsPage/ScreenplayAnalyticsPage'));
const PromotePage = React.lazy(async () => import('@/pages/ScreenplayCoverage/PromotePage'));
const ScreenplayCoveragePage = React.lazy(async () => import('@/pages/ScreenplayCoverage/ScreenplayCoveragePage'));
const SelectScriptPage = React.lazy(async () => import('@/pages/ScreenplayCoverage/SelectScriptPage'));
const RewardsPage = React.lazy(async () => import('@/pages/Rewards/RewardsPage'));
const ShopPage = React.lazy(async () => import('@/pages/Shop/ShopPage'));
const ProductOrderPage = React.lazy(async () => import('@/pages/Shop/ProductOrderPage'));
const ScreenplayInnerComponent = React.lazy(async () => import('@/pages/Screenplays/ScreenplayInnerComponent'));
const Seo = React.lazy(async () => import('@/ui-kit/Components/Layout/Seo'));

window.zESettings = {
  webWidget: {
    zIndex: 9990,
  },
  contactOptions: {
    enabled: true,
    contactButton: { '*': 'Get in touch' },
  },
};

export const getMenuItems = (pages?: StrapiPage[]) => {
  return pages
    ?.filter((page) => page.isVisibleInHeader)
    ?.map((page) => {
      return {
        id: page.slug,
        path: page.slug,
        name: page?.title ?? '',
        tag: typeof page?.isVisibleInHeader === 'boolean' ? [] : page?.isVisibleInHeader,
      } as IMenuItem;
    });
};

export const getFooterItems = (pages?: StrapiPage[]) => {
  return pages
    ?.filter((page) => page.isVisibleInFooter).map((page) => {
      return {
        id: page.slug,
        path: page.slug,
        name: page?.title ?? '',
      } as IMenuItem;
    });
};

export default function App(): ReactElement {
  const {
    VITE_REACT_KEYCLOCK_URL,
    VITE_REACT_KEYCLOCK_PUBLIC_REALM,
    VITE_REACT_KEYCLOCK_PUBLIC_CLIENT_ID,
    VITE_DISABLE_CONSOLE,
  } = import.meta.env;
  const config = {
    url: `${VITE_REACT_KEYCLOCK_URL}`,
    realm: `${VITE_REACT_KEYCLOCK_PUBLIC_REALM}`,
    clientId: `${VITE_REACT_KEYCLOCK_PUBLIC_CLIENT_ID}`,
    enableLogging: true,
  };

  if (VITE_DISABLE_CONSOLE) {
    // @ts-ignore
    window.console[VITE_DISABLE_CONSOLE] = () => {};
  }
  const pages = getPages() as StrapiPage[];
  const menuItems = getMenuItems(pages);
  const footerItems = getFooterItems(pages);

  const mapComponent = (page: StrapiPage) => {
    if (!page) {
      return null;
    }

    return renderPage(page);
  };

  const renderRoutes = (): ReactElement => {
    if (import.meta.env?.VITE_IS_UNDER_CONSTRUCTION === 'true') {
      return (
        <Routes>
          <Route
            path="*"
            element={(
              <UnderConstruction />
            )}
          />
        </Routes>
      );
    }

    return (
      <Routes>
        {
          pages.map((page): ReactElement => {
            return (
              <Route
                path={page.slug}
                key={page.slug}
                element={mapComponent(page)}
              />
            );
          })
        }
        <Route
          path={`${SystemPaths.DASHBOARD}/*`}
          element={(
            <ProtectedRoute allowedRoles={['GUEST', 'PUBLIC_USER', 'SCREENWRITER']}>
              <Routes>
                <Route
                  path={DashboardPaths.ROOT}
                  index
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isSiderVisible
                      headerVariant="dashboard"
                    >
                      <Dashboard />
                    </Page>
                                    )}
                />
                <Route
                  path={DashboardPaths.PROFILE_SETTINGS}
                  index
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isSiderVisible
                      headerVariant="dashboard"
                    >
                      <UserProfilePage />
                    </Page>
                                    )}
                />
                <Route
                  path={`${DashboardPaths.MY_LAB}/:competitionId`}
                  index
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isSiderVisible
                      headerVariant="dashboard"
                    >
                      <StrapiCompetitionFilterWrapperComponent>
                        {({ currentCompetition }) => {
                          if (!currentCompetition) {
                            return <Navigate to={SystemPaths.NOT_FOUND} replace />;
                          }
                          return (
                            <UserWrapper>
                              {(user) => (
                                <MyLabsPage user={user} />
                              )}
                            </UserWrapper>
                          );
                        }}
                      </StrapiCompetitionFilterWrapperComponent>
                    </Page>
                                    )}
                />
                <Route
                  path={DashboardPaths.ACCOUNT}
                  index
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={getFooterItems()}
                      isSiderVisible
                      headerVariant="dashboard"
                    >
                      <AccountPage />
                    </Page>
                                    )}
                />
                <Route
                  path={DashboardPaths.BILLING}
                  index
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isSiderVisible
                      headerVariant="dashboard"
                    >
                      <BillingPage />
                    </Page>
                                    )}
                />
                <Route
                  path={DashboardPaths.MY_SCREENPLAYS}
                  index
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isSiderVisible
                      headerVariant="dashboard"
                    >

                      <StrapiCompetitionFilterWrapperComponent
                        isVisibleForCompetition={[{
                          roundSequence: 0,
                          stepSequence: 0,
                        }]}
                      >
                        {({ currentCompetition }) => (
                          <MyScreenplaysPage currentCompetition={currentCompetition} />
                        )}
                      </StrapiCompetitionFilterWrapperComponent>
                    </Page>
                                    )}
                />
                <Route
                  path={DashboardPaths.SELECT_SCREENPLAY}
                  index
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isDisabledFooter
                      headerVariant="simple"
                      headerType="simple"
                    >
                      <SelectScriptPage isSimplePage />
                    </Page>
                                    )}
                />
                <Route
                  path={`${DashboardPaths.EDIT_SCREENPLAY}/:scriptId`}
                  index
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isSiderVisible
                      headerVariant="dashboard"
                    >
                      <EditScreenplayPage />
                    </Page>
                                    )}
                />
                <Route
                  path={`${DashboardPaths.SCREENPLAY_ANALYTICS}/:scriptId`}
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={getFooterItems()}
                      isSiderVisible
                      headerVariant="dashboard"
                    >
                      <ScreenplayAnalyticsPage />
                    </Page>
                                    )}
                />
                <Route
                  path={DashboardPaths.PROMOTE_SCREENPLAY}
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isDisabledFooter
                      headerVariant="simple"
                      headerType="simple"
                    >
                      <PromotePage isSimplePage />
                    </Page>
                                    )}
                />
                <Route
                  path={DashboardPaths.SCRIPT_COVERAGE}
                  index
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isSiderVisible
                      headerVariant="dashboard"
                    >
                      <ScreenplayCoveragePage />
                    </Page>
                                    )}
                />
                <Route
                  path={`${DashboardPaths.SCRIPT_COVERAGE}/:scriptIdCoverage`}
                  index
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isSiderVisible
                      headerVariant="dashboard"
                    >
                      <ScreenplayCoveragePage />
                    </Page>
                                    )}
                />
                <Route
                  path={DashboardPaths.NEW_SERVICE_ORDER}
                  index
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isDisabledFooter
                      headerVariant="simple"
                      headerType="simple"
                    >
                      <NewOrderPage isSimplePage />
                    </Page>
                                    )}
                />
                <Route
                  path={DashboardPaths.CONSULTATION_SERVICE}
                  index
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isSiderVisible
                      headerVariant="dashboard"
                    >
                      <ConsultationServicePage />
                    </Page>
                                    )}
                />
                <Route
                  path={DashboardPaths.INVITE_FRIENDS}
                  index
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isSiderVisible
                      headerVariant="dashboard"
                    >
                      <InviteFriendsPage />
                    </Page>
                                    )}
                />
                <Route
                  path={DashboardPaths.NEW_USER_INVITE_FRIENDS}
                  index
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isDisabledFooter
                      headerVariant="simple"
                      headerType="simple"
                    >
                      <InviteFriendsPage isSimplePage />
                    </Page>
                                    )}
                />
                <Route
                  path={`${DashboardPaths.UPLOAD_SCREENPLAY}:id`}
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isDisabledFooter
                    >
                      <StrapiCompetitionFilterWrapperComponent
                        isVisibleForCompetition={[{
                          roundSequence: 0,
                          stepSequence: 0,
                          mustBeActive: true,
                        }]}
                        fallback={<NotFound />}
                      >
                        {() => (
                          <UploadScreenplay />
                        )}
                      </StrapiCompetitionFilterWrapperComponent>
                    </Page>
                                    )}
                />
                <Route
                  path={DashboardPaths.UPLOAD_SCREENPLAY_FINAL}
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isDisabledFooter
                    >
                      <StrapiCompetitionFilterWrapperComponent
                        isVisibleForCompetition={[{
                          roundSequence: 0,
                          stepSequence: 0,
                        }]}
                      >
                        {() => (
                          <UploadScreenplayFinal />
                        )}
                      </StrapiCompetitionFilterWrapperComponent>
                    </Page>
                                    )}
                />
                <Route
                  path={DashboardPaths.WELCOME_NEW_USER_SETTINGS}
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isDisabledFooter
                      headerVariant="simple"
                      headerType="simple"
                    >
                      <NewUserProfilePage />
                    </Page>
                  )}
                />

                <Route
                  path={DashboardPaths.RESTORE_USER}
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={footerItems}
                      isDisabledFooter
                      headerVariant="simple"
                      headerType="simple"
                    >
                      <RestoreUserPage />
                    </Page>
                  )}
                />
                <Route
                  path={DashboardPaths.REWARDS}
                  index
                  element={(
                    <Page
                      menuItems={menuItems as IMenuItem[]}
                      footerItems={getFooterItems()}
                      isSiderVisible
                      headerVariant="dashboard"
                    >
                      <RewardsPage />
                    </Page>
                                    )}
                />
                <Route path={DashboardPaths.LOGIN} element={<Login />} />
                <Route path={DashboardPaths.SIGN_UP} element={<SignUp />} />
                <Route path={DashboardPaths.LOGOUT} element={<Logout />} />

                <Route path="*" element={<Navigate to={SystemPaths.NOT_FOUND} replace />} />
              </Routes>
            </ProtectedRoute>
                    )}
        />
        <Route
          path={ShopPaths.SHOP}
          index
          element={(
            <UserWrapper isForceEnabled>
              {(user) => (
                <Page
                  menuItems={menuItems as IMenuItem[]}
                  footerItems={footerItems}
                  headerVariant="shop"
                  headerType="simple"
                >
                  <ShopPage user={user} />
                </Page>
              )}
            </UserWrapper>
                    )}
        />

        <Route
          path={`${ShopPaths.SHOP}${ShopPaths.ORDER}`}
          index
          element={(
            <UserWrapper isForceEnabled>
              {(user) => (
                <Page
                  menuItems={menuItems as IMenuItem[]}
                  footerItems={footerItems}
                  headerVariant="shop"
                  headerType="simple"
                >
                  <ProductOrderPage user={user} />
                </Page>
              )}
            </UserWrapper>
                    )}
        />

        <Route
          path={`${SystemPaths.PUBLIC_SCREENPLAYS}/:id/read`}
          element={(
            <Suspense fallback={<span />}>

              <UserWrapper isForceEnabled>
                {(user) => (

                  <ScreenplayPageWrapper>
                    {(screenplayData) => (
                      <StrapiCompetitionFilterWrapperComponent
                        isVisibleForCompetition={screenplayData?.isArchived ? [] : [{
                          roundSequence: 1,
                          stepSequence: 0,
                          mustBeActive: true,
                        }, {
                          roundSequence: 2,
                          stepSequence: 0,
                          mustBeActive: true,
                        }, {
                          roundSequence: 3,
                          stepSequence: 0,
                          mustBeActive: true,
                        }]}
                      >
                        {(competitionHook) => {
                          return (
                            <>
                              {' '}
                              <Seo
                                title={`${screenplayData?.nameTitleCase} | Kinolime`}
                                description={`${screenplayData?.logline}`}
                                slug={`${SystemPaths.PUBLIC_SCREENPLAYS}/${screenplayData?.reference}/read`}
                                inIndex
                              />
                              <ScreenplayFullPageWrapper
                                reference={screenplayData?.reference}
                              >
                                {(scriptData) => (
                                  <ScreenplayReadPage
                                    scriptData={scriptData}
                                    scriptPublicData={screenplayData}
                                    user={user}
                                    competitionHook={competitionHook}
                                  />
                                )}
                              </ScreenplayFullPageWrapper>
                            </>
                          );
                        }}
                      </StrapiCompetitionFilterWrapperComponent>
                    )}
                  </ScreenplayPageWrapper>
                )}
              </UserWrapper>

            </Suspense>
          )}
        />

        <Route
          path={`${SystemPaths.PUBLIC_SCREENPLAYS}/:id/listen`}
          element={(
            <Suspense fallback={<span />}>

              <UserWrapper isForceEnabled>
                {(user) => (

                  <ScreenplayPageWrapper>
                    {(screenplayData) => (
                      <StrapiCompetitionFilterWrapperComponent
                        isVisibleForCompetition={screenplayData?.isArchived ? [] : [{
                          roundSequence: 1,
                          stepSequence: 0,
                          mustBeActive: true,
                        }, {
                          roundSequence: 2,
                          stepSequence: 0,
                          mustBeActive: true,
                        }, {
                          roundSequence: 3,
                          stepSequence: 0,
                          mustBeActive: true,
                        }]}
                      >
                        {(competitionHook) => (
                          <>
                            <Seo
                              title={`${screenplayData?.nameTitleCase} | Kinolime`}
                              description={`${screenplayData?.logline}`}
                              slug={`${SystemPaths.PUBLIC_SCREENPLAYS}/${screenplayData?.reference}/listen`}
                              inIndex
                            />
                            <ScreenplayFullPageWrapper reference={screenplayData?.reference}>
                              {(scriptData) => (
                                <ScreenplayListenPage
                                  scriptData={scriptData}
                                  scriptPublicData={screenplayData}
                                  user={user}
                                  competitionHook={competitionHook}
                                />
                              )}
                            </ScreenplayFullPageWrapper>
                          </>
                        )}
                      </StrapiCompetitionFilterWrapperComponent>
                    )}
                  </ScreenplayPageWrapper>
                )}
              </UserWrapper>

            </Suspense>
          )}
        />

        <Route
          path={`${SystemPaths.PUBLIC_SCREENPLAYS}/:id/podcast`}
          element={(
            <Suspense fallback={<span />}>

              <UserWrapper isForceEnabled>
                {(user) => (
                  <ScreenplayPageWrapper>
                    {(screenplayData) => (
                      <StrapiCompetitionFilterWrapperComponent
                        isVisibleForCompetition={screenplayData?.isArchived ? [] : [{
                          roundSequence: 1,
                          stepSequence: 0,
                        },
                        {
                          roundSequence: 2,
                          stepSequence: 0,
                        },
                        {
                          roundSequence: 3,
                          mustBeActive: true,
                          stepSequence: 0,
                        }]}
                      >
                        {(competitionHook) => (
                          <>
                            <Seo
                              title={`${screenplayData?.nameTitleCase} | Kinolime`}
                              description={`${screenplayData?.logline}`}
                              slug={`${SystemPaths.PUBLIC_SCREENPLAYS}/${screenplayData?.reference}/podcast`}
                              inIndex
                            />
                            <ScreenplayPodcastPage
                              scriptDataPublic={screenplayData}
                              user={user}
                              competitionHook={competitionHook}
                            />
                          </>
                        )}
                      </StrapiCompetitionFilterWrapperComponent>
                    )}
                  </ScreenplayPageWrapper>
                )}
              </UserWrapper>

            </Suspense>
          )}
        />
        <Route
          path={`${SystemPaths.PUBLIC_SCREENPLAYS}/:id/interview`}
          element={(
            <Suspense fallback={<span />}>

              <UserWrapper isForceEnabled>
                {(user) => (
                  <ScreenplayPageWrapper>
                    {(screenplayData) => (
                      <StrapiCompetitionFilterWrapperComponent
                        isVisibleForCompetition={screenplayData?.isArchived ? [] : [{
                          roundSequence: 3,
                          stepSequence: 0,
                          mustBeActive: true,
                          actionShouldBeActive: true,
                        }]}
                        fallback={<NotFound />}
                      >
                        {(competitionHook) => (
                          <>
                            <Seo
                              title={`${screenplayData?.nameTitleCase} | Kinolime`}
                              description={`${screenplayData?.logline}`}
                              slug={`${SystemPaths.PUBLIC_SCREENPLAYS}/${screenplayData?.reference}/interview`}
                              inIndex
                            />
                            <ScreenplayInterviewPage
                              scriptDataPublic={screenplayData}
                              user={user}
                              competitionHook={competitionHook}
                            />
                          </>
                        )}
                      </StrapiCompetitionFilterWrapperComponent>
                    )}
                  </ScreenplayPageWrapper>
                )}
              </UserWrapper>

            </Suspense>
          )}
        />
        <Route path={`${SystemPaths.INVITE_CODE}/:id`} element={<InviteCodePage />} />
        <Route
          path={`${SystemPaths.PUBLIC_SCREENPLAYS}/:id`}
          element={
            <Suspense fallback={<span />}>
              <ScreenplayPageWrapper>
                {(screenplayData) => (
                  <Page
                    menuItems={menuItems as IMenuItem[]}
                    footerItems={footerItems}
                    seo={{
                      title: `${screenplayData?.nameTitleCase} | Kinolime`,
                      description: `${screenplayData?.logline}`,
                      inIndex: true,
                      slug: `${SystemPaths.PUBLIC_SCREENPLAYS}/${screenplayData?.reference}`,
                    }}
                  >
                    <UserWrapper>
                      {(user) => (
                        <ScreenplayInnerComponent scriptData={screenplayData} user={user} />
                      )}
                    </UserWrapper>

                  </Page>
                )}
              </ScreenplayPageWrapper>
            </Suspense>
          }
        />
        <Route path="*" element={<Navigate to={SystemPaths.NOT_FOUND} replace />} />
        <Route path={`${Paths.COMPETITION}/:activeCompetitionId`} element={<CompetitionByReference />} />
        <Route path={SystemPaths.NOT_FOUND} element={<NotFound />} />
        <Route path={SystemPaths.UNAVAILABLE} element={<Unavailable />} />
      </Routes>
    );
  };

  return (
    <BrowserRouter future={{ v7_startTransition: true }}>
      <LocationHistory />
      <ParallaxProvider>
        <AuthContextProvider config={config}>
          <Suspense fallback={<GeneralLoader />}>
            {renderRoutes()}
          </Suspense>
        </AuthContextProvider>
      </ParallaxProvider>
    </BrowserRouter>
  );
}
