/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	KlItemProcessRequest,
	KlScriptReadingProcessProcessingUsingPostData,
	KlScriptReadingProcessProcessingUsingPostError,
	KlScriptReadingProcessRecordFromHistoryUsingGetData,
	KlScriptReadingProcessRecordFromHistoryUsingGetError
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class ScriptReadingProcess<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http
	}

	/**
	 * No description
	 *
	 * @tags script-reading-process-controller
	 * @name ScriptReadingProcessProcessingUsingPost
	 * @summary processing
	 * @request POST:/api/v1/script-reading-process
	 * @secure
	 * @response `200` `KlScriptReadingProcessProcessingUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptReadingProcessProcessingUsingPost = (
		payload: KlItemProcessRequest,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptReadingProcessProcessingUsingPostData,
			KlScriptReadingProcessProcessingUsingPostError
		>({
			path: `/api/v1/script-reading-process`,
			method: 'POST',
			body: payload,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-reading-process-controller
	 * @name ScriptReadingProcessRecordFromHistoryUsingGet
	 * @summary recordFromHistory
	 * @request GET:/api/v1/script-reading-process/{id}
	 * @secure
	 * @response `200` `KlScriptReadingProcessRecordFromHistoryUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptReadingProcessRecordFromHistoryUsingGet = (
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptReadingProcessRecordFromHistoryUsingGetData,
			KlScriptReadingProcessRecordFromHistoryUsingGetError
		>({
			path: `/api/v1/script-reading-process/${id}`,
			method: 'GET',
			secure: true,
			...params
		})
}
