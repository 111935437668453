const Twitter = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="currentColor">
    <g clipPath="url(#clip0_179722_86777)">
      <path fillRule="evenodd" clipRule="evenodd" d="M25.0543 11.0396H22.5997L18.5563 15.6602L15.0621 11.0396H10L16.0461 18.9492L10.3129 25.5028H12.771L17.1934 20.4476L21.063 25.5028H26L19.6932 17.1656L25.0543 11.0396ZM23.0969 24.0356H21.7375L12.8614 12.4303H14.3216L23.0969 24.0356Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18ZM1.63638 18C1.63638 27.0374 8.96259 34.3636 18 34.3636C27.0374 34.3636 34.3636 27.0374 34.3636 18C34.3636 8.96259 27.0374 1.63638 18 1.63638C8.96259 1.63638 1.63638 8.96259 1.63638 18Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_179722_86777">
        <rect width="36" height="36" fill="none" />
      </clipPath>
    </defs>
  </svg>
);

export default Twitter;
