import { useGetScriptByReferenceUsingGet } from '@/api/http/screenplay';
import { KlUploadScriptData } from '@/api/generated/data-contracts';
import React from 'react';

interface ScreenplayFullPageWrapperProperties {
  reference: string | undefined
  children: (scriptData: KlUploadScriptData | undefined) => React.ReactNode | null;
}

const ScreenplayFullPageWrapper: React.FC<ScreenplayFullPageWrapperProperties> = ({
  children,
  reference,
}) => {
  const {
    data: scriptData,
    isLoading: scriptDataIsLoading,
  } = useGetScriptByReferenceUsingGet(reference);

  if (scriptDataIsLoading) {
    return null;
  }

  return (
    <>
      {children(scriptData)}
    </>
  );
};

export default ScreenplayFullPageWrapper;
