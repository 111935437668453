const CircleUser = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_12281_32247)">
      <path
        d="M28.582 28.0969C27.2391 24.8133 24.0117 22.5 20.25 22.5H15.75C11.9883 22.5 8.76094 24.8133 7.41797 28.0969C4.91484 25.4672 3.375 21.9164 3.375 18C3.375 9.92109 9.92109 3.375 18 3.375C26.0789 3.375 32.625 9.92109 32.625 18C32.625 21.9164 31.0852 25.4672 28.582 28.0969ZM25.7625 30.3961C23.5125 31.8094 20.8547 32.625 18 32.625C15.1453 32.625 12.4875 31.8094 10.2305 30.3961C10.7437 27.8156 13.0219 25.875 15.75 25.875H20.25C22.9781 25.875 25.2563 27.8156 25.7695 30.3961H25.7625ZM18 36C22.7739 36 27.3523 34.1036 30.7279 30.7279C34.1036 27.3523 36 22.7739 36 18C36 13.2261 34.1036 8.64773 30.7279 5.27208C27.3523 1.89642 22.7739 0 18 0C13.2261 0 8.64773 1.89642 5.27208 5.27208C1.89642 8.64773 0 13.2261 0 18C0 22.7739 1.89642 27.3523 5.27208 30.7279C8.64773 34.1036 13.2261 36 18 36ZM18 16.875C17.2541 16.875 16.5387 16.5787 16.0113 16.0512C15.4838 15.5238 15.1875 14.8084 15.1875 14.0625C15.1875 13.3166 15.4838 12.6012 16.0113 12.0738C16.5387 11.5463 17.2541 11.25 18 11.25C18.7459 11.25 19.4613 11.5463 19.9887 12.0738C20.5162 12.6012 20.8125 13.3166 20.8125 14.0625C20.8125 14.8084 20.5162 15.5238 19.9887 16.0512C19.4613 16.5787 18.7459 16.875 18 16.875ZM11.8125 14.0625C11.8125 15.7035 12.4644 17.2773 13.6248 18.4377C14.7852 19.5981 16.359 20.25 18 20.25C19.641 20.25 21.2148 19.5981 22.3752 18.4377C23.5356 17.2773 24.1875 15.7035 24.1875 14.0625C24.1875 12.4215 23.5356 10.8477 22.3752 9.68728C21.2148 8.5269 19.641 7.875 18 7.875C16.359 7.875 14.7852 8.5269 13.6248 9.68728C12.4644 10.8477 11.8125 12.4215 11.8125 14.0625Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_12281_32247">
        <rect width="100%" height="100%" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CircleUser;
