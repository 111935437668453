/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	KlRewardHistoryGetHistoryUsingGetData,
	KlRewardHistoryGetHistoryUsingGetError,
	KlRewardHistoryGetHistoryUsingGetParams
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Reward<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http
	}

	/**
	 * No description
	 *
	 * @tags reward-history-controller
	 * @name RewardHistoryGetHistoryUsingGet
	 * @summary getHistory
	 * @request GET:/api/v1/reward/history/{code}
	 * @secure
	 * @response `200` `KlRewardHistoryGetHistoryUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	rewardHistoryGetHistoryUsingGet = (
		{ code, ...query }: KlRewardHistoryGetHistoryUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlRewardHistoryGetHistoryUsingGetData,
			KlRewardHistoryGetHistoryUsingGetError
		>({
			path: `/api/v1/reward/history/${code}`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
}
