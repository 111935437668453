import React, { PropsWithChildren } from 'react';
import Typography from 'antd/es/typography';
import { TitleProps } from 'antd/es/typography/Title';
import './title.less';
import classNames from 'classnames';
import {
  Color, FontWeight, TextTransform, TitleVariant,
} from '@/ui-kit/types';

export interface UiKitTitleProps extends TitleProps {
  variant?: typeof TitleVariant;
  fontWeight?: typeof FontWeight;
  textTransform?: typeof TextTransform;
  color?: typeof Color;
}

const Title: React.FC<PropsWithChildren<UiKitTitleProps>> = ({
  children,
  className,
  variant,
  fontWeight,
  textTransform,
  color = 'dark',
  ...rest
}) => (
  <Typography.Title
    className={
      classNames('ui-kit-title', className, variant, fontWeight, color, textTransform)
    }
    {...rest}
  >
    {children}
  </Typography.Title>
);

export default Title;
