import { KlCompetitionRoundStepData } from '@/api/generated/data-contracts';
import { CircleCheckmarkIcon, CircleIcon, CircleLimeIcon } from '@/ui-kit/Base/Iconography';
import dayjs from 'dayjs';
import Paragraph from '../../../ui-kit/Base/Typography/Paragraph';
import Text from '../../../ui-kit/Base/Typography/Text';
import { useTranslation } from 'react-i18next';

export const useTimeLineItems = () => {
  const { t } = useTranslation();

  const getTimelineItems = (steps: KlCompetitionRoundStepData[], activeStepIndex: number) => {
    const timeLineItems = steps.map((step, index) => {
      const getIcon = (isActive: boolean, isPassed: boolean) => {
        if (isPassed) {
          return (
            <CircleCheckmarkIcon variant="lime" size={16} />
          );
        }

        if (isActive) {
          return (
            <CircleLimeIcon variant="lime" size={16} />
          );
        }

        return (
          <CircleIcon variant="white" size={16} />
        );
      };

      const getSubtitle = (stepData: KlCompetitionRoundStepData) => {
        const timeToCompetitionStart = dayjs(stepData?.activeFrom as string).diff(dayjs(), 'minutes', true);
        const timeToCompetitionStartDays = Math.round(dayjs(stepData?.activeFrom as string).diff(dayjs(), 'days', true));
        const competitionDuration = dayjs(stepData?.activeTo as string).diff(dayjs(stepData?.activeFrom as string), 'days', true);

        if (stepData?.isActive) {
          return (
            <Paragraph className="white">
              {t('Duration:')}
              {' '}
              {competitionDuration}
              {' '}
              {competitionDuration > 1 ? 'days' : 'day'}
            </Paragraph>
          );
        }

        if (timeToCompetitionStart < 0) {
          return (
            <Paragraph className="white">
              {t('Closed')}
            </Paragraph>
          );
        }

        if (timeToCompetitionStartDays === 0) {
          return (
            <Paragraph className="white">
              {t('Opens tomorrow')}
            </Paragraph>
          );
        }

        return (
          <Paragraph className="white">
            {t('Opens in')}
            {' '}
            {dayjs(stepData?.activeFrom as string).diff(dayjs(), 'days')}
            {' '}
            {t('days')}
          </Paragraph>
        );
      };

      const isPassed = () => {
        if (activeStepIndex < 0) {
          return true;
        }
        return step?.isActive ? false : index < activeStepIndex;
      };

      return {
        dot: getIcon(step?.isActive ?? false, isPassed()),
        children: (
          <div className={step?.isActive ? 'active' : isPassed() ? 'passed' : ''}>
            <Text className={step?.isActive ? 'white' : 'light'}>{step.name}</Text>
            {getSubtitle(step)}
          </div>
        ),
      };
    });

    return timeLineItems;
  };

  return {
    getTimelineItems,
  };
};
