const ArrowPartCircleRight = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6742_2567)">
      <path d="M31.0887 20.8252C30.3137 20.4502 29.3762 20.7815 29.0012 21.5565C27.9637 23.7127 26.3512 25.5377 24.3324 26.8377C22.2637 28.169 19.8637 28.8752 17.3887 28.8752C10.2887 28.8752 4.51367 23.1002 4.51367 16.0002C4.51367 8.90024 10.2887 3.12524 17.3887 3.12524C19.8637 3.12524 22.2637 3.83149 24.3387 5.15649C26.3574 6.45024 27.9699 8.28149 29.0074 10.4377C29.3824 11.2127 30.3137 11.544 31.0949 11.169C31.8699 10.794 32.2012 9.86274 31.8262 9.08149C30.5387 6.40024 28.5387 4.13149 26.0262 2.52524C23.4512 0.875244 20.4637 0.000244141 17.3887 0.000244141C13.1137 0.000244141 9.09492 1.66274 6.07617 4.68774C3.05117 7.70649 1.38867 11.7252 1.38867 16.0002C1.38867 20.2752 3.05117 24.294 6.07617 27.3127C9.10117 30.3377 13.1137 32.0002 17.3887 32.0002C20.4637 32.0002 23.4512 31.1252 26.0262 29.469C28.5324 27.8565 30.5387 25.5877 31.8262 22.9127C32.1949 22.1315 31.8699 21.2002 31.0887 20.8252Z" fill="currentColor" />
      <path d="M18.2195 22.0815C18.5258 22.3878 18.9258 22.5378 19.3258 22.5378C19.7258 22.5378 20.1258 22.3878 20.432 22.0815L24.3633 18.1503C24.9383 17.5753 25.2508 16.8128 25.2508 16.0065C25.2508 15.2003 24.932 14.4315 24.3633 13.8628L20.432 9.93151C19.8195 9.31901 18.832 9.31901 18.2195 9.93151C17.607 10.544 17.607 11.5315 18.2195 12.144L20.5258 14.4503H11.6445C10.782 14.4503 10.082 15.1503 10.082 16.0128C10.082 16.8753 10.782 17.5753 11.6445 17.5753H20.532L18.2258 19.8815C17.6133 20.4815 17.6133 21.469 18.2195 22.0815Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_6742_2567">
        <rect width="100%" height="100%" fill="currentColor" transform="translate(0.5 0.000244141)" />
      </clipPath>
    </defs>
  </svg>

);

export default ArrowPartCircleRight;
