const Question = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2376_34786)">
      <path d="M10 0.5C4.47301 0.5 0 4.9725 0 10.5C0 16.0269 4.4725 20.5 10 20.5C15.527 20.5 20 16.0275 20 10.5C20 4.97301 15.5275 0.5 10 0.5ZM10 19.1046C5.25539 19.1046 1.39535 15.2446 1.39535 10.5C1.39535 5.75535 5.25539 1.89535 10 1.89535C14.7446 1.89535 18.6046 5.75535 18.6046 10.5C18.6046 15.2446 14.7446 19.1046 10 19.1046Z" fill="currentColor" />
      <path d="M9.7041 13.1533C9.15125 13.1533 8.70371 13.614 8.70371 14.1668C8.70371 14.7065 9.13809 15.1804 9.7041 15.1804C10.2701 15.1804 10.7176 14.7065 10.7176 14.1668C10.7176 13.614 10.2569 13.1533 9.7041 13.1533ZM9.87519 5.47926C8.09816 5.47926 7.28207 6.53234 7.28207 7.24312C7.28207 7.75648 7.71645 7.99344 8.07184 7.99344C8.78266 7.99344 8.49309 6.97984 9.8357 6.97984C10.4938 6.97984 11.0204 7.26945 11.0204 7.87496C11.0204 8.58574 10.2832 8.99379 9.84887 9.36234C9.46711 9.69137 8.96695 10.2311 8.96695 11.3631C8.96695 12.0476 9.15125 12.245 9.6909 12.245C10.3359 12.245 10.4675 11.9555 10.4675 11.7053C10.4675 11.0209 10.4807 10.626 11.2046 10.06C11.56 9.78356 12.6789 8.88844 12.6789 7.65113C12.6789 6.41383 11.56 5.47926 9.87519 5.47926Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_2376_34786">
        <rect width="100%" height="100%" fill="none" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>

);

export default Question;
