const ArrowRight = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_179722_86836)">
      <path d="M0 20.2499H27.3825L14.805 32.8273L18 35.9998L36 17.9999L18 0.00012207L14.8275 3.17259L27.3825 15.75H0L0 20.2499Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_179722_86836">
        <rect width="36" height="36" fill="none" />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowRight;
