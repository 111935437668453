import { IMenuItem } from '@/ui-kit/types';
import StrapiBlockWrapper from '@/components/Cms/StrapiBlockWrapper';
import { getPages } from '@/const/pages';
import { StrapiPage } from '@/const/cmsInterface';
import { getFooterItems, getMenuItems } from '@/App';
import React from 'react';

const Page = React.lazy(async () => import('@/ui-kit/Components/Layout/Page'));

export const renderPage = (page: StrapiPage) => {
  const pages = getPages() as StrapiPage[];
  const menuItems = getMenuItems(pages);
  const footerItems = getFooterItems(pages);

  return (
    <Page
      menuItems={menuItems as IMenuItem[]}
      footerItems={footerItems}
      seo={{ ...page?.seo, slug: page?.slug }}
    >
      {page.sections?.map((section) => {
        if (!section.blocks) {
          return null;
        }

        return (
          <div key={section?.id} id={`anchor-${section?.id}`} className={section?.groupId || ''}>
            {section.blocks.map((block) => {
              if (!block) {
                return;
              }
              return (
                <StrapiBlockWrapper block={block} key={block.id} />
              );
            })}
          </div>
        );
      })}
    </Page>
  );
};
