import {
  KlMyLabDto,
  KlMyLabScriptFilterGetScriptsUsingGetParams,
} from '@/api/generated/data-contracts';
import { useApi } from '@/hooks/useApi';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { MyLabScriptFilter } from '@/api/generated/MyLabScriptFilter';
import { options } from '@/api/http/helper';
import { MyLab } from '@/api/generated/MyLab';
import { ContentType } from '@/api/generated/http-client';

export const useMyLabScriptFilterGetScriptsUsingGet = (query: KlMyLabScriptFilterGetScriptsUsingGetParams, pageSize = 10) => {
  const httpClient = useApi();

  return useInfiniteQuery({
    initialPageParam: 0,
    queryKey: ['get:/api/v1/my-lab-script-filter', JSON.stringify(query)],
    queryFn: async ({ pageParam }) => {
      return new MyLabScriptFilter(httpClient)
        .myLabScriptFilterGetScriptsUsingGet({
          ...query,
          pageNumber: pageParam,
          pageSize,
        });
    },
    getNextPageParam: (lastPage, allPages) => {
      const currentPage = allPages?.length ? allPages.length - 1 : 0;
      const hasNextPage = (lastPage.pageable?.totalElements || 0) > (currentPage + 1) * pageSize;

      if (hasNextPage) {
        return currentPage + 1;
      }
    },
    enabled: !!query?.competitionIds?.find((id) => id),
  });
};

export const useMyLabDeleteFromMyLabUsingDelete = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['delete:/api/v1/my-lab'],
    mutationFn: async (params: KlMyLabDto) => {
      return new MyLab(httpClient).myLabDeleteFromMyLabUsingDelete(params, {
        type: ContentType.Json,
      });
    },
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v1/my-lab-script-filter'],
      });
    },
  });

  return mutation;
};

export const useMyLabAddToMyLabUsingPost = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['post:/api/v1/my-lab'],
    mutationFn: async (params: KlMyLabDto) => {
      return new MyLab(httpClient).myLabAddToMyLabUsingPost(params);
    },
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v1/my-lab-script-filter'],
      });
    },
  });

  return mutation;
};
