import {
  useLocation,
} from 'react-router-dom';
import type { KlUserData } from '@/api/generated/data-contracts';
import { DashboardPaths, SystemPaths } from '@/const/routes';
import isUserProfileSet from '@/utils/useIsUserProfileSet';
import { useGetUser } from '@/api/http/user';
import React from 'react';
import { sentrySetUser } from '@/utils/setupSentry';

interface PrivateRouteProperties {
  allowedRoles?: KlUserData['roles'];
  children?: React.ReactElement | React.ReactElement[] | null;
}

// @ts-ignore
const ProtectedRoute: React.FC<PrivateRouteProperties> = ({
  allowedRoles,
  children,
}) => {
  const { data: user, isLoading } = useGetUser();
  const isProfileSet = isUserProfileSet(user);
  const isUserMarkedAsDeleted = user?.deletedAt || false;
  const isRoleAllowed = allowedRoles?.filter((role) => user?.roles?.includes(role));
  const location = useLocation();
  const { pathname } = location;

  if (isLoading) {
    return null;
  }

  const isLogoutPage = pathname.includes(DashboardPaths.LOGOUT.toString());
  const isLoginPage = pathname.includes(DashboardPaths.LOGIN.toString())
      || pathname.includes(DashboardPaths.SIGN_UP.toString());
  if (!user?.id) {
    if (isLoginPage) {
      return children;
    }

    if (isLogoutPage) {
      localStorage.setItem('session', '');
      window.location.href = window.location.origin;
      return children;
    }

    window.location.href = `${window.location.origin}${SystemPaths.DASHBOARD}${DashboardPaths.LOGIN}?redirect=${window.location.href}`;
    return null;
  }

  // setting user id for sentry
  sentrySetUser(user.id.toString());

  // newUser page is used as redirect url, for users with empty profile,
  // there is no direct access to /new-user-setting
  if (!isProfileSet && (!pathname.includes(DashboardPaths.WELCOME_NEW_USER_SETTINGS.toString()) && !isLogoutPage)) {
    window.location.href = `${window.location.origin}${SystemPaths.DASHBOARD}${DashboardPaths.WELCOME_NEW_USER_SETTINGS}`;
    return null;
  }

  if (isUserMarkedAsDeleted && (!pathname.includes(DashboardPaths.RESTORE_USER.toString()) && !isLogoutPage)) {
    window.location.href = `${window.location.origin}${SystemPaths.DASHBOARD}${DashboardPaths.RESTORE_USER}`;
    return null;
  }

  if (isRoleAllowed?.length === 0) {
    return null;
  }

  return children;
};

export default ProtectedRoute;
