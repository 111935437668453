const CircleFilled = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_181214_2397)">
      <path d="M23.3871 13.5625C23.3871 12.7161 22.6769 12 21.8376 12H20.8046C19.933 12 19.255 12.6836 19.255 13.5299V22.9049C19.255 23.7513 19.933 24.4674 20.8046 24.4674L21.8376 24.5C22.6769 24.5 23.3871 23.8164 23.3871 22.9375V13.5625Z" fill="currentColor" />
      <path d="M17.189 13.5625C17.189 12.7161 16.4788 12 15.6394 12H14.6064C13.7348 12 13.0569 12.6836 13.0569 13.5625V22.9375C13.0569 23.8164 13.7348 24.5 14.6064 24.5H15.6394C16.4788 24.5 17.189 23.8164 17.189 22.9375V13.5625Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.9232 -0.00219727C7.98011 -0.00219727 -0.0773926 8.05638 -0.0773926 18.0008C-0.0773926 27.9416 7.98011 35.9978 17.9232 35.9978C27.8639 35.9978 35.9226 27.9404 35.9226 18.0008C35.9238 8.05638 27.8639 -0.00219727 17.9232 -0.00219727ZM17.9232 32.9941C9.64288 32.9941 2.9295 26.2834 2.9295 18.0008C2.9295 9.72177 9.64288 3.0027 17.9232 3.0027C26.2023 3.0027 32.9145 9.72057 32.9145 18.0008C32.9157 26.2834 26.2023 32.9941 17.9232 32.9941Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_181214_2397">
        <rect width="36" height="36" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
export default CircleFilled;
