const Chart = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <path
      d="M1.6875 2C2.62266 2 3.375 2.75234 3.375 3.6875V28.4375C3.375 29.3727 4.12734 30.125 5.0625 30.125H34.3125C35.2477 30.125 36 30.8773 36 31.8125C36 32.7477 35.2477 33.5 34.3125 33.5H5.0625C2.26406 33.5 0 31.2359 0 28.4375V3.6875C0 2.75234 0.752344 2 1.6875 2ZM9 9.3125C9 8.37734 9.75234 7.625 10.6875 7.625H25.3125C26.2477 7.625 27 8.37734 27 9.3125C27 10.2477 26.2477 11 25.3125 11H10.6875C9.75234 11 9 10.2477 9 9.3125ZM10.6875 14.375H20.8125C21.7477 14.375 22.5 15.1273 22.5 16.0625C22.5 16.9977 21.7477 17.75 20.8125 17.75H10.6875C9.75234 17.75 9 16.9977 9 16.0625C9 15.1273 9.75234 14.375 10.6875 14.375ZM10.6875 21.125H29.8125C30.7477 21.125 31.5 21.8773 31.5 22.8125C31.5 23.7477 30.7477 24.5 29.8125 24.5H10.6875C9.75234 24.5 9 23.7477 9 22.8125C9 21.8773 9.75234 21.125 10.6875 21.125Z"
      fill="currentColor"
    />
  </svg>
);

export default Chart;
