export enum STRIPE_PRODUCT_TYPES {
  SCRIPT_REPORT_1 = 'SCRIPT_REPORT_1',
  SCRIPT_REPORT_1_VIP = 'SCRIPT_REPORT_1_VIP',
  SCRIPT_REPORT_2 = 'SCRIPT_REPORT_2',
  SCRIPT_REPORT_2_VIP = 'SCRIPT_REPORT_2_VIP',
  CONSULTATION_COUPON = 'CONSULTATION_COUPON',
  CONSULTATION_COUPON_VIP = 'CONSULTATION_COUPON_VIP',
  SCRIPT_REPORT_COUPON_1 = 'SCRIPT_REPORT_COUPON_1',
  SCRIPT_REPORT_CONVERSATION_1 = 'SCRIPT_REPORT_CONVERSATION_1',
  VIP_SUBSCRIPTION = 'VIP_SUBSCRIPTION',
}

export enum STRIPE_PRODUCT_GROUPS {
  SCRIPT_REPORT_GROUP = 'SCRIPT_REPORT',
  SCRIPT_PROMOTION_GROUP = 'SCRIPT_PROMOTION',
  COUPON = 'COUPON',
  SUBSCRIPTION = 'SUBSCRIPTION',
}
