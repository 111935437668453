import { StrapiScreenplayCarouselGenreSelector } from '@/const/cmsInterface';
import If from '@/ui-kit/Base/If/If';
import StrapiCompetitionFilterWrapperComponent from '@/components/Cms/StrapiCompetitionFilterWrapperComponent';
import GenreScreenplayCarousel from '@/ui-kit/Components/ScreenplayCarouselSection/GenreScreenplayCarousel';
import { useGetDictionaryUsingGet } from '@/api/http/public';
import { Paths, ScreenplaysFilterPaths } from '@/const/routes';

interface StrapiScreenplayCarouselGenreSelectorComponentProperties {
  block: StrapiScreenplayCarouselGenreSelector;
}

const StrapiScreenplayCarouselGenreSelectorComponent: React.FC<StrapiScreenplayCarouselGenreSelectorComponentProperties> = ({
  block,
}) => {
  const { data: genres = [], isLoading: isLoadingGenres } = useGetDictionaryUsingGet({
    type: 'GENRE',
  });

  const items = genres?.map((genre) => {
    return genre;
  });

  return (
    <StrapiCompetitionFilterWrapperComponent
      isVisibleForCompetition={block?.isVisibleForCompetition}
      isHiddenForCompetition={block?.isHiddenForCompetition}
    >
      {() => (
        <If truthy={!isLoadingGenres}>
          <GenreScreenplayCarousel
            title={block?.title?.text}
            variant={block?.theme}
            items={items}
            linkTo={`${Paths.SCREENPLAYS_FILTER}${ScreenplaysFilterPaths.GENRES}`}
            autoplay={false}
          />
        </If>
      )}
    </StrapiCompetitionFilterWrapperComponent>
  );
};

export default StrapiScreenplayCarouselGenreSelectorComponent;
