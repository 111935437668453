import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useKeycloakProvider } from '@/hooks/useKeycloakProvider';
import { useGetUser } from '@/api/http/user';

const Login: React.FC = () => {
  const { keycloak } = useKeycloakProvider();
  const { data: user, isLoading } = useGetUser();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');
  const { href } = window.location;
  const redirectUri = redirect || href;

  const login = () => {
    keycloak?.login({
      redirectUri,
    });
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (user?.id) {
      window.location.href = redirectUri || '/';
    } else {
      login();
    }
  }, [isLoading]);

  return (
    <span />
  );
};

export default Login;
