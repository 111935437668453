import React from 'react';
import { KlScriptVoteStatisticPublicData } from '@/api/generated/data-contracts';
import CompetitionWrapper from '@/components/Wrappers/CompetitionWrapper';

interface AlloriProperties {
  voteStatistic?: KlScriptVoteStatisticPublicData;
}

const Allori: React.FC<AlloriProperties> = ({
  voteStatistic,
}) => {
  return (
    <CompetitionWrapper>
      {({ isVoteForWinner }) => {
        const getAllori = () => {
          if (isVoteForWinner()) {
            return voteStatistic?.finalVoteRating?.toLocaleString();
          }
          return voteStatistic?.voteRating?.toLocaleString();
        };
        return (
          <>
            {' '}
            {getAllori() || 'N/A'}
          </>
        );
      }}
    </CompetitionWrapper>
  );
};

export default Allori;
