const LinkCircle = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_181078_18788)">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.1321 11.1723C19.8916 10.4375 20.9094 10.0305 21.9661 10.0393C23.0228 10.048 24.0338 10.4716 24.781 11.2189C25.5283 11.9661 25.9519 12.9771 25.9606 14.0338C25.9693 15.0905 25.5624 16.1083 24.8276 16.8678L21.2783 20.4171C20.9061 20.7959 20.4621 21.0968 19.9722 21.3021C19.4824 21.5074 18.9565 21.6129 18.4254 21.6126C17.8943 21.6123 17.3685 21.506 16.8789 21.3002C16.3893 21.0943 15.9457 20.7929 15.5739 20.4135C15.413 20.2446 15.325 20.0191 15.329 19.7859C15.333 19.5526 15.4286 19.3302 15.5952 19.1669C15.7618 19.0036 15.986 18.9124 16.2193 18.913C16.4526 18.9137 16.6763 19.0061 16.842 19.1703C17.0487 19.3814 17.2954 19.549 17.5678 19.6635C17.8401 19.778 18.1325 19.8369 18.4279 19.8369C18.7233 19.8369 19.0158 19.778 19.2881 19.6635C19.5604 19.549 19.8072 19.3814 20.0139 19.1703L20.0201 19.1641L23.572 15.6122C23.9943 15.1899 24.2315 14.6172 24.2315 14.02C24.2315 13.4229 23.9943 12.8501 23.572 12.4279C23.1497 12.0056 22.577 11.7684 21.9799 11.7684C21.3827 11.7684 20.81 12.0056 20.3877 12.4279L19.9437 12.8719C19.8618 12.9567 19.7638 13.0243 19.6555 13.0709C19.5471 13.1174 19.4306 13.1419 19.3127 13.1429C19.1948 13.144 19.0779 13.1215 18.9687 13.0768C18.8596 13.0322 18.7605 12.9663 18.6771 12.8829C18.5937 12.7995 18.5278 12.7004 18.4831 12.5912C18.4385 12.4821 18.416 12.3652 18.417 12.2473C18.4181 12.1294 18.4426 12.0128 18.4891 11.9045C18.5356 11.7962 18.6033 11.6982 18.6881 11.6163L19.1321 11.1723Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.9925 14.7305C16.7307 14.4204 17.545 14.3393 18.3299 14.4977C19.1147 14.656 19.8339 15.0465 20.3942 15.6184C20.5551 15.7874 20.6431 16.0128 20.6391 16.2461C20.6352 16.4794 20.5395 16.7017 20.3729 16.8651C20.2063 17.0284 19.9822 17.1196 19.7488 17.1189C19.5155 17.1183 19.2918 17.0259 19.1262 16.8616C18.9194 16.6506 18.6727 16.4829 18.4004 16.3685C18.128 16.254 17.8356 16.195 17.5402 16.195C17.2448 16.195 16.9524 16.254 16.6801 16.3685C16.4077 16.4829 16.161 16.6506 15.9543 16.8616L15.9481 16.8678L12.3961 20.4198C11.9739 20.842 11.7366 21.4147 11.7366 22.0119C11.7366 22.3076 11.7949 22.6004 11.908 22.8736C12.0212 23.1468 12.187 23.395 12.3961 23.6041C12.6052 23.8132 12.8534 23.979 13.1266 24.0922C13.3998 24.2053 13.6926 24.2636 13.9883 24.2636C14.5855 24.2636 15.1582 24.0263 15.5804 23.6041L16.0244 23.1601C16.1063 23.0753 16.2043 23.0076 16.3127 22.9611C16.421 22.9145 16.5375 22.89 16.6554 22.889C16.7733 22.888 16.8903 22.9105 16.9994 22.9551C17.1085 22.9998 17.2077 23.0657 17.291 23.1491C17.3744 23.2324 17.4404 23.3316 17.485 23.4407C17.5296 23.5499 17.5521 23.6668 17.5511 23.7847C17.5501 23.9026 17.5256 24.0191 17.479 24.1275C17.4325 24.2358 17.3648 24.3338 17.28 24.4157L16.836 24.8597C16.0808 25.615 15.0564 26.0393 13.9883 26.0393C12.9202 26.0393 11.8958 25.615 11.1405 24.8597C10.3852 24.1044 9.96094 23.08 9.96094 22.0119C9.96094 20.9438 10.3852 19.9194 11.1405 19.1642L14.6889 15.6149C15.0605 15.2368 15.5036 14.9356 15.9925 14.7305Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18ZM1.63638 18C1.63638 27.0374 8.96259 34.3636 18 34.3636C27.0374 34.3636 34.3636 27.0374 34.3636 18C34.3636 8.96259 27.0374 1.63638 18 1.63638C8.96259 1.63638 1.63638 8.96259 1.63638 18Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_181078_18788">
        <rect width="36" height="36" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
export default LinkCircle;
