const StatisticsDetails = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_181737_5817)">
      <path d="M0.460915 33.1755L6.99415 26.6422C4.34405 23.3972 3.04228 19.2579 3.35803 15.0801C3.67377 10.9024 5.58288 7.00568 8.6906 4.19581C11.7983 1.38593 15.867 -0.122263 20.0553 -0.0169263C24.2437 0.0884105 28.2314 1.79922 31.1939 4.76176C34.1565 7.72431 35.8673 11.712 35.9726 15.9004C36.078 20.0887 34.5698 24.1574 31.7599 27.2651C28.95 30.3728 25.0533 32.2819 20.8756 32.5977C16.6978 32.9134 12.5585 31.6116 9.31345 28.9615L2.78021 35.4948C2.62838 35.6479 2.44773 35.7694 2.2487 35.8523C2.04966 35.9352 1.83618 35.9779 1.62056 35.9779C1.40495 35.9779 1.19146 35.9352 0.992428 35.8523C0.793394 35.7694 0.612752 35.6479 0.460915 35.4948C0.307827 35.3429 0.186315 35.1623 0.103394 34.9633C0.0204726 34.7642 -0.0222168 34.5507 -0.0222168 34.3351C-0.0222168 34.1195 0.0204726 33.906 0.103394 33.707C0.186315 33.508 0.307827 33.3273 0.460915 33.1755ZM19.587 29.4352C22.1713 29.4352 24.6975 28.6689 26.8463 27.2331C28.9951 25.7973 30.6699 23.7566 31.6588 21.369C32.6478 18.9815 32.9066 16.3542 32.4024 13.8196C31.8982 11.2849 30.6537 8.9567 28.8264 7.12932C26.999 5.30194 24.6708 4.05748 22.1361 3.55331C19.6015 3.04913 16.9742 3.30789 14.5866 4.29687C12.1991 5.28584 10.1584 6.9606 8.72259 9.10937C7.28683 11.2581 6.52049 13.7844 6.52049 16.3687C6.52049 19.8342 7.89713 23.1577 10.3476 25.6081C12.798 28.0586 16.1215 29.4352 19.587 29.4352Z" fill="currentColor" fillOpacity="0.1" />
      <path d="M24.4868 16.3687V21.2686C24.4868 21.7018 24.6589 22.1172 24.9652 22.4235C25.2715 22.7298 25.6869 22.9019 26.1201 22.9019C26.5533 22.9019 26.9687 22.7298 27.275 22.4235C27.5813 22.1172 27.7534 21.7018 27.7534 21.2686V16.3687C27.7534 15.9355 27.5813 15.5201 27.275 15.2138C26.9687 14.9075 26.5533 14.7354 26.1201 14.7354C25.6869 14.7354 25.2715 14.9075 24.9652 15.2138C24.6589 15.5201 24.4868 15.9355 24.4868 16.3687ZM17.9536 11.4688V21.2686C17.9536 21.7018 18.1256 22.1172 18.4319 22.4235C18.7382 22.7298 19.1537 22.9019 19.5869 22.9019C20.02 22.9019 20.4355 22.7298 20.7418 22.4235C21.0481 22.1172 21.2202 21.7018 21.2202 21.2686V11.4688C21.2202 11.0356 21.0481 10.6201 20.7418 10.3138C20.4355 10.0075 20.02 9.83545 19.5869 9.83545C19.1537 9.83545 18.7382 10.0075 18.4319 10.3138C18.1256 10.6201 17.9536 11.0356 17.9536 11.4688ZM11.4203 13.1021V21.2686C11.4203 21.7018 11.5924 22.1172 11.8987 22.4235C12.205 22.7298 12.6204 22.9019 13.0536 22.9019C13.4868 22.9019 13.9022 22.7298 14.2086 22.4235C14.5149 22.1172 14.6869 21.7018 14.6869 21.2686V13.1021C14.6869 12.6689 14.5149 12.2535 14.2086 11.9471C13.9022 11.6408 13.4868 11.4688 13.0536 11.4688C12.6204 11.4688 12.205 11.6408 11.8987 11.9471C11.5924 12.2535 11.4203 12.6689 11.4203 13.1021Z" fill="currentColor" fillOpacity="0.1" />
    </g>
    <defs>
      <clipPath id="clip0_181737_5817">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default StatisticsDetails;
