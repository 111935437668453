import React from 'react';

interface IfProperties {
  children: React.ReactNode | React.ReactNode[];
  truthy: boolean;
}

const If: React.FC<IfProperties> = ({
  children,
  truthy,
}) => {
  if (truthy) {
    return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {children}
      </>
    );
  }
  return null;
};

export default If;
