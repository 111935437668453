const Cross = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_180074_88577)">
      <path d="M0.781607 35.7033C1.07073 35.8965 1.41064 35.9995 1.75834 35.9995C1.98923 35.9998 2.21791 35.9545 2.43124 35.8662C2.64457 35.7778 2.83835 35.6482 3.00144 35.4848L18.0002 20.486L32.999 35.4848C33.1621 35.6482 33.3559 35.7778 33.5692 35.8662C33.7826 35.9545 34.0113 35.9998 34.2421 35.9995C34.5898 35.9995 34.9298 35.8965 35.2189 35.7033C35.508 35.5102 35.7334 35.2356 35.8664 34.9144C35.9995 34.5932 36.0343 34.2397 35.9664 33.8987C35.8986 33.5577 35.7311 33.2444 35.4852 32.9986L20.4864 17.9998L35.4851 3.00116C35.8148 2.67147 36 2.22432 36 1.75807C36 1.29181 35.8148 0.844658 35.4851 0.514969C35.1554 0.185279 34.7082 6.10352e-05 34.242 6.10352e-05C33.7757 6.10352e-05 33.3286 0.185279 32.9989 0.514969L18.0002 15.5136L3.00162 0.514969C2.67193 0.185279 2.22477 6.10352e-05 1.75852 6.10352e-05C1.29227 6.10352e-05 0.845116 0.185279 0.515426 0.514969C0.185737 0.844658 0.000518794 1.29181 0.000518794 1.75807C0.000518794 2.22432 0.185737 2.67147 0.515426 3.00116L15.5141 17.9998L0.515248 32.9986C0.269353 33.2444 0.101889 33.5577 0.034038 33.8987C-0.0338132 34.2397 0.000997111 34.5932 0.134066 34.9144C0.267134 35.2356 0.492483 35.5102 0.781607 35.7033Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_180074_88577">
        <rect width="36" height="36" fill="none" />
      </clipPath>
    </defs>
  </svg>
);

export default Cross;
