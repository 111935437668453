import pages from './pages.json';

export const getPages = () => {
  const disabledPages = JSON.parse(import.meta.env.VITE_PAGES_DISABLED as string) as string[];
  const filteredPages = pages?.pages?.filter((page) => {
    if (disabledPages.some((disabledPage) => disabledPage === page?.slug)) {
      return false;
    }

    return true;
  });

  return filteredPages;
};
