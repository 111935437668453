const ScriptCoverage = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2326_34733)">
      <rect width="100%" height="100%" transform="translate(0 0.5)" fill="white" fillOpacity="0.01" />
      <g clipPath="url(#clip1_2326_34733)">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.1163 11.6628C17.5554 11.6628 19.5349 13.6423 19.5349 16.0814C19.5349 18.5204 17.5554 20.5 15.1163 20.5C12.6772 20.5 10.6977 18.5204 10.6977 16.0814C10.6977 13.6423 12.6772 11.6628 15.1163 11.6628ZM15.1163 13.0581C14.3275 13.0777 13.5775 13.4048 13.0265 13.9696C12.4755 14.5345 12.1671 15.2923 12.1671 16.0814C12.1671 16.8704 12.4755 17.6283 13.0265 18.1931C13.5775 18.7579 14.3275 19.0851 15.1163 19.1046C15.9051 19.0851 16.6551 18.7579 17.2061 18.1931C17.7571 17.6283 18.0655 16.8704 18.0655 16.0814C18.0655 15.2923 17.7571 14.5345 17.2061 13.9696C16.6551 13.4048 15.9051 13.0777 15.1163 13.0581Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.9488 16.5744C12.8181 16.4437 12.7446 16.2663 12.7446 16.0814C12.7446 15.8965 12.8181 15.7191 12.9488 15.5884C13.0796 15.4576 13.2569 15.3842 13.4419 15.3842C13.6268 15.3842 13.8041 15.4576 13.9349 15.5884L14.5581 16.2107L16.2977 14.4721C16.4284 14.3413 16.6058 14.2679 16.7907 14.2679C16.9756 14.2679 17.153 14.3413 17.2837 14.4721C17.4145 14.6029 17.4879 14.7802 17.4879 14.9651C17.4879 15.15 17.4145 15.3274 17.2837 15.4581L15.0512 17.6907C14.9865 17.7555 14.9096 17.8069 14.825 17.842C14.7404 17.8771 14.6497 17.8951 14.5581 17.8951C14.4666 17.8951 14.3759 17.8771 14.2913 17.842C14.2067 17.8069 14.1298 17.7555 14.0651 17.6907L12.9488 16.5744ZM3.95349 8.40698C3.76845 8.40698 3.591 8.33347 3.46016 8.20263C3.32932 8.07179 3.25581 7.89434 3.25581 7.7093C3.25581 7.52427 3.32932 7.34681 3.46016 7.21597C3.591 7.08513 3.76845 7.01163 3.95349 7.01163H8.60465C8.78969 7.01163 8.96714 7.08513 9.09798 7.21597C9.22882 7.34681 9.30233 7.52427 9.30233 7.7093C9.30233 7.89434 9.22882 8.07179 9.09798 8.20263C8.96714 8.33347 8.78969 8.40698 8.60465 8.40698H3.95349ZM3.95349 15.8488C3.76845 15.8488 3.591 15.7753 3.46016 15.6445C3.32932 15.5137 3.25581 15.3362 3.25581 15.1512C3.25581 14.9661 3.32932 14.7887 3.46016 14.6578C3.591 14.527 3.76845 14.4535 3.95349 14.4535H8.60465C8.78969 14.4535 8.96714 14.527 9.09798 14.6578C9.22882 14.7887 9.30233 14.9661 9.30233 15.1512C9.30233 15.3362 9.22882 15.5137 9.09798 15.6445C8.96714 15.7753 8.78969 15.8488 8.60465 15.8488H3.95349ZM3.95349 12.1279C3.76845 12.1279 3.591 12.0544 3.46016 11.9236C3.32932 11.7927 3.25581 11.6153 3.25581 11.4302C3.25581 11.2452 3.32932 11.0677 3.46016 10.9369C3.591 10.8061 3.76845 10.7326 3.95349 10.7326H10.4651C10.6502 10.7326 10.8276 10.8061 10.9584 10.9369C11.0893 11.0677 11.1628 11.2452 11.1628 11.4302C11.1628 11.6153 11.0893 11.7927 10.9584 11.9236C10.8276 12.0544 10.6502 12.1279 10.4651 12.1279H3.95349Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.2093 10.0349C17.2093 10.2199 17.1358 10.3974 17.005 10.5282C16.8741 10.6591 16.6967 10.7326 16.5116 10.7326C16.3266 10.7326 16.1491 10.6591 16.0183 10.5282C15.8875 10.3974 15.814 10.2199 15.814 10.0349V6.67395C15.8141 6.61584 15.7926 6.55975 15.7535 6.51674L11.6214 1.97163C11.5995 1.94771 11.573 1.92858 11.5433 1.91546C11.5137 1.90233 11.4817 1.89548 11.4493 1.89535H2.55814C2.2499 1.89584 1.95442 2.01851 1.73647 2.23647C1.51851 2.45442 1.39584 2.7499 1.39535 3.05814V17.9419C1.39584 18.2501 1.51851 18.5456 1.73647 18.7635C1.95442 18.9815 2.2499 19.1042 2.55814 19.1047H10C10.185 19.1047 10.3625 19.1782 10.4933 19.309C10.6242 19.4398 10.6977 19.6173 10.6977 19.8023C10.6977 19.9874 10.6242 20.1648 10.4933 20.2957C10.3625 20.4265 10.185 20.5 10 20.5H2.55814C1.88 20.5 1.22884 20.2302 0.748837 19.7512C0.269577 19.271 0.000284852 18.6203 0 17.9419V3.05814C0 2.38 0.269767 1.72884 0.748837 1.24884C1.22904 0.769577 1.87969 0.500285 2.55814 0.5H11.4493C11.9079 0.5 12.3451 0.693488 12.654 1.03302L16.786 5.57814C17.0586 5.8786 17.2093 6.26837 17.2093 6.67395V10.0349Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M11.1628 1.66281C11.1628 1.47777 11.2363 1.30032 11.3671 1.16948C11.498 1.03864 11.6754 0.965135 11.8605 0.965135C12.0455 0.965135 12.223 1.03864 12.3538 1.16948C12.4846 1.30032 12.5581 1.47777 12.5581 1.66281V5.38374C12.5581 5.51211 12.6623 5.6163 12.7907 5.6163H16.0465C16.2316 5.6163 16.409 5.6898 16.5398 5.82064C16.6707 5.95148 16.7442 6.12894 16.7442 6.31397C16.7442 6.49901 16.6707 6.67646 16.5398 6.8073C16.409 6.93814 16.2316 7.01165 16.0465 7.01165H12.7907C12.359 7.01165 11.9449 6.84014 11.6396 6.53484C11.3343 6.22955 11.1628 5.81549 11.1628 5.38374V1.66281Z" fill="currentColor" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2326_34733">
        <rect width="100%" height="100%" fill="white" transform="translate(0 0.5)" />
      </clipPath>
      <clipPath id="clip1_2326_34733">
        <rect width="100%" height="100%" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default ScriptCoverage;
