import { getToken } from '@/utils/getToken';
import { HttpClient } from '@/api/generated/http-client';

console.log(import.meta.env.VITE_REACT_API_URL as string);

export const useApi = () => new HttpClient<never>({
  baseUrl: import.meta.env.VITE_REACT_API_URL as string,
  securityWorker: () => ({
    format: 'json',
    headers: {
      ...(getToken() ? { Authorization: `Bearer ${getToken()}` } : {}),
    },
  }),
});
