import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useKeycloakProvider } from '@/hooks/useKeycloakProvider';
import { SystemPaths } from '@/const/routes';
import { useGetUser } from '@/api/http/user';

const SignUp: React.FC = () => {
  const { keycloak } = useKeycloakProvider();
  const { data: user } = useGetUser();
  const [searchParams] = useSearchParams();

  const register = () => {
    const redirect = searchParams.get('redirect');
    const redirectUri = redirect || `${window.location.origin}${SystemPaths.DASHBOARD}`;

    keycloak?.register({
      redirectUri,
    });
  };

  if (!user?.id) {
    register();
    return null;
  }

  return null;
};

export default SignUp;
