const BadgeTop10 = (): JSX.Element => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M76.7895 40L68.46 30.7521L69.7642 18.3715L57.5843 15.7931L51.3713 5L40.0001 10.0821L38.4653 40L40.0001 69.9178L51.3715 74.9999L57.5843 64.207L69.7642 61.6284L68.46 49.2478L76.7895 40Z" fill="#FABE2C" />
    <path d="M51.3715 74.9999L40.0002 69.9178L40.0001 62.8787L40.0145 62.8723L48.6916 66.7503L53.4386 58.5038L62.7536 56.5318L61.7569 47.07L68.1249 40L61.7569 32.9299L62.7536 23.4682L53.4387 21.4963L48.6915 13.2496L40.0145 17.1276L40.0001 17.1213V10.0821L51.3714 5L57.5843 15.7931L69.7642 18.3715L68.4601 30.7521L76.7895 40L68.4601 49.2478L69.7642 61.6284L57.5843 64.207L51.3715 74.9999Z" fill="#FF9100" />
    <path d="M28.6167 5.05007L28.5034 5V5.05007H28.6167Z" fill="#FF9100" />
    <path d="M28.5034 74.95V74.9999L28.6163 74.95H28.5034Z" fill="#FF9100" />
    <path d="M28.6285 5L22.4157 15.7931L10.2357 18.3716L11.5399 30.7521L3.21045 40L11.5399 49.2478L10.2357 61.6284L22.4157 64.207L28.6286 74.9999L40 69.9178V26.6639V10.0821L28.6285 5Z" fill="#FED843" />
    <path d="M39.9815 10.0739L28.6285 5L22.4157 15.7931L10.2357 18.3716L11.5399 30.7521L3.21045 40L11.5399 49.2478L10.2357 61.6284L22.4157 64.207L28.6286 74.9999L39.9815 69.926V62.8741L31.3085 66.7503L26.5613 58.5038L17.2464 56.5318L18.2431 47.07L11.8751 40L18.2431 32.9299L17.2464 23.4683L26.5614 21.4962L31.3084 13.2496L39.9815 17.1258V10.0739Z" fill="#FABE2C" />
    <path d="M51.3837 74.95L51.4966 74.9999V74.95H51.3837Z" fill="#FABE2C" />
    <path d="M51.4966 5.05007V5L51.3833 5.05007H51.4966Z" fill="#FABE2C" />
    <path d="M31.5449 37.5898C31.2516 37.5898 31.0249 37.5098 30.8649 37.3498C30.7049 37.1832 30.6249 36.9532 30.6249 36.6598V31.9498H29.0549C28.8083 31.9498 28.6183 31.8865 28.4849 31.7598C28.3583 31.6265 28.2949 31.4398 28.2949 31.1998C28.2949 30.9532 28.3583 30.7665 28.4849 30.6398C28.6183 30.5132 28.8083 30.4498 29.0549 30.4498H34.0349C34.2816 30.4498 34.4683 30.5132 34.5949 30.6398C34.7283 30.7665 34.7949 30.9532 34.7949 31.1998C34.7949 31.4398 34.7283 31.6265 34.5949 31.7598C34.4683 31.8865 34.2816 31.9498 34.0349 31.9498H32.4649V36.6598C32.4649 36.9532 32.3883 37.1832 32.2349 37.3498C32.0816 37.5098 31.8516 37.5898 31.5449 37.5898Z" fill="#774605" />
    <path d="M40.3903 37.6098C39.677 37.6098 39.0503 37.4598 38.5103 37.1598C37.9703 36.8532 37.5503 36.4265 37.2503 35.8798C36.957 35.3332 36.8103 34.6965 36.8103 33.9698C36.8103 33.4232 36.8936 32.9265 37.0603 32.4798C37.2336 32.0332 37.477 31.6498 37.7903 31.3298C38.1103 31.0098 38.4903 30.7665 38.9303 30.5998C39.3703 30.4265 39.857 30.3398 40.3903 30.3398C41.1036 30.3398 41.727 30.4898 42.2603 30.7898C42.8003 31.0898 43.2203 31.5132 43.5203 32.0598C43.8203 32.5998 43.9703 33.2365 43.9703 33.9698C43.9703 34.5165 43.8836 35.0132 43.7103 35.4598C43.5436 35.9065 43.3003 36.2932 42.9803 36.6198C42.667 36.9398 42.2903 37.1865 41.8503 37.3598C41.4103 37.5265 40.9236 37.6098 40.3903 37.6098ZM40.3903 36.0698C40.7436 36.0698 41.0436 35.9865 41.2903 35.8198C41.537 35.6532 41.727 35.4132 41.8603 35.0998C41.9936 34.7865 42.0603 34.4098 42.0603 33.9698C42.0603 33.3098 41.9136 32.7965 41.6203 32.4298C41.327 32.0632 40.917 31.8798 40.3903 31.8798C40.0436 31.8798 39.7436 31.9632 39.4903 32.1298C39.2436 32.2898 39.0536 32.5265 38.9203 32.8398C38.787 33.1465 38.7203 33.5232 38.7203 33.9698C38.7203 34.6298 38.867 35.1465 39.1603 35.5198C39.4536 35.8865 39.8636 36.0698 40.3903 36.0698Z" fill="#774605" />
    <path d="M47.9767 37.5898C47.6834 37.5898 47.4567 37.5098 47.2967 37.3498C47.1367 37.1832 47.0567 36.9532 47.0567 36.6598V31.3798C47.0567 31.0798 47.1367 30.8498 47.2967 30.6898C47.4634 30.5298 47.6934 30.4498 47.9867 30.4498H50.4467C51.2467 30.4498 51.8634 30.6565 52.2967 31.0698C52.7367 31.4765 52.9567 32.0398 52.9567 32.7598C52.9567 33.4798 52.7367 34.0465 52.2967 34.4598C51.8634 34.8665 51.2467 35.0698 50.4467 35.0698H48.8967V36.6598C48.8967 36.9532 48.8201 37.1832 48.6667 37.3498C48.5134 37.5098 48.2834 37.5898 47.9767 37.5898ZM48.8967 33.6598H50.1267C50.4734 33.6598 50.7401 33.5865 50.9267 33.4398C51.1134 33.2865 51.2067 33.0598 51.2067 32.7598C51.2067 32.4532 51.1134 32.2265 50.9267 32.0798C50.7401 31.9332 50.4734 31.8598 50.1267 31.8598H48.8967V33.6598Z" fill="#774605" />
    <path d="M34.0368 52.4998C33.6627 52.4998 33.3745 52.4038 33.1723 52.2117C32.9802 52.0095 32.8842 51.7263 32.8842 51.3623C32.8842 50.9983 32.9802 50.7203 33.1723 50.5282C33.3745 50.3361 33.6627 50.24 34.0368 50.24H35.4018V44.0368H36.6758L34.4767 45.3867C34.2138 45.5485 33.966 45.6142 33.7335 45.5838C33.5009 45.5434 33.3038 45.4423 33.142 45.2805C32.9802 45.1187 32.874 44.9216 32.8235 44.689C32.7729 44.4565 32.7932 44.2239 32.8842 43.9913C32.9752 43.7588 33.1572 43.5616 33.4302 43.3998L35.5232 42.0955C35.7456 41.954 35.9731 41.8478 36.2057 41.777C36.4483 41.7062 36.7011 41.6708 36.964 41.6708C37.3482 41.6708 37.6465 41.772 37.8588 41.9742C38.0712 42.1663 38.1773 42.4494 38.1773 42.8235V50.24H39.3907C39.7648 50.24 40.0479 50.3361 40.24 50.5282C40.4422 50.7203 40.5433 50.9983 40.5433 51.3623C40.5433 51.7263 40.4422 52.0095 40.24 52.2117C40.0479 52.4038 39.7648 52.4998 39.3907 52.4998H34.0368Z" fill="#774605" />
    <path d="M45.2997 52.6667C43.9853 52.6667 42.9742 52.1864 42.2664 51.2258C41.5687 50.2552 41.2199 48.8851 41.2199 47.1157C41.2199 45.9226 41.3766 44.9216 41.6901 44.1127C42.0035 43.2937 42.4636 42.6769 43.0702 42.2623C43.6769 41.8478 44.4201 41.6405 45.2997 41.6405C46.6243 41.6405 47.6354 42.1056 48.3331 43.0358C49.0307 43.9661 49.3796 45.321 49.3796 47.1005C49.3796 48.2835 49.2228 49.2896 48.9094 50.1187C48.596 50.9478 48.1359 51.5797 47.5292 52.0145C46.9226 52.4493 46.1794 52.6667 45.2997 52.6667ZM45.2997 50.4523C45.7851 50.4523 46.144 50.1895 46.3766 49.6637C46.6192 49.1278 46.7406 48.2734 46.7406 47.1005C46.7406 45.9377 46.6192 45.1086 46.3766 44.6132C46.144 44.1076 45.7851 43.8548 45.2997 43.8548C44.8144 43.8548 44.4504 44.1076 44.2077 44.6132C43.9752 45.1086 43.8589 45.9377 43.8589 47.1005C43.8589 48.2734 43.9752 49.1278 44.2077 49.6637C44.4504 50.1895 44.8144 50.4523 45.2997 50.4523Z" fill="#774605" />
    <path d="M40 30.356C39.6171 30.3884 39.2606 30.4697 38.9303 30.5998C38.4903 30.7665 38.1103 31.0098 37.7903 31.3298C37.477 31.6498 37.2336 32.0331 37.0603 32.4798C36.8936 32.9265 36.8103 33.4231 36.8103 33.9698C36.8103 34.6965 36.957 35.3331 37.2503 35.8798C37.5503 36.4265 37.9703 36.8531 38.5103 37.1598C38.9495 37.4038 39.4461 37.5486 40 37.5941V36.0319C39.6566 35.961 39.3767 35.7903 39.1603 35.5198C38.867 35.1465 38.7203 34.6298 38.7203 33.9698C38.7203 33.5231 38.787 33.1465 38.9203 32.8398C39.0536 32.5265 39.2436 32.2898 39.4903 32.1298C39.6432 32.0292 39.8131 31.959 40 31.9191V30.356Z" fill="#AD6200" />
    <path d="M40 50.3594C39.8346 50.2798 39.6315 50.24 39.3907 50.24H38.1773V42.8235C38.1773 42.4493 38.0712 42.1662 37.8588 41.9741C37.6465 41.7719 37.3482 41.6708 36.964 41.6708C36.7011 41.6708 36.4483 41.7062 36.2057 41.777C35.9731 41.8477 35.7456 41.9539 35.5232 42.0955L33.4302 43.3998C33.1572 43.5616 32.9752 43.7587 32.8842 43.9913C32.7932 44.2238 32.7729 44.4564 32.8235 44.689C32.874 44.9215 32.9802 45.1187 33.142 45.2805C33.3038 45.4422 33.5009 45.5433 33.7335 45.5838C33.966 45.6141 34.2138 45.5484 34.4767 45.3866L35.4018 44.8188V50.24H34.0368C33.6627 50.24 33.3745 50.336 33.1723 50.5281C32.9802 50.7202 32.8842 50.9983 32.8842 51.3623C32.8842 51.7263 32.9802 52.0094 33.1723 52.2116C33.3745 52.4037 33.6627 52.4998 34.0368 52.4998H39.3907C39.6315 52.4998 39.8346 52.46 40 52.3804V50.3594Z" fill="#AD6200" />
    <path d="M31.5449 37.5898C31.2516 37.5898 31.0249 37.5098 30.8649 37.3498C30.7049 37.1831 30.6249 36.9531 30.6249 36.6598V31.9498H29.0549C28.8083 31.9498 28.6183 31.8865 28.4849 31.7598C28.3583 31.6265 28.2949 31.4398 28.2949 31.1998C28.2949 30.9531 28.3583 30.7665 28.4849 30.6398C28.6183 30.5131 28.8083 30.4498 29.0549 30.4498H34.0349C34.2816 30.4498 34.4683 30.5131 34.5949 30.6398C34.7283 30.7665 34.7949 30.9531 34.7949 31.1998C34.7949 31.4398 34.7283 31.6265 34.5949 31.7598C34.4683 31.8865 34.2816 31.9498 34.0349 31.9498H32.4649V36.6598C32.4649 36.9531 32.3883 37.1831 32.2349 37.3498C32.0816 37.5098 31.8516 37.5898 31.5449 37.5898Z" fill="#AD6200" />
  </svg>
);

export default BadgeTop10;
