/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	KlMyLabAddToMyLabUsingPostData,
	KlMyLabAddToMyLabUsingPostError,
	KlMyLabDeleteFromMyLabUsingDeleteData,
	KlMyLabDeleteFromMyLabUsingDeleteError,
	KlMyLabDto
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class MyLab<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http
	}

	/**
	 * No description
	 *
	 * @tags my-lab-controller
	 * @name MyLabAddToMyLabUsingPost
	 * @summary addToMyLab
	 * @request POST:/api/v1/my-lab
	 * @secure
	 * @response `200` `KlMyLabAddToMyLabUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	myLabAddToMyLabUsingPost = (
		request: KlMyLabDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlMyLabAddToMyLabUsingPostData,
			KlMyLabAddToMyLabUsingPostError
		>({
			path: `/api/v1/my-lab`,
			method: 'POST',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags my-lab-controller
	 * @name MyLabDeleteFromMyLabUsingDelete
	 * @summary deleteFromMyLab
	 * @request DELETE:/api/v1/my-lab
	 * @secure
	 * @response `200` `KlMyLabDeleteFromMyLabUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	myLabDeleteFromMyLabUsingDelete = (
		request: KlMyLabDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlMyLabDeleteFromMyLabUsingDeleteData,
			KlMyLabDeleteFromMyLabUsingDeleteError
		>({
			path: `/api/v1/my-lab`,
			method: 'DELETE',
			body: request,
			secure: true,
			...params
		})
}
