export const DEFAULT_HTTP_STALE_TIME = 10 * 60 * 1000; // 10 minute
export const DEFAULT_HTTP_REFETCH_INTERVAL = 60 * 1000; // 1 minute
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_SCREENPLAYS_CAROUSEL_SIZE = 30;
export const PROMOTED_SCREENPLAYS_CAROUSEL_SIZE = 10;

export const SCRIPT_VIEW_PROCESS_INTERVAL = 10 * 1000; // 10 sec

export const AUDIO_PODCAST_STORAGE = 'audio-podcast-player';
export const AUDIO_PODCAST_STORAGE_EXPIRE = 60 * 24 * 5;

export const PODCAST_AUDIO_MAX_DURATION_OF_INTERVAL = 60; // 60 sec
export const PODCAST_AUDIO_PROCESS_INTERVAL = 10 * 1000; // 10 sec

export const TRENDING_ASC_SORT = 'scriptVoteStatistics.trendingIndex: asc';
export const TRENDING_DESC_SORT = 'scriptVoteStatistics.trendingIndex: desc';
export const RANK_ASC_SORT = 'scriptVoteStatistics.rank: asc';
export const RANK_DESC_SORT = 'scriptVoteStatistics.rank: desc';

export const staleTime = () => {
  if (window.localStorage.getItem('isTest')) {
    return 0;
  }

  return DEFAULT_HTTP_STALE_TIME;
};
