const Hamburger = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <path
      d="M33.7499 31.7714H2.25001C1.00732 31.7714 0 30.7353 0 29.4571C0 28.179 1.00732 27.1428 2.25001 27.1428H33.75C34.9926 27.1428 36 28.179 36 29.4571C35.9999 30.7353 34.9926 31.7714 33.7499 31.7714ZM33.7499 20.2H2.25001C1.00732 20.2 0 19.1639 0 17.8858C0 16.6076 1.00732 15.5715 2.25001 15.5715H33.75C34.9926 15.5715 36 16.6076 36 17.8858C36 19.1639 34.9926 20.2 33.7499 20.2ZM33.7499 8.62858H2.25001C1.00732 8.62858 0 7.59249 0 6.31429C0 5.0361 1.00732 4 2.25001 4H33.75C34.9926 4 36 5.0361 36 6.31429C36 7.59249 34.9926 8.62858 33.7499 8.62858Z"
      fill="currentColor"
    />
  </svg>
);

export default Hamburger;
