import {
  KlScriptPromotionDto,
  KlScriptPromotionGetAvailablePromotionSlotsUsingGetParams,
} from '@/api/generated/data-contracts';
import { useApi } from '@/hooks/useApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { staleTime } from '@/const/httpQuery';
import { Script } from '@/api/generated/Script';
import { options } from '@/api/http/helper';

export const useGetAvailableSlots = (
  query: KlScriptPromotionGetAvailablePromotionSlotsUsingGetParams,
) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/script/promotion/slots'],
    queryFn: async () => {
      return new Script(httpClient).scriptPromotionGetAvailablePromotionSlotsUsingGet(query);
    },
    staleTime: staleTime(),
  });
};

export const usePromoteByTokens = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['post:/api/v1/script/promotion'],
    mutationFn: async (params: KlScriptPromotionDto) => {
      return new Script(httpClient).scriptPromotionPromoteUsingPost(params);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v1/script/promotion/slots'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/:scriptId'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v1/script/'],
      });
    },
    ...options,
  });
};

export const useGetScriptWithPromotions = (scriptId: number) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/script/', scriptId],
    queryFn: async () => {
      return new Script(httpClient).scriptFindByIdUsingGet(scriptId);
    },
    staleTime: staleTime(),
  });
};
