const ArrowCircleLeft = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_103_31566)">
      <circle cx="18" cy="18" r="17.561" stroke="currentColor" strokeWidth="0.878049" />
      <path
        d="M26.0488 16.8292H13.5819L19.3083 11.1028L17.8536 9.65845L9.65851 17.8536L17.8536 26.0487L19.298 24.6043L13.5819 18.878H26.0488V16.8292Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default ArrowCircleLeft;
