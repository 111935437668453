import { KlUserData } from '@/api/generated/data-contracts';
import React from 'react';
import { useGetUser } from '@/api/http/user';

interface UserWrapperWrapperProperties {
  children: (user: KlUserData) => React.ReactNode | null;
  isForceEnabled?: boolean;
}

const UserWrapper: React.FC<UserWrapperWrapperProperties> = ({
  children,
  isForceEnabled = false,
}) => {
  // force user GET to redirect to log in since we should have only single domain
  // for the protected routs, so here we're implementing force redirect
  const { data: user, isLoading: isUserLoading } = useGetUser(isForceEnabled);

  if (isUserLoading) {
    return null;
  }

  return (
    <>
      {children(user as KlUserData)}
    </>
  );
};

export default UserWrapper;
