const ArrowCircleRight = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_103_31575)">
      <circle cx="18" cy="18" r="17.561" stroke="currentColor" strokeWidth="0.878049" />
      <path
        d="M9.65854 18.878H22.1254L16.399 24.6043L17.8537 26.0487L26.0488 17.8536L17.8537 9.65845L16.4093 11.1028L22.1254 16.8292H9.65854V18.878Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default ArrowCircleRight;
