import { StrapiScreenplayCompetitionStatus } from '@/const/cmsInterface';
import Text from '@/ui-kit/Base/Typography/Text';
import Paragraph from '@/ui-kit/Base/Typography/Paragraph';

import Statistic from 'antd/es/statistic';
import { useTranslation } from 'react-i18next';
import { useGetPublicScriptsForCompetition } from '@/api/http/public';
import { KlScriptPublicData } from '@/api/generated/data-contracts';
import './strapiScreenplayCompetitionStatusComponent.less';
import { TitleProps } from 'antd/lib/typography/Title';
import React from 'react';
import { useCompetition, useCurrentCompetition } from '@/hooks/useCompetition';
import dayjs from 'dayjs';
import { SystemPaths } from '@/const/routes';
import StrapiCompetitionFilterWrapperComponent from '@/components/Cms/StrapiCompetitionFilterWrapperComponent';
import { KinolimeUpIcon } from '@/ui-kit/Base/Iconography';
import Heading from '@/ui-kit/Base/Typography/Heading';

import StrapiButtonComponent from '@/components/Cms/StrapiButtonComponent';
import { If } from '@/ui-kit/Base/If';
import Allori from '@/ui-kit/Components/Allori/Allori';
import LazyImage from '@/ui-kit/Base/Image/LazyImage';

const { Countdown } = Statistic;

interface StrapiScreenplayCompetitionStatusComponentProperties {
  block: StrapiScreenplayCompetitionStatus;
}

const StrapiScreenplayCompetitionStatusComponent: React.FC<StrapiScreenplayCompetitionStatusComponentProperties> = ({
  block,
}) => {
  const currentCompetition = useCurrentCompetition();
  const { getActiveStep } = useCompetition(currentCompetition);
  const activeStep = getActiveStep();
  const timeToStepEnds = dayjs(activeStep?.activeTo as string)
    .diff(dayjs(), 'milliseconds');

  const query = () => {
    switch (block.filter) {
      case 'LATEST': {
        return { sort: ['uploadDate: desc'] };
      }
      case 'EDITORS_PICK': {
        return { sort: ['uploadDate: desc'], isPickByEditor: true };
      }
      case 'SPONSORED': {
        return { sort: ['uploadDate: desc'], isSponsored: true };
      }
      case 'PODCAST': {
        return { sort: ['uploadDate: desc'], isPodcast: true };
      }

      case 'RATING': {
        return { sort: ['scriptVoteStatistics.rank: asc'] };
      }
      default: {
        return {};
      }
    }
  };

  const { t } = useTranslation();
  const { data: screenplays } = useGetPublicScriptsForCompetition({
    pageSize: 3,
    pageNumber: 0,
    ...(currentCompetition?.id && { competitionIds: [currentCompetition?.id] }),
    ...query(),
  });

  const isRatingVisible = import.meta.env?.VITE_IS_RATING_VISIBLE
    ? import.meta.env?.VITE_IS_RATING_VISIBLE_IN_CAROUSEL === 'true'
    : false;

  const renderScreenplays = () => {
    let rating = 0;
    return screenplays?.content?.map((item: KlScriptPublicData) => {
      rating += 1;
      // @todo refactor that component to use screenplay card component
      const dataRank = block?.displayLeaderboardNumbers ? rating : null;

      return (
        <a
          className="cSS-featured--item"
          key={item?.id}
          href={`${window.location.origin}${SystemPaths.PUBLIC_SCREENPLAYS}/${item?.reference}`}
        >
          <div className="ui-kit-screenplay-carousel-img-holder">
            <LazyImage
              data-rank={dataRank}
              src={`${window.location.origin}/${item.posterUrl}`}
              alt={item.name || ''}
            />
          </div>
          <div className="cSS-featured--item-body">
            <div className="cSS-featured--item-genre">
              <Paragraph
                className="white"
                ellipsis={
                  {
                    tooltip: {
                      title: item?.scriptGenres?.map((g) => g.name)?.join(' • '),
                      trigger: 'click',
                      arrow: false,
                    },
                  }
                }
              >
                {t('Genre: ')}
                <strong className={item?.scriptGenres?.length ? '' : 'secondary'}>
                  {item?.scriptGenres?.length ? item?.scriptGenres?.map((g) => g.name)?.join(' • ') : t('Not defined') }
                </strong>
              </Paragraph>
            </div>
            <Text className="cSS-featured--item-title">
              {item?.name}
            </Text>
            <If truthy={isRatingVisible}>
              <div className="cSS-featured--item-limes">
                <KinolimeUpIcon className="lime" size={14} />
                <span>
                  {t('Allori: ')}
                  <strong>
                    <Allori voteStatistic={item?.scriptVoteStatistic} />
                  </strong>
                </span>
              </div>
            </If>
          </div>
        </a>
      );
    });
  };

  // @todo  we should add this component to the storybook and refactor it
  // https://kinolime.atlassian.net/browse/KINDEV-3909
  return (
    <StrapiCompetitionFilterWrapperComponent
      isVisibleForCompetition={block?.isVisibleForCompetition}
      isHiddenForCompetition={block?.isHiddenForCompetition}
    >
      {() => (
        <div className="bg-light">
          <div className="cSS-section bg-white">
            <div className="cSS-content">
              <If truthy={!!block?.showCompetitionStatus}>
                <div className="cSS-info">
                  <Heading
                    title={block?.title?.text || ''}
                    titleClass="font-semibold"
                    subtitle={block?.title?.subtitle || ''}
                    subtitleClass="ui-kit-heading-subtitle font-bold"
                    size={block?.title?.size as TitleProps['level']}
                    color={block?.title?.color || 'dark'}
                    gap="xs"
                  />
                  <div className="cSS-info--body">
                    <Text className="cSS-info--competitionStatusValue">{activeStep?.name}</Text>

                    <div className="cSS-info--countdownAction">
                      <StrapiButtonComponent button={block.actionButton} key={block.actionButton.id} />
                      <div className="cSS-info--countdownDesc">
                        <Countdown value={Date.now() + timeToStepEnds} format="DD [days], HH [hours], mm [minutes], ss [seconds]" />
                        <Text>left till submission period ends</Text>
                      </div>
                    </div>
                    <StrapiButtonComponent button={block.linkButton} key={block.linkButton.id} />
                  </div>
                </div>
              </If>
              <div className="cSS-featured">
                <Heading
                  title={block?.carouselTitle || t('Featured: For Your Consideration')}
                  titleFontWeight="font-regular"
                  size={2}
                  color="light"
                />
                <div className="cSS-featured--body">
                  <div className="cSS-featured--container">
                    <div className="cSS-featured--items">
                      {renderScreenplays()}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      )}
    </StrapiCompetitionFilterWrapperComponent>
  );
};

export default StrapiScreenplayCompetitionStatusComponent;
