/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	KlBadgeFilterGetDataUsingGetData,
	KlBadgeFilterGetDataUsingGetError,
	KlBadgeFilterGetDataUsingGetParams,
	KlBillingReportHistoryFilterGetDataUsingGetData,
	KlBillingReportHistoryFilterGetDataUsingGetError,
	KlBillingReportHistoryFilterGetDataUsingGetParams,
	KlCustomAudioFilterGetDataUsingGetData,
	KlCustomAudioFilterGetDataUsingGetError,
	KlCustomAudioFilterGetDataUsingGetParams,
	KlProcessHistoryFilterGetDataUsingGetData,
	KlProcessHistoryFilterGetDataUsingGetError,
	KlProcessHistoryFilterGetDataUsingGetParams,
	KlScriptBonusReviewFilterGetDataUsingGetData,
	KlScriptBonusReviewFilterGetDataUsingGetError,
	KlScriptBonusReviewFilterGetDataUsingGetParams,
	KlScriptContinueReadingFilterGetDataUsingGetData,
	KlScriptContinueReadingFilterGetDataUsingGetError,
	KlScriptContinueReadingFilterGetDataUsingGetParams,
	KlScriptListeningProcessHistoryFilterGetDataUsingGetData,
	KlScriptListeningProcessHistoryFilterGetDataUsingGetError,
	KlScriptListeningProcessHistoryFilterGetDataUsingGetParams,
	KlScriptNoteFilterGetDataUsingGetData,
	KlScriptNoteFilterGetDataUsingGetError,
	KlScriptNoteFilterGetDataUsingGetParams,
	KlScriptPickByEditorFilterGetDataUsingGetData,
	KlScriptPickByEditorFilterGetDataUsingGetError,
	KlScriptPickByEditorFilterGetDataUsingGetParams,
	KlScriptSpeechActionAnnotationFilterGetDataUsingGetData,
	KlScriptSpeechActionAnnotationFilterGetDataUsingGetError,
	KlScriptSpeechActionAnnotationFilterGetDataUsingGetParams,
	KlScriptSpeechActorPersonFilterGetDataUsingGetData,
	KlScriptSpeechActorPersonFilterGetDataUsingGetError,
	KlScriptSpeechActorPersonFilterGetDataUsingGetParams,
	KlScriptSpeechActorSceneFilterGetDataUsingGetData,
	KlScriptSpeechActorSceneFilterGetDataUsingGetError,
	KlScriptSpeechActorSceneFilterGetDataUsingGetParams,
	KlScriptSpeechVoiceFilterGetDataUsingGetData,
	KlScriptSpeechVoiceFilterGetDataUsingGetError,
	KlScriptSpeechVoiceFilterGetDataUsingGetParams,
	KlScriptStatisticFilterGetDataUsingGetData,
	KlScriptStatisticFilterGetDataUsingGetError,
	KlScriptStatisticFilterGetDataUsingGetParams,
	KlScriptTopicFilterGetDataUsingGetData,
	KlScriptTopicFilterGetDataUsingGetError,
	KlScriptTopicFilterGetDataUsingGetParams,
	KlUserBadgeFilterGetDataUsingGetData,
	KlUserBadgeFilterGetDataUsingGetError,
	KlUserBadgeFilterGetDataUsingGetParams,
	KlUserBadgeHierarchyFilterGetDataUsingGetData,
	KlUserBadgeHierarchyFilterGetDataUsingGetError,
	KlUserBadgeHierarchyFilterGetDataUsingGetParams
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Filter<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http
	}

	/**
	 * No description
	 *
	 * @tags Filters
	 * @name BadgeFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/badge
	 * @secure
	 * @response `200` `KlBadgeFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	badgeFilterGetDataUsingGet = (
		query: KlBadgeFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlBadgeFilterGetDataUsingGetData,
			KlBadgeFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/badge`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name BillingReportHistoryFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/billing-report-history
	 * @secure
	 * @response `200` `KlBillingReportHistoryFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	billingReportHistoryFilterGetDataUsingGet = (
		query: KlBillingReportHistoryFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlBillingReportHistoryFilterGetDataUsingGetData,
			KlBillingReportHistoryFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/billing-report-history`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name CustomAudioFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/custom-audio
	 * @secure
	 * @response `200` `KlCustomAudioFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	customAudioFilterGetDataUsingGet = (
		query: KlCustomAudioFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlCustomAudioFilterGetDataUsingGetData,
			KlCustomAudioFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/custom-audio`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name ProcessHistoryFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/process-history
	 * @secure
	 * @response `200` `KlProcessHistoryFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	processHistoryFilterGetDataUsingGet = (
		query: KlProcessHistoryFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlProcessHistoryFilterGetDataUsingGetData,
			KlProcessHistoryFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/process-history`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name ScriptBonusReviewFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/script-bonus-review
	 * @secure
	 * @response `200` `KlScriptBonusReviewFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptBonusReviewFilterGetDataUsingGet = (
		query: KlScriptBonusReviewFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptBonusReviewFilterGetDataUsingGetData,
			KlScriptBonusReviewFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/script-bonus-review`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name ScriptContinueReadingFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/script-continue-reading
	 * @secure
	 * @response `200` `KlScriptContinueReadingFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptContinueReadingFilterGetDataUsingGet = (
		query: KlScriptContinueReadingFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptContinueReadingFilterGetDataUsingGetData,
			KlScriptContinueReadingFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/script-continue-reading`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name ScriptListeningProcessHistoryFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/script-listening-process-history
	 * @secure
	 * @response `200` `KlScriptListeningProcessHistoryFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptListeningProcessHistoryFilterGetDataUsingGet = (
		query: KlScriptListeningProcessHistoryFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptListeningProcessHistoryFilterGetDataUsingGetData,
			KlScriptListeningProcessHistoryFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/script-listening-process-history`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name ScriptNoteFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/script-notes
	 * @secure
	 * @response `200` `KlScriptNoteFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptNoteFilterGetDataUsingGet = (
		query: KlScriptNoteFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptNoteFilterGetDataUsingGetData,
			KlScriptNoteFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/script-notes`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name ScriptPickByEditorFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/script-pick-by-editor
	 * @secure
	 * @response `200` `KlScriptPickByEditorFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptPickByEditorFilterGetDataUsingGet = (
		query: KlScriptPickByEditorFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptPickByEditorFilterGetDataUsingGetData,
			KlScriptPickByEditorFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/script-pick-by-editor`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name ScriptSpeechActionAnnotationFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/script-speech-action-annotation
	 * @secure
	 * @response `200` `KlScriptSpeechActionAnnotationFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSpeechActionAnnotationFilterGetDataUsingGet = (
		query: KlScriptSpeechActionAnnotationFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSpeechActionAnnotationFilterGetDataUsingGetData,
			KlScriptSpeechActionAnnotationFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/script-speech-action-annotation`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name ScriptSpeechActorPersonFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/script-speech-actor-person
	 * @secure
	 * @response `200` `KlScriptSpeechActorPersonFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSpeechActorPersonFilterGetDataUsingGet = (
		query: KlScriptSpeechActorPersonFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSpeechActorPersonFilterGetDataUsingGetData,
			KlScriptSpeechActorPersonFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/script-speech-actor-person`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name ScriptSpeechActorSceneFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/script-speech-actor-scene
	 * @secure
	 * @response `200` `KlScriptSpeechActorSceneFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSpeechActorSceneFilterGetDataUsingGet = (
		query: KlScriptSpeechActorSceneFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSpeechActorSceneFilterGetDataUsingGetData,
			KlScriptSpeechActorSceneFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/script-speech-actor-scene`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name ScriptSpeechVoiceFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/script-speech-voice
	 * @secure
	 * @response `200` `KlScriptSpeechVoiceFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSpeechVoiceFilterGetDataUsingGet = (
		query: KlScriptSpeechVoiceFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSpeechVoiceFilterGetDataUsingGetData,
			KlScriptSpeechVoiceFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/script-speech-voice`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name ScriptStatisticFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/script-statistic
	 * @secure
	 * @response `200` `KlScriptStatisticFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptStatisticFilterGetDataUsingGet = (
		query: KlScriptStatisticFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptStatisticFilterGetDataUsingGetData,
			KlScriptStatisticFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/script-statistic`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name ScriptTopicFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/script-topic
	 * @secure
	 * @response `200` `KlScriptTopicFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptTopicFilterGetDataUsingGet = (
		query: KlScriptTopicFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptTopicFilterGetDataUsingGetData,
			KlScriptTopicFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/script-topic`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name UserBadgeFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/user-badge
	 * @secure
	 * @response `200` `KlUserBadgeFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	userBadgeFilterGetDataUsingGet = (
		query: KlUserBadgeFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlUserBadgeFilterGetDataUsingGetData,
			KlUserBadgeFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/user-badge`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name UserBadgeHierarchyFilterGetDataUsingGet
	 * @summary getData
	 * @request GET:/api/v1/filter/user-badge-hierarchy
	 * @secure
	 * @response `200` `KlUserBadgeHierarchyFilterGetDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	userBadgeHierarchyFilterGetDataUsingGet = (
		query: KlUserBadgeHierarchyFilterGetDataUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlUserBadgeHierarchyFilterGetDataUsingGetData,
			KlUserBadgeHierarchyFilterGetDataUsingGetError
		>({
			path: `/api/v1/filter/user-badge-hierarchy`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
}
