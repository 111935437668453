import classNames from 'classnames';
import If from '@/ui-kit/Base/If/If';
import Text from '@/ui-kit/Base/Typography/Text';

export interface ChessCarouselItemProperties {
  controls: React.ReactNode;
  text: string;
  subtitle: string;
  img: string;
  alt: string;
}

const ChessCarouselItem: React.FC<ChessCarouselItemProperties> = ({
  controls = null,
  text = null,
  subtitle = null,
  img,
  alt,
}) => {
  return (
    <div className={classNames('chess-carousel-item')}>
      <div>
        <img src={img} alt={alt} />
        <div className="controls">
          <Text className="controls-subtitle secondary">{subtitle}</Text>
          <If truthy={!!controls}>
            {controls}
          </If>
        </div>
      </div>
      <div className="content">
        <Text className="pre-line">{subtitle}</Text>
        <div className="line" />
        <If truthy={!!text}>
          <Text>
            {text}
          </Text>
        </If>
      </div>
    </div>
  );
};

export default ChessCarouselItem;
