/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	KlAvailableSubmitSurveyAvailableSubmitVoteReviewFormSurveysUsingGetData,
	KlAvailableSubmitSurveyAvailableSubmitVoteReviewFormSurveysUsingGetError,
	KlAvailableSubmitSurveyAvailableSubmitVoteReviewFormSurveysUsingGetParams,
	KlSubmitSurveySubmitSurveyInterviewBonusReviewUsingPostData,
	KlSubmitSurveySubmitSurveyInterviewBonusReviewUsingPostError,
	KlSubmitSurveySubmitSurveyInterviewBonusReviewUsingPostParams,
	KlSubmitSurveySubmitSurveyPodcastBonusReviewUsingPostData,
	KlSubmitSurveySubmitSurveyPodcastBonusReviewUsingPostError,
	KlSubmitSurveySubmitSurveyPodcastBonusReviewUsingPostParams,
	KlSubmitSurveySubmitSurveyReelBonusReviewUsingPostData,
	KlSubmitSurveySubmitSurveyReelBonusReviewUsingPostError,
	KlSubmitSurveySubmitSurveyReelBonusReviewUsingPostParams,
	KlSubmitSurveySubmitSurveyScriptBonusReviewUsingPostData,
	KlSubmitSurveySubmitSurveyScriptBonusReviewUsingPostError,
	KlSubmitSurveySubmitSurveyScriptBonusReviewUsingPostParams,
	KlSubmitSurveySubmitSurveyScriptListenBonusReviewUsingPostData,
	KlSubmitSurveySubmitSurveyScriptListenBonusReviewUsingPostError,
	KlSubmitSurveySubmitSurveyScriptListenBonusReviewUsingPostParams,
	KlSubmitSurveySubmitVoteReviewFormSurveyUsingPostData,
	KlSubmitSurveySubmitVoteReviewFormSurveyUsingPostError,
	KlSubmitSurveySubmitVoteReviewFormSurveyUsingPostParams,
	KlSurveyAuditGetSurveyAuditDataUsingGetData,
	KlSurveyAuditGetSurveyAuditDataUsingGetError,
	KlSurveyAuditHistoryUsingGetData,
	KlSurveyAuditHistoryUsingGetError,
	KlSurveyAuditHistoryUsingGetParams,
	KlSurveyGetSurveyInterviewBonusReviewUsingGetData,
	KlSurveyGetSurveyInterviewBonusReviewUsingGetError,
	KlSurveyGetSurveyInterviewBonusReviewUsingGetParams,
	KlSurveyGetSurveyPodcastBonusReviewUsingGetData,
	KlSurveyGetSurveyPodcastBonusReviewUsingGetError,
	KlSurveyGetSurveyPodcastBonusReviewUsingGetParams,
	KlSurveyGetSurveyReelBonusReviewUsingGetData,
	KlSurveyGetSurveyReelBonusReviewUsingGetError,
	KlSurveyGetSurveyReelBonusReviewUsingGetParams,
	KlSurveyGetSurveyScriptBonusReviewUsingGetData,
	KlSurveyGetSurveyScriptBonusReviewUsingGetError,
	KlSurveyGetSurveyScriptBonusReviewUsingGetParams,
	KlSurveyGetSurveyScriptListenBonusReviewUsingGetData,
	KlSurveyGetSurveyScriptListenBonusReviewUsingGetError,
	KlSurveyGetSurveyScriptListenBonusReviewUsingGetParams,
	KlSurveyGetSurveyScriptReviewUsingGetData,
	KlSurveyGetSurveyScriptReviewUsingGetError,
	KlSurveyGetSurveyScriptReviewUsingGetParams,
	KlSurveyGetVoteReviewFormSurveyUsingGetData,
	KlSurveyGetVoteReviewFormSurveyUsingGetError,
	KlSurveyGetVoteReviewFormSurveyUsingGetParams,
	KlSurveyParseFileGetSurveysUsingPostData,
	KlSurveyParseFileGetSurveysUsingPostError,
	KlSurveyReportZipExportUsingGetData,
	KlSurveyReportZipExportUsingGetError,
	KlSurveyReportZipRegenerateUsingPostData,
	KlSurveyReportZipRegenerateUsingPostError,
	KlSurveyRevoteStartRevoteReviewFormSurveyUsingPutData,
	KlSurveyRevoteStartRevoteReviewFormSurveyUsingPutError,
	KlSurveyRevoteStartRevoteReviewFormSurveyUsingPutParams,
	KlSurveySelectedAnswerDto,
	KlSurveySelectedAnswerSaveSurveyInterviewBonusReviewAnswersUsingPostData,
	KlSurveySelectedAnswerSaveSurveyInterviewBonusReviewAnswersUsingPostError,
	KlSurveySelectedAnswerSaveSurveyInterviewBonusReviewAnswersUsingPostParams,
	KlSurveySelectedAnswerSaveSurveyPodcastBonusReviewAnswersUsingPostData,
	KlSurveySelectedAnswerSaveSurveyPodcastBonusReviewAnswersUsingPostError,
	KlSurveySelectedAnswerSaveSurveyPodcastBonusReviewAnswersUsingPostParams,
	KlSurveySelectedAnswerSaveSurveyReelBonusReviewAnswersUsingPostData,
	KlSurveySelectedAnswerSaveSurveyReelBonusReviewAnswersUsingPostError,
	KlSurveySelectedAnswerSaveSurveyReelBonusReviewAnswersUsingPostParams,
	KlSurveySelectedAnswerSaveSurveyScriptBonusReviewAnswersUsingPostData,
	KlSurveySelectedAnswerSaveSurveyScriptBonusReviewAnswersUsingPostError,
	KlSurveySelectedAnswerSaveSurveyScriptBonusReviewAnswersUsingPostParams,
	KlSurveySelectedAnswerSaveSurveyScriptListenBonusReviewAnswersUsingPostData,
	KlSurveySelectedAnswerSaveSurveyScriptListenBonusReviewAnswersUsingPostError,
	KlSurveySelectedAnswerSaveSurveyScriptListenBonusReviewAnswersUsingPostParams,
	KlSurveySelectedAnswerSaveSurveyScriptReviewAnswersUsingPostData,
	KlSurveySelectedAnswerSaveSurveyScriptReviewAnswersUsingPostError,
	KlSurveySelectedAnswerSaveSurveyScriptReviewAnswersUsingPostParams,
	KlSurveySelectedAnswerSaveVoteReviewSurveySelectedAnswersUsingPostData,
	KlSurveySelectedAnswerSaveVoteReviewSurveySelectedAnswersUsingPostError,
	KlSurveySelectedAnswerSaveVoteReviewSurveySelectedAnswersUsingPostParams
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Survey<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http
	}

	/**
	 * No description
	 *
	 * @tags survey-audit-controller
	 * @name SurveyAuditHistoryUsingGet
	 * @summary history
	 * @request GET:/api/v1/survey/audit
	 * @secure
	 * @response `200` `KlSurveyAuditHistoryUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveyAuditHistoryUsingGet = (
		query: KlSurveyAuditHistoryUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveyAuditHistoryUsingGetData,
			KlSurveyAuditHistoryUsingGetError
		>({
			path: `/api/v1/survey/audit`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags survey-audit-controller
	 * @name SurveyAuditGetSurveyAuditDataUsingGet
	 * @summary getSurveyAuditData
	 * @request GET:/api/v1/survey/audit/{id}
	 * @secure
	 * @response `200` `KlSurveyAuditGetSurveyAuditDataUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveyAuditGetSurveyAuditDataUsingGet = (
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveyAuditGetSurveyAuditDataUsingGetData,
			KlSurveyAuditGetSurveyAuditDataUsingGetError
		>({
			path: `/api/v1/survey/audit/${id}`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags survey-report-zip-controller
	 * @name SurveyReportZipExportUsingGet
	 * @summary export
	 * @request GET:/api/v1/survey/export
	 * @secure
	 * @response `200` `KlSurveyReportZipExportUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveyReportZipExportUsingGet = (params: RequestParams = {}) =>
		this.http.request<
			KlSurveyReportZipExportUsingGetData,
			KlSurveyReportZipExportUsingGetError
		>({
			path: `/api/v1/survey/export`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SurveyGetSurveyInterviewBonusReviewUsingGet
	 * @summary getSurveyInterviewBonusReview
	 * @request GET:/api/v1/survey/interview-bonus-review
	 * @deprecated
	 * @secure
	 * @response `200` `KlSurveyGetSurveyInterviewBonusReviewUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveyGetSurveyInterviewBonusReviewUsingGet = (
		query: KlSurveyGetSurveyInterviewBonusReviewUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveyGetSurveyInterviewBonusReviewUsingGetData,
			KlSurveyGetSurveyInterviewBonusReviewUsingGetError
		>({
			path: `/api/v1/survey/interview-bonus-review`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script import
	 * @name SurveyParseFileGetSurveysUsingPost
	 * @summary getSurveys
	 * @request POST:/api/v1/survey/parse
	 * @secure
	 * @response `200` `KlSurveyParseFileGetSurveysUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveyParseFileGetSurveysUsingPost = (
		data: any,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveyParseFileGetSurveysUsingPostData,
			KlSurveyParseFileGetSurveysUsingPostError
		>({
			path: `/api/v1/survey/parse`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SurveyGetSurveyPodcastBonusReviewUsingGet
	 * @summary getSurveyPodcastBonusReview
	 * @request GET:/api/v1/survey/podcast-bonus-review
	 * @deprecated
	 * @secure
	 * @response `200` `KlSurveyGetSurveyPodcastBonusReviewUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveyGetSurveyPodcastBonusReviewUsingGet = (
		query: KlSurveyGetSurveyPodcastBonusReviewUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveyGetSurveyPodcastBonusReviewUsingGetData,
			KlSurveyGetSurveyPodcastBonusReviewUsingGetError
		>({
			path: `/api/v1/survey/podcast-bonus-review`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SurveyGetSurveyReelBonusReviewUsingGet
	 * @summary getSurveyReelBonusReview
	 * @request GET:/api/v1/survey/reel-bonus-review
	 * @deprecated
	 * @secure
	 * @response `200` `KlSurveyGetSurveyReelBonusReviewUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveyGetSurveyReelBonusReviewUsingGet = (
		query: KlSurveyGetSurveyReelBonusReviewUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveyGetSurveyReelBonusReviewUsingGetData,
			KlSurveyGetSurveyReelBonusReviewUsingGetError
		>({
			path: `/api/v1/survey/reel-bonus-review`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags survey-report-zip-controller
	 * @name SurveyReportZipRegenerateUsingPost
	 * @summary regenerate
	 * @request POST:/api/v1/survey/regenerate
	 * @secure
	 * @response `200` `KlSurveyReportZipRegenerateUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveyReportZipRegenerateUsingPost = (params: RequestParams = {}) =>
		this.http.request<
			KlSurveyReportZipRegenerateUsingPostData,
			KlSurveyReportZipRegenerateUsingPostError
		>({
			path: `/api/v1/survey/regenerate`,
			method: 'POST',
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SurveyRevoteStartRevoteReviewFormSurveyUsingPut
	 * @summary startRevoteReviewFormSurvey
	 * @request PUT:/api/v1/survey/revote/vote-review
	 * @secure
	 * @response `200` `KlSurveyRevoteStartRevoteReviewFormSurveyUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveyRevoteStartRevoteReviewFormSurveyUsingPut = (
		query: KlSurveyRevoteStartRevoteReviewFormSurveyUsingPutParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveyRevoteStartRevoteReviewFormSurveyUsingPutData,
			KlSurveyRevoteStartRevoteReviewFormSurveyUsingPutError
		>({
			path: `/api/v1/survey/revote/vote-review`,
			method: 'PUT',
			query: query,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SurveyGetSurveyScriptBonusReviewUsingGet
	 * @summary getSurveyScriptBonusReview
	 * @request GET:/api/v1/survey/script-bonus-review
	 * @deprecated
	 * @secure
	 * @response `200` `KlSurveyGetSurveyScriptBonusReviewUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveyGetSurveyScriptBonusReviewUsingGet = (
		query: KlSurveyGetSurveyScriptBonusReviewUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveyGetSurveyScriptBonusReviewUsingGetData,
			KlSurveyGetSurveyScriptBonusReviewUsingGetError
		>({
			path: `/api/v1/survey/script-bonus-review`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SurveyGetSurveyScriptListenBonusReviewUsingGet
	 * @summary getSurveyScriptListenBonusReview
	 * @request GET:/api/v1/survey/script-listen-bonus-review
	 * @deprecated
	 * @secure
	 * @response `200` `KlSurveyGetSurveyScriptListenBonusReviewUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveyGetSurveyScriptListenBonusReviewUsingGet = (
		query: KlSurveyGetSurveyScriptListenBonusReviewUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveyGetSurveyScriptListenBonusReviewUsingGetData,
			KlSurveyGetSurveyScriptListenBonusReviewUsingGetError
		>({
			path: `/api/v1/survey/script-listen-bonus-review`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SurveyGetSurveyScriptReviewUsingGet
	 * @summary getSurveyScriptReview
	 * @request GET:/api/v1/survey/script-review
	 * @secure
	 * @response `200` `KlSurveyGetSurveyScriptReviewUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveyGetSurveyScriptReviewUsingGet = (
		query: KlSurveyGetSurveyScriptReviewUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveyGetSurveyScriptReviewUsingGetData,
			KlSurveyGetSurveyScriptReviewUsingGetError
		>({
			path: `/api/v1/survey/script-review`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SurveySelectedAnswerSaveSurveyInterviewBonusReviewAnswersUsingPost
	 * @summary saveSurveyInterviewBonusReviewAnswers
	 * @request POST:/api/v1/survey/selected-answer/interview-bonus-review
	 * @deprecated
	 * @secure
	 * @response `200` `KlSurveySelectedAnswerSaveSurveyInterviewBonusReviewAnswersUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveySelectedAnswerSaveSurveyInterviewBonusReviewAnswersUsingPost = (
		query: KlSurveySelectedAnswerSaveSurveyInterviewBonusReviewAnswersUsingPostParams,
		selectedAnswers: KlSurveySelectedAnswerDto[],
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveySelectedAnswerSaveSurveyInterviewBonusReviewAnswersUsingPostData,
			KlSurveySelectedAnswerSaveSurveyInterviewBonusReviewAnswersUsingPostError
		>({
			path: `/api/v1/survey/selected-answer/interview-bonus-review`,
			method: 'POST',
			query: query,
			body: selectedAnswers,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SurveySelectedAnswerSaveSurveyPodcastBonusReviewAnswersUsingPost
	 * @summary saveSurveyPodcastBonusReviewAnswers
	 * @request POST:/api/v1/survey/selected-answer/podcast-bonus-review
	 * @deprecated
	 * @secure
	 * @response `200` `KlSurveySelectedAnswerSaveSurveyPodcastBonusReviewAnswersUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveySelectedAnswerSaveSurveyPodcastBonusReviewAnswersUsingPost = (
		query: KlSurveySelectedAnswerSaveSurveyPodcastBonusReviewAnswersUsingPostParams,
		selectedAnswers: KlSurveySelectedAnswerDto[],
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveySelectedAnswerSaveSurveyPodcastBonusReviewAnswersUsingPostData,
			KlSurveySelectedAnswerSaveSurveyPodcastBonusReviewAnswersUsingPostError
		>({
			path: `/api/v1/survey/selected-answer/podcast-bonus-review`,
			method: 'POST',
			query: query,
			body: selectedAnswers,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SurveySelectedAnswerSaveSurveyReelBonusReviewAnswersUsingPost
	 * @summary saveSurveyReelBonusReviewAnswers
	 * @request POST:/api/v1/survey/selected-answer/reel-bonus-review
	 * @deprecated
	 * @secure
	 * @response `200` `KlSurveySelectedAnswerSaveSurveyReelBonusReviewAnswersUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveySelectedAnswerSaveSurveyReelBonusReviewAnswersUsingPost = (
		query: KlSurveySelectedAnswerSaveSurveyReelBonusReviewAnswersUsingPostParams,
		selectedAnswers: KlSurveySelectedAnswerDto[],
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveySelectedAnswerSaveSurveyReelBonusReviewAnswersUsingPostData,
			KlSurveySelectedAnswerSaveSurveyReelBonusReviewAnswersUsingPostError
		>({
			path: `/api/v1/survey/selected-answer/reel-bonus-review`,
			method: 'POST',
			query: query,
			body: selectedAnswers,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SurveySelectedAnswerSaveSurveyScriptBonusReviewAnswersUsingPost
	 * @summary saveSurveyScriptBonusReviewAnswers
	 * @request POST:/api/v1/survey/selected-answer/script-bonus-review
	 * @deprecated
	 * @secure
	 * @response `200` `KlSurveySelectedAnswerSaveSurveyScriptBonusReviewAnswersUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveySelectedAnswerSaveSurveyScriptBonusReviewAnswersUsingPost = (
		query: KlSurveySelectedAnswerSaveSurveyScriptBonusReviewAnswersUsingPostParams,
		selectedAnswers: KlSurveySelectedAnswerDto[],
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveySelectedAnswerSaveSurveyScriptBonusReviewAnswersUsingPostData,
			KlSurveySelectedAnswerSaveSurveyScriptBonusReviewAnswersUsingPostError
		>({
			path: `/api/v1/survey/selected-answer/script-bonus-review`,
			method: 'POST',
			query: query,
			body: selectedAnswers,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SurveySelectedAnswerSaveSurveyScriptListenBonusReviewAnswersUsingPost
	 * @summary saveSurveyScriptListenBonusReviewAnswers
	 * @request POST:/api/v1/survey/selected-answer/script-listen-bonus-review
	 * @deprecated
	 * @secure
	 * @response `200` `KlSurveySelectedAnswerSaveSurveyScriptListenBonusReviewAnswersUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveySelectedAnswerSaveSurveyScriptListenBonusReviewAnswersUsingPost = (
		query: KlSurveySelectedAnswerSaveSurveyScriptListenBonusReviewAnswersUsingPostParams,
		selectedAnswers: KlSurveySelectedAnswerDto[],
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveySelectedAnswerSaveSurveyScriptListenBonusReviewAnswersUsingPostData,
			KlSurveySelectedAnswerSaveSurveyScriptListenBonusReviewAnswersUsingPostError
		>({
			path: `/api/v1/survey/selected-answer/script-listen-bonus-review`,
			method: 'POST',
			query: query,
			body: selectedAnswers,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SurveySelectedAnswerSaveSurveyScriptReviewAnswersUsingPost
	 * @summary saveSurveyScriptReviewAnswers
	 * @request POST:/api/v1/survey/selected-answer/script-review
	 * @secure
	 * @response `200` `KlSurveySelectedAnswerSaveSurveyScriptReviewAnswersUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveySelectedAnswerSaveSurveyScriptReviewAnswersUsingPost = (
		query: KlSurveySelectedAnswerSaveSurveyScriptReviewAnswersUsingPostParams,
		selectedAnswers: KlSurveySelectedAnswerDto[],
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveySelectedAnswerSaveSurveyScriptReviewAnswersUsingPostData,
			KlSurveySelectedAnswerSaveSurveyScriptReviewAnswersUsingPostError
		>({
			path: `/api/v1/survey/selected-answer/script-review`,
			method: 'POST',
			query: query,
			body: selectedAnswers,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SurveySelectedAnswerSaveVoteReviewSurveySelectedAnswersUsingPost
	 * @summary saveVoteReviewSurveySelectedAnswers
	 * @request POST:/api/v1/survey/selected-answer/vote-review
	 * @secure
	 * @response `200` `KlSurveySelectedAnswerSaveVoteReviewSurveySelectedAnswersUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveySelectedAnswerSaveVoteReviewSurveySelectedAnswersUsingPost = (
		query: KlSurveySelectedAnswerSaveVoteReviewSurveySelectedAnswersUsingPostParams,
		selectedAnswers: KlSurveySelectedAnswerDto[],
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveySelectedAnswerSaveVoteReviewSurveySelectedAnswersUsingPostData,
			KlSurveySelectedAnswerSaveVoteReviewSurveySelectedAnswersUsingPostError
		>({
			path: `/api/v1/survey/selected-answer/vote-review`,
			method: 'POST',
			query: query,
			body: selectedAnswers,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SubmitSurveySubmitSurveyInterviewBonusReviewUsingPost
	 * @summary submitSurveyInterviewBonusReview
	 * @request POST:/api/v1/survey/submit/interview-bonus-review
	 * @deprecated
	 * @secure
	 * @response `200` `KlSubmitSurveySubmitSurveyInterviewBonusReviewUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	submitSurveySubmitSurveyInterviewBonusReviewUsingPost = (
		query: KlSubmitSurveySubmitSurveyInterviewBonusReviewUsingPostParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSubmitSurveySubmitSurveyInterviewBonusReviewUsingPostData,
			KlSubmitSurveySubmitSurveyInterviewBonusReviewUsingPostError
		>({
			path: `/api/v1/survey/submit/interview-bonus-review`,
			method: 'POST',
			query: query,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SubmitSurveySubmitSurveyPodcastBonusReviewUsingPost
	 * @summary submitSurveyPodcastBonusReview
	 * @request POST:/api/v1/survey/submit/podcast-bonus-review
	 * @deprecated
	 * @secure
	 * @response `200` `KlSubmitSurveySubmitSurveyPodcastBonusReviewUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	submitSurveySubmitSurveyPodcastBonusReviewUsingPost = (
		query: KlSubmitSurveySubmitSurveyPodcastBonusReviewUsingPostParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSubmitSurveySubmitSurveyPodcastBonusReviewUsingPostData,
			KlSubmitSurveySubmitSurveyPodcastBonusReviewUsingPostError
		>({
			path: `/api/v1/survey/submit/podcast-bonus-review`,
			method: 'POST',
			query: query,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SubmitSurveySubmitSurveyReelBonusReviewUsingPost
	 * @summary submitSurveyReelBonusReview
	 * @request POST:/api/v1/survey/submit/reel-bonus-review
	 * @deprecated
	 * @secure
	 * @response `200` `KlSubmitSurveySubmitSurveyReelBonusReviewUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	submitSurveySubmitSurveyReelBonusReviewUsingPost = (
		query: KlSubmitSurveySubmitSurveyReelBonusReviewUsingPostParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSubmitSurveySubmitSurveyReelBonusReviewUsingPostData,
			KlSubmitSurveySubmitSurveyReelBonusReviewUsingPostError
		>({
			path: `/api/v1/survey/submit/reel-bonus-review`,
			method: 'POST',
			query: query,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SubmitSurveySubmitSurveyScriptBonusReviewUsingPost
	 * @summary submitSurveyScriptBonusReview
	 * @request POST:/api/v1/survey/submit/script-bonus-review
	 * @deprecated
	 * @secure
	 * @response `200` `KlSubmitSurveySubmitSurveyScriptBonusReviewUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	submitSurveySubmitSurveyScriptBonusReviewUsingPost = (
		query: KlSubmitSurveySubmitSurveyScriptBonusReviewUsingPostParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSubmitSurveySubmitSurveyScriptBonusReviewUsingPostData,
			KlSubmitSurveySubmitSurveyScriptBonusReviewUsingPostError
		>({
			path: `/api/v1/survey/submit/script-bonus-review`,
			method: 'POST',
			query: query,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SubmitSurveySubmitSurveyScriptListenBonusReviewUsingPost
	 * @summary submitSurveyScriptListenBonusReview
	 * @request POST:/api/v1/survey/submit/script-listen-bonus-review
	 * @deprecated
	 * @secure
	 * @response `200` `KlSubmitSurveySubmitSurveyScriptListenBonusReviewUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	submitSurveySubmitSurveyScriptListenBonusReviewUsingPost = (
		query: KlSubmitSurveySubmitSurveyScriptListenBonusReviewUsingPostParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSubmitSurveySubmitSurveyScriptListenBonusReviewUsingPostData,
			KlSubmitSurveySubmitSurveyScriptListenBonusReviewUsingPostError
		>({
			path: `/api/v1/survey/submit/script-listen-bonus-review`,
			method: 'POST',
			query: query,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SubmitSurveySubmitVoteReviewFormSurveyUsingPost
	 * @summary submitVoteReviewFormSurvey
	 * @request POST:/api/v1/survey/submit/vote-review
	 * @secure
	 * @response `200` `KlSubmitSurveySubmitVoteReviewFormSurveyUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	submitSurveySubmitVoteReviewFormSurveyUsingPost = (
		query: KlSubmitSurveySubmitVoteReviewFormSurveyUsingPostParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSubmitSurveySubmitVoteReviewFormSurveyUsingPostData,
			KlSubmitSurveySubmitVoteReviewFormSurveyUsingPostError
		>({
			path: `/api/v1/survey/submit/vote-review`,
			method: 'POST',
			query: query,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name SurveyGetVoteReviewFormSurveyUsingGet
	 * @summary getVoteReviewFormSurvey
	 * @request GET:/api/v1/survey/vote-review
	 * @secure
	 * @response `200` `KlSurveyGetVoteReviewFormSurveyUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	surveyGetVoteReviewFormSurveyUsingGet = (
		query: KlSurveyGetVoteReviewFormSurveyUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlSurveyGetVoteReviewFormSurveyUsingGetData,
			KlSurveyGetVoteReviewFormSurveyUsingGetError
		>({
			path: `/api/v1/survey/vote-review`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Survey
	 * @name AvailableSubmitSurveyAvailableSubmitVoteReviewFormSurveysUsingGet
	 * @summary availableSubmitVoteReviewFormSurveys
	 * @request GET:/api/v2/survey/submit/available
	 * @secure
	 * @response `200` `KlAvailableSubmitSurveyAvailableSubmitVoteReviewFormSurveysUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	availableSubmitSurveyAvailableSubmitVoteReviewFormSurveysUsingGet = (
		query: KlAvailableSubmitSurveyAvailableSubmitVoteReviewFormSurveysUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlAvailableSubmitSurveyAvailableSubmitVoteReviewFormSurveysUsingGetData,
			KlAvailableSubmitSurveyAvailableSubmitVoteReviewFormSurveysUsingGetError
		>({
			path: `/api/v2/survey/submit/available`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
}
