const CircleFilled = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_181207_1560)">
      <path d="M16.3885 11.8592L23.8646 17.2945C23.9801 17.3773 24.0743 17.4865 24.1392 17.613C24.2041 17.7395 24.238 17.8796 24.238 18.0218C24.238 18.164 24.2041 18.3041 24.1392 18.4306C24.0743 18.5571 23.9801 18.6663 23.8646 18.7492L16.3885 24.1797C16.2545 24.2784 16.0957 24.3381 15.9298 24.3519C15.7639 24.3656 15.5974 24.3331 15.4489 24.2578C15.3005 24.1825 15.1758 24.0674 15.0889 23.9254C15.002 23.7834 14.9563 23.62 14.9568 23.4535V12.5865C14.9569 12.4203 15.003 12.2573 15.09 12.1157C15.177 11.974 15.3015 11.8592 15.4497 11.7839C15.5979 11.7086 15.764 11.6758 15.9297 11.6891C16.0954 11.7024 16.2542 11.7612 16.3885 11.8592Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M-0.0773926 18.0008C-0.0773926 8.05638 7.98011 -0.00219727 17.9232 -0.00219727C27.8639 -0.00219727 35.9238 8.05638 35.9226 18.0008C35.9226 27.9404 27.8639 35.9978 17.9232 35.9978C7.98011 35.9978 -0.0773926 27.9416 -0.0773926 18.0008ZM2.9295 18.0008C2.9295 26.2834 9.64288 32.9941 17.9232 32.9941C26.2023 32.9941 32.9157 26.2834 32.9145 18.0008C32.9145 9.72057 26.2023 3.0027 17.9232 3.0027C9.64288 3.0027 2.9295 9.72177 2.9295 18.0008Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_181207_1560">
        <rect width="36" height="36" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
export default CircleFilled;
