import { useTranslation } from 'react-i18next';
import LazyImage from '@/ui-kit/Base/Image/LazyImage';
import { SystemPaths } from '@/const/routes';
import { KlCompetitionData, KlReviewFormsEnum, KlScriptVoteStatisticPublicData } from '@/api/generated/data-contracts';
import Space from 'antd/es/space';
import Progress from 'antd/es/progress';
import React, { Suspense } from 'react';
import AddToMyLabButton from '@/ui-kit/Components/ScreenplayCarouselSection/AddToMyLabButton';
import If from '@/ui-kit/Base/If/If';
import { KinolimeUpIcon, PodcastIcon } from '@/ui-kit/Base/Iconography';
import Paragraph from '@/ui-kit/Base/Typography/Paragraph';
import Text from '@/ui-kit/Base/Typography/Text';

import './screenplayCarouselItem.less';
import { useGetCompetitionsUsingGet } from '@/api/http/public';
import { useCompetition } from '@/hooks/useCompetition';
import Allori from '@/ui-kit/Components/Allori/Allori';
import Button from '../../Base/Button/Button';
import { useCurrentStatusUsingGet } from '@/api/http/screenplay';
import { useGetUser } from '@/api/http/user';
import { useNavigateWithHistory } from '@/hooks/useNavigateBack';

export interface ScreenplayCarouselItemProperties {
  id: number | undefined;
  title?: string;
  genres?: string[];
  imageSrc?: string;
  logline?: string;
  reference?: string;
  showLogline?: boolean;
  onClick?: () => void;
  onLinkClick?: () => Promise<void>;
  voteStatistic?: KlScriptVoteStatisticPublicData;
  continueReading?: boolean;
  readingPercent?: number;
  isInMyLab?: boolean;
  hasPodcast?: boolean;
  activityBar?: React.ReactNode;
  badges?: React.ReactNode | React.ReactNode[];
  podcastId?: number,
  interviewId?: number,
  isArchived?: boolean,
}

export const ScreenplayCarouselItem: React.FC<ScreenplayCarouselItemProperties> = ({
  id,
  title = '',
  genres,
  imageSrc,
  logline = '',
  reference = '',
  showLogline = false,
  onClick,
  onLinkClick,
  voteStatistic,
  continueReading = false,
  readingPercent = 0,
  isInMyLab = false,
  hasPodcast = false,
  activityBar = null,
  badges = null,
  podcastId,
  interviewId,
  isArchived = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigateWithHistory();
  const href = `${window.location.origin}${SystemPaths.PUBLIC_SCREENPLAYS}/${reference}${continueReading ? '/read' : ''}`;
  const { data: competitionData } = useGetCompetitionsUsingGet(true);
  const currentCompetition = competitionData?.content?.find((item) => item.isActive);
  const { competitionPodcastsEnabled, isFinalVote } = useCompetition(currentCompetition as KlCompetitionData);
  const isCompetitionPodcastsEnabled = competitionPodcastsEnabled();
  const { data: user } = useGetUser();
  const isFinalVoteActive = isFinalVote();

  const showPodcastIcon = hasPodcast && isCompetitionPodcastsEnabled;
  const isRatingVisible = import.meta.env?.VITE_IS_RATING_VISIBLE
    ? import.meta.env?.VITE_IS_RATING_VISIBLE_IN_CAROUSEL === 'true'
    : false;

  const { data: scriptStatusData, isLoading: isScriptStatusDataLoading } = useCurrentStatusUsingGet(
    user?.id && !isArchived && isFinalVoteActive ? id : undefined,
    ['SCRIPT_READ_VOTE_FORM', 'SCRIPT_LISTEN_VOTE_FORM'] as unknown as KlReviewFormsEnum,
  );
  const { data: scriptPodcastStatusData, isLoading: isScriptPodcastStatusDataLoading } = useCurrentStatusUsingGet(
    user?.id && !isArchived && isFinalVoteActive ? podcastId : undefined,
    ['PODCAST_VOTE_FORM'] as unknown as KlReviewFormsEnum,
  );

  const { data: scriptInterviewStatusData, isLoading: isScriptInterviewStatusDataLoading } = useCurrentStatusUsingGet(
    user?.id && !isArchived && isFinalVoteActive ? interviewId : undefined,
    ['INTERVIEW_VOTE_FORM'] as unknown as KlReviewFormsEnum,
  );

  const isReadReviewedAvailable = !!scriptStatusData?.SCRIPT_READ_VOTE_FORM?.isSubmitted || !!scriptStatusData?.SCRIPT_READ_VOTE_FORM?.isAvailableToSubmit;
  const isListenReviewAvailable = !!scriptStatusData?.SCRIPT_LISTEN_VOTE_FORM?.isSubmitted || !!scriptStatusData?.SCRIPT_LISTEN_VOTE_FORM?.isAvailableToSubmit;
  const isPodcastReviewedAvailable = !!scriptPodcastStatusData?.PODCAST_VOTE_FORM?.isSubmitted || !!scriptPodcastStatusData?.PODCAST_VOTE_FORM?.isAvailableToSubmit;
  const isInterviewReviewedAvailable = !!scriptInterviewStatusData?.INTERVIEW_VOTE_FORM.isSubmitted || !!scriptInterviewStatusData?.INTERVIEW_VOTE_FORM?.isAvailableToSubmit;

  const isFinalVoteAvailable = isReadReviewedAvailable || isListenReviewAvailable || isPodcastReviewedAvailable || isInterviewReviewedAvailable;

  return (
    <a
      className="ui-kit-screenplay-carousel-item"
      href={href}
      onClick={async (event) => {
        if (!onClick) {
          await onLinkClick?.();
          return;
        }
        if (!continueReading) {
          event.preventDefault();
          onClick?.();
        }
      }}
    >
      <Suspense fallback={<span />}>
        <If truthy={!!imageSrc}>
          <div className="ui-kit-screenplay-carousel-img-holder">
            <If truthy={!isArchived && isFinalVote() && !isScriptStatusDataLoading && !isScriptPodcastStatusDataLoading && !isScriptInterviewStatusDataLoading}>
              <div className="overlay-bottom-left">
                <Button
                  type="primary"
                  variant={isFinalVoteAvailable ? 'dark' : 'light'}
                  onClick={(e) => {
                    e.preventDefault();
                    if (isFinalVoteAvailable) {
                      if (isReadReviewedAvailable) {
                        return navigate(`${SystemPaths.PUBLIC_SCREENPLAYS}/${reference}/read?vote=true`);
                      }

                      if (isListenReviewAvailable) {
                        return navigate(`${SystemPaths.PUBLIC_SCREENPLAYS}/${reference}/listen?vote=true`);
                      }

                      if (isPodcastReviewedAvailable) {
                        return navigate(`${SystemPaths.PUBLIC_SCREENPLAYS}/${reference}/podcast?vote=true`);
                      }

                      if (isInterviewReviewedAvailable) {
                        return navigate(`${SystemPaths.PUBLIC_SCREENPLAYS}/${reference}/interview?vote=true`);
                      }

                      return navigate(`${SystemPaths.PUBLIC_SCREENPLAYS}/${reference}/read$?vote=true`);
                    }
                    navigate(`${SystemPaths.PUBLIC_SCREENPLAYS}/${reference}`);
                  }}
                >
                  {isFinalVoteAvailable ? t('Vote now') : t('Unlock vote')}
                </Button>
              </div>
            </If>
            <LazyImage data-rank={isFinalVote() ? voteStatistic?.finalRank : voteStatistic?.rank} src={`${window.location.origin}/${imageSrc}`} alt={title} />
            <If truthy={!!activityBar}>
              {activityBar}
            </If>
            <If truthy={!!badges}>
              <div className="overlay-top-right">
                {badges}
              </div>
            </If>
            <If truthy={continueReading}>
              <div className="reading-progress-wrapper">
                <Progress showInfo={false} size="small" status="active" strokeColor="#ffffff" trailColor="#aeaeae" percent={readingPercent} />
              </div>
            </If>
            <If truthy={showPodcastIcon}>
              <div className="overlay-bottom-left">
                <PodcastIcon className="light" size={24} />
              </div>
            </If>
          </div>
        </If>
        <If truthy={!imageSrc}>
          <div className="image-placeholder" />
        </If>
        <div className="screenplay-content">
          <div className="screenplay-data">
            <Space className="screenplay-data-mylab-and-genres">
              <If truthy={!!currentCompetition}>
                <AddToMyLabButton
                  disabled={isInMyLab}
                  screenplayId={id}
                  screenplayTitle={title}
                />
              </If>
              <Paragraph
                className="white"
                ellipsis={
                {
                  tooltip:
                    {
                      title: genres?.join(' • '),
                      trigger: 'hover',
                      arrow: false,
                    },
                }
              }
              >
                {t('Genre: ')}
                <strong className={genres?.length ? '' : 'secondary'}>
                  { genres?.length ? genres.join(' • ') : t('Not defined') }
                </strong>
              </Paragraph>
            </Space>
            <If truthy={isRatingVisible}>
              <Space className="count" size={4}>
                <KinolimeUpIcon size={14} />
                <Paragraph className="lime">
                  {t('Allori: ')}
                  <Allori voteStatistic={voteStatistic} />
                </Paragraph>
              </Space>
            </If>
          </div>
          <Text
            className="light"
            ellipsis={
          {
            tooltip:
              {
                title,
                trigger: 'hover',
                arrow: false,
              },
          }
        }
          >
            {title}
          </Text>
        </div>
        <If truthy={showLogline}>
          <Paragraph className="screenplay-logline">
            {logline}
          </Paragraph>
        </If>
      </Suspense>
    </a>

  );
};

export default ScreenplayCarouselItem;
