import React, { useEffect, useRef, useState } from 'react';
import If from '@/ui-kit/Base/If/If';
import './lazyImage.less';

export interface LazyImageProperties {
  src: string;
  alt: string;
  className?: string;
}

const LazyImage: React.FC<LazyImageProperties> = ({
  src, alt, className = '', ...rest
}) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            setIsVisible(true);
            setIsPreviewLoading(true);
            observer.unobserve(entry.target);
          }
        }
      },
      { threshold: 0.01, rootMargin: '20px' },
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  return (
    <div className="lazy-image" {...rest}>
      <If truthy={isVisible}>
        <img
          className={className}
          ref={imageRef}
          src={src}
          alt={alt}
          style={{
            opacity: isPreviewLoading ? '0.3' : '1',
          }}
          onLoad={() => {
            setIsPreviewLoading(false);
          }}
          onError={() => {
            setIsPreviewLoading(false);
          }}
        />
      </If>
      <If truthy={!isVisible}>
        <img
          className={className}
          ref={imageRef}
          src="data:image/svg;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
          alt={alt}
        />
      </If>
    </div>
  );
};

export default LazyImage;
