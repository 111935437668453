import React from 'react';
import { Helmet } from 'react-helmet';

export interface PageProperties {
  title: string;
  description: string;
  inIndex: boolean;
  slug?: string;
}

const Seo: React.FC<PageProperties> = ({
  title = '',
  description = '',
  inIndex = false,
  slug,
}) => {
  const renderCanonical = () => {
    if (!slug) {
      return null;
    }

    return <link rel="canonical" href={`${import.meta.env.VITE_REACT_PUBLIC_SITE_URL as string}${slug?.replace(/^\//, '')}`} />;
  };

  return (
    <Helmet>
      <title lang="en">{title}</title>
      { inIndex ? <meta name="robots" content="all" /> : <meta name="robots" content="noindex" /> }
      <meta name="title" lang="en" content={title} />
      <meta name="description" lang="en" content={description} />
      {renderCanonical()}
    </Helmet>
  );
};

export default Seo;
