const Plus = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_12396_3978)">
      <path
        d="M36 20.7295H20.7295V36H15.2705V20.7295H0V15.2705H15.2705V0H20.7295V15.2705H36V20.7295Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_12396_3978">
        <rect width="100%" height="100%" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Plus;
