const Star = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_12281_32227)">
      <path
        d="M18.0006 1C18.6282 1 19.2012 1.3547 19.474 1.92086L24.1533 11.5592L34.6034 13.1008C35.2173 13.1895 35.7289 13.6192 35.9199 14.2127C36.1109 14.8061 35.954 15.4473 35.5175 15.8839L27.9391 23.4008L29.7263 34.0146C29.8286 34.6285 29.5762 35.2492 29.0714 35.6176C28.5667 35.9859 27.8914 36.0269 27.3457 35.7336L18.0006 30.7404L8.6624 35.7267C8.10988 36.02 7.4414 35.9791 6.93663 35.6108C6.43187 35.2424 6.17266 34.6217 6.27498 34.0078L8.06213 23.394L0.483779 15.8839C0.0404009 15.4473 -0.109666 14.7993 0.0813281 14.2127C0.272322 13.626 0.783912 13.1963 1.39782 13.1008L11.8479 11.5592L16.5272 1.92086C16.8069 1.3547 17.3731 1 18.0006 1ZM18.0006 6.38875L14.4195 13.7693C14.1807 14.2536 13.7237 14.5947 13.1849 14.6765L5.11537 15.8634L10.9748 21.6682C11.35 22.0434 11.5273 22.5755 11.4386 23.1007L10.0539 31.2657L17.2298 27.4322C17.7141 27.1729 18.2939 27.1729 18.7714 27.4322L25.9473 31.2657L24.5694 23.1075C24.4808 22.5823 24.6513 22.0502 25.0333 21.6751L30.8927 15.8702L22.8232 14.6765C22.2912 14.5947 21.8273 14.2604 21.5886 13.7693L18.0006 6.38875Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_12281_32227">
        <rect width="100%" height="100%" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Star;
