import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import { Reels } from '@/api/generated/Reels';
import { KlPreSignedConfirmRequest, KlReelDto } from '@/api/generated/data-contracts';
import { options } from '@/api/http/helper';
import { Public } from '@/api/generated/Public';

export const useGetPreSignedUploadURL = (fileName: string | undefined) => {
  const httpClient = useApi();

  return useQuery({
    queryKey: ['get:/api/v1/reels/file/upload/pre-signed', fileName],
    queryFn: async () => {
      if (!fileName) {
        return;
      }
      return new Reels(httpClient).reelsPreSignedUrlUsingGet(fileName);
    },
    gcTime: 3000, // aws presigned url lifetime
    enabled: !!fileName,
  });
};

export const useRegisterReel = () => {
  const httpClient = useApi();

  return useMutation({
    mutationKey: ['post:/api/v1/reels/file/upload/confirm'],
    mutationFn: async (params: KlPreSignedConfirmRequest) => {
      return new Reels(httpClient).reelsConfirmUploadReelFileUsingPost(params);
    },
    ...options,
  });
};

export const useSaveReel = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['post:/api/v1/reels/file/upload/confirm'],
    mutationFn: async (params: KlReelDto) => {
      return new Reels(httpClient).reelsSaveUsingPost(params);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v3/script/:scriptId'],
      });
    },
    ...options,
  });
};

export const useGetReel = (reelId: number) => {
  const httpClient = useApi();

  return useQuery({
    queryKey: ['get:/api/v1/reels/', reelId],
    queryFn: async () => {
      if (!reelId) {
        return;
      }
      return new Public(httpClient).reelsPublicGetByIdUsingGet(reelId);
    },
  });
};
