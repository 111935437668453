import React from 'react';
import { useKeycloakProvider } from '@/hooks/useKeycloakProvider';
import { useSearchParams } from 'react-router-dom';

const Logout: React.FC = () => {
  const { keycloak } = useKeycloakProvider();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');
  const { origin } = window.location;
  const redirectUri = redirect || origin;

  const logout = () => {
    keycloak.logout({
      redirectUri,
    });
  };

  localStorage.removeItem('session');
  logout();
  return null;
};

export default Logout;
