const CircleFilled = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_181087_1831)">
      <circle cx="18" cy="18" r="18" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_181087_1831">
        <rect width="36" height="36" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
export default CircleFilled;
