import React from 'react';
import { useGetCompetitionsUsingGet } from '@/api/http/public';
import { CompetitionHook, useCompetition } from '@/hooks/useCompetition';

export interface CompetitionWrapperProperties {
  children: (helpers: CompetitionHook) => React.ReactNode | null;
  loader?: React.ReactNode;
}

const CompetitionWrapper: React.FC<CompetitionWrapperProperties> = ({
  children,
  loader = null,
}) => {
  const { data: competitions, isLoading: isCompetitionLoading } = useGetCompetitionsUsingGet(true);
  const activeCompetition = competitions?.content?.find((item) => item.isActive);
  const inactiveCompetition = competitions?.content?.find((item) => !item.isActive);
  const currentCompetition = activeCompetition || inactiveCompetition;

  const helpers = useCompetition(currentCompetition);
  if (isCompetitionLoading) {
    return loader as React.ReactElement;
  }

  return (
    <>
      {children(helpers)}
    </>
  );
};

export default CompetitionWrapper;
