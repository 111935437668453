import find from 'lodash/find';
import notification from 'antd/es/notification';
import { ApiError } from '@/api/ReactQueryProvider';

export const options = {
  // on error is deprecated, see more info here: https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#react-query-v5
  // @todo we should remove this and use error
  onError: (error: any) => {
    const apiError = error as unknown as ApiError;

    const otherError = find(apiError?.error?.fieldErrors, { key: 'OTHER' });
    const defaultErrorText = 'Our apologies—we\'re encountering a temporary system issue.  If you continue to experience problems, feel free to contact us for assistance.';
    notification.error({
      message: 'Error',
      description: otherError?.reason ?? (apiError?.error?.message || defaultErrorText),
    });
  },
};
