const StairsEqual = (): JSX.Element => (

  <svg width="100%" height="100%" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10859_2014)">
      <path d="M23.3561 8.03736H31.3545C31.6575 8.03736 31.9481 8.15774 32.1624 8.37203C32.3767 8.58631 32.4971 8.87694 32.4971 9.17998C32.4971 9.48302 32.3767 9.77365 32.1624 9.98793C31.9481 10.2022 31.6575 10.3226 31.3545 10.3226H24.4987V16.0357C24.4987 16.3387 24.3783 16.6294 24.1641 16.8437C23.9498 17.0579 23.6592 17.1783 23.3561 17.1783H17.643V22.8914C17.643 23.1945 17.5226 23.4851 17.3083 23.6994C17.0941 23.9137 16.8034 24.034 16.5004 24.034H10.7873V29.7471C10.7873 30.0502 10.6669 30.3408 10.4526 30.5551C10.2383 30.7694 9.94772 30.8898 9.64467 30.8898H1.64634C1.34329 30.8898 1.05266 30.7694 0.838381 30.5551C0.624098 30.3408 0.503716 30.0502 0.503716 29.7471C0.503716 29.4441 0.624098 29.1535 0.838381 28.9392C1.05266 28.7249 1.34329 28.6045 1.64634 28.6045H8.50205V22.8914C8.50205 22.5884 8.62244 22.2977 8.83672 22.0835C9.051 21.8692 9.34163 21.7488 9.64467 21.7488H15.3578V16.0357C15.3578 15.7327 15.4782 15.442 15.6924 15.2277C15.9067 15.0135 16.1974 14.8931 16.5004 14.8931H22.2135V9.17998C22.2135 8.87694 22.3339 8.58631 22.5482 8.37203C22.7624 8.15774 23.0531 8.03736 23.3561 8.03736Z" fill="currentColor" fillOpacity="0.1" />
      <path d="M0.503765 17.0548C0.483053 17.2453 0.510526 17.4385 0.584418 17.6165C0.671447 17.8261 0.818927 18.005 1.00805 18.1305C1.19665 18.2556 1.41814 18.3219 1.64442 18.3209L1.64639 18.321H5.82837C6.13142 18.321 6.42205 18.2006 6.63633 17.9863C6.85061 17.772 6.97099 17.4814 6.97099 17.1784C6.97099 16.8753 6.85061 16.5847 6.63633 16.3704C6.42205 16.1561 6.13142 16.0357 5.82837 16.0357H4.41144L15.3578 5.0894V6.50625C15.3578 6.80929 15.4782 7.09992 15.6924 7.3142C15.9067 7.52849 16.1974 7.64887 16.5004 7.64887C16.8034 7.64887 17.0941 7.52849 17.3083 7.3142C17.5226 7.09992 17.643 6.80929 17.643 6.50625V2.32426C17.643 2.02122 17.5226 1.73059 17.3083 1.51631C17.0941 1.30202 16.8034 1.18164 16.5004 1.18164H12.3184C12.0154 1.18164 11.7247 1.30202 11.5105 1.51631C11.2962 1.73059 11.1758 2.02122 11.1758 2.32426C11.1758 2.6273 11.2962 2.91793 11.5105 3.13221C11.7247 3.3465 12.0154 3.46688 12.3184 3.46688H13.7353L2.78901 14.4131V12.9964C2.78901 12.6933 2.66862 12.4027 2.45434 12.1884C2.24006 11.9741 1.94943 11.8537 1.64639 11.8537C1.34334 11.8537 1.05271 11.9741 0.838431 12.1884C0.624148 12.4027 0.503765 12.6933 0.503765 12.9964V17.0548Z" fill="currentColor" fillOpacity="0.1" />
    </g>
    <defs>
      <clipPath id="clip0_10859_2014">
        <rect width="32" height="32" fill="white" transform="translate(0.5 0.0356445)" />
      </clipPath>
    </defs>
  </svg>
);

export default StairsEqual;
