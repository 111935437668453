const NewWindow = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="currentColor">
    <g clipPath="url(#clip0_180880_24489)">
      <path d="M26.0001 36.0001H6.00001C4.39724 36.0001 2.89053 35.376 1.75732 34.2427C0.624105 33.1094 0 31.6026 0 30.0001V9.99994C0 8.39724 0.624175 6.89053 1.75739 5.75732C2.8906 4.6241 4.39731 3.99993 6.00008 3.99993H14.0001C15.1048 3.99993 16.0001 4.89532 16.0001 5.99996C16.0001 7.10459 15.1048 7.99998 14.0001 7.99998H6.00001C5.46575 7.99998 4.96356 8.20804 4.58587 8.58573C4.20818 8.96342 4.00005 9.46568 4.00005 9.99994V30.0001C4.00005 30.5343 4.20811 31.0365 4.58587 31.4143C4.96356 31.792 5.46575 32.0001 6.00001 32.0001H26.0001C26.5343 32.0001 27.0365 31.792 27.4143 31.4142C27.792 31.0365 28 30.5343 28 30V22C28 20.8954 28.8955 20.0001 30.0001 20.0001C31.1048 20.0001 32.0002 20.8955 32.0002 22V30.0001C32.0002 31.6028 31.376 33.1094 30.2428 34.2428C29.1095 35.376 27.6027 36.0001 26.0001 36.0001ZM14.0001 24C13.4882 24 12.9764 23.8048 12.5859 23.4142C11.8049 22.6332 11.8049 21.3668 12.5859 20.5858L29.1717 3.99993H22.0002C20.8955 3.99993 20.0002 3.10454 20.0002 1.99991C20.0002 0.89527 20.8955 -0.000120749 22.0002 -0.000120749H34.0002C34.5225 -0.000721423 35.0241 0.203573 35.3973 0.56886L35.3975 0.569L35.4014 0.572912L35.4026 0.57403L35.4057 0.577104L35.4077 0.57913L35.4099 0.581366L35.4142 0.585698L35.4186 0.590029L35.4207 0.592265L35.4228 0.594291C35.4243 0.595613 35.4257 0.597037 35.427 0.598553L35.4309 0.602535L35.431 0.602675C35.7963 0.97595 36.0006 1.47759 36 1.99984V13.9999C36 15.1046 35.1045 15.9999 33.9999 15.9999C32.8953 15.9999 31.9999 15.1046 31.9999 13.9999V6.82842L15.414 23.4143C15.0238 23.8048 14.512 24 14.0001 24Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_180880_24489">
        <rect width="36" height="36" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default NewWindow;
