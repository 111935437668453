const FileTick = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_11934_215954)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2093 20.093C31.5996 20.093 35.1628 23.6561 35.1628 28.0465C35.1628 32.4368 31.5996 36 27.2093 36C22.819 36 19.2558 32.4368 19.2558 28.0465C19.2558 23.6561 22.819 20.093 27.2093 20.093ZM27.2093 22.6046C25.7894 22.6398 24.4395 23.2286 23.4477 24.2453C22.4559 25.262 21.9008 26.6261 21.9008 28.0465C21.9008 29.4668 22.4559 30.8309 23.4477 31.8476C24.4395 32.8643 25.7894 33.4531 27.2093 33.4883C28.6292 33.4531 29.9791 32.8643 30.9709 31.8476C31.9627 30.8309 32.5179 29.4668 32.5179 28.0465C32.5179 26.6261 31.9627 25.262 30.9709 24.2453C29.9791 23.2286 28.6292 22.6398 27.2093 22.6046Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3079 28.934C23.0725 28.6986 22.9403 28.3794 22.9403 28.0465C22.9403 27.7137 23.0725 27.3944 23.3079 27.1591C23.5433 26.9237 23.8625 26.7915 24.1953 26.7915C24.5282 26.7915 24.8474 26.9237 25.0828 27.1591L26.2047 28.2793L29.3358 25.1498C29.5712 24.9144 29.8904 24.7822 30.2233 24.7822C30.5561 24.7822 30.8753 24.9144 31.1107 25.1498C31.3461 25.3851 31.4783 25.7044 31.4783 26.0372C31.4783 26.3701 31.3461 26.6893 31.1107 26.9247L27.0921 30.9433C26.9756 31.0599 26.8373 31.1524 26.685 31.2156C26.5327 31.2787 26.3695 31.3112 26.2047 31.3112C26.0398 31.3112 25.8766 31.2787 25.7243 31.2156C25.572 31.1524 25.4337 31.0599 25.3172 30.9433L23.3079 28.934ZM7.11628 14.2326C6.78322 14.2326 6.4638 14.1002 6.22828 13.8647C5.99277 13.6292 5.86047 13.3098 5.86047 12.9767C5.86047 12.6437 5.99277 12.3243 6.22828 12.0888C6.4638 11.8532 6.78322 11.7209 7.11628 11.7209H15.4884C15.8214 11.7209 16.1409 11.8532 16.3764 12.0888C16.6119 12.3243 16.7442 12.6437 16.7442 12.9767C16.7442 13.3098 16.6119 13.6292 16.3764 13.8647C16.1409 14.1002 15.8214 14.2326 15.4884 14.2326H7.11628ZM7.11628 27.6279C6.78322 27.6279 6.4638 27.4956 6.22828 27.2601C5.99277 27.0246 5.86047 26.7052 5.86047 26.3721C5.86047 26.039 5.99277 25.7196 6.22828 25.4841C6.4638 25.2486 6.78322 25.1163 7.11628 25.1163H15.4884C15.8214 25.1163 16.1409 25.2486 16.3764 25.4841C16.6119 25.7196 16.7442 26.039 16.7442 26.3721C16.7442 26.7052 16.6119 27.0246 16.3764 27.2601C16.1409 27.4956 15.8214 27.6279 15.4884 27.6279H7.11628ZM7.11628 20.9302C6.78322 20.9302 6.4638 20.7979 6.22828 20.5624C5.99277 20.3269 5.86047 20.0075 5.86047 19.6744C5.86047 19.3414 5.99277 19.0219 6.22828 18.7864C6.4638 18.5509 6.78322 18.4186 7.11628 18.4186H18.8372C19.1703 18.4186 19.4897 18.5509 19.7252 18.7864C19.9607 19.0219 20.093 19.3414 20.093 19.6744C20.093 20.0075 19.9607 20.3269 19.7252 20.5624C19.4897 20.7979 19.1703 20.9302 18.8372 20.9302H7.11628Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.9767 17.1628C30.9767 17.4959 30.8444 17.8153 30.6089 18.0508C30.3734 18.2863 30.054 18.4186 29.7209 18.4186C29.3879 18.4186 29.0684 18.2863 28.8329 18.0508C28.5974 17.8153 28.4651 17.4959 28.4651 17.1628V11.1131C28.4655 11.0085 28.4266 10.9076 28.3563 10.8301L20.9185 2.64893C20.8792 2.60588 20.8313 2.57145 20.778 2.54782C20.7247 2.52419 20.6671 2.51186 20.6087 2.51163H4.60465C4.04982 2.51251 3.51796 2.73331 3.12564 3.12564C2.73331 3.51796 2.51251 4.04982 2.51163 4.60465V31.3953C2.51251 31.9502 2.73331 32.482 3.12564 32.8744C3.51796 33.2667 4.04982 33.4875 4.60465 33.4884H18C18.3331 33.4884 18.6525 33.6207 18.888 33.8562C19.1235 34.0917 19.2558 34.4111 19.2558 34.7442C19.2558 35.0773 19.1235 35.3967 18.888 35.6322C18.6525 35.8677 18.3331 36 18 36H4.60465C3.384 36 2.21191 35.5144 1.34791 34.6521C0.485239 33.7877 0.000512733 32.6166 0 31.3953V4.60465C0 3.384 0.485582 2.21191 1.34791 1.34791C2.21228 0.485239 3.38345 0.000512733 4.60465 0H20.6087C21.4342 0 22.2212 0.348279 22.7771 0.959442L30.2149 9.14065C30.7055 9.68149 30.9767 10.3831 30.9767 11.1131V17.1628Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.093 2.09306C20.093 1.75999 20.2253 1.44057 20.4609 1.20506C20.6964 0.969552 21.0158 0.837243 21.3488 0.837243C21.6819 0.837243 22.0013 0.969552 22.2368 1.20506C22.4723 1.44057 22.6047 1.75999 22.6047 2.09306V8.79073C22.6047 9.0218 22.7922 9.20934 23.0233 9.20934H28.8837C29.2168 9.20934 29.5362 9.34165 29.7717 9.57716C30.0072 9.81267 30.1395 10.1321 30.1395 10.4652C30.1395 10.7982 30.0072 11.1176 29.7717 11.3531C29.5362 11.5887 29.2168 11.721 28.8837 11.721H23.0233C22.2461 11.721 21.5008 11.4122 20.9513 10.8627C20.4018 10.3132 20.093 9.56788 20.093 8.79073V2.09306Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_11934_215954">
        <rect width="100%" height="100%" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FileTick;
