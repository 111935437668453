export const usePlatformInfo = () => {
  // @ts-ignore
  const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';

  const iOS_1to12 = /iPad|iPhone|iPod/.test(platform as string);

  const iOS13_iPad = (platform === 'MacIntel' && platform > 1);

  const iOS1to12quirk = () => {
    const audio = new Audio(); // temporary Audio object
    audio.volume = 0.5; // has no effect on iOS <= 12
    return audio.volume === 1;
  };

  const isIOS = iOS_1to12 || iOS13_iPad || iOS1to12quirk();

  return {
    isIOS,
  };
};
