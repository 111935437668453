const Earn = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2376_34742)">
      <path d="M9.9711 6.78476L9.95313 6.78046C8.79845 6.51679 8.79845 6.01913 8.79845 5.85546C8.79845 5.23046 9.33829 4.94335 9.83985 4.94335C10.0452 4.94307 10.2461 5.00361 10.4171 5.11734C10.5881 5.23107 10.7216 5.3929 10.8008 5.58241C10.9093 5.84128 11.2072 5.96319 11.466 5.85468C11.7249 5.74616 11.8468 5.44831 11.7383 5.18944C11.6154 4.89545 11.4258 4.63409 11.1844 4.42608C10.9431 4.21806 10.6566 4.06911 10.3477 3.99101V3.55702C10.3477 3.27655 10.1203 3.04921 9.83985 3.04921C9.55938 3.04921 9.33204 3.27655 9.33204 3.55702V3.98202C8.4211 4.18319 7.78282 4.92148 7.78282 5.85507C7.7836 6.40234 8.03399 7.37499 9.70821 7.76562L9.72618 7.76991C10.8809 8.03359 10.8809 8.53124 10.8809 8.69491C10.8809 9.31991 10.341 9.60702 9.83946 9.60702C9.63408 9.60731 9.43322 9.54676 9.2622 9.43303C9.09119 9.3193 8.95768 9.15747 8.87853 8.96796C8.77001 8.70909 8.47216 8.58718 8.21329 8.69569C7.95442 8.80421 7.83251 9.10206 7.94103 9.36093C8.06382 9.65512 8.25346 9.91666 8.49491 10.1248C8.73635 10.333 9.02297 10.482 9.33204 10.5601V10.9937C9.33204 11.2742 9.55938 11.5016 9.83985 11.5016C10.1203 11.5016 10.3477 11.2742 10.3477 10.9937V10.5684C11.2578 10.3668 11.8957 9.6289 11.8957 8.69569C11.8957 8.14882 11.6449 7.1746 9.9711 6.78476Z" fill="currentColor" />
      <path d="M19.5012 8.11406L16.8484 5.87383V1.96992C16.8473 1.15855 16.1899 0.501094 15.3785 0.5H4.41016C3.59801 0.5 2.93938 1.15777 2.93828 1.96992V5.78281L2.28867 6.32969L0.179297 8.11172C0.165586 8.12402 0.152539 8.13707 0.140234 8.15078L0.132422 8.15977C0.123047 8.17031 0.113672 8.18086 0.105078 8.1918L0.0972656 8.20273C0.0894531 8.21367 0.0816406 8.22461 0.075 8.23555L0.0675781 8.24883C0.0613281 8.25977 0.0554688 8.2707 0.05 8.28203L0.0429688 8.29766C0.035897 8.31418 0.0295114 8.33099 0.0238281 8.34805C0.021875 8.35391 0.0175781 8.36914 0.0148438 8.37969C0.0121094 8.39023 0.0117188 8.39297 0.0101563 8.39961C0.00859376 8.40625 0.00625001 8.42109 0.00468751 8.43164C0.00312501 8.44219 0.00273438 8.44492 0.00195313 8.45156C0.00117188 8.4582 0.00195313 8.47578 0 8.48789V18.6352C0.00109375 19.6639 0.83418 20.4979 1.86289 20.5H17.8168C18.8463 20.4989 19.6805 19.6646 19.6816 18.6352V8.50195C19.6816 8.42806 19.6654 8.35507 19.6342 8.28808C19.6031 8.22108 19.5577 8.1617 19.5012 8.11406ZM16.8488 7.20469L18.3852 8.50078L16.8488 9.5875V7.20469ZM3.95391 1.96992C3.95434 1.71859 4.15844 1.5152 4.40977 1.51562H15.3785C15.6293 1.51605 15.8324 1.71918 15.8328 1.96992V10.3047L9.89336 14.5066L3.95352 10.3047L3.95391 1.96992ZM2.35156 7.60938L2.9375 7.11406V9.58906L1.34336 8.45937L2.35156 7.60938ZM18.666 18.6352C18.6656 19.104 18.2856 19.4839 17.8168 19.4844H1.86289C1.39406 19.4839 1.0141 19.104 1.01367 18.6352V9.47031L7.20586 13.8496L2.83516 17.1965C2.61133 17.3655 2.56684 17.6839 2.73578 17.9077C2.90477 18.1316 3.22316 18.1761 3.44703 18.0071L8.07461 14.4637L9.60039 15.543C9.68609 15.6035 9.78843 15.636 9.89336 15.636C9.99828 15.636 10.1006 15.6035 10.1863 15.543L11.7121 14.4637L16.334 18.0027C16.5554 18.1748 16.8745 18.1349 17.0466 17.9134C17.2187 17.692 17.1787 17.373 16.9573 17.2009L12.5805 13.8496L18.666 9.54609V18.6352Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_2376_34742">
        <rect width="100%" height="100%" fill="none" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>

);

export default Earn;
