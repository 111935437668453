const User = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_11934_215962)">
      <path
        d="M30.728 5.27203C27.3282 1.87242 22.8079 0 18 0C13.1921 0 8.67178 1.87235 5.27203 5.27203C1.87242 8.67178 0 13.1921 0 18C0 22.8079 1.87235 27.3282 5.27203 30.728C8.67178 34.1276 13.1921 36 18 36C22.8079 36 27.3282 34.1277 30.728 30.728C34.1276 27.3282 36 22.8079 36 18C36 13.1921 34.1277 8.67178 30.728 5.27203ZM7.81207 30.1849C8.40649 25.0708 12.8035 21.1224 18 21.1224C20.7394 21.1224 23.3155 22.1896 25.2532 24.1271C26.8901 25.7643 27.9226 27.902 28.1881 30.1847C25.4279 32.4965 21.8738 33.8906 18 33.8906C14.1262 33.8906 10.5724 32.4967 7.81207 30.1849ZM18 18.9497C14.9856 18.9497 12.5329 16.4971 12.5329 13.4827C12.5329 10.4681 14.9857 8.01562 18 8.01562C21.0143 8.01562 23.4671 10.4681 23.4671 13.4827C23.4671 16.4971 21.0144 18.9497 18 18.9497ZM30.0037 28.4016C29.4617 26.2191 28.3349 24.2256 26.7446 22.6357C25.4482 21.3393 23.9145 20.3667 22.2418 19.7573C24.2526 18.3936 25.5765 16.0898 25.5765 13.4827C25.5765 9.30516 22.1775 5.90625 18 5.90625C13.8225 5.90625 10.4235 9.30516 10.4235 13.4827C10.4235 16.0912 11.7485 18.3958 13.7609 19.7592C12.222 20.32 10.798 21.1871 9.5727 22.3286C7.79836 23.9809 6.56627 26.0878 5.99498 28.3999C3.57553 25.6108 2.10938 21.9735 2.10938 18C2.10938 9.23787 9.23787 2.10938 18 2.10938C26.7621 2.10938 33.8906 9.23787 33.8906 18C33.8906 21.9743 32.4239 25.6125 30.0037 28.4016Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_11934_215962">
        <rect width="100%" height="100%" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default User;
