const FileQuestion = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_181669_2026)">
      <path d="M25.0724 9.95694V8.02836C25.0724 7.85158 24.9277 7.70694 24.7509 7.70694H9.32237C9.14558 7.70694 9.00094 7.85158 9.00094 8.02836V9.95694C9.00094 10.1337 9.14558 10.2784 9.32237 10.2784H24.7509C24.9277 10.2784 25.0724 10.1337 25.0724 9.95694ZM9.32237 13.4927C9.14558 13.4927 9.00094 13.6373 9.00094 13.8141V15.7427C9.00094 15.9194 9.14558 16.0641 9.32237 16.0641H15.7152C15.892 16.0641 16.0367 15.9194 16.0367 15.7427V13.8141C16.0367 13.6373 15.892 13.4927 15.7152 13.4927H9.32237ZM13.6786 31.6534H5.78666V3.36765H28.2867V12.6715C28.2867 12.8483 28.4313 12.9929 28.6081 12.9929H30.8581C31.0349 12.9929 31.1795 12.8483 31.1795 12.6715V1.76051C31.1795 1.04935 30.605 0.474792 29.8938 0.474792H4.17951C3.46835 0.474792 2.8938 1.04935 2.8938 1.76051V33.2605C2.8938 33.9717 3.46835 34.5462 4.17951 34.5462H13.6786C13.8554 34.5462 14 34.4016 14 34.2248V31.9748C14 31.798 13.8554 31.6534 13.6786 31.6534Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.2839 25.1421C14.2839 19.1451 19.1449 14.2841 25.1419 14.2841C31.1389 14.2841 35.9998 19.1451 35.9998 25.1421C35.9998 31.1391 31.1389 36 25.1419 36C19.1449 36 14.2839 31.1391 14.2839 25.1421ZM25.1421 33.394C29.6995 33.394 33.394 29.6995 33.394 25.1421C33.394 20.5846 29.6995 16.8901 25.1421 16.8901C20.5847 16.8901 16.8902 20.5846 16.8902 25.1421C16.8902 29.6995 20.5847 33.394 25.1421 33.394Z" fill="currentColor" />
      <path d="M27.0874 23.1395C27.0874 23.9403 26.4193 24.6525 25.4934 24.7735L25.4917 24.7738C24.7631 24.8795 24.1937 25.5367 24.1861 26.2812V27.3877C24.1861 27.6467 24.3975 27.8581 24.6565 27.8581H25.7618C26.0207 27.8581 26.2321 27.6467 26.2321 27.3877V26.9663C26.2321 26.8131 26.3362 26.6715 26.4806 26.6259C28.0681 26.1133 29.1585 24.7001 29.1333 23.0891C29.1046 21.0972 27.3705 19.4854 25.2624 19.457C23.0803 19.4321 21.2849 21.0795 21.2849 23.1395C21.2849 23.4 21.498 23.5926 21.7484 23.5926H22.8674C23.1178 23.5926 23.3309 23.3966 23.3309 23.1429C23.3309 22.2506 24.1526 21.4857 25.2091 21.4857C26.2658 21.4857 27.0874 22.2508 27.0874 23.1395Z" fill="currentColor" />
      <path d="M23.9789 29.8744C23.9789 30.2007 24.1085 30.5136 24.3392 30.7443C24.5699 30.9751 24.8829 31.1047 25.2091 31.1047C25.5354 31.1047 25.8483 30.9751 26.079 30.7443C26.3098 30.5136 26.4394 30.2007 26.4394 29.8744C26.4394 29.5482 26.3098 29.2353 26.079 29.0045C25.8483 28.7738 25.5354 28.6442 25.2091 28.6442C24.8829 28.6442 24.5699 28.7738 24.3392 29.0045C24.1085 29.2353 23.9789 29.5482 23.9789 29.8744Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_181669_2026">
        <rect width="100%" height="100%" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default FileQuestion;
