import React, { useEffect, useRef, useState } from 'react';
import { VideoBackground, VideoBackgroundProperties } from '@/ui-kit/Base/Video/VideoBackground';
import { Video } from '@/ui-kit/Base/Video';
import VideoComponentWithProgress, {
  VideoComponentWithProgressProps,
} from '@/ui-kit/Components/VideoComponentWithProgress/VideoComponentWithProgress';
import If from '@/ui-kit/Base/If/If';

export interface LazyVideoProperties extends VideoBackgroundProperties, VideoComponentWithProgressProps {
  isBackground?: boolean;
  isWithProgress?: boolean;
  poster?: string;
}

// lazy load for the video to split dashjs to chunks
const LazyVideo: React.FC<LazyVideoProperties> = ({
  isBackground = false,
  isWithProgress = false,
  poster,
  ...rest
}) => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        }
      },
      { threshold: 0.01, rootMargin: '20px' },
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);

  return (
    <>
      <If truthy={isVisible}>
        <If truthy={isBackground}>
          <VideoBackground {...rest} />
        </If>
        <If truthy={!isBackground && !isWithProgress}>
          <Video {...rest} />
        </If>
        <If truthy={isWithProgress}>
          <VideoComponentWithProgress {...rest} poster={poster} />
        </If>
      </If>
      <If truthy={!isVisible}>
        <div ref={divRef} className="lazy-video" style={{ minHeight: '1px', minWidth: '1px' }} />
      </If>
    </>
  );
};

export default LazyVideo;
