import React, { PropsWithChildren } from 'react';
import Typography from 'antd/es/typography';
import './typography.less';
import { TextProps } from 'antd/es/typography/Text';
import classNames from 'classnames';
import { Color, FontWeight } from '@/ui-kit/types';

export interface UiKitTextProps extends TextProps {
  fontWeight?: typeof FontWeight;
  color?: typeof Color;
}

const Text: React.FC<PropsWithChildren<UiKitTextProps>> = ({
  className,
  children,
  fontWeight,
  color,
  ...rest
}) => {
  return (
    <Typography.Text
      className={classNames('ui-kit-text', className, `${fontWeight || ''} ${color || ''}`)}
      {...rest}
    >
      {children}
    </Typography.Text>
  );
};

export default Text;
