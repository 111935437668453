import React from 'react';
import Typography from 'antd/es/typography';
import './typography.less';
import { ParagraphProps } from 'antd/es/typography/Paragraph';
import classNames from 'classnames';
import {
  Color, FontWeight, Size, TextTransform,
} from '@/ui-kit/types';

export interface UiKitParagraphProps extends ParagraphProps {
  fontWeight?: typeof FontWeight;
  color?: typeof Color;
  fontSize?: typeof Size;
  textTransform?: typeof TextTransform;
}

const Paragraph: React.FC<UiKitParagraphProps> = ({
  fontSize,
  fontWeight,
  textTransform,
  color,
  className,
  children,
  ...rest
}) => (
  <Typography.Paragraph
    className={classNames('ui-kit-paragraph', `${fontWeight || ''} ${color || ''}`, className, { [`font-size-${fontSize}`]: fontSize }, textTransform)}
    {...rest}
  >
    {children}
  </Typography.Paragraph>
);

export default Paragraph;
