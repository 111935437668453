import CustomIcon from './CustomIcon';
import { Variant } from '@/ui-kit/types';
import React from 'react';

import StairsUp from '@/ui-kit/Base/Iconography/svg/StairsUp';
import StairsDown from '@/ui-kit/Base/Iconography/svg/StairsDown';
import StairsEqual from '@/ui-kit/Base/Iconography/svg/StairsEqual';

const ArrowCircleUpFilled = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/ArrowCircleUpFilled'));
const KinolimeUp = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/KinolimeUp'));
const SignOut = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/SignOut'));
const Share = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Share'));
const Copy = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Copy'));
const Upload = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Upload'));
const Question = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Question'));
const Billing = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Billing'));
const NewWindow = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/NewWindow'));
const Clapper = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Clapper'));
const Cup = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Cup'));
const Cross = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Cross'));
const ArrowLeft = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/ArrowLeft'));
const ArrowRight = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/ArrowRight'));
const ArrowCircleLeft = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/ArrowCircleLeft'));
const ArrowCircleRight = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/ArrowCircleRight'));
const Chart = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Chart'));
const Circle = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Circle'));
const CircleCheckmark = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/CircleCheckmark'));
const CircleLime = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/CircleLime'));
const CircleUser = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/CircleUser'));
const CircleX = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/CircleX'));
const Comments = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Comments'));
const Dashboard = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Dashboard'));
const Earn = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Earn'));
const EnvelopeDollar = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/EnvelopeDollar'));
const Facebook = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Facebook'));
const FileLines = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/FileLines'));
const FileQuestion = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/FileQuestion'));
const FileTick = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/FileTick'));
const Help = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Help'));
const HeadphonesAlt = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/HeadphonesAlt'));
const Microphone = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Microphone'));
const Interview = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Interview'));
const History = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/History'));
const Hamburger = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Humburger'));
const Instagram = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Instagram'));
const Layout = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Layout'));
const LinkedIn = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/LinkedIn'));
const Minus = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Minus'));
const Money = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Money'));
const Notification = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Notification'));
const NotificationAdded = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/NotificationAdded'));
const Plus = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Plus'));
const Profile = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Profile'));
const ScriptCoverage = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/ScriptCoverage'));
const Star = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Star'));
const StarSparkling = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/StarSparkling'));
const Rewards = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Rewards'));
const Trophy = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Trophy'));
const Twitter = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Twitter'));
const User = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/User'));
const Video = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Video'));
const Bookmark = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Bookmark'));
const News = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/News'));
const Live = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Live'));
const Book = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Book'));
const Link = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Link'));
const LinkCircle = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/LinkCircle'));
const CircleFilled = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/CircleFilled'));
const PlayCircle = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/PlayCircle'));
const PauseCircle = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/PauseCircle'));
const Previous15 = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Previous15'));
const Next30 = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Next30'));
const Filter = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Filter'));
const Sort = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Sort'));
const Logline = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Logline'));
const Promote = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Promote'));
const MyLabCollection = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/MyLabCollection'));
const MyLabCollectionAdd = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/MyLabCollectionAdd'));
const MyLabCollectionAdded = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/MyLabCollectionAdded'));
const Store = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Store'));
const ArrowPartCircleRight = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/ArrowPartCircleRight'));
const ArrowSquareLeft = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/ArrowSquareLeft'));
const Podcast = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Podcast'));
const StatisticsDetails = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/StatisticsDetails'));
const Text = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/Text'));
const BadgeFinalist = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/BadgeFinalist'));
const BadgeWinner = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/BadgeWinner'));
const BadgeTop3 = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/BadgeTop3'));
const BadgeTop10 = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/BadgeTop10'));
const BadgeTop30 = React.lazy(async () => import('@/ui-kit/Base/Iconography/svg/BadgeTop30'));

export interface IconProperties {
  className?: string;
  size?: number;
  style?: React.CSSProperties;
  variant?: typeof Variant;
}

export const ArrowCircleUpFilledIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={ArrowCircleUpFilled} />;
export const KinolimeUpIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={KinolimeUp} />;
export const SignOutIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={SignOut} />;
export const ShareIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Share} />;
export const CopyIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Copy} />;
export const UploadIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Upload} />;
export const QuestionIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Question} />;
export const BillingIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Billing} />;
export const NewWindowIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={NewWindow} />;
export const ClapperIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Clapper} />;
export const CupIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Cup} />;
export const CrossIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Cross} />;
export const ArrowLeftIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={ArrowLeft} />;
export const ArrowRightIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={ArrowRight} />;
export const ArrowCircleLeftIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={ArrowCircleLeft} />;
export const ArrowCircleRightIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={ArrowCircleRight} />;
export const ChartIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Chart} />;
export const CircleIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Circle} />;
export const CircleCheckmarkIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={CircleCheckmark} />;
export const CircleLimeIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={CircleLime} />;
export const CircleUserIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={CircleUser} />;
export const CircleXIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={CircleX} />;
export const CommentsIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Comments} />;
export const DashboardIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Dashboard} />;
export const EarnIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Earn} />;
export const EnvelopeDollarIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={EnvelopeDollar} />;
export const FacebookIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Facebook} />;
export const FileLinesIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={FileLines} />;
export const FileQuestionIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={FileQuestion} />;
export const FileTickIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={FileTick} />;
export const HelpIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Help} />;
export const HeadphonesAltIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={HeadphonesAlt} />;
export const MicrophoneIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Microphone} />;
export const InterviewIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Interview} />;
export const HistoryIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={History} />;
export const HamburgerIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Hamburger} />;
export const InstagramIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Instagram} />;
export const LayoutIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Layout} />;
export const LinkedInIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={LinkedIn} />;
export const MinusIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Minus} />;
export const MoneyIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Money} />;
export const NotificationIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Notification} />;
export const NotificationAddedIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={NotificationAdded} />;
export const PlusIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Plus} />;
export const ProfileIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Profile} />;
export const ScriptCoverageIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={ScriptCoverage} />;
export const StarIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Star} />;
export const StarSparklingIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={StarSparkling} />;
export const RewardsIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Rewards} />;
export const TrophyIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Trophy} />;
export const TwitterIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Twitter} />;
export const UserIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={User} />;
export const VideoIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Video} />;
export const BookmarkIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Bookmark} />;
export const NewsIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={News} />;
export const LiveIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Live} />;
export const BookIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Book} />;
export const LinkIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Link} />;
export const LinkCircleIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={LinkCircle} />;
export const CircleFilledIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={CircleFilled} />;
export const PlayCircleIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={PlayCircle} />;
export const Previous15Icon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Previous15} />;
export const Next30Icon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Next30} />;
export const PauseCircleIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={PauseCircle} />;
export const FilterIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Filter} />;
export const SortIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Sort} />;
export const LoglineIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Logline} />;
export const PromoteIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Promote} />;
export const MyLabIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={MyLabCollection} />;
export const MyLabCollectionAddIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={MyLabCollectionAdd} />;
export const MyLabCollectionAddedIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={MyLabCollectionAdded} />;
export const StoreIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Store} />;
export const ArrowPartCircleRightIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={ArrowPartCircleRight} />;
export const ArrowSquareLeftIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={ArrowSquareLeft} />;
export const PodcastIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Podcast} />;
export const StatisticsDetailsIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={StatisticsDetails} />;
export const TextIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={Text} />;
export const StairsUpIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={StairsUp} />;
export const StairsDownIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={StairsDown} />;
export const StairsEqualIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={StairsEqual} />;
export const BadgeFinalistIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={BadgeFinalist} />;
export const BadgeWinnerIcon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={BadgeWinner} />;
export const BadgeTop3Icon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={BadgeTop3} />;
export const BadgeTop10Icon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={BadgeTop10} />;
export const BadgeTop30Icon = ({ ...properties }: IconProperties): JSX.Element => <CustomIcon {...properties} component={BadgeTop30} />;
