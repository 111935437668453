import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const DeferScriptLoader: React.FC = () => {
  const [shouldRender, setShouldRender] = React.useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldRender(true);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  const renderZendesk = () => {
    if (!import.meta.env.VITE_ZENDESK_KEY_ID) {
      return null;
    }
    return (
      <script defer id="ze-snippet" src={`https://static.zdassets.com/ekr/snippet.js?key=${import.meta.env.VITE_ZENDESK_KEY_ID}`} />
    );
  };

  if (!shouldRender) {
    return null;
  }

  return (
    <Helmet>
      {renderZendesk()}
    </Helmet>
  );
};

export default DeferScriptLoader;
