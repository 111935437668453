import { StrapiJudgesSession } from '@/const/cmsInterface';
import React from 'react';
import classNames from 'classnames';
import StrapiCompetitionFilterWrapperComponent from '@/components/Cms/StrapiCompetitionFilterWrapperComponent';
import { SemiFinalistsScreenplays } from '@/ui-kit/Components/ViewAll';

interface StrapiJudgesSessionProperties {
  block: StrapiJudgesSession;
}

const StrapiJudgesSessionComponent: React.FC<StrapiJudgesSessionProperties> = ({
  block,
}) => {
  return (
    <StrapiCompetitionFilterWrapperComponent
      isVisibleForCompetition={block?.isVisibleForCompetition}
      isHiddenForCompetition={block?.isHiddenForCompetition}
    >
      {() => (
        <div className={classNames(`bg-${block?.theme ?? 'dark-blue'}`)}>
          <SemiFinalistsScreenplays />
        </div>
      )}
    </StrapiCompetitionFilterWrapperComponent>
  );
};

export default StrapiJudgesSessionComponent;
