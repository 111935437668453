const KinolimeUp = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_180932_77611)">
      <path d="M21.8869 0C24.9074 2.00954 25.7523 7.73462 22.1929 10.0829C21.4449 9.76291 19.8993 7.29789 19.7166 6.1389C19.3252 3.65741 20.4728 1.53391 21.8869 0Z" fill="#C4F316" />
      <path d="M15.8989 15.8153L16.0219 15.8742C17.0708 16.5269 18.1774 17.1276 19.2533 17.7042C20.0573 18.135 20.8933 18.5365 21.7385 18.9116C21.2164 19.1479 20.7561 19.4914 20.378 19.9107L19.5037 20.8523L15.7915 21.0102C13.6582 19.7853 11.5059 18.361 9.82187 16.913C9.67382 16.7857 9.42865 16.4142 9.99192 16.0599L15.8989 15.8153Z" fill="#C4F316" />
      <path d="M19.2594 17.7006C20.0636 18.1316 20.8999 18.5332 21.7455 18.9085C22.2472 18.6826 22.8058 18.5557 23.403 18.5557C24.6245 18.5557 25.6422 19.0825 26.3614 19.8017L26.4036 19.8439L26.6763 20.1375C26.7026 20.0126 26.7014 19.8613 26.6689 19.6846C26.2378 17.3426 25.2539 15.2375 23.6606 13.7399C23.8719 12.8378 23.4156 11.8356 22.5095 11.35C21.6039 10.8646 20.5442 11.0536 19.9459 11.7476C17.8556 11.2636 15.6305 11.6281 13.5533 12.629C12.5519 13.1114 12.8871 13.4949 13.1932 13.7759C14.8789 15.323 17.1291 16.5589 19.2594 17.7006Z" fill="#C4F316" />
      <path d="M19.0097 34.5469V33.3987C17.0519 32.6991 15.1912 31.8823 13.3756 30.9446L17.525 30.5462C16.8143 30.1669 16.2172 29.581 15.8073 28.8197L15.6755 28.5748L15.5875 28.3109C15.4284 27.8335 15.3507 27.3361 15.3611 26.8402C15.171 26.7403 14.9767 26.6372 14.7782 26.5308C14.5353 26.4006 14.3018 26.2735 14.0764 26.1489L6.93831 26.533C6.61472 26.5141 6.32801 26.5924 6.15682 26.7415C5.93613 26.9336 5.99824 27.5732 6.04673 27.8538C6.43104 30.0791 7.42835 32.0369 8.97454 33.4441C8.8204 34.3181 9.27586 35.2626 10.1471 35.7295C11.032 36.2037 12.0639 36.0342 12.6687 35.3789C14.7822 35.859 17.12 35.551 19.0097 34.5469Z" fill="#C4F316" />
      <path d="M16.4605 24.1297L18.1564 22.3033C17.8529 22.1441 17.544 21.9796 17.2255 21.8089C14.6493 20.4282 11.8781 18.6871 9.81168 16.9105C9.6626 16.7823 9.41468 16.4061 9.99431 16.0495C8.91689 16.1618 8.51313 16.5461 8.13349 17.233C7.883 17.6862 7.68642 18.0951 7.53936 18.4011C7.47893 18.5268 7.42687 18.6351 7.38285 18.722C7.2074 19.0681 7.06795 19.4209 6.92857 19.7734L6.85094 19.9692C6.68814 20.3772 6.87436 21.0054 7.24243 21.3207L7.39537 21.4517C9.31281 23.0954 10.8822 24.4407 14.7768 26.5278C14.9759 26.6345 15.1706 26.7378 15.3612 26.8379C15.3818 25.8837 15.7287 24.9347 16.449 24.1423L16.4605 24.1297Z" fill="#C4F316" />
      <path d="M29.1363 6.83976C26.8069 5.86472 23.5374 7.7389 23.3951 10.6968C24.2973 11.1034 25.9439 11.1961 27.1117 10.3875C28.2094 9.62741 29.0587 8.34752 29.1363 6.83976Z" fill="#C4F316" />
      <path d="M28.1613 27.2919C27.9851 27.6192 27.7207 27.7954 27.3682 27.8206H24.6492V35.4742H22.2323V27.8206H19.4755C19.0979 27.7954 18.8209 27.6192 18.6447 27.2919C18.5188 26.9142 18.5818 26.5869 18.8335 26.31L22.761 22.0804C22.9372 21.879 23.1512 21.7783 23.403 21.7783C23.6548 21.7783 23.8813 21.879 24.0828 22.0804L28.0102 26.31C28.2872 26.5869 28.3375 26.9142 28.1613 27.2919Z" fill="#C4F316" />
    </g>
    <defs>
      <clipPath id="clip0_180932_77611">
        <rect width="100%" height="100%" fill="none" />
      </clipPath>
    </defs>
  </svg>
);

export default KinolimeUp;
