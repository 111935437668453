const MyLabCollectionAdd = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_181370_3305)">
      <path d="M30.8562 0H9.32045C9.14367 0 8.99902 0.144643 8.99902 0.321429V2.57143C8.99902 2.74821 9.14367 2.89286 9.32045 2.89286H29.249V30.5357C29.249 30.7125 29.3937 30.8571 29.5705 30.8571H31.8205C31.9972 30.8571 32.1419 30.7125 32.1419 30.5357V1.28571C32.1419 0.574554 31.5673 0 30.8562 0Z" fill="currentColor" />
      <path d="M7.11003 13.3899V11.6835C7.11003 11.5271 7.23802 11.3991 7.39444 11.3991H21.0458C21.2022 11.3991 21.3302 11.5271 21.3302 11.6835V13.3899C21.3302 13.5463 21.2022 13.6743 21.0458 13.6743H7.39444C7.23802 13.6743 7.11003 13.5463 7.11003 13.3899ZM21.0458 16.5183C21.2022 16.5183 21.3302 16.6463 21.3302 16.8028V18.5092C21.3302 18.6656 21.2022 18.7936 21.0458 18.7936H14.5045C14.3481 18.7936 14.2201 18.6656 14.2201 18.5092V16.8028C14.2201 16.6463 14.3481 16.5183 14.5045 16.5183H21.0458ZM15.9265 32.5872H24.1743V7.55963H4.266V19.789C4.266 19.9454 4.13802 20.0734 3.98159 20.0734H1.99077C1.83435 20.0734 1.70637 19.9454 1.70637 19.789V6.13761C1.70637 5.50837 2.21474 5 2.84398 5H25.5963C26.2255 5 26.7339 5.50837 26.7339 6.13761V34.0092C26.7339 34.6384 26.2255 35.1468 25.5963 35.1468H15.9265C15.7701 35.1468 15.6421 35.0188 15.6421 34.8624V32.8716C15.6421 32.7151 15.7701 32.5872 15.9265 32.5872Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.84121 26.1589H14.14C14.8511 26.1589 15.4287 26.7365 15.4287 27.4476V29.1239C15.4287 29.835 14.8511 30.4126 14.14 30.4126H9.84121V34.7114C9.84121 35.4225 9.2636 36.0001 8.55248 36.0001H6.87623C6.16512 36.0001 5.5875 35.4225 5.5875 34.7114V30.4126H1.28873C0.577628 30.4126 0 29.835 0 29.1239V27.4476C0 26.7365 0.577619 26.1589 1.28873 26.1589H5.5875V21.8601C5.5875 21.149 6.16513 20.5714 6.87623 20.5714H8.55248C9.2636 20.5714 9.84121 21.149 9.84121 21.8601V26.1589Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_181370_3305">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>

);
export default MyLabCollectionAdd;
