import './timeline.less';
import classNames from 'classnames';
import { TimelineItemProps } from 'antd/es/timeline/TimelineItem';
import React, { Suspense } from 'react';
import Space from 'antd/es/space';
import { Title } from '@/ui-kit/Base/Typography';
import { If } from '@/ui-kit/Base/If';
import DeferRender from '@/components/DeferRender';

const AntdTimeline = React.lazy(async () => import('antd/es/timeline'));

export interface TimelineProperties {
  items: TimelineItemProps[];
  title?: string;
}

const Timeline: React.FC<TimelineProperties> = ({
  items,
  title,
}) => {
  return (
    <Suspense fallback={<div className="ui-kit-timeline loader"> </div>}>
      <DeferRender className={classNames('ui-kit-timeline')} timeout={500}>
        <Space direction="vertical" size={36}>
          <If truthy={!!title}>
            <Title level={3} color="white" fontWeight="font-extra-light">{title}</Title>
          </If>
          <AntdTimeline items={items} mode="left" />
        </Space>
      </DeferRender>
    </Suspense>
  );
};

export default Timeline;
