import { useMutation, useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import { options } from '@/api/http/helper';
import { KlScriptVIPDto } from '@/api/generated/data-contracts';
import { Vip } from '@/api/generated/Vip';
import { Public } from '@/api/generated/Public';

export const useSaveWritersStatement = (scriptId: number | undefined) => {
  const httpClient = useApi();

  return useMutation({
    mutationKey: ['put:/api/v1/vip/script/:scriptId', scriptId],
    mutationFn: async (params: KlScriptVIPDto) => {
      if (!scriptId) {
        return;
      }
      const request = new Vip(httpClient).scriptVipUpdateScriptVipUsingPut(scriptId, params);
      return request;
    },
    ...options,
  });
};

export const useGetVipData = (scriptId: number | undefined) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/vip/script/', scriptId],
    queryFn: async () => {
      if (!scriptId) {
        return;
      }
      return new Public(httpClient).scriptVipPublicGetScriptVipUsingGet(scriptId);
    },
    enabled: !!scriptId,
    ...options,
  });
};
