import React, { MutableRefObject, useEffect, useState } from 'react';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { MediaPlayer } from 'dashjs';
import { usePlatformInfo } from '@/hooks/usePlatformInfo';
import { PODCAST_VIEW_PROCESS_INTERVAL, PODCAST_VIEWING_CHECK_INTERVAL } from '@/const/podcast';
import './video-with-progress.less';
import If from '@/ui-kit/Base/If/If';
import { VideoSettings } from '@/ui-kit/Components/VideoComponentWithProgress/config';

export interface VideoComponentWithProgressProps {
  mediaUrl: string;
  onIsViewingCheckInterval?: (currentVideoInterval: number) => void;
  onProgressInterval?: (currentVideoTime: number) => void;
  play?: boolean;
  currentTime?: number;
  poster?: string;
  disclaimer?: string;
  playerRef?: MutableRefObject<HTMLVideoElement | null>
}

let isFirstShown = false;
let isSecondShown = false;

const VideoComponentWithProgress: React.FC<VideoComponentWithProgressProps> = ({
  mediaUrl,
  onIsViewingCheckInterval,
  onProgressInterval,
  play = false,
  currentTime,
  poster,
  disclaimer = '',
  playerRef,
}) => {
  // const playerRef = useRef<HTMLVideoElement | null>(null);
  const { isIOS } = usePlatformInfo();

  const [isPlaying, setIsPlaying] = useState<boolean>(play);
  const [isVideoInitialized, setIsVideoInitialized] = useState<boolean>(false);

  let fullVideoPath = `${window.location.origin}/${mediaUrl}#t=${currentTime?.toFixed(0)}`;
  if (isIOS) {
    fullVideoPath = fullVideoPath.replace('.mpd', '.m3u8');
  }

  useEffect(() => {
    if (!isPlaying) {
      return;
    }
    const interval = setInterval(() => {
      const firstPartEnd = playerRef?.current?.duration as number / 3;
      const secondPartEnd = 2 * (playerRef?.current?.duration as number / 3);
      const currentProgressTime = playerRef?.current?.currentTime || 0;

      if (currentProgressTime > firstPartEnd && currentProgressTime < secondPartEnd && !isFirstShown && !isSecondShown) {
        onIsViewingCheckInterval?.(1);
        isFirstShown = true;
        playerRef?.current?.pause();
      } else if (currentProgressTime > secondPartEnd && !isSecondShown) {
        onIsViewingCheckInterval?.(2);
        isSecondShown = true;
        playerRef?.current?.pause();
      }
    }, PODCAST_VIEWING_CHECK_INTERVAL);
    return () => clearInterval(interval);
  }, [isPlaying]);

  useEffect(() => {
    if (!isVideoInitialized) {
      return;
    }
    if (!play) {
      playerRef?.current?.pause();
      return;
    }
    try {
      playerRef?.current?.play();
    } catch (error) {
      console.log(error);
    }
  }, [play]);

  useEffect(() => {
    if (!isPlaying) {
      return;
    }
    const interval = setInterval(() => {
      onProgressInterval?.(playerRef?.current?.currentTime as number);
    }, PODCAST_VIEW_PROCESS_INTERVAL);
    return () => clearInterval(interval);
  }, [isPlaying]);

  useEffect(() => {
    playerRef?.current?.addEventListener('playing', () => {
      setIsPlaying(true);
    });
    playerRef?.current?.addEventListener('pause', () => {
      setIsPlaying(false);
    });

    if (!isIOS) {
      try {
        const player = MediaPlayer().create();
        player.initialize(
          document.querySelector('#kinolime-video') as HTMLMediaElement,
          fullVideoPath,
          false,
        );

        player.updateSettings({
          debug: {},
          streaming: VideoSettings.streaming,
        });
      } catch (error) {
        console.error(error);
      }
    }

    setIsVideoInitialized(true);
  }, []);

  let dataAttributes = {};
  if (disclaimer && !isPlaying) {
    dataAttributes = {
      'data-podcast-disclaimer': disclaimer,
    };
  }

  return (
    <div className="video-with-progress" {...dataAttributes}>
      <video
        id="kinolime-video"
        controls
        disablePictureInPicture
        controlsList="nodownload noremoteplayback noplaybackrate"
        preload="yes"
        autoPlay={false}
        width="100%"
        height="100%"
        ref={playerRef}
        poster={`${window.location.origin}/${poster}`}
      >
        <If truthy={isIOS}>
          <source
            src={fullVideoPath}
          />
        </If>
      </video>
    </div>
  );
};

export default VideoComponentWithProgress;
