const Podcast = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7693_14095)">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 0.00537109C15.3136 0.00537109 18.3137 1.34856 20.4852 3.52013C22.6568 5.6917 24 8.69175 24 12.0054C24 15.319 22.6568 18.319 20.4852 20.4906C18.3137 22.6622 15.3136 24.0054 12 24.0054C8.68638 24.0054 5.68633 22.6622 3.51476 20.4906C1.34319 18.319 0 15.319 0 12.0054C0 8.69175 1.34319 5.6917 3.51476 3.52013C5.68633 1.34856 8.68638 0.00537109 12 0.00537109ZM14.2208 18.5291V17.3863H12.5714V15.5833C13.5497 15.4546 14.4287 15.0004 15.0951 14.3339C15.8891 13.54 16.3819 12.4444 16.3819 11.2388H15.239C15.239 12.129 14.8744 12.9386 14.2871 13.5259C13.6998 14.1133 12.8901 14.4778 12 14.4778C11.1099 14.4778 10.3002 14.1133 9.7129 13.5259C9.12557 12.9386 8.761 12.129 8.761 11.2388H7.61814C7.61814 12.4445 8.1109 13.5401 8.90486 14.3339C9.57129 15.0004 10.4504 15.4546 11.4286 15.5833V17.3863H9.77919V18.5292H14.2208V18.5291ZM12.1127 5.48156C11.4341 5.48156 10.8177 5.75875 10.3711 6.20532C9.92452 6.6519 9.64733 7.26837 9.64733 7.94694V11.2162C9.64733 11.2229 9.64743 11.2295 9.64748 11.2362C9.65286 11.9068 9.92895 12.5157 10.3711 12.9578C10.8177 13.4044 11.4341 13.6816 12.1127 13.6816C12.7913 13.6816 13.4078 13.4044 13.8543 12.9578C14.2984 12.5137 14.575 11.9016 14.578 11.2274L14.578 11.2162V7.9469C14.578 7.26832 14.3009 6.65185 13.8543 6.20528C13.4078 5.75875 12.7913 5.48156 12.1127 5.48156Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_7693_14095">
        <rect width="100%" height="100%" fill="currentColor" transform="translate(0 0.00537109)" />
      </clipPath>
    </defs>
  </svg>

);

export default Podcast;
