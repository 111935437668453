import { useKeycloak } from '@react-keycloak/web';
import { isTest } from '@/utils/isTest';

const KeycloakMock = {
  login: () => {},
  register: () => {},
  logout: () => {},
};

export const useKeycloakProvider = () => {
  if (isTest) {
    return { keycloak: KeycloakMock };
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useKeycloak();
};
