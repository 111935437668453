import {
  useMutation, useQuery, useQueryClient, UseQueryResult,
} from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import { User } from '@/api/generated/User';
import { getToken } from '@/utils/getToken';
import { DEFAULT_HTTP_REFETCH_INTERVAL, staleTime } from '@/const/httpQuery';
import { Wallet } from '@/api/generated/Wallet';
import { options } from '@/api/http/helper';
import { KlBaseData, KlUserData, KlUserProfileImageUploadUsingPostPayload } from '@/api/generated/data-contracts';

export const useGetUser = (isForceEnabled = false): UseQueryResult<KlUserData> => {
  const httpClient = useApi();
  const token = getToken();

  return useQuery({
    queryKey: ['get:/api/v1/user/me', token],
    queryFn: async () => {
      return new User(httpClient).userMeGetUserMeUsingGet();
    },
    enabled: isForceEnabled ? true : !!token,
    staleTime: staleTime(),
  });
};

export const useWalletBalance = () => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/wallet', getToken()],
    queryFn: async () => {
      return new Wallet(httpClient).walletListUsingGet();
    },
    refetchInterval: DEFAULT_HTTP_REFETCH_INTERVAL,
    refetchOnWindowFocus: true,
  });
};

export const useUploadUserPicture = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['post:/api/v1/user/profile/image/:type'],
    mutationFn: async (params: KlUserProfileImageUploadUsingPostPayload) => new User(httpClient).userProfileImageUploadUsingPost('SOURCE', {
      file: params.file,
    }),
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v1/user/me'],
      });
    },
    ...options,
  });
};

export const useDeleteUserPicture = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['delete:/api/v1/user/profile/image/:type'],
    mutationFn: async () => new User(httpClient).userProfileImageDeleteUsingDelete('SOURCE'),
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v1/user/me'],
      });
    },
    ...options,
  });
};

export interface KlUserDataForm extends KlUserData {
  genresField?: KlBaseData[] | number[];
}

export const useUpdateUserMeUsingPut = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (params: {
      userData: KlUserData;
      formData: KlUserDataForm;
    }) => {
      const { formData, userData } = params;

      return new User(httpClient).userMeUpdateUserMeUsingPut({
        ...formData,
        address: formData?.address || userData.address,
        firstName: formData?.firstName || userData.firstName || '',
        lastName: formData?.lastName || userData.lastName || '',
        state: formData?.state || userData.state,
        email: formData?.email || userData.email,
        username: formData?.username || '',
        genreIds: formData?.genresField?.map((g) => { return g as number; }),
      });
    },

    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v1/user/me'],
      });
      queryClient.setQueryData(['get:/api/v1/user/me'], data);
    },
    ...options,

  });
  return mutation;
};

export const useUserMeV2RestoreUserMeUsingPut = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async () => {
      return new User(httpClient).userMeV2RestoreUserMeUsingPut();
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v1/user/me'],
      });
    },
    ...options,

  });
  return mutation;
};
