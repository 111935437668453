import React from 'react';

// we use this component with styles inlined in index.html
// to show loader before the app is loaded
const GeneralLoader: React.FC = () => {
  return (
    <div className="loader-container">
      <div className="loader-container-text">
        <h2> Kinolime </h2>
        <div className="css-loader" />
      </div>
    </div>
  );
};

export default GeneralLoader;
