const CircleCheckmark = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 36 36" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_180490_51661)">
      <path fillRule="evenodd" clipRule="evenodd" d="M18 34.2C26.947 34.2 34.2 26.947 34.2 18C34.2 9.05299 26.947 1.8 18 1.8C9.05299 1.8 1.8 9.05299 1.8 18C1.8 26.947 9.05299 34.2 18 34.2ZM18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="currentColor" />
    </g>
    <path d="M16.5096 24.7487C16.4292 24.8283 16.3335 24.8915 16.2282 24.9347C16.1229 24.9778 16.0099 25 15.8959 25C15.7818 25 15.6689 24.9778 15.5635 24.9347C15.4582 24.8915 15.3626 24.8283 15.2822 24.7487L10.3812 19.9216C10.1371 19.6811 10 19.3551 10 19.0151C10 18.6751 10.1371 18.349 10.3812 18.1086L10.9949 17.5042C11.2391 17.2638 11.5702 17.1288 11.9155 17.1288C12.2607 17.1288 12.5919 17.2638 12.836 17.5042L15.8959 20.5174L24.164 12.3754C24.4081 12.135 24.7393 12 25.0845 12C25.4298 12 25.7609 12.135 26.0051 12.3754L26.6188 12.9798C26.8629 13.2202 27 13.5463 27 13.8863C27 14.2263 26.8629 14.5523 26.6188 14.7928L16.5096 24.7487Z" fill="currentColor" />
  </svg>
);

export default CircleCheckmark;
