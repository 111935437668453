const Notification = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 2.97326C23.8616 2.94668 29.2737 7.71482 29.4973 13.6696C29.5024 13.8039 29.5049 16.2046 29.5049 16.3403H29.4992C29.5007 18.375 29.5371 20.0989 30.3341 21.7527C30.8858 22.8975 31.692 23.9154 32.699 24.7336L32.7048 24.7384C33.5229 25.4135 34 26.3916 34 27.4418C34 29.4236 32.3389 30.9707 30.3745 30.9707H24.3581C24.3495 30.9885 24.3407 31.0062 24.3319 31.0239C24.3267 31.0343 24.3215 31.0448 24.3162 31.0552C23.8722 31.9363 23.2624 32.7223 22.5164 33.3618C21.2413 34.4547 19.6462 35.0528 18 35.0552C16.3538 35.0528 14.7587 34.4547 13.4836 33.3618C12.7376 32.7223 12.1278 31.9363 11.6838 31.0552C11.6696 31.0271 11.6557 30.9989 11.6419 30.9707H5.61964L5.61638 30.9707C4.88537 30.9683 4.16935 30.751 3.56406 30.3435C2.95835 29.9356 2.49037 29.355 2.22939 28.6749C1.96821 27.9942 1.92884 27.2511 2.11756 26.5471C2.30616 25.8434 2.71187 25.2182 3.27349 24.7519L3.2826 24.7443C4.29277 23.9256 5.10195 22.9063 5.65582 21.7596C6.20962 20.613 6.49552 19.3654 6.495 18.104V14.0747C6.495 7.95326 11.9846 2.94599 18 2.97326ZM18 4.97271C13.0125 4.99421 8.495 8.97872 8.495 14.0747V18.1032C8.49558 19.6681 8.1407 21.2134 7.45676 22.6294C6.77381 24.0434 5.77997 25.2934 4.54726 26.2938C4.05077 26.7087 3.87657 27.3849 4.09664 27.9584C4.31751 28.534 4.94176 28.9679 5.62135 28.9707H30.3745C31.3056 28.9707 32 28.249 32 27.4418C32 27.0131 31.8088 26.5933 31.4343 26.2831C30.2044 25.2828 29.2132 24.0336 28.5324 22.621C27.4951 20.4686 27.4971 18.2448 27.499 16.2044C27.4995 15.361 27.5352 14.5124 27.4954 13.6696C27.2638 8.76914 22.8545 4.99363 18 4.97271ZM18 33.0552C19.1583 33.0528 20.2942 32.6324 21.2148 31.8433C21.4895 31.6078 21.7402 31.3435 21.9633 31.0552H14.0367C14.2598 31.3435 14.5105 31.6078 14.7852 31.8433C15.7058 32.6324 16.8417 33.0528 18 33.0552Z"
      fill="currentColor"
    />
  </svg>
);

export default Notification;
