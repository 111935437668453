/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	KlCouponDiscountGetCouponDiscountsUsingGetData,
	KlCouponDiscountGetCouponDiscountsUsingGetError,
	KlCouponDiscountGetCouponDiscountsUsingGetParams,
	KlCouponDiscountSaveExternalCouponDiscountsUsingPostData,
	KlCouponDiscountSaveExternalCouponDiscountsUsingPostError,
	KlPaymentCheckoutSessionCreateCheckoutSessionUsingPostData,
	KlPaymentCheckoutSessionCreateCheckoutSessionUsingPostError,
	KlPaymentCheckoutSessionDto,
	KlPaymentCheckoutSessionExpireCheckoutSessionUsingPutData,
	KlPaymentCheckoutSessionExpireCheckoutSessionUsingPutError,
	KlPaymentCheckoutSessionExpireCheckoutSessionUsingPutParams
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Payment<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http
	}

	/**
	 * No description
	 *
	 * @tags Payment
	 * @name PaymentCheckoutSessionCreateCheckoutSessionUsingPost
	 * @summary createCheckoutSession
	 * @request POST:/api/v1/payment/checkout/session
	 * @secure
	 * @response `200` `KlPaymentCheckoutSessionCreateCheckoutSessionUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	paymentCheckoutSessionCreateCheckoutSessionUsingPost = (
		request: KlPaymentCheckoutSessionDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlPaymentCheckoutSessionCreateCheckoutSessionUsingPostData,
			KlPaymentCheckoutSessionCreateCheckoutSessionUsingPostError
		>({
			path: `/api/v1/payment/checkout/session`,
			method: 'POST',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Payment
	 * @name PaymentCheckoutSessionExpireCheckoutSessionUsingPut
	 * @summary expireCheckoutSession
	 * @request PUT:/api/v1/payment/checkout/session/expire
	 * @secure
	 * @response `200` `KlPaymentCheckoutSessionExpireCheckoutSessionUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	paymentCheckoutSessionExpireCheckoutSessionUsingPut = (
		query: KlPaymentCheckoutSessionExpireCheckoutSessionUsingPutParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlPaymentCheckoutSessionExpireCheckoutSessionUsingPutData,
			KlPaymentCheckoutSessionExpireCheckoutSessionUsingPutError
		>({
			path: `/api/v1/payment/checkout/session/expire`,
			method: 'PUT',
			query: query,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Payment
	 * @name CouponDiscountGetCouponDiscountsUsingGet
	 * @summary getCouponDiscounts
	 * @request GET:/api/v1/payment/coupon-discount
	 * @secure
	 * @response `200` `KlCouponDiscountGetCouponDiscountsUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	couponDiscountGetCouponDiscountsUsingGet = (
		query: KlCouponDiscountGetCouponDiscountsUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlCouponDiscountGetCouponDiscountsUsingGetData,
			KlCouponDiscountGetCouponDiscountsUsingGetError
		>({
			path: `/api/v1/payment/coupon-discount`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Payment
	 * @name CouponDiscountSaveExternalCouponDiscountsUsingPost
	 * @summary saveExternalCouponDiscounts
	 * @request POST:/api/v1/payment/coupon-discount/external
	 * @secure
	 * @response `200` `KlCouponDiscountSaveExternalCouponDiscountsUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	couponDiscountSaveExternalCouponDiscountsUsingPost = (
		params: RequestParams = {}
	) =>
		this.http.request<
			KlCouponDiscountSaveExternalCouponDiscountsUsingPostData,
			KlCouponDiscountSaveExternalCouponDiscountsUsingPostError
		>({
			path: `/api/v1/payment/coupon-discount/external`,
			method: 'POST',
			secure: true,
			type: ContentType.Json,
			...params
		})
}
