import { StrapiAnnouncement } from '@/const/cmsInterface';
import React, { useEffect, useState } from 'react';
import StrapiCompetitionFilterWrapperComponent from '@/components/Cms/StrapiCompetitionFilterWrapperComponent';
import { useGetCompetitionsUsingGet } from '@/api/http/public';
import { useCompetition } from '@/hooks/useCompetition';
import './strapiAnnouncement.less';
import { useTranslation } from 'react-i18next';
import If from '@/ui-kit/Base/If/If';
import { AppStateInterface, useGetAppState, useUpdateAppState } from '@/api/local/app';

interface StrapiAnnouncementComponentProperties {
  block: StrapiAnnouncement;
}

const MAX_STARS = 30;

const MAX_STAR_LIFE = 1;
const MIN_STAR_LIFE = 0.5;

const MAX_STAR_SIZE = 70;
const MIN_STAR_SIZE = 30;

const ANIMATION_DELAY = 23;

const MIN_STAR_TRAVEL_X = 100;
const MIN_STAR_TRAVEL_Y = 100;

const StrapiAnnouncementComponent: React.FC<StrapiAnnouncementComponentProperties> = ({
  block,
}) => {
  const { t } = useTranslation();
  const { data: competitions, isLoading } = useGetCompetitionsUsingGet(true);
  const currentCompetition = competitions?.content?.find((item) => item.isActive);
  const { getActiveStep } = useCompetition(currentCompetition);
  const activeStep = getActiveStep();
  const { data: appState } = useGetAppState();
  const appStateMutation = useUpdateAppState();

  const [isAnnouncementShown, setIsAnnouncementShown] = useState(!!appState?.stargazer?.announcementShown);
  const [isWinnerAnnouncementShown, setIsWinnerAnnouncementShown] = useState(!!appState?.stargazer?.winnerAnnouncementShown);

  const random = (max: number, min: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const randomMinus = () => {
    return Math.random() > 0.5 ? 1 : -1;
  };

  const drawStar = (_: unknown, i: number) => {
    const size = random(MAX_STAR_SIZE, MIN_STAR_SIZE);

    const root = document.documentElement;
    const computedStyle = window.getComputedStyle(root);

    const init_x_from_css = (currentCompetition ? Number(computedStyle.getPropertyValue('--sparkle-max-w')) : Number(computedStyle.getPropertyValue('--sparkle-max-w')) * 1.5);

    const init_x = window?.innerWidth >= init_x_from_css ? init_x_from_css : window?.innerWidth;
    const init_y = Number(computedStyle.getPropertyValue('--sparkle-max-h'));

    const x = init_x / 2 - size / 2;
    const y = init_y / 2 - size / 2;

    const x_dir = randomMinus();
    const y_dir = randomMinus();

    const x_max_travel = random(init_x_from_css, 100);
    const y_max_travel = random(init_y, 100);

    const x_travel_dist = random(x_max_travel, MIN_STAR_TRAVEL_X);
    const y_travel_dist = random(y_max_travel, MIN_STAR_TRAVEL_Y);

    const x_end = x + x_travel_dist * x_dir;
    const y_end = y + y_travel_dist * y_dir;

    const life = random(MAX_STAR_LIFE, MIN_STAR_LIFE);

    const style = {
      '--star-color': '#ffcc00',
      '--start-left': `${x}px`,
      '--start-top': `${y}px`,
      '--end-left': `${x_end}px`,
      '--end-top': `${y_end}px`,
      '--star-life': `${life}s`,
      '--star-life-num': `${life}s`,
      '--star-size': `${size}px`,
      '--animation-delay': `${i * ANIMATION_DELAY}ms`,
    } as React.CSSProperties;
    return <div className="star fa fa-star" style={style} />;
  };

  const drawStars = () => {
    return Array.from({ length: MAX_STARS }, (_, i) => drawStar(_, i));
  };

  useEffect(() => {
    if (isAnnouncementShown) {
      return;
    }
    const timeout = setTimeout(() => {
      if (currentCompetition) {
        appStateMutation.mutate({
          stargazer: {
            announcementShown: true,
          },
        } as Partial<AppStateInterface>);
        setIsAnnouncementShown(true);
      }
    }, 10 * 1000);

    return () => {
      clearInterval(timeout);
    };
  }, []);

  return (
    <StrapiCompetitionFilterWrapperComponent
      isVisibleForCompetition={block?.isVisibleForCompetition}
    >
      {() => (
        <If truthy={(!isLoading && !isAnnouncementShown && !!currentCompetition) || (!isLoading && !isWinnerAnnouncementShown && !currentCompetition)}>
          <div
            className="sparkle-overlay"
            role="button"
            tabIndex={-1}
            onClick={() => {
              if (currentCompetition) {
                appStateMutation.mutate({
                  stargazer: {
                    announcementShown: true,
                  },
                } as Partial<AppStateInterface>);
                setIsAnnouncementShown(true);
              }
              if (!currentCompetition) {
                appStateMutation.mutate({
                  stargazer: {
                    winnerAnnouncementShown: true,
                  },
                } as Partial<AppStateInterface>);
                setIsWinnerAnnouncementShown(true);
              }
            }}
          >
            <div className={`sparkle ${(currentCompetition) ? '' : 'max-w-900'}`}>
              <h2 className="h1 ant-typography light">
                <If truthy={!!currentCompetition}>
                  { activeStep?.name }
                  <small>{t('Next Round Started!')}</small>
                </If>
                <If truthy={!currentCompetition}>
                  <small>{t('The curtain has fallen')}</small>
                  {t('Let’s reveal the winner!')}
                </If>
              </h2>
              {drawStars()}
            </div>
          </div>
        </If>
      )}
    </StrapiCompetitionFilterWrapperComponent>
  );
};

export default StrapiAnnouncementComponent;
