import Slider, { Settings } from 'react-slick';
import React, {
  RefObject, useRef,
} from 'react';
import './screenplay-carousel.less';
import Button from '@/ui-kit/Base/Button/Button';
import classNames from 'classnames';
import { Variant } from '@/ui-kit/types';
import Space from 'antd/es/space';
import Flex from 'antd/es/flex';
import { ArrowCircleRightIcon, ArrowLeftIcon, ArrowRightIcon } from '@/ui-kit/Base/Iconography/Icons';
import { KlBaseData } from '@/api/generated/data-contracts';
import { Paths, ScreenplaysFilterPaths } from '@/const/routes';
import { useTranslation } from 'react-i18next';
import Title from '@/ui-kit/Base/Typography/Title';
import If from '@/ui-kit/Base/If/If';
import Link from '@/ui-kit/Base/Typography/Link';
import Text from '@/ui-kit/Base/Typography/Text';
import { useNavigateWithHistory } from '@/hooks/useNavigateBack';

interface GenreScreenplayCarouselProperties {
  items: KlBaseData[];
  title?: string;
  linkTo?: string;
  autoplay?: boolean;
  className?: string;
  variant?: typeof Variant;
}

export const GenreScreenplayCarousel: React.FC<GenreScreenplayCarouselProperties> = ({
  items,
  title = '',
  linkTo = '',
  className = '',
  autoplay = false,
  variant,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigateWithHistory();
  const sliderRef = useRef<Slider>();
  const settings: Settings = {
    className: 'slider',
    dots: false,
    infinite: false,
    autoplaySpeed: 5000,
    autoplay,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="ui-kit-screenplay-carousel-container">
      <div className={classNames('ui-kit-screenplay-carousel', className, { [`bg-${variant}`]: variant })}>
        <div className="header">
          <div className="carousel-heading">
            <Title level={3} variant="h4" color="lime">{title}</Title>
            <Space size={0} direction="horizontal">
              <Button
                variant="light"
                type="text"
                icon={<ArrowLeftIcon />}
                onClick={() => {
                  sliderRef.current?.slickPrev();
                }}
              />
              <Button
                variant="light"
                type="text"
                icon={<ArrowRightIcon />}
                onClick={() => {
                  sliderRef.current?.slickNext();
                }}
              />
            </Space>
          </div>
          <If truthy={!!linkTo}>
            <div className="view-all-link">
              <Link href={linkTo}>
                <ArrowCircleRightIcon className="light" size={16} />
                {' '}
                {t('View All')}
              </Link>
            </div>
          </If>
        </div>
        <Slider
          ref={sliderRef as RefObject<Slider>}
          {...settings}
        >
          {items?.map((item: KlBaseData) => {
            return (
              <div
                key={item.id}
                className="ui-kit-screenplay-carousel-genre-item"
                role="button"
                tabIndex={-1}
                onClick={() => {
                  navigate(`${Paths.SCREENPLAYS_FILTER}${ScreenplaysFilterPaths.VIEW_ALL}?genres=${String(item?.id)}`);
                }}
              >
                <Flex align="center" justify="center" gap={8} style={{ width: '100%', height: '100%' }}>
                  <Text className="light">
                    {item.name}
                  </Text>
                </Flex>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default GenreScreenplayCarousel;
