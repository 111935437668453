import { MessageAnnounce } from '@/const/cmsInterface';
import If from '@/ui-kit/Base/If/If';
import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { AppStateInterface, useGetAppState, useUpdateAppState } from '@/api/local/app';
import Alert from 'antd/es/alert';
import StrapiCompetitionFilterWrapperComponent from '@/components/Cms/StrapiCompetitionFilterWrapperComponent';

interface StrapiVideoComponentProperties {
  messagesAnnounce?: MessageAnnounce[];
  onVisibleChange?: (visible: boolean) => void;
}

const StrapiMessagesAnnounceComponent: React.FC<StrapiVideoComponentProperties> = ({
  messagesAnnounce,
  onVisibleChange,
}) => {
  const { data: appState } = useGetAppState();
  const appStateMutation = useUpdateAppState();

  // @todo add loader component to the StrapiCompetitionFilterWrapperComponent
  return (
    <div className="message-announce">
      {messagesAnnounce?.map((messageAnnounce) => {
        return (
          <StrapiCompetitionFilterWrapperComponent
            key={messageAnnounce?.id}
            isVisibleForCompetition={messageAnnounce?.isVisibleForCompetition}
          >
            {() => {
              if (appState?.messageAnnounce?.hiddenByUser === messageAnnounce?.id) {
                return null;
              }
              onVisibleChange?.(true);
              return (
                <Alert
                  message={messageAnnounce?.message}
                  description={
                    <If truthy={!!messageAnnounce?.description}>
                      <>
                        {messageAnnounce?.description}
                        <a target="_blank" rel="noreferrer" href={messageAnnounce?.anchorUrl}>
                          {messageAnnounce?.anchorText}
                        </a>
                      </>
                    </If>
                          }
                  type="info"
                  showIcon
                  icon={<InfoCircleOutlined />}
                  closable
                  onClose={() => {
                    appStateMutation.mutate({ messageAnnounce: { hiddenByUser: messageAnnounce?.id } } as Partial<AppStateInterface>);
                    onVisibleChange?.(false);
                  }}
                />);
            }}
          </StrapiCompetitionFilterWrapperComponent>
        );
      })}
    </div>
  );
};

export default StrapiMessagesAnnounceComponent;
