import type { ReactElement } from 'react';
import { StrapiBanner } from '@/const/cmsInterface';
import { IMenuItem } from '@/ui-kit/types';
import React from 'react';
import StrapiBannerComponent from '@/components/Cms/StrapiBannerComponent';

const Page = React.lazy(async () => import('@/ui-kit/Components/Layout/Page'));

export default function UnderConstruction(): ReactElement {
  const block = {
    id: '1',
    type: 'StrapiBanner',
    theme: 'dark',
    opacity: 0.5,
    displayImageAsCover: true,
    align: 'middle',
    content: [
      {
        id: '1',
        heading: {
          reverse: false,
          title: {
            text: 'Platform is under construction now',
            color: 'white',
            size: 1,
            emphasizeNewRow: true,
          },

        },
        content: 'We\'ll be back online as soon as possible',
        button: [
          {
            id: '12',
            text: 'Attend our blog',
            url: 'https://blog.kinolime.com/',
            theme: 'light',
            type: 'primary',
            transparent: true,
          },
        ],
      },
    ],
  } as StrapiBanner;

  return (
    <Page
      menuItems={[] as IMenuItem[]}
      footerItems={[]}
      isSiderVisible={false}
      isSimpleFooter
      headerType="empty"
      variant="dark"
    >
      <StrapiBannerComponent block={block} key="under-construction" />
    </Page>
  );
}
