const Facebook = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_12012_37499)">
      <path
        d="M15.9629 13.9091V15.5455H14.3265V18H15.9629V25.3636H19.2356V18H21.412L21.6901 15.5455H19.2356V14.1136C19.2356 13.4509 19.3011 13.0991 20.3238 13.0991H21.6901V10.6363H19.4974C16.8793 10.6364 15.9629 11.8636 15.9629 13.9091Z"
        fill="currentColor"
      />
      <path
        d="M18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0ZM18 34.3636C8.96259 34.3636 1.63638 27.0374 1.63638 18C1.63638 8.96259 8.96259 1.63638 18 1.63638C27.0374 1.63638 34.3636 8.96259 34.3636 18C34.3636 27.0374 27.0374 34.3636 18 34.3636Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_12012_37499">
        <rect width="100%" height="100%" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Facebook;
