import { useTranslation } from 'react-i18next';
import first from 'lodash/first';
import { useGetPublicScriptsSearch } from '@/api/http/search';
import classNames from 'classnames';
import If from '@/ui-kit/Base/If/If';
import './competition-winner.less';
import WinnerText from '@/ui-kit/static/images/winner.svg';
import WinnerCup from '@/ui-kit/static/images/cup.svg';
import RunnerUps from '@/ui-kit/static/images/runner-up.svg';
import takeRight from 'lodash/takeRight';
import Title from '@/ui-kit/Base/Typography/Title';
import Paragraph from '@/ui-kit/Base/Typography/Paragraph';
import Space from 'antd/es/space';
import AddToMyLabButton from '@/ui-kit/Components/ScreenplayCarouselSection/AddToMyLabButton';
import { KinolimeUpIcon } from '@/ui-kit/Base/Iconography';
import React from 'react';
import ScreenplayCarouselItem from '@/ui-kit/Components/ScreenplayCarouselSection/ScreenplayCarouselItem';
import { SystemPaths } from '@/const/routes';
import { useNavigateWithHistory } from '@/hooks/useNavigateBack';
import LazyImage from '@/ui-kit/Base/Image/LazyImage';

import { KlCompetitionData } from '@/api/generated/data-contracts';
import Allori from '@/ui-kit/Components/Allori/Allori';

export interface CompetitionWinnerProperties {
  currentCompetition: KlCompetitionData;
}
const CompetitionWinnerComponent: React.FC<CompetitionWinnerProperties> = ({
  currentCompetition,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigateWithHistory();

  const { data, isLoading: isScreenplaysLoading } = useGetPublicScriptsSearch({
    pageSize: 3,
    pageNumber: 0,
    sort: ['scriptVoteStatistics.rank: asc'],
    competitionReference: currentCompetition?.reference,
  }, false);

  const winner = first(data?.content);
  const runnerUps = takeRight(data?.content, 2);
  const href = `${window.location.origin}${SystemPaths.PUBLIC_SCREENPLAYS}/${winner?.reference}`;
  const isRatingVisible = import.meta.env?.VITE_IS_RATING_VISIBLE
    ? import.meta.env?.VITE_IS_RATING_VISIBLE_IN_CAROUSEL === 'true'
    : false;

  return (
    <If truthy={!isScreenplaysLoading && !!winner && !!runnerUps.length}>
      <div className={classNames('view-all', 'ui-kit-container', 'competition-winner', { 'as-page': true })}>
        <div className="winner-block">
          <img className="winner-banner" src={WinnerText} alt={winner?.name} />
          <div className="winner-screenplay">
            <If truthy={!!winner}>
              <LazyImage className="poster" src={`${window.location.origin}/${winner?.posterUrl}`} alt={winner?.name as string} />
            </If>
            <div className="winner-card">
              <div className="cup-wrapper">
                <img className="cup" src={WinnerCup} alt="" />
              </div>
              <div className="winner-card-content">
                <Paragraph className="font-size-lg light">{winner?.name}</Paragraph>
                <Paragraph className="light">
                  {winner?.logline}
                </Paragraph>
                <div className="screenplay-data">
                  <Space className="screenplay-data-mylab-and-genres">
                    <If truthy={!!currentCompetition.isActive}>
                      <AddToMyLabButton
                        disabled={winner?.isInMyLab}
                        screenplayId={winner?.id}
                        screenplayTitle={winner?.name as string}
                      />
                    </If>
                    <Paragraph
                      className="white"
                      ellipsis={
                        {
                          tooltip:
                            {
                              title: winner?.scriptGenres?.map((genre) => genre.name).join(' • '),
                              trigger: 'hover',
                              arrow: false,
                            },
                        }
                      }
                    >
                      {t('Genre: ')}
                      <strong className={winner?.scriptGenres?.length ? '' : 'secondary'}>
                        { winner?.scriptGenres?.length ? winner?.scriptGenres.map((genre) => genre.name).join(' • ') : t('Not defined') }
                      </strong>
                    </Paragraph>
                  </Space>
                  <If truthy={isRatingVisible}>
                    <Space className="count" size={4}>
                      <KinolimeUpIcon size={14} />
                      <Paragraph className="lime">
                        {t('Allori: ')}
                        <Allori voteStatistic={winner?.scriptVoteStatistic} />
                      </Paragraph>
                    </Space>
                  </If>
                </div>
              </div>
            </div>
            <a href={href} className="stretched-link" />
          </div>
        </div>
        <div className="runner-ups-block">
          <Title level={2} variant="h3" color="lime">{t('Runner Ups ')}</Title>
          <div className="runners-up">
            {
              runnerUps?.map((script) => {
                return (
                  <ScreenplayCarouselItem
                    key={script?.id}
                    id={script?.id}
                    title={script?.name}
                    genres={script?.scriptGenres?.map((genre) => genre?.name as string)}
                    imageSrc={script?.posterUrl}
                    logline={script?.logline}
                    reference={script?.reference}
                    showLogline={false}
                    onClick={() => navigate(`${SystemPaths.PUBLIC_SCREENPLAYS}/${script.reference}`)}
                    onLinkClick={async () => {}}
                    voteStatistic={script?.scriptVoteStatistic}
                    continueReading={false}
                    isInMyLab={script?.isInMyLab}
                    hasPodcast={!!script?.relatedPodcast}
                    badges={<img className="badge" src={RunnerUps} alt={script?.name} />}
                  />
                );
              })
            }
          </div>
        </div>
      </div>
    </If>
  );
};

export default CompetitionWinnerComponent;
