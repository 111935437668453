import { StrapiBannerMedia, StrapiVideo } from '@/const/cmsInterface';
import Text from '@/ui-kit/Base/Typography/Text';
import If from '@/ui-kit/Base/If/If';
import { TitleProps } from 'antd/es/typography/Title';
import React from 'react';
import CardContent from '@/ui-kit/Components/Section/CardContent/CardContent';
import Heading from '@/ui-kit/Base/Typography/Heading';

import StrapiButtonComponent from '@/components/Cms/StrapiButtonComponent';

import StrapiContentComponent from '@/components/Cms/StrapiContentComponent';
import CompetitionWrapper from '@/components/Wrappers/CompetitionWrapper';
import Timeline from '../../ui-kit/Components/Timeline/Timeline';
import { useTimeLineItems } from '@/components/Cms/hooks/useTimeLineItems';
import StrapiCompetitionFilterWrapperComponent from '@/components/Cms/StrapiCompetitionFilterWrapperComponent';
import { Banner } from '@/ui-kit/Components/Banner';
import StrapiMessagesAnnounceComponent from '@/components/Cms/StrapiMessagesAnnounceComponent';

interface StrapiVideoComponentProperties {
  block: StrapiVideo;
}

const TimelineCompetitionWrapper: React.FC<StrapiVideoComponentProperties> = ({
  block,
}) => {
  const { getTimelineItems } = useTimeLineItems();
  if (!block?.hasCompetitionTimeline) {
    return null;
  }
  return (
    <CompetitionWrapper
      loader={(<Timeline items={[]} />)}
    >
      {({ getActiveStepIndex, getSteps }) => {
        const steps = getSteps();
        const activeStepIndex = getActiveStepIndex();
        const timeLineItems = getTimelineItems(steps, activeStepIndex);
        return (
          <Timeline items={timeLineItems || []} />
        );
      }}
    </CompetitionWrapper>
  );
};

const StrapiVideoComponent: React.FC<StrapiVideoComponentProperties> = ({
  block,
}) => {
  const [isAlertVisible, setIsAlertVisible] = React.useState(true);

  const videoUrl = (): StrapiBannerMedia => {
    const isProd = import.meta.env.VITE_REACT_PUBLIC_SITE_URL as string === 'https://www.kinolime.com/';
    const src = isProd
      ? `${import.meta.env.VITE_REACT_PUBLIC_SITE_URL as string}media/e6935bc8-74ec-4a4d-85b0-674dcc08c7d8/converted/e6935bc8-74ec-4a4d-85b0-674dcc08c7d8_Kino F_30.4.mpd`
      : `${import.meta.env.VITE_REACT_PUBLIC_SITE_URL as string}media/5a7286f0-201e-49a2-bcdd-f2b961211862/converted/5a7286f0-201e-49a2-bcdd-f2b961211862_Kino F_30.4.mpd`;
    return {
      src,
      type: 'video',
    };
  };

  // @todo add loader component to the StrapiCompetitionFilterWrapperComponent
  return (
    <CompetitionWrapper loader={<Banner height="full-screen"><div /></Banner>}>
      {() => (
        <StrapiCompetitionFilterWrapperComponent
          isVisibleIfNoActiveCompetitionOnly={block?.isVisibleIfNoActiveCompetitionOnly}
          isVisibleForCompetition={block?.isVisibleForCompetition}
        >
          {() => {
            return (
              <Banner
                key={block.id}
                alignment={block.align ?? 'left-bottom'}
                bgColor={block.theme ?? 'light'}
                mediaOpacity={block.opacity ?? 1}
                media={videoUrl()}
                className={`${block.class ?? ''} ${isAlertVisible ? 'has-alert-info' : ''}`}
              >
                {block?.content?.map((card) => {
                  return (
                    <div key={card.id}>
                      <CardContent
                        key={`card-${card.id}`}
                        alt={card?.image?.alternativeText || ''}
                        img={card?.image?.url || ''}
                        controls={
                      card?.button?.map((button) => {
                        return (
                          <StrapiButtonComponent button={button} key={button?.id} />
                        );
                      })
                    }
                      >
                        <Heading
                          title={block.title?.text ?? ''}
                          subtitle={block.subtitle?.text ?? ''}
                          subtitleClass={block?.subtitle?.subtitleClass}
                          size={block.title?.size as TitleProps['level']}
                          titleClass={block.title?.titleClass}
                          titleVariant={block.title?.titleVariant ?? 'h1'}
                          titleFontWeight={block.title?.titleFontWeight}
                          color={block.title?.color}
                          emphasize={block.title?.emphasize ?? 'lime'}
                          emphasizeNewRow={block.title?.emphasizeNewRow ?? false}
                        />
                        {/* @todo this block should be done as mardown */}
                        <If truthy={!!card?.title}>
                          <StrapiContentComponent item={card?.title ?? ''} theme={card?.theme || 'secondary'} />
                        </If>
                        <If truthy={!!card?.content}>
                          <Text className={card?.theme || 'secondary'}>
                            <span dangerouslySetInnerHTML={{ __html: card.content || '' }} />
                          </Text>
                        </If>
                      </CardContent>

                      <StrapiMessagesAnnounceComponent
                        messagesAnnounce={block?.messageAnnounce}
                        onVisibleChange={(isVisible) => {
                          setIsAlertVisible(isVisible);
                        }}
                      />
                    </div>
                  );
                })}

                <TimelineCompetitionWrapper block={block} />
              </Banner>
            );
          }}
        </StrapiCompetitionFilterWrapperComponent>
      )}
    </CompetitionWrapper>
  );
};

export default StrapiVideoComponent;
