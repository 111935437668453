import './video-background.less';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { MediaPlayer } from 'dashjs';
import { usePlatformInfo } from '@/hooks/usePlatformInfo';
import If from '@/ui-kit/Base/If/If';
import { VideoSettings } from '@/ui-kit/Components/VideoComponentWithProgress/config';

export interface VideoBackgroundProperties {
  mediaUrl: string;
  autoPlay?: boolean;
  startTime?: number;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  className?: string;
  style?: React.CSSProperties;
  play?: boolean | undefined;
  fallbackImage?: string | undefined;
}
export const VideoBackground: React.FC<VideoBackgroundProperties> = ({
  mediaUrl,
  autoPlay = true,
  startTime = 0,
  controls = true,
  loop = false,
  muted = true,
  className = '',
  style,
  play = true,
  fallbackImage,
}) => {
  const playerRef = useRef<HTMLVideoElement | null>(null);
  const { isIOS } = usePlatformInfo();
  const [isVideoInitialized, setIsVideoInitialized] = useState<boolean>(false);

  const isFullUrl = mediaUrl.includes('https');
  let pathToMedia = isFullUrl ? mediaUrl : `${window.location.origin}/${mediaUrl}`;
  if (isIOS) {
    pathToMedia = pathToMedia.replace('.mpd', '.m3u8');
  }

  useEffect(() => {
    if (!isVideoInitialized) {
      return;
    }
    if (!play) {
      playerRef.current?.pause();
      return;
    }
    try {
      playerRef?.current?.play().then();
    } catch (error) {
      console.log(error);
    }
  }, [play]);

  useEffect(() => {
    if (isIOS) {
      setIsVideoInitialized(true);
      return;
    }
    try {
      const player = MediaPlayer().create();
      player.initialize(
        document.querySelector('#media') as HTMLMediaElement,
        pathToMedia,
        autoPlay,
        startTime,
      );

      player.updateSettings({
        streaming: VideoSettings.streaming,
      });
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
    }
    setIsVideoInitialized(true);
  }, []);

  let backgroundStyle = {};
  if (fallbackImage && !controls && isIOS) {
    backgroundStyle = {
      objectFit: 'cover',
      background: fallbackImage ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${fallbackImage}')` : 'none',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    };

    return (
      <div
        className={classNames(['ui-kit-video-background', 'banner-bg', className])}
        style={{
          ...style,
          ...backgroundStyle,
        }}
      />
    );
  }

  return (
    <video
      className={classNames(['ui-kit-video-background', 'banner-bg', className])}
      id="media"
      controls={controls}
      preload="yes"
      loop={loop}
      autoPlay={autoPlay}
      disablePictureInPicture
      controlsList="nodownload noplaybackrate"
      ref={playerRef}
      muted={muted}
      style={style}
    >
      <track kind="captions" src="" />
      <If truthy={isIOS}>
        <source
          src={pathToMedia}
        />
      </If>
    </video>
  );
};

export default VideoBackground;
