import { useNavigate, useParams } from 'react-router-dom';
import { useGetPublicScriptByReferenceUsingGet } from '@/api/http/screenplay';
import { KlScriptPublicData } from '@/api/generated/data-contracts';
import React, { useEffect } from 'react';
import { ApiError } from '@/api/ReactQueryProvider';
import { SystemPaths } from '@/const/routes';

interface ScreenplayPageWrapperProperties {
  children: (scriptData: KlScriptPublicData | undefined) => React.ReactNode | null;
}

const ScreenplayPageWrapper: React.FC<ScreenplayPageWrapperProperties> = ({
  children,
}) => {
  const { id: scriptReference } = useParams();
  const navigate = useNavigate();
  const { data: scriptData, isLoading: scriptDataIsLoading, error } = useGetPublicScriptByReferenceUsingGet(scriptReference);

  useEffect(() => {
    if (!error) {
      return;
    }
    const apiError = error as unknown as ApiError;
    if (apiError.status === 404) {
      navigate(SystemPaths.NOT_FOUND);
    }
  }, [error]);

  if (scriptDataIsLoading) {
    return null;
  }

  return (
    <>
      {children(scriptData)}
    </>
  );
};

export default ScreenplayPageWrapper;
