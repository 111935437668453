import React, { useEffect } from 'react';

interface DeferRenderProperties {
  children: React.ReactNode | React.ReactNode[];
  timeout?: number;
  className?: string;
  style?: React.CSSProperties;
}

const DeferRender: React.FC<DeferRenderProperties> = ({
  children,
  timeout = 1000,
  className,
  style,
}) => {
  const [shouldRender, setShouldRender] = React.useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShouldRender(true);
    }, timeout);
    return () => clearTimeout(timeoutId);
  }, [timeout]);

  return (
    <div style={{ opacity: shouldRender ? 1 : 0, ...style }} className={className}>
      {children}
    </div>
  );
};

export default DeferRender;
