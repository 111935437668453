import React, { useEffect } from 'react';
import { DashboardPaths, SystemPaths } from '@/const/routes';
import notification from 'antd/es/notification';
import { useTranslation } from 'react-i18next';
import { useGetCompetitionsUsingGet } from '@/api/http/public';

export interface AddedToMyLabNotificationWithCompetitionProperties {
  screenplayTitle: string;
}

export const AddedToMyLabNotificationWithCompetition: React.FC<AddedToMyLabNotificationWithCompetitionProperties> = ({
  screenplayTitle,
}) => {
  const { t } = useTranslation();
  const { data: competitions, isLoading: isCompetitionLoading } = useGetCompetitionsUsingGet(true);
  const currentCompetition = competitions?.content?.find((item) => item.isActive);

  useEffect(() => {
    if (isCompetitionLoading) {
      return;
    }
    // we use custom link since we want underline and black color for notification description only
    notification.success({
      message: t(`${screenplayTitle} added to My Lab`),
      description: (
        <>
          {t('Added screenplay can be found on')}
          {' '}
          <a
            href={`${window.location.origin}${SystemPaths.DASHBOARD}${DashboardPaths.MY_LAB}/${currentCompetition?.id}`}
            style={{ color: '#000', textDecoration: 'underline' }}
          >
            {t('Reads')}
          </a>
          {' '}
          {t('tab')}
        </>
      ),
    });
  }, [isCompetitionLoading]);

  return null;
};

export default AddedToMyLabNotificationWithCompetition;
