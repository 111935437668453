const Profile = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2376_34751)">
      <path d="M17.0711 3.42891C15.1823 1.54023 12.6711 0.5 10 0.5C7.32895 0.5 4.81766 1.5402 2.92891 3.42891C1.04023 5.31766 0 7.82895 0 10.5C0 13.1711 1.0402 15.6823 2.92891 17.5711C4.81766 19.4598 7.32895 20.5 10 20.5C12.6711 20.5 15.1823 19.4598 17.0711 17.5711C18.9598 15.6823 20 13.1711 20 10.5C20 7.82895 18.9598 5.31766 17.0711 3.42891ZM4.34004 17.2694C4.67027 14.4282 7.11305 12.2346 10 12.2346C11.5219 12.2346 12.953 12.8276 14.0295 13.9039C14.9389 14.8135 15.5125 16.0011 15.6601 17.2693C14.1266 18.5536 12.1521 19.3281 10 19.3281C7.84789 19.3281 5.87355 18.5537 4.34004 17.2694ZM10 11.0276C8.32535 11.0276 6.96273 9.66504 6.96273 7.99039C6.96273 6.31559 8.32539 4.95312 10 4.95312C11.6746 4.95312 13.0373 6.31559 13.0373 7.99039C13.0373 9.66504 11.6746 11.0276 10 11.0276ZM16.6687 16.2787C16.3676 15.0662 15.7416 13.9587 14.8581 13.0754C14.1379 12.3552 13.2858 11.8148 12.3566 11.4763C13.4737 10.7187 14.2091 9.43875 14.2091 7.99039C14.2091 5.66953 12.3209 3.78125 10 3.78125C7.67914 3.78125 5.79086 5.66953 5.79086 7.99039C5.79086 9.43953 6.52695 10.7199 7.64496 11.4773C6.79 11.7889 5.99887 12.2706 5.31816 12.9048C4.33242 13.8227 3.64793 14.9932 3.33055 16.2777C1.98641 14.7282 1.17188 12.7075 1.17188 10.5C1.17188 5.63215 5.13215 1.67188 10 1.67188C14.8679 1.67188 18.8281 5.63215 18.8281 10.5C18.8281 12.7079 18.0133 14.7291 16.6687 16.2787Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_2376_34751">
        <rect width="100%" height="100%" fill="none" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default Profile;
