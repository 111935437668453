import { useMutation, useQuery } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import { Public } from '@/api/generated/Public';
import { staleTime } from '@/const/httpQuery';
import {
  KlCompetitionFilterGetDataUsingGetParams,
  KlCompetitionScriptFilterGetScriptsUsingGetParams,
  KlContactUsDto,
  KlDictionaryGetDictionaryUsingGetParams, KlScriptContinueReadingFilterGetDataUsingGetParams,
} from '@/api/generated/data-contracts';
import { Filter } from '@/api/generated/Filter';

export const useSubscribeUsingPost = () => {
  const httpClient = useApi();

  const mutation = useMutation({
    mutationKey: ['post:/api/v1/newsletter/subscribe'],
    mutationFn: async (email: string) => {
      await new Public(httpClient).newsletterSubscriptionSubscribeUsingPost({ type: 'COMPETITION', email });
    },
  });
  return mutation;
};

export const useContactUsUsingPost = () => {
  const httpClient = useApi();

  const mutation = useMutation({
    mutationKey: ['post:/api/v1/public/contact-us'],
    mutationFn: async (params: KlContactUsDto) => {
      await new Public(httpClient).contactUsSaveContactUsUsingPost(params);
    },
  });
  return mutation;
};

export const useGetCompetitionsUsingGet = (enabled: boolean) => {
  const httpClient = useApi();

  return useQuery({
    queryKey: ['get:/api/v1/public/competition'],
    queryFn: async () => {
      return new Public(httpClient).competitionFilterGetDataUsingGet({});
    },
    staleTime: staleTime(),
    enabled,
  });
};

export const useGetCompetitionsByStatus = (query: KlCompetitionFilterGetDataUsingGetParams) => {
  const httpClient = useApi();

  return useQuery({
    queryKey: ['get:/api/v1/public/competition', query],
    queryFn: async () => {
      console.log(query);
      return new Public(httpClient).competitionFilterGetDataUsingGet(query);
    },
    staleTime: staleTime(),
  });
};

export const useCompetitionPublicGetCompetitionUsingGet = (id: number | undefined) => {
  const httpClient = useApi();

  return useQuery({
    queryKey: ['get:/api/v1/public/competition/:id', id],
    queryFn: async () => {
      if (!id) {
        return;
      }
      return new Public(httpClient).competitionPublicGetCompetitionUsingGet(id);
    },
    staleTime: staleTime(),
    enabled: !!id,
  });
};

export const useGetDictionaryUsingGet = (
  query: KlDictionaryGetDictionaryUsingGetParams,
) => {
  const httpClient = useApi();

  return useQuery({
    queryKey: ['get:/api/v1/public/dictionary', JSON.stringify(query)],
    queryFn: async () => new Public(httpClient).dictionaryGetDictionaryUsingGet(query),
    staleTime: staleTime(),
  });
};

export const useGetContinueReading = (
  query: KlScriptContinueReadingFilterGetDataUsingGetParams,
  enabled: boolean,
) => {
  const httpClient = useApi();

  return useQuery({
    queryKey: ['get:/api/v1/filter/script-continue-reading', JSON.stringify(query)],
    queryFn: async () => {
      return new Filter(httpClient).scriptContinueReadingFilterGetDataUsingGet(query);
    },
    staleTime: staleTime(),
    enabled,
  });
};

export const useGetPromoted = (
  query: KlCompetitionScriptFilterGetScriptsUsingGetParams,
  enabled: boolean,
) => {
  const httpClient = useApi();

  return useQuery({
    queryKey: ['get:/api/v1/public/competition-script-filter', JSON.stringify(query)],
    queryFn: async () => {
      return new Public(httpClient).competitionScriptFilterGetScriptsUsingGet(query);
    },
    staleTime: staleTime(),
    enabled,
  });
};

export const useGetPublicScriptsForCompetition = (
  query: KlCompetitionScriptFilterGetScriptsUsingGetParams,
) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/public/complex-script-filter', JSON.stringify(query)],
    queryFn: async () => {
      return new Public(httpClient).competitionScriptFilterGetScriptsUsingGet(query);
    },
    staleTime: staleTime(),
    enabled: !!query?.competitionIds?.length || !!query?.competitionReference,
  });
};

export const useCurrencyEventRateGetRatesUsingGet = () => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['@get:/api/v1/public/currency-event-rate'],
    queryFn: async () => {
      return new Public(httpClient).currencyEventRateGetRatesUsingGet();
    },
    staleTime: staleTime(),
  });
};
