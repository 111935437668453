import * as Sentry from '@sentry/react';
import { sentryOption } from '@/const/sentry';

export const setupSentry = () => {
  if (import.meta.env.MODE === 'development') {
    return;
  }
  Sentry.init(sentryOption);
  const version = APP_VERSION?.replace('v', '');

  console.info(`Sentry is initialized for the mode ${import.meta.env.MODE} app version ${version}`);
  Sentry.setTag('env', import.meta.env.MODE);
  Sentry.setTag('release', version);
  Sentry.setTag('environment', import.meta.env.MODE);
};

export const sentrySetTag = (key: string, value: string) => {
  if (import.meta.env.MODE === 'development') {
    return;
  }
  Sentry.setTag(key, value);
};

export const sentrySetUser = (id: string) => {
  if (import.meta.env.MODE === 'development') {
    return;
  }
  Sentry.setUser({ id });
};
