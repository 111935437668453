/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	KlCommentReportRequest,
	KlCommentRequest,
	KlGenerateImageRequest,
	KlImageGeneratorForImportedScriptsGenerateImageForImportedScriptsUsingPostData,
	KlImageGeneratorForImportedScriptsGenerateImageForImportedScriptsUsingPostError,
	KlImageGeneratorGenerateImageUsingPostData,
	KlImageGeneratorGenerateImageUsingPostError,
	KlScriptActivityGetScriptActivityDetailsUsingGetData,
	KlScriptActivityGetScriptActivityDetailsUsingGetError,
	KlScriptActivityGetScriptActivityDetailsUsingGetParams,
	KlScriptArchivedDto,
	KlScriptArchivedSetArchivedUsingPutData,
	KlScriptArchivedSetArchivedUsingPutError,
	KlScriptAvailableReviewFilterAvailableReviewsUsingGetData,
	KlScriptAvailableReviewFilterAvailableReviewsUsingGetError,
	KlScriptAvailableReviewFilterAvailableReviewsUsingGetParams,
	KlScriptChangeLifeStatusUsingPutData,
	KlScriptChangeLifeStatusUsingPutError,
	KlScriptCommentCreateCommentUsingPostData,
	KlScriptCommentCreateCommentUsingPostError,
	KlScriptCommentCreateReportUsingPostData,
	KlScriptCommentCreateReportUsingPostError,
	KlScriptCommentPageUsingGetData,
	KlScriptCommentPageUsingGetError,
	KlScriptCommentPageUsingGetParams,
	KlScriptCommentRemoveCommentUsingDeleteData,
	KlScriptCommentRemoveCommentUsingDeleteError,
	KlScriptCommentUpdateCommentUsingPutData,
	KlScriptCommentUpdateCommentUsingPutError,
	KlScriptDislikesDislikeUsingPutData,
	KlScriptDislikesDislikeUsingPutError,
	KlScriptDislikesRemoveDislikeUsingDeleteData,
	KlScriptDislikesRemoveDislikeUsingDeleteError,
	KlScriptFavoriteV2AddToFavoritesUsingPutData,
	KlScriptFavoriteV2AddToFavoritesUsingPutError,
	KlScriptFavoriteV2RemoveFromFavoritesUsingDeleteData,
	KlScriptFavoriteV2RemoveFromFavoritesUsingDeleteError,
	KlScriptFeaturedDto,
	KlScriptFeaturedSetFeaturedUsingPutData,
	KlScriptFeaturedSetFeaturedUsingPutError,
	KlScriptFinalVoteDto,
	KlScriptFinalVoteGetFinalVoteUsingGetData,
	KlScriptFinalVoteGetFinalVoteUsingGetError,
	KlScriptFinalVoteGetFinalVoteUsingGetParams,
	KlScriptFinalVoteSaveFinalVoteUsingPostData,
	KlScriptFinalVoteSaveFinalVoteUsingPostError,
	KlScriptFindByIdUsingGetData,
	KlScriptFindByIdUsingGetError,
	KlScriptGetExtendInformationByIdUsingGetData,
	KlScriptGetExtendInformationByIdUsingGetError,
	KlScriptLifeStatusDto,
	KlScriptLikesV2LikeUsingPutData,
	KlScriptLikesV2LikeUsingPutError,
	KlScriptLikesV2RemoveLikeUsingDeleteData,
	KlScriptLikesV2RemoveLikeUsingDeleteError,
	KlScriptNoteCreateScriptNoteUsingPostData,
	KlScriptNoteCreateScriptNoteUsingPostError,
	KlScriptNoteDto,
	KlScriptNoteRemoveScriptNoteUsingDeleteData,
	KlScriptNoteRemoveScriptNoteUsingDeleteError,
	KlScriptNoteUpdateScriptNoteUsingPutData,
	KlScriptNoteUpdateScriptNoteUsingPutError,
	KlScriptPositionGetListenPositionUsingGetData,
	KlScriptPositionGetListenPositionUsingGetError,
	KlScriptPositionGetListenPositionUsingGetParams,
	KlScriptPositionGetReadPositionUsingGetData,
	KlScriptPositionGetReadPositionUsingGetError,
	KlScriptPositionGetReadPositionUsingGetParams,
	KlScriptPromotionDto,
	KlScriptPromotionGetAvailablePromotionSlotsUsingGetData,
	KlScriptPromotionGetAvailablePromotionSlotsUsingGetError,
	KlScriptPromotionGetAvailablePromotionSlotsUsingGetParams,
	KlScriptPromotionPromoteUsingPostData,
	KlScriptPromotionPromoteUsingPostError,
	KlScriptReferenceGenerateScriptReferencesUsingPutData,
	KlScriptReferenceGenerateScriptReferencesUsingPutError,
	KlScriptRemoveUsingDeleteData,
	KlScriptRemoveUsingDeleteError,
	KlScriptReviewAvailableStatusCurrentStatusUsingGetData,
	KlScriptReviewAvailableStatusCurrentStatusUsingGetError,
	KlScriptRoleVisibilityDto,
	KlScriptRoleVisibilityHideRoleUsingPostData,
	KlScriptRoleVisibilityHideRoleUsingPostError,
	KlScriptRoleVisibilityShowAllUsingDeleteData,
	KlScriptRoleVisibilityShowAllUsingDeleteError,
	KlScriptRoleVisibilityShowRoleUsingDeleteData,
	KlScriptRoleVisibilityShowRoleUsingDeleteError,
	KlScriptRoleVisibilityShowRoleUsingDeleteParams,
	KlScriptSaveScriptListUsingPostData,
	KlScriptSaveScriptListUsingPostError,
	KlScriptSaveScriptListUsingPostParams,
	KlScriptSaveScriptListUsingPostPayload,
	KlScriptSaveScriptUsingPostData,
	KlScriptSaveScriptUsingPostError,
	KlScriptSaveScriptUsingPostParams,
	KlScriptSaveScriptUsingPostPayload,
	KlScriptSaveScriptV2UsingPostData,
	KlScriptSaveScriptV2UsingPostError,
	KlScriptSaveScriptV2UsingPostParams,
	KlScriptSaveScriptV2UsingPostPayload,
	KlScriptSectionAddScriptSectionUsingPostData,
	KlScriptSectionAddScriptSectionUsingPostError,
	KlScriptSectionDto,
	KlScriptSectionImageDeleteUsingDeleteData,
	KlScriptSectionImageDeleteUsingDeleteError,
	KlScriptSectionImageUploadUsingPostData,
	KlScriptSectionImageUploadUsingPostError,
	KlScriptSectionImageUploadUsingPostPayload,
	KlScriptSectionUpdateScriptSectionUsingPutData,
	KlScriptSectionUpdateScriptSectionUsingPutError,
	KlScriptSpeechActorDto,
	KlScriptSpeechActorPersonUpdateAllScriptSpeechActorsUsingPutData,
	KlScriptSpeechActorPersonUpdateAllScriptSpeechActorsUsingPutError,
	KlScriptSpeechActorPersonUpdateScriptSpeechActorsUsingPutData,
	KlScriptSpeechActorPersonUpdateScriptSpeechActorsUsingPutError,
	KlScriptSpeechActorSceneUpdateAllScriptSpeechActorsUsingPutData,
	KlScriptSpeechActorSceneUpdateAllScriptSpeechActorsUsingPutError,
	KlScriptSpeechActorSceneUpdateScriptSpeechActorsUsingPutData,
	KlScriptSpeechActorSceneUpdateScriptSpeechActorsUsingPutError,
	KlScriptSpeechGroupRegenerateScriptSpeechGroupUsingPutData,
	KlScriptSpeechGroupRegenerateScriptSpeechGroupUsingPutError,
	KlScriptSpeechGroupUpdateScriptSpeechGroupUsingPutData,
	KlScriptSpeechGroupUpdateScriptSpeechGroupUsingPutError,
	KlScriptSpeechGroupUpdateScriptSpeechGroupUsingPutPayload,
	KlScriptTopicCreteScriptTopicUsingPostData,
	KlScriptTopicCreteScriptTopicUsingPostError,
	KlScriptTopicPostGetScriptTopicPostsUsingGetData,
	KlScriptTopicPostGetScriptTopicPostsUsingGetError,
	KlScriptUploadDeleteScriptImageByGenreUsingDeleteData,
	KlScriptUploadDeleteScriptImageByGenreUsingDeleteError,
	KlScriptUploadDeleteScriptImageByGenreUsingDeleteParams,
	KlScriptUploadGetScriptBasedLifeStoryUsingGetData,
	KlScriptUploadGetScriptBasedLifeStoryUsingGetError,
	KlScriptUploadGetScriptBasedWorkUsingGetData,
	KlScriptUploadGetScriptBasedWorkUsingGetError,
	KlScriptUploadGetScriptByReferenceUsingGetData,
	KlScriptUploadGetScriptByReferenceUsingGetError,
	KlScriptUploadGetScriptUsingGetData,
	KlScriptUploadGetScriptUsingGetError,
	KlScriptUploadSaveScriptBasedLifeStoryUsingPostData,
	KlScriptUploadSaveScriptBasedLifeStoryUsingPostError,
	KlScriptUploadSaveScriptBasedLifeStoryUsingPostPayload,
	KlScriptUploadSaveScriptBasedWorkUsingPostData,
	KlScriptUploadSaveScriptBasedWorkUsingPostError,
	KlScriptUploadSaveScriptBasedWorkUsingPostPayload,
	KlScriptUploadSaveScriptFileUsingPostData,
	KlScriptUploadSaveScriptFileUsingPostError,
	KlScriptUploadSaveScriptFileUsingPostPayload,
	KlScriptUploadSaveScriptImageAiUsingPostData,
	KlScriptUploadSaveScriptImageAiUsingPostError,
	KlScriptUploadSaveScriptImageAiUsingPostParams,
	KlScriptUploadSaveScriptImageByGenreUsingPostData,
	KlScriptUploadSaveScriptImageByGenreUsingPostError,
	KlScriptUploadSaveScriptImageByGenreUsingPostParams,
	KlScriptUploadSaveScriptImageUsingPostData,
	KlScriptUploadSaveScriptImageUsingPostError,
	KlScriptUploadSaveScriptImageUsingPostParams,
	KlScriptUploadSaveScriptImageUsingPostPayload,
	KlScriptUploadSaveScriptUsingPostData,
	KlScriptUploadSaveScriptUsingPostError,
	KlScriptUploadSubmitUpdateScriptUsingPostData,
	KlScriptUploadSubmitUpdateScriptUsingPostError,
	KlScriptUploadSubmitUploadScriptUsingPostData,
	KlScriptUploadSubmitUploadScriptUsingPostError,
	KlScriptUploadUpdateScriptUsingPutData,
	KlScriptUploadUpdateScriptUsingPutError,
	KlScriptUploadUpdateScriptUsingPutParams,
	KlUploadScriptDto
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Script<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http
	}

	/**
	 * No description
	 *
	 * @tags script-controller
	 * @name ScriptSaveScriptUsingPost
	 * @summary saveScript
	 * @request POST:/api/v1/script
	 * @deprecated
	 * @secure
	 * @response `200` `KlScriptSaveScriptUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSaveScriptUsingPost = (
		query: KlScriptSaveScriptUsingPostParams,
		data: KlScriptSaveScriptUsingPostPayload,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSaveScriptUsingPostData,
			KlScriptSaveScriptUsingPostError
		>({
			path: `/api/v1/script`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.FormData,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-archived-controller
	 * @name ScriptArchivedSetArchivedUsingPut
	 * @summary setArchived
	 * @request PUT:/api/v1/script/archived
	 * @secure
	 * @response `200` `KlScriptArchivedSetArchivedUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptArchivedSetArchivedUsingPut = (
		scriptArchivedDto: KlScriptArchivedDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptArchivedSetArchivedUsingPutData,
			KlScriptArchivedSetArchivedUsingPutError
		>({
			path: `/api/v1/script/archived`,
			method: 'PUT',
			body: scriptArchivedDto,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-featured-controller
	 * @name ScriptFeaturedSetFeaturedUsingPut
	 * @summary setFeatured
	 * @request PUT:/api/v1/script/featured
	 * @secure
	 * @response `200` `KlScriptFeaturedSetFeaturedUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptFeaturedSetFeaturedUsingPut = (
		scriptFeaturedDto: KlScriptFeaturedDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptFeaturedSetFeaturedUsingPutData,
			KlScriptFeaturedSetFeaturedUsingPutError
		>({
			path: `/api/v1/script/featured`,
			method: 'PUT',
			body: scriptFeaturedDto,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-final-vote-controller
	 * @name ScriptFinalVoteGetFinalVoteUsingGet
	 * @summary getFinalVote
	 * @request GET:/api/v1/script/final-vote
	 * @secure
	 * @response `200` `KlScriptFinalVoteGetFinalVoteUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptFinalVoteGetFinalVoteUsingGet = (
		query: KlScriptFinalVoteGetFinalVoteUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptFinalVoteGetFinalVoteUsingGetData,
			KlScriptFinalVoteGetFinalVoteUsingGetError
		>({
			path: `/api/v1/script/final-vote`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-final-vote-controller
	 * @name ScriptFinalVoteSaveFinalVoteUsingPost
	 * @summary saveFinalVote
	 * @request POST:/api/v1/script/final-vote
	 * @secure
	 * @response `200` `KlScriptFinalVoteSaveFinalVoteUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptFinalVoteSaveFinalVoteUsingPost = (
		request: KlScriptFinalVoteDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptFinalVoteSaveFinalVoteUsingPostData,
			KlScriptFinalVoteSaveFinalVoteUsingPostError
		>({
			path: `/api/v1/script/final-vote`,
			method: 'POST',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags image-generator-controller
	 * @name ImageGeneratorGenerateImageUsingPost
	 * @summary generateImage
	 * @request POST:/api/v1/script/image/generate
	 * @secure
	 * @response `200` `KlImageGeneratorGenerateImageUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	imageGeneratorGenerateImageUsingPost = (
		request: KlGenerateImageRequest,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlImageGeneratorGenerateImageUsingPostData,
			KlImageGeneratorGenerateImageUsingPostError
		>({
			path: `/api/v1/script/image/generate`,
			method: 'POST',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags image-generator-for-imported-scripts-controller
	 * @name ImageGeneratorForImportedScriptsGenerateImageForImportedScriptsUsingPost
	 * @summary generateImageForImportedScripts
	 * @request POST:/api/v1/script/image/generate/imported
	 * @secure
	 * @response `200` `KlImageGeneratorForImportedScriptsGenerateImageForImportedScriptsUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	imageGeneratorForImportedScriptsGenerateImageForImportedScriptsUsingPost = (
		params: RequestParams = {}
	) =>
		this.http.request<
			KlImageGeneratorForImportedScriptsGenerateImageForImportedScriptsUsingPostData,
			KlImageGeneratorForImportedScriptsGenerateImageForImportedScriptsUsingPostError
		>({
			path: `/api/v1/script/image/generate/imported`,
			method: 'POST',
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-controller
	 * @name ScriptChangeLifeStatusUsingPut
	 * @summary changeLifeStatus
	 * @request PUT:/api/v1/script/life-status
	 * @secure
	 * @response `200` `KlScriptChangeLifeStatusUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptChangeLifeStatusUsingPut = (
		request: KlScriptLifeStatusDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptChangeLifeStatusUsingPutData,
			KlScriptChangeLifeStatusUsingPutError
		>({
			path: `/api/v1/script/life-status`,
			method: 'PUT',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-controller
	 * @name ScriptSaveScriptListUsingPost
	 * @summary saveScriptList
	 * @request POST:/api/v1/script/list
	 * @secure
	 * @response `200` `KlScriptSaveScriptListUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSaveScriptListUsingPost = (
		query: KlScriptSaveScriptListUsingPostParams,
		data: KlScriptSaveScriptListUsingPostPayload,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSaveScriptListUsingPostData,
			KlScriptSaveScriptListUsingPostError
		>({
			path: `/api/v1/script/list`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.FormData,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Note
	 * @name ScriptNoteCreateScriptNoteUsingPost
	 * @summary createScriptNote
	 * @request POST:/api/v1/script/note
	 * @secure
	 * @response `200` `KlScriptNoteCreateScriptNoteUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptNoteCreateScriptNoteUsingPost = (
		request: KlScriptNoteDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptNoteCreateScriptNoteUsingPostData,
			KlScriptNoteCreateScriptNoteUsingPostError
		>({
			path: `/api/v1/script/note`,
			method: 'POST',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Note
	 * @name ScriptNoteUpdateScriptNoteUsingPut
	 * @summary updateScriptNote
	 * @request PUT:/api/v1/script/note/{id}
	 * @secure
	 * @response `200` `KlScriptNoteUpdateScriptNoteUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptNoteUpdateScriptNoteUsingPut = (
		id: number,
		request: KlScriptNoteDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptNoteUpdateScriptNoteUsingPutData,
			KlScriptNoteUpdateScriptNoteUsingPutError
		>({
			path: `/api/v1/script/note/${id}`,
			method: 'PUT',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Note
	 * @name ScriptNoteRemoveScriptNoteUsingDelete
	 * @summary removeScriptNote
	 * @request DELETE:/api/v1/script/note/{id}
	 * @secure
	 * @response `200` `KlScriptNoteRemoveScriptNoteUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptNoteRemoveScriptNoteUsingDelete = (
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptNoteRemoveScriptNoteUsingDeleteData,
			KlScriptNoteRemoveScriptNoteUsingDeleteError
		>({
			path: `/api/v1/script/note/${id}`,
			method: 'DELETE',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-promotion-controller
	 * @name ScriptPromotionPromoteUsingPost
	 * @summary promote
	 * @request POST:/api/v1/script/promotion
	 * @secure
	 * @response `200` `KlScriptPromotionPromoteUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptPromotionPromoteUsingPost = (
		scriptPromotionDto: KlScriptPromotionDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptPromotionPromoteUsingPostData,
			KlScriptPromotionPromoteUsingPostError
		>({
			path: `/api/v1/script/promotion`,
			method: 'POST',
			body: scriptPromotionDto,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-promotion-controller
	 * @name ScriptPromotionGetAvailablePromotionSlotsUsingGet
	 * @summary getAvailablePromotionSlots
	 * @request GET:/api/v1/script/promotion/slots
	 * @secure
	 * @response `200` `KlScriptPromotionGetAvailablePromotionSlotsUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptPromotionGetAvailablePromotionSlotsUsingGet = (
		query: KlScriptPromotionGetAvailablePromotionSlotsUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptPromotionGetAvailablePromotionSlotsUsingGetData,
			KlScriptPromotionGetAvailablePromotionSlotsUsingGetError
		>({
			path: `/api/v1/script/promotion/slots`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reference generator
	 * @name ScriptReferenceGenerateScriptReferencesUsingPut
	 * @summary generateScriptReferences
	 * @request PUT:/api/v1/script/reference/generate
	 * @secure
	 * @response `200` `KlScriptReferenceGenerateScriptReferencesUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptReferenceGenerateScriptReferencesUsingPut = (
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptReferenceGenerateScriptReferencesUsingPutData,
			KlScriptReferenceGenerateScriptReferencesUsingPutError
		>({
			path: `/api/v1/script/reference/generate`,
			method: 'PUT',
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Filters
	 * @name ScriptAvailableReviewFilterAvailableReviewsUsingGet
	 * @summary availableReviews
	 * @request GET:/api/v1/script/review
	 * @secure
	 * @response `200` `KlScriptAvailableReviewFilterAvailableReviewsUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptAvailableReviewFilterAvailableReviewsUsingGet = (
		query: KlScriptAvailableReviewFilterAvailableReviewsUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptAvailableReviewFilterAvailableReviewsUsingGetData,
			KlScriptAvailableReviewFilterAvailableReviewsUsingGetError
		>({
			path: `/api/v1/script/review`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-role-visibility-controller
	 * @name ScriptRoleVisibilityHideRoleUsingPost
	 * @summary hideRole
	 * @request POST:/api/v1/script/visibility/role
	 * @secure
	 * @response `200` `KlScriptRoleVisibilityHideRoleUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptRoleVisibilityHideRoleUsingPost = (
		request: KlScriptRoleVisibilityDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptRoleVisibilityHideRoleUsingPostData,
			KlScriptRoleVisibilityHideRoleUsingPostError
		>({
			path: `/api/v1/script/visibility/role`,
			method: 'POST',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-role-visibility-controller
	 * @name ScriptRoleVisibilityShowRoleUsingDelete
	 * @summary showRole
	 * @request DELETE:/api/v1/script/visibility/role
	 * @secure
	 * @response `200` `KlScriptRoleVisibilityShowRoleUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptRoleVisibilityShowRoleUsingDelete = (
		query: KlScriptRoleVisibilityShowRoleUsingDeleteParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptRoleVisibilityShowRoleUsingDeleteData,
			KlScriptRoleVisibilityShowRoleUsingDeleteError
		>({
			path: `/api/v1/script/visibility/role`,
			method: 'DELETE',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-role-visibility-controller
	 * @name ScriptRoleVisibilityShowAllUsingDelete
	 * @summary showAll
	 * @request DELETE:/api/v1/script/visibility/role/{scriptId}
	 * @secure
	 * @response `200` `KlScriptRoleVisibilityShowAllUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptRoleVisibilityShowAllUsingDelete = (
		scriptId: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptRoleVisibilityShowAllUsingDeleteData,
			KlScriptRoleVisibilityShowAllUsingDeleteError
		>({
			path: `/api/v1/script/visibility/role/${scriptId}`,
			method: 'DELETE',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-controller
	 * @name ScriptFindByIdUsingGet
	 * @summary findById
	 * @request GET:/api/v1/script/{id}
	 * @secure
	 * @response `200` `KlScriptFindByIdUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptFindByIdUsingGet = (id: number, params: RequestParams = {}) =>
		this.http.request<
			KlScriptFindByIdUsingGetData,
			KlScriptFindByIdUsingGetError
		>({
			path: `/api/v1/script/${id}`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-controller
	 * @name ScriptRemoveUsingDelete
	 * @summary remove
	 * @request DELETE:/api/v1/script/{id}
	 * @secure
	 * @response `200` `KlScriptRemoveUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptRemoveUsingDelete = (id: number, params: RequestParams = {}) =>
		this.http.request<
			KlScriptRemoveUsingDeleteData,
			KlScriptRemoveUsingDeleteError
		>({
			path: `/api/v1/script/${id}`,
			method: 'DELETE',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-activity-controller
	 * @name ScriptActivityGetScriptActivityDetailsUsingGet
	 * @summary getScriptActivityDetails
	 * @request GET:/api/v1/script/{id}/activity/details
	 * @secure
	 * @response `200` `KlScriptActivityGetScriptActivityDetailsUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptActivityGetScriptActivityDetailsUsingGet = (
		{ id, ...query }: KlScriptActivityGetScriptActivityDetailsUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptActivityGetScriptActivityDetailsUsingGetData,
			KlScriptActivityGetScriptActivityDetailsUsingGetError
		>({
			path: `/api/v1/script/${id}/activity/details`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-dislikes-controller
	 * @name ScriptDislikesDislikeUsingPut
	 * @summary dislike
	 * @request PUT:/api/v1/script/{id}/dislike
	 * @secure
	 * @response `200` `KlScriptDislikesDislikeUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptDislikesDislikeUsingPut = (id: number, params: RequestParams = {}) =>
		this.http.request<
			KlScriptDislikesDislikeUsingPutData,
			KlScriptDislikesDislikeUsingPutError
		>({
			path: `/api/v1/script/${id}/dislike`,
			method: 'PUT',
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-dislikes-controller
	 * @name ScriptDislikesRemoveDislikeUsingDelete
	 * @summary removeDislike
	 * @request DELETE:/api/v1/script/{id}/dislike
	 * @secure
	 * @response `200` `KlScriptDislikesRemoveDislikeUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptDislikesRemoveDislikeUsingDelete = (
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptDislikesRemoveDislikeUsingDeleteData,
			KlScriptDislikesRemoveDislikeUsingDeleteError
		>({
			path: `/api/v1/script/${id}/dislike`,
			method: 'DELETE',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-likes-v-2-controller
	 * @name ScriptLikesV2LikeUsingPut
	 * @summary like
	 * @request PUT:/api/v1/script/{id}/like
	 * @secure
	 * @response `200` `KlScriptLikesV2LikeUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptLikesV2LikeUsingPut = (id: number, params: RequestParams = {}) =>
		this.http.request<
			KlScriptLikesV2LikeUsingPutData,
			KlScriptLikesV2LikeUsingPutError
		>({
			path: `/api/v1/script/${id}/like`,
			method: 'PUT',
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-likes-v-2-controller
	 * @name ScriptLikesV2RemoveLikeUsingDelete
	 * @summary removeLike
	 * @request DELETE:/api/v1/script/{id}/like
	 * @secure
	 * @response `200` `KlScriptLikesV2RemoveLikeUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptLikesV2RemoveLikeUsingDelete = (
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptLikesV2RemoveLikeUsingDeleteData,
			KlScriptLikesV2RemoveLikeUsingDeleteError
		>({
			path: `/api/v1/script/${id}/like`,
			method: 'DELETE',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-review-available-status-controller
	 * @name ScriptReviewAvailableStatusCurrentStatusUsingGet
	 * @summary currentStatus
	 * @request GET:/api/v1/script/{id}/review/available
	 * @deprecated
	 * @secure
	 * @response `200` `KlScriptReviewAvailableStatusCurrentStatusUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptReviewAvailableStatusCurrentStatusUsingGet = (
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptReviewAvailableStatusCurrentStatusUsingGetData,
			KlScriptReviewAvailableStatusCurrentStatusUsingGetError
		>({
			path: `/api/v1/script/${id}/review/available`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-comment-controller
	 * @name ScriptCommentPageUsingGet
	 * @summary page
	 * @request GET:/api/v1/script/{scriptId}/comment
	 * @secure
	 * @response `200` `KlScriptCommentPageUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptCommentPageUsingGet = (
		{ scriptId, ...query }: KlScriptCommentPageUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptCommentPageUsingGetData,
			KlScriptCommentPageUsingGetError
		>({
			path: `/api/v1/script/${scriptId}/comment`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-comment-controller
	 * @name ScriptCommentCreateCommentUsingPost
	 * @summary createComment
	 * @request POST:/api/v1/script/{scriptId}/comment
	 * @secure
	 * @response `200` `KlScriptCommentCreateCommentUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptCommentCreateCommentUsingPost = (
		scriptId: number,
		request: KlCommentRequest,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptCommentCreateCommentUsingPostData,
			KlScriptCommentCreateCommentUsingPostError
		>({
			path: `/api/v1/script/${scriptId}/comment`,
			method: 'POST',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-comment-controller
	 * @name ScriptCommentUpdateCommentUsingPut
	 * @summary updateComment
	 * @request PUT:/api/v1/script/{scriptId}/comment
	 * @secure
	 * @response `200` `KlScriptCommentUpdateCommentUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptCommentUpdateCommentUsingPut = (
		scriptId: number,
		request: KlCommentRequest,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptCommentUpdateCommentUsingPutData,
			KlScriptCommentUpdateCommentUsingPutError
		>({
			path: `/api/v1/script/${scriptId}/comment`,
			method: 'PUT',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-comment-controller
	 * @name ScriptCommentRemoveCommentUsingDelete
	 * @summary removeComment
	 * @request DELETE:/api/v1/script/{scriptId}/comment/{commentId}
	 * @secure
	 * @response `200` `KlScriptCommentRemoveCommentUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptCommentRemoveCommentUsingDelete = (
		commentId: number,
		scriptId: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptCommentRemoveCommentUsingDeleteData,
			KlScriptCommentRemoveCommentUsingDeleteError
		>({
			path: `/api/v1/script/${scriptId}/comment/${commentId}`,
			method: 'DELETE',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-comment-controller
	 * @name ScriptCommentCreateReportUsingPost
	 * @summary createReport
	 * @request POST:/api/v1/script/{scriptId}/comment/{commentId}/report
	 * @secure
	 * @response `200` `KlScriptCommentCreateReportUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptCommentCreateReportUsingPost = (
		commentId: number,
		scriptId: number,
		request: KlCommentReportRequest,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptCommentCreateReportUsingPostData,
			KlScriptCommentCreateReportUsingPostError
		>({
			path: `/api/v1/script/${scriptId}/comment/${commentId}/report`,
			method: 'POST',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script position
	 * @name ScriptPositionGetListenPositionUsingGet
	 * @summary getListenPosition
	 * @request GET:/api/v1/script/{scriptId}/position/listen
	 * @secure
	 * @response `200` `KlScriptPositionGetListenPositionUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptPositionGetListenPositionUsingGet = (
		{ scriptId, ...query }: KlScriptPositionGetListenPositionUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptPositionGetListenPositionUsingGetData,
			KlScriptPositionGetListenPositionUsingGetError
		>({
			path: `/api/v1/script/${scriptId}/position/listen`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script position
	 * @name ScriptPositionGetReadPositionUsingGet
	 * @summary getReadPosition
	 * @request GET:/api/v1/script/{scriptId}/position/read
	 * @secure
	 * @response `200` `KlScriptPositionGetReadPositionUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptPositionGetReadPositionUsingGet = (
		{ scriptId, ...query }: KlScriptPositionGetReadPositionUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptPositionGetReadPositionUsingGetData,
			KlScriptPositionGetReadPositionUsingGetError
		>({
			path: `/api/v1/script/${scriptId}/position/read`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script section
	 * @name ScriptSectionAddScriptSectionUsingPost
	 * @summary addScriptSection
	 * @request POST:/api/v1/script/{scriptId}/section
	 * @secure
	 * @response `200` `KlScriptSectionAddScriptSectionUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSectionAddScriptSectionUsingPost = (
		scriptId: number,
		section: KlScriptSectionDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSectionAddScriptSectionUsingPostData,
			KlScriptSectionAddScriptSectionUsingPostError
		>({
			path: `/api/v1/script/${scriptId}/section`,
			method: 'POST',
			body: section,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script section
	 * @name ScriptSectionUpdateScriptSectionUsingPut
	 * @summary updateScriptSection
	 * @request PUT:/api/v1/script/{scriptId}/section/{sectionId}
	 * @secure
	 * @response `200` `KlScriptSectionUpdateScriptSectionUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSectionUpdateScriptSectionUsingPut = (
		scriptId: number,
		sectionId: number,
		section: KlScriptSectionDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSectionUpdateScriptSectionUsingPutData,
			KlScriptSectionUpdateScriptSectionUsingPutError
		>({
			path: `/api/v1/script/${scriptId}/section/${sectionId}`,
			method: 'PUT',
			body: section,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script section
	 * @name ScriptSectionImageUploadUsingPost
	 * @summary upload
	 * @request POST:/api/v1/script/{scriptId}/section/{sectionId}/image
	 * @secure
	 * @response `200` `KlScriptSectionImageUploadUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSectionImageUploadUsingPost = (
		scriptId: number,
		sectionId: number,
		data: KlScriptSectionImageUploadUsingPostPayload,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSectionImageUploadUsingPostData,
			KlScriptSectionImageUploadUsingPostError
		>({
			path: `/api/v1/script/${scriptId}/section/${sectionId}/image`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script section
	 * @name ScriptSectionImageDeleteUsingDelete
	 * @summary delete
	 * @request DELETE:/api/v1/script/{scriptId}/section/{sectionId}/image
	 * @secure
	 * @response `200` `KlScriptSectionImageDeleteUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSectionImageDeleteUsingDelete = (
		scriptId: number,
		sectionId: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSectionImageDeleteUsingDeleteData,
			KlScriptSectionImageDeleteUsingDeleteError
		>({
			path: `/api/v1/script/${scriptId}/section/${sectionId}/image`,
			method: 'DELETE',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags PDF to speech
	 * @name ScriptSpeechGroupUpdateScriptSpeechGroupUsingPut
	 * @summary updateScriptSpeechGroup
	 * @request PUT:/api/v1/script/{scriptId}/speech/group/{id}
	 * @secure
	 * @response `200` `KlScriptSpeechGroupUpdateScriptSpeechGroupUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSpeechGroupUpdateScriptSpeechGroupUsingPut = (
		id: number,
		scriptId: number,
		request: KlScriptSpeechGroupUpdateScriptSpeechGroupUsingPutPayload,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSpeechGroupUpdateScriptSpeechGroupUsingPutData,
			KlScriptSpeechGroupUpdateScriptSpeechGroupUsingPutError
		>({
			path: `/api/v1/script/${scriptId}/speech/group/${id}`,
			method: 'PUT',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags PDF to speech
	 * @name ScriptSpeechGroupRegenerateScriptSpeechGroupUsingPut
	 * @summary regenerateScriptSpeechGroup
	 * @request PUT:/api/v1/script/{scriptId}/speech/group/{id}/regenerate
	 * @secure
	 * @response `200` `KlScriptSpeechGroupRegenerateScriptSpeechGroupUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSpeechGroupRegenerateScriptSpeechGroupUsingPut = (
		id: number,
		scriptId: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSpeechGroupRegenerateScriptSpeechGroupUsingPutData,
			KlScriptSpeechGroupRegenerateScriptSpeechGroupUsingPutError
		>({
			path: `/api/v1/script/${scriptId}/speech/group/${id}/regenerate`,
			method: 'PUT',
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Forum
	 * @name ScriptTopicCreteScriptTopicUsingPost
	 * @summary creteScriptTopic
	 * @request POST:/api/v1/script/{scriptId}/topic
	 * @secure
	 * @response `200` `KlScriptTopicCreteScriptTopicUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptTopicCreteScriptTopicUsingPost = (
		scriptId: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptTopicCreteScriptTopicUsingPostData,
			KlScriptTopicCreteScriptTopicUsingPostError
		>({
			path: `/api/v1/script/${scriptId}/topic`,
			method: 'POST',
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params
		})
	/**
	 * No description
	 *
	 * @tags Forum
	 * @name ScriptTopicPostGetScriptTopicPostsUsingGet
	 * @summary getScriptTopicPosts
	 * @request GET:/api/v1/script/{scriptId}/topic/post
	 * @secure
	 * @response `200` `KlScriptTopicPostGetScriptTopicPostsUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptTopicPostGetScriptTopicPostsUsingGet = (
		scriptId: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptTopicPostGetScriptTopicPostsUsingGetData,
			KlScriptTopicPostGetScriptTopicPostsUsingGetError
		>({
			path: `/api/v1/script/${scriptId}/topic/post`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-controller
	 * @name ScriptSaveScriptV2UsingPost
	 * @summary saveScriptV2
	 * @request POST:/api/v2/script
	 * @secure
	 * @response `200` `KlScriptSaveScriptV2UsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSaveScriptV2UsingPost = (
		query: KlScriptSaveScriptV2UsingPostParams,
		data: KlScriptSaveScriptV2UsingPostPayload,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSaveScriptV2UsingPostData,
			KlScriptSaveScriptV2UsingPostError
		>({
			path: `/api/v2/script`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.FormData,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-controller
	 * @name ScriptGetExtendInformationByIdUsingGet
	 * @summary getExtendInformationById
	 * @request GET:/api/v2/script/{id}/extend
	 * @secure
	 * @response `200` `KlScriptGetExtendInformationByIdUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptGetExtendInformationByIdUsingGet = (
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptGetExtendInformationByIdUsingGetData,
			KlScriptGetExtendInformationByIdUsingGetError
		>({
			path: `/api/v2/script/${id}/extend`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-favorite-v-2-controller
	 * @name ScriptFavoriteV2AddToFavoritesUsingPut
	 * @summary addToFavorites
	 * @request PUT:/api/v2/script/{id}/favorite
	 * @secure
	 * @response `200` `KlScriptFavoriteV2AddToFavoritesUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptFavoriteV2AddToFavoritesUsingPut = (
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptFavoriteV2AddToFavoritesUsingPutData,
			KlScriptFavoriteV2AddToFavoritesUsingPutError
		>({
			path: `/api/v2/script/${id}/favorite`,
			method: 'PUT',
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-favorite-v-2-controller
	 * @name ScriptFavoriteV2RemoveFromFavoritesUsingDelete
	 * @summary removeFromFavorites
	 * @request DELETE:/api/v2/script/{id}/favorite
	 * @secure
	 * @response `200` `KlScriptFavoriteV2RemoveFromFavoritesUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptFavoriteV2RemoveFromFavoritesUsingDelete = (
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptFavoriteV2RemoveFromFavoritesUsingDeleteData,
			KlScriptFavoriteV2RemoveFromFavoritesUsingDeleteError
		>({
			path: `/api/v2/script/${id}/favorite`,
			method: 'DELETE',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags PDF to speech
	 * @name ScriptSpeechActorPersonUpdateAllScriptSpeechActorsUsingPut
	 * @summary updateAllScriptSpeechActors
	 * @request PUT:/api/v2/script/{scriptId}/speech-actor-person
	 * @secure
	 * @response `200` `KlScriptSpeechActorPersonUpdateAllScriptSpeechActorsUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSpeechActorPersonUpdateAllScriptSpeechActorsUsingPut = (
		scriptId: number,
		request: KlScriptSpeechActorDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSpeechActorPersonUpdateAllScriptSpeechActorsUsingPutData,
			KlScriptSpeechActorPersonUpdateAllScriptSpeechActorsUsingPutError
		>({
			path: `/api/v2/script/${scriptId}/speech-actor-person`,
			method: 'PUT',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags PDF to speech
	 * @name ScriptSpeechActorPersonUpdateScriptSpeechActorsUsingPut
	 * @summary updateScriptSpeechActors
	 * @request PUT:/api/v2/script/{scriptId}/speech-actor-person/list
	 * @secure
	 * @response `200` `KlScriptSpeechActorPersonUpdateScriptSpeechActorsUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSpeechActorPersonUpdateScriptSpeechActorsUsingPut = (
		scriptId: number,
		actors: Record<string, KlScriptSpeechActorDto>,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSpeechActorPersonUpdateScriptSpeechActorsUsingPutData,
			KlScriptSpeechActorPersonUpdateScriptSpeechActorsUsingPutError
		>({
			path: `/api/v2/script/${scriptId}/speech-actor-person/list`,
			method: 'PUT',
			body: actors,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags PDF to speech
	 * @name ScriptSpeechActorSceneUpdateAllScriptSpeechActorsUsingPut
	 * @summary updateAllScriptSpeechActors
	 * @request PUT:/api/v2/script/{scriptId}/speech-actor-scene
	 * @secure
	 * @response `200` `KlScriptSpeechActorSceneUpdateAllScriptSpeechActorsUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSpeechActorSceneUpdateAllScriptSpeechActorsUsingPut = (
		scriptId: number,
		request: KlScriptSpeechActorDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSpeechActorSceneUpdateAllScriptSpeechActorsUsingPutData,
			KlScriptSpeechActorSceneUpdateAllScriptSpeechActorsUsingPutError
		>({
			path: `/api/v2/script/${scriptId}/speech-actor-scene`,
			method: 'PUT',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags PDF to speech
	 * @name ScriptSpeechActorSceneUpdateScriptSpeechActorsUsingPut
	 * @summary updateScriptSpeechActors
	 * @request PUT:/api/v2/script/{scriptId}/speech-actor-scene/list}
	 * @secure
	 * @response `200` `KlScriptSpeechActorSceneUpdateScriptSpeechActorsUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptSpeechActorSceneUpdateScriptSpeechActorsUsingPut = (
		scriptId: number,
		actors: Record<string, KlScriptSpeechActorDto>,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptSpeechActorSceneUpdateScriptSpeechActorsUsingPutData,
			KlScriptSpeechActorSceneUpdateScriptSpeechActorsUsingPutError
		>({
			path: `/api/v2/script/${scriptId}/speech-actor-scene/list}`,
			method: 'PUT',
			body: actors,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script upload
	 * @name ScriptUploadSaveScriptUsingPost
	 * @summary saveScript
	 * @request POST:/api/v3/script
	 * @secure
	 * @response `200` `KlScriptUploadSaveScriptUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptUploadSaveScriptUsingPost = (
		script: KlUploadScriptDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptUploadSaveScriptUsingPostData,
			KlScriptUploadSaveScriptUsingPostError
		>({
			path: `/api/v3/script`,
			method: 'POST',
			body: script,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script upload
	 * @name ScriptUploadGetScriptByReferenceUsingGet
	 * @summary getScriptByReference
	 * @request GET:/api/v3/script/ref/{reference}
	 * @secure
	 * @response `200` `KlScriptUploadGetScriptByReferenceUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptUploadGetScriptByReferenceUsingGet = (
		reference: string,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptUploadGetScriptByReferenceUsingGetData,
			KlScriptUploadGetScriptByReferenceUsingGetError
		>({
			path: `/api/v3/script/ref/${reference}`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script upload
	 * @name ScriptUploadGetScriptUsingGet
	 * @summary getScript
	 * @request GET:/api/v3/script/{scriptId}
	 * @secure
	 * @response `200` `KlScriptUploadGetScriptUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptUploadGetScriptUsingGet = (
		scriptId: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptUploadGetScriptUsingGetData,
			KlScriptUploadGetScriptUsingGetError
		>({
			path: `/api/v3/script/${scriptId}`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script upload
	 * @name ScriptUploadUpdateScriptUsingPut
	 * @summary updateScript
	 * @request PUT:/api/v3/script/{scriptId}
	 * @secure
	 * @response `200` `KlScriptUploadUpdateScriptUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptUploadUpdateScriptUsingPut = (
		{ scriptId, ...query }: KlScriptUploadUpdateScriptUsingPutParams,
		script: KlUploadScriptDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptUploadUpdateScriptUsingPutData,
			KlScriptUploadUpdateScriptUsingPutError
		>({
			path: `/api/v3/script/${scriptId}`,
			method: 'PUT',
			query: query,
			body: script,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script upload
	 * @name ScriptUploadGetScriptBasedLifeStoryUsingGet
	 * @summary getScriptBasedLifeStory
	 * @request GET:/api/v3/script/{scriptId}/based-life-story
	 * @secure
	 * @response `200` `KlScriptUploadGetScriptBasedLifeStoryUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptUploadGetScriptBasedLifeStoryUsingGet = (
		scriptId: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptUploadGetScriptBasedLifeStoryUsingGetData,
			KlScriptUploadGetScriptBasedLifeStoryUsingGetError
		>({
			path: `/api/v3/script/${scriptId}/based-life-story`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script upload
	 * @name ScriptUploadSaveScriptBasedLifeStoryUsingPost
	 * @summary saveScriptBasedLifeStory
	 * @request POST:/api/v3/script/{scriptId}/based-life-story
	 * @secure
	 * @response `200` `KlScriptUploadSaveScriptBasedLifeStoryUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptUploadSaveScriptBasedLifeStoryUsingPost = (
		scriptId: number,
		data: KlScriptUploadSaveScriptBasedLifeStoryUsingPostPayload,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptUploadSaveScriptBasedLifeStoryUsingPostData,
			KlScriptUploadSaveScriptBasedLifeStoryUsingPostError
		>({
			path: `/api/v3/script/${scriptId}/based-life-story`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script upload
	 * @name ScriptUploadGetScriptBasedWorkUsingGet
	 * @summary getScriptBasedWork
	 * @request GET:/api/v3/script/{scriptId}/based-work
	 * @secure
	 * @response `200` `KlScriptUploadGetScriptBasedWorkUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptUploadGetScriptBasedWorkUsingGet = (
		scriptId: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptUploadGetScriptBasedWorkUsingGetData,
			KlScriptUploadGetScriptBasedWorkUsingGetError
		>({
			path: `/api/v3/script/${scriptId}/based-work`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script upload
	 * @name ScriptUploadSaveScriptBasedWorkUsingPost
	 * @summary saveScriptBasedWork
	 * @request POST:/api/v3/script/{scriptId}/based-work
	 * @secure
	 * @response `200` `KlScriptUploadSaveScriptBasedWorkUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptUploadSaveScriptBasedWorkUsingPost = (
		scriptId: number,
		data: KlScriptUploadSaveScriptBasedWorkUsingPostPayload,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptUploadSaveScriptBasedWorkUsingPostData,
			KlScriptUploadSaveScriptBasedWorkUsingPostError
		>({
			path: `/api/v3/script/${scriptId}/based-work`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script upload
	 * @name ScriptUploadSaveScriptFileUsingPost
	 * @summary saveScriptFile
	 * @request POST:/api/v3/script/{scriptId}/file
	 * @secure
	 * @response `200` `KlScriptUploadSaveScriptFileUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptUploadSaveScriptFileUsingPost = (
		scriptId: number,
		data: KlScriptUploadSaveScriptFileUsingPostPayload,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptUploadSaveScriptFileUsingPostData,
			KlScriptUploadSaveScriptFileUsingPostError
		>({
			path: `/api/v3/script/${scriptId}/file`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script upload
	 * @name ScriptUploadSaveScriptImageUsingPost
	 * @summary saveScriptImage
	 * @request POST:/api/v3/script/{scriptId}/image
	 * @secure
	 * @response `200` `KlScriptUploadSaveScriptImageUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptUploadSaveScriptImageUsingPost = (
		{ scriptId, ...query }: KlScriptUploadSaveScriptImageUsingPostParams,
		data: KlScriptUploadSaveScriptImageUsingPostPayload,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptUploadSaveScriptImageUsingPostData,
			KlScriptUploadSaveScriptImageUsingPostError
		>({
			path: `/api/v3/script/${scriptId}/image`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.FormData,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script upload
	 * @name ScriptUploadSaveScriptImageAiUsingPost
	 * @summary saveScriptImageAI
	 * @request POST:/api/v3/script/{scriptId}/image-ai
	 * @secure
	 * @response `200` `KlScriptUploadSaveScriptImageAiUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptUploadSaveScriptImageAiUsingPost = (
		{ scriptId, ...query }: KlScriptUploadSaveScriptImageAiUsingPostParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptUploadSaveScriptImageAiUsingPostData,
			KlScriptUploadSaveScriptImageAiUsingPostError
		>({
			path: `/api/v3/script/${scriptId}/image-ai`,
			method: 'POST',
			query: query,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script upload
	 * @name ScriptUploadSaveScriptImageByGenreUsingPost
	 * @summary saveScriptImageByGenre
	 * @request POST:/api/v3/script/{scriptId}/image-by-genre
	 * @secure
	 * @response `200` `KlScriptUploadSaveScriptImageByGenreUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptUploadSaveScriptImageByGenreUsingPost = (
		{ scriptId, ...query }: KlScriptUploadSaveScriptImageByGenreUsingPostParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptUploadSaveScriptImageByGenreUsingPostData,
			KlScriptUploadSaveScriptImageByGenreUsingPostError
		>({
			path: `/api/v3/script/${scriptId}/image-by-genre`,
			method: 'POST',
			query: query,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script upload
	 * @name ScriptUploadDeleteScriptImageByGenreUsingDelete
	 * @summary deleteScriptImageByGenre
	 * @request DELETE:/api/v3/script/{scriptId}/image-by-genre
	 * @deprecated
	 * @secure
	 * @response `200` `KlScriptUploadDeleteScriptImageByGenreUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptUploadDeleteScriptImageByGenreUsingDelete = (
		{
			scriptId,
			...query
		}: KlScriptUploadDeleteScriptImageByGenreUsingDeleteParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptUploadDeleteScriptImageByGenreUsingDeleteData,
			KlScriptUploadDeleteScriptImageByGenreUsingDeleteError
		>({
			path: `/api/v3/script/${scriptId}/image-by-genre`,
			method: 'DELETE',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script upload
	 * @name ScriptUploadSubmitUpdateScriptUsingPost
	 * @summary submitUpdateScript
	 * @request POST:/api/v3/script/{scriptId}/submit-update
	 * @secure
	 * @response `200` `KlScriptUploadSubmitUpdateScriptUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptUploadSubmitUpdateScriptUsingPost = (
		scriptId: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptUploadSubmitUpdateScriptUsingPostData,
			KlScriptUploadSubmitUpdateScriptUsingPostError
		>({
			path: `/api/v3/script/${scriptId}/submit-update`,
			method: 'POST',
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Script upload
	 * @name ScriptUploadSubmitUploadScriptUsingPost
	 * @summary submitUploadScript
	 * @request POST:/api/v3/script/{scriptId}/submit-upload
	 * @secure
	 * @response `200` `KlScriptUploadSubmitUploadScriptUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptUploadSubmitUploadScriptUsingPost = (
		scriptId: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptUploadSubmitUploadScriptUsingPostData,
			KlScriptUploadSubmitUploadScriptUsingPostError
		>({
			path: `/api/v3/script/${scriptId}/submit-upload`,
			method: 'POST',
			secure: true,
			type: ContentType.Json,
			...params
		})
}
