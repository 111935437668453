import { useGetArchiveSummary } from '@/api/http/search';
import { SystemPaths } from '@/const/routes';
import If from '../../Base/If/If';
import classNames from 'classnames';
import Paragraph from '../../Base/Typography/Paragraph';
import './competition-archived-screenplyas.less';
import { KlCompetitionData } from '@/api/generated/data-contracts';
import React from 'react';

export interface CompetitionArchivedScreenplaysProperties {
  currentCompetition: KlCompetitionData;
}
const CompetitionArchivedScreenplays: React.FC<CompetitionArchivedScreenplaysProperties> = ({ currentCompetition }) => {
  const { data: archiveScripts, isLoading: isArchiveScriptsLoading } = useGetArchiveSummary(currentCompetition?.id as number);

  const renderContestantsList = (key: string) => {
    if (archiveScripts) {
      const list = archiveScripts[key];
      const title = key.split('_').join(' ');
      return [
        <li className="title" key={title}>
          <Paragraph className="light secondary-045 font-size-md font-bold">{key === 'REST_CONTESTANTS' ? title.toLowerCase() : title}</Paragraph>
        </li>,
        list?.map((script) => {
          return (
            <li key={script?.id}>
              <a href={`${window.location.origin}${SystemPaths.PUBLIC_SCREENPLAYS}/${script?.reference}`}>
                <Paragraph className="light">{script?.name}</Paragraph>
              </a>
            </li>
          );
        }),
      ] as React.ReactNode;
    }
  };

  const getScreenplaysList = () => {
    if (archiveScripts) {
      return [
        renderContestantsList('TOP_10'),
        renderContestantsList('TOP_30'),
        renderContestantsList('REST_CONTESTANTS'),
      ];
    }
  };

  return (
    <div className={classNames('view-all', 'ui-kit-container', 'competition-winner-archive')}>
      <If truthy={!isArchiveScriptsLoading}>
        <div className="archived-screenplays">
          <ul className="archived-screenplays-content">
            {
              getScreenplaysList()
            }
          </ul>
        </div>
      </If>
    </div>
  );
};

export default CompetitionArchivedScreenplays;
