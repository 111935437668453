const BadgeFinalist = (): JSX.Element => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11461_102172)">
      <rect width="80" height="80" fill="white" fillOpacity="0.01" />
      <path d="M49.49 80C49.4067 80 49.3232 79.9955 49.2399 79.9862C48.2856 79.8878 47.4888 79.2285 47.2155 78.3198L41.3322 58.317C41.1749 57.7879 41.2112 57.2209 41.4348 56.7156C41.6585 56.2103 42.0552 55.7991 42.5552 55.5541C42.9687 55.3517 43.3573 55.1028 43.7132 54.8125C44.3201 54.318 45.1308 54.1579 45.8858 54.3729C47.3867 54.8101 48.7255 54.7895 49.8512 54.3179C51.0556 53.8372 52.0422 52.8849 52.8066 51.4795C52.9884 51.1464 53.2488 50.8616 53.5658 50.6491C53.8827 50.4367 54.2468 50.303 54.6272 50.2594C56.2856 50.0762 57.5689 49.5452 58.4397 48.6822C58.6414 48.4844 58.8258 48.2699 58.9909 48.0412C59.519 47.3155 60.4084 46.9516 61.3164 47.0913C61.7542 47.1644 62.1625 47.3574 62.495 47.6483C62.8274 47.9392 63.0707 48.3164 63.1972 48.737L69.9792 71.3801C70.1125 71.8252 70.1094 72.2994 69.97 72.7428C69.8307 73.1861 69.5614 73.5786 69.1963 73.8707C68.4505 74.4635 67.4035 74.5596 66.5698 74.1041L56.8417 68.8535L51.5745 78.7732C51.371 79.1442 51.07 79.454 50.7031 79.67C50.3362 79.8859 49.9171 79.9999 49.49 80Z" fill="#9FC414" />
      <path d="M30.6045 80C30.1774 79.9999 29.7582 79.8859 29.3913 79.67C29.0244 79.454 28.7233 79.1441 28.5198 78.773L23.2526 68.8535L13.5246 74.1046C12.6908 74.5601 11.6391 74.464 10.898 73.8688C10.5329 73.5767 10.2636 73.1842 10.1243 72.7409C9.98494 72.2976 9.98177 71.8233 10.1152 71.3782L16.9017 48.8289C17.0289 48.4081 17.273 48.0309 17.6063 47.7404C17.9396 47.4498 18.3487 47.2575 18.7871 47.1853C19.6672 47.032 20.5799 47.405 21.108 48.1353C21.2609 48.3504 21.4138 48.5381 21.5713 48.6916C22.3866 49.5271 23.5958 50.0353 25.2865 50.2687C25.6474 50.3174 25.992 50.4478 26.2934 50.65C26.5947 50.8521 26.8447 51.1204 27.0237 51.4339C27.825 52.8279 28.7932 53.7664 29.9051 54.2241C31.0817 54.7415 32.4483 54.7506 34.0001 54.286C34.3759 54.1734 34.7741 54.1549 35.159 54.232C35.5439 54.3091 35.9033 54.4794 36.2051 54.7278C36.5803 55.0369 37.0251 55.3252 37.6087 55.6364C38.6002 56.1629 39.0818 57.3029 38.7622 58.3673L32.879 78.322C32.7451 78.7665 32.4809 79.1618 32.1198 79.458C31.7586 79.7542 31.3168 79.938 30.85 79.9861C30.7667 79.9955 30.688 80 30.6045 80Z" fill="#C5F315" />
      <path d="M39.9998 60.9358C37.873 60.9358 35.9789 60.2418 34.1814 59.0725C31.9671 59.5349 29.8871 59.3472 28.0295 58.53C26.2738 57.8066 24.7452 56.5224 23.5082 54.7187C21.2754 54.2494 19.5244 53.3453 18.181 51.9719C16.7364 50.5219 15.8563 48.6295 15.4433 46.636C13.5718 45.4183 12.2377 43.8572 11.4733 41.9825C10.7275 40.2221 10.5561 38.2079 10.9684 36.118C9.77321 34.3235 9.1665 32.4258 9.1665 30.464C9.1665 28.4954 9.8012 26.568 11.0519 24.7185C10.5934 22.5188 10.774 20.4792 11.5986 18.641C12.3305 16.9013 13.6322 15.3906 15.4573 14.1659C15.9298 11.9593 16.8471 10.2288 18.2368 8.90122C19.6172 7.53922 21.4378 6.62366 23.6428 6.19096C24.875 4.34595 26.4501 3.03207 28.3447 2.2745C30.1281 1.54193 32.1665 1.36348 34.2788 1.7732C37.9059 -0.595928 42.0798 -0.614211 45.8135 1.8579C48.0233 1.40692 50.1263 1.5924 51.9654 2.39576C53.7258 3.12145 55.2545 4.40548 56.4913 6.20925C58.7241 6.67851 60.4751 7.58266 61.8185 8.95607C63.1989 10.3249 64.1209 12.1218 64.561 14.2941C66.4279 15.5118 67.762 17.0729 68.5263 18.9454C69.2722 20.7058 69.4436 22.72 69.0312 24.8099C70.2265 26.6045 70.8332 28.5021 70.8332 30.4639C70.8332 32.4325 70.1985 34.3599 68.9477 36.2094C69.4063 38.4092 69.2257 40.4487 68.4011 42.2869C67.6692 44.0288 66.3628 45.5419 64.533 46.7665C64.0109 49.136 63.1297 50.6533 61.7999 51.9923C60.3869 53.384 58.5617 54.3043 56.3567 54.7368C55.1245 56.5795 53.5495 57.8957 51.6549 58.6533C49.876 59.3925 47.847 59.5666 45.7253 59.1546C43.9958 60.2522 42.0794 60.9358 39.9998 60.9358Z" fill="#FABE2C" />
      <path d="M39.9941 60.9359C42.074 60.9359 43.9906 60.2519 45.7198 59.1551C47.8415 59.5672 49.8705 59.3931 51.6493 58.6538C53.5439 57.8961 55.119 56.58 56.3512 54.7374C58.5562 54.3047 60.3814 53.3846 61.7944 51.9929C63.1178 50.6601 64.0025 49.1495 64.5275 46.7671C66.3573 45.5424 67.6636 44.0293 68.3956 42.2874C69.2202 40.4493 69.4008 38.4099 68.9422 36.21C70.1929 34.3604 70.8276 32.4331 70.8276 30.4645C70.8276 28.5027 70.2208 26.6052 69.0257 24.8105C69.4379 22.7206 69.2665 20.7062 68.5208 18.946C67.7565 17.0737 66.4224 15.5124 64.5555 14.2946C64.1154 12.1224 63.1936 10.3255 61.813 8.95664C60.4696 7.58323 58.7186 6.67908 56.4858 6.20982C55.2489 4.40605 53.7202 3.12187 51.9599 2.39633C50.1208 1.59282 48.0176 1.40749 45.808 1.85847C43.9389 0.621323 41.9602 0.00860863 39.9942 0.00142045L39.9941 60.9359Z" fill="#FF9100" />
      <path d="M39.9991 51.56C28.2281 51.56 18.6528 42.0971 18.6528 30.4643C18.6528 18.8315 28.2281 9.36854 39.9991 9.36854C51.7701 9.36854 61.3454 18.8315 61.3454 30.4643C61.3454 42.0971 51.7701 51.56 39.9991 51.56Z" fill="#FED843" />
      <path d="M39.9941 51.56C51.7651 51.56 61.3404 42.0971 61.3404 30.4643C61.3404 18.8315 51.7651 9.36854 39.9941 9.36854V51.56Z" fill="#FABE2C" />
      <path d="M24.4315 33.7159V26.992H29.0253V28.1651H25.9177V29.7102H28.8323V30.8833H25.9177V33.7159H24.4315Z" fill="#774605" />
      <path d="M29.8385 33.7159V29.033H31.2958V33.7159H29.8385ZM29.7902 26.7822H31.3537V28.127H29.7902V26.7822Z" fill="#774605" />
      <path d="M35.3838 28.9186C35.95 28.9186 36.3714 29.0839 36.6481 29.4145C36.9247 29.7388 37.063 30.2316 37.063 30.8928V33.7159H35.6058V30.9596C35.6058 30.6417 35.5447 30.4096 35.4224 30.2634C35.3066 30.1171 35.1233 30.044 34.8723 30.044C34.5699 30.044 34.3255 30.1394 34.1389 30.3301C33.9587 30.5209 33.8687 30.7752 33.8687 31.0931V33.7159H32.4114V29.033H33.8301V29.7006C33.9973 29.4463 34.2129 29.2524 34.4767 29.1189C34.7469 28.9853 35.0493 28.9186 35.3838 28.9186Z" fill="#774605" />
      <path d="M40.2548 28.9186C40.9625 28.9186 41.4837 29.0839 41.8182 29.4145C42.1592 29.7452 42.3297 30.257 42.3297 30.9501V33.7159H40.9497V33.0197C40.8531 33.2613 40.6923 33.452 40.4671 33.5919C40.2419 33.7254 39.9781 33.7922 39.6758 33.7922C39.3541 33.7922 39.0613 33.7286 38.7975 33.6015C38.5402 33.4743 38.3343 33.2963 38.1799 33.0674C38.0319 32.8385 37.9579 32.5841 37.9579 32.3044C37.9579 31.961 38.0448 31.6908 38.2185 31.4937C38.3986 31.2966 38.6849 31.1535 39.0774 31.0645C39.4699 30.9755 40.0071 30.931 40.6891 30.931H40.94V30.7593C40.94 30.4795 40.8789 30.2824 40.7566 30.168C40.6344 30.0535 40.4221 29.9963 40.1197 29.9963C39.8881 29.9963 39.6307 30.0376 39.3476 30.1203C39.0645 30.203 38.7943 30.3174 38.537 30.4636L38.1509 29.5004C38.4212 29.335 38.7525 29.1983 39.145 29.0903C39.5439 28.9758 39.9138 28.9186 40.2548 28.9186ZM39.9942 32.8003C40.2773 32.8003 40.5057 32.7081 40.6794 32.5237C40.8531 32.333 40.94 32.0882 40.94 31.7893V31.6272H40.7759C40.2548 31.6272 39.8881 31.6685 39.6758 31.7512C39.4699 31.8339 39.3669 31.9833 39.3669 32.1995C39.3669 32.3711 39.4248 32.5142 39.5406 32.6286C39.6629 32.7431 39.8141 32.8003 39.9942 32.8003Z" fill="#774605" />
      <path d="M43.4193 33.7159V26.992H44.8765V33.7159H43.4193Z" fill="#774605" />
      <path d="M45.9922 33.7159V29.033H47.4494V33.7159H45.9922ZM45.9439 26.7822H47.5073V28.127H45.9439V26.7822Z" fill="#774605" />
      <path d="M50.389 33.7922C49.9515 33.7922 49.5494 33.7445 49.1827 33.6492C48.8224 33.5474 48.5104 33.4044 48.2466 33.22L48.6326 32.2281C48.89 32.3997 49.1731 32.5333 49.4819 32.6286C49.7907 32.724 50.1027 32.7717 50.418 32.7717C50.6432 32.7717 50.8169 32.7367 50.9391 32.6668C51.0678 32.5905 51.1322 32.4888 51.1322 32.3616C51.1322 32.2471 51.0871 32.1581 50.997 32.0945C50.9134 32.031 50.7526 31.9737 50.5145 31.9229L49.7424 31.7512C49.2856 31.6495 48.9479 31.4937 48.7291 31.2839C48.5168 31.0677 48.4106 30.7752 48.4106 30.4064C48.4106 30.1139 48.4943 29.8564 48.6616 29.6339C48.8353 29.4113 49.0765 29.2365 49.3854 29.1093C49.7006 28.9822 50.0577 28.9186 50.4566 28.9186C50.7976 28.9186 51.1289 28.9694 51.4506 29.0712C51.7723 29.1729 52.0618 29.316 52.3192 29.5004L51.9332 30.4541C51.4313 30.1108 50.9359 29.9391 50.447 29.9391C50.2218 29.9391 50.0448 29.9772 49.9162 30.0535C49.7875 30.1298 49.7231 30.2379 49.7231 30.3778C49.7231 30.4795 49.7617 30.559 49.839 30.6162C49.9162 30.6735 50.0513 30.7275 50.2443 30.7784L51.0453 30.9596C51.5278 31.0677 51.8785 31.233 52.0972 31.4555C52.3224 31.6717 52.435 31.9674 52.435 32.3425C52.435 32.794 52.2516 33.15 51.8849 33.4107C51.5246 33.665 51.026 33.7922 50.389 33.7922Z" fill="#774605" />
      <path d="M55.8458 32.7336C55.9938 32.7336 56.1482 32.724 56.309 32.7049L56.2318 33.7541C56.0452 33.7795 55.8587 33.7922 55.6721 33.7922C54.9515 33.7922 54.4239 33.6364 54.0893 33.3249C53.7612 33.0133 53.5972 32.5396 53.5972 31.9038V30.1108H52.6996V29.033H53.5972V27.6596H55.0544V29.033H56.2415V30.1108H55.0544V31.8943C55.0544 32.4538 55.3182 32.7336 55.8458 32.7336Z" fill="#774605" />
    </g>
    <defs>
      <clipPath id="clip0_11461_102172">
        <rect width="80" height="80" fill="none" />
      </clipPath>
    </defs>
  </svg>

);

export default BadgeFinalist;
