import React from 'react';
import './card-content.less';
import classNames from 'classnames';
import { BorderRadius, Size } from '@/ui-kit/types';
import If from '@/ui-kit/Base/If/If';

export interface CardContentProperties {
  alt?: string;
  img?: string;
  gap?: typeof Size;
  aspectRatio?: '1/1' | '3/2';
  imageBorderRadius?: typeof BorderRadius;
  controls?: React.ReactNode | React.ReactNode[];
  heading?: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
}

const CardContent: React.FC<CardContentProperties> = ({
  alt,
  img,
  gap = 'xl',
  aspectRatio = '3/2',
  imageBorderRadius = 'md',
  controls,
  heading,
  children,
}) => {
  const imageStyle = {
    aspectRatio,
  } as React.CSSProperties;

  return (
    <div className={classNames('ui-kit-card-content', `gap-${gap}`)}>
      <If truthy={!!img}>
        <img src={img} alt={alt} style={imageStyle} className={classNames(`radius-${imageBorderRadius}`)} />
      </If>
      {/* TODO:
        1) rename css class `body` to `ui-kit-card-content-body`
        2) check Press cards frontend-public\src\ui-kit\Components\Section\section.less: ~111
      */}
      <div className="body">
        <If truthy={!!heading}>
          {heading}
        </If>
        <If truthy={!!children}>
          {children}
        </If>
      </div>
      <If truthy={!!controls}>
        {/* TODO:
          1) rename css class `controls` to `ui-kit-card-content-controls`
          2) check Press cards frontend-public\src\ui-kit\Components\Section\section.less: ~111
        */}
        <div className="controls">
          {controls}
        </div>
      </If>
    </div>
  );
};

export default CardContent;
