import React, { Suspense } from 'react';
import './banner.less';
import { BannerAlignment, BannerHeight, Variant } from '@/ui-kit/types';
import classNames from 'classnames';
import If from '@/ui-kit/Base/If/If';

const LazyVideo = React.lazy(async () => import('@/ui-kit/Base/Video/LazyVideo'));

export interface VideoBannerProperties {
  className?: string;
  videoUrl: string;
  autoPlay: boolean;
  variant?: typeof Variant;
  height?: typeof BannerHeight;
  alignment?: typeof BannerAlignment;
  opacity?: number;
  children?: React.ReactNode | React.ReactNode[];
  play?: boolean;
  fallbackImage?: string;
}

const VideoBanner: React.FC<VideoBannerProperties> = ({
  className,
  videoUrl,
  autoPlay,
  variant = 'black',
  height = 'full-screen',
  alignment = 'left',
  opacity = 0.5,
  children = null,
  play = true,
  fallbackImage,
}) => {
  const style = {
    opacity,
  } as React.CSSProperties;

  return (
    <div className={classNames('ui-kit-banner ui-kit-video-banner', `bg-${variant}`, alignment, { [height]: height }, className)}>

      <If truthy={!!videoUrl}>
        <Suspense fallback={<span />}>
          <LazyVideo
            isBackground
            mediaUrl={videoUrl}
            autoPlay={autoPlay}
            style={style}
            loop
            controls={false}
            muted
            play={play}
            fallbackImage={fallbackImage}
          />
        </Suspense>
      </If>

      <div className="banner-body">
        <If truthy={!!React.Children.count(children)}>
          {children}
        </If>
      </div>
    </div>
  );
};

export default VideoBanner;
