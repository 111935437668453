import { StrapiCompetitionWinner } from '@/const/cmsInterface';
import React from 'react';
import classNames from 'classnames';
import StrapiCompetitionFilterWrapperComponent from '@/components/Cms/StrapiCompetitionFilterWrapperComponent';
import CompetitionWinnerComponent from '@/ui-kit/Components/ViewAll/CompetitionWinner';
import CompetitionArchivedScreenplays from '@/ui-kit/Components/ViewAll/CompetitonArchivedScreenplays';

interface StrapiWinnerPageComponentProperties {
  block: StrapiCompetitionWinner;
}

const StrapiCompetitionWinnerComponent: React.FC<StrapiWinnerPageComponentProperties> = ({
  block,
}) => {
  return (
    <StrapiCompetitionFilterWrapperComponent
      isVisibleForCompetition={block?.isVisibleForCompetition}
    >
      {({ currentCompetition }) => {
        return (
          <div className={classNames(`bg-${block?.theme ?? 'dark-blue'}`)}>
            <CompetitionWinnerComponent currentCompetition={currentCompetition} />
            <CompetitionArchivedScreenplays currentCompetition={currentCompetition} />
          </div>
        );
      }}
    </StrapiCompetitionFilterWrapperComponent>
  );
};

export default StrapiCompetitionWinnerComponent;
