import React, { Suspense } from 'react';
import './banner.less';
import {
  BannerAlignment, BannerHeight, BannerMedia, Variant,
} from '@/ui-kit/types';
import classNames from 'classnames';
import If from '@/ui-kit/Base/If/If';
import LazyVideo from '@/ui-kit/Base/Video/LazyVideo';

export interface BannerProperties {
  className?: string;
  media?: typeof BannerMedia
  mediaOpacity?: number,
  bgColor?: typeof Variant,
  height?: typeof BannerHeight,
  vignette?: boolean,
  alignment?: typeof BannerAlignment;
  children?: React.ReactNode | React.ReactNode[];
}

const defaultMedia = {
  src: '',
  type: 'img',
  fallbackImg: '',
};

const Banner: React.FC<BannerProperties> = ({
  className,
  media = defaultMedia,
  vignette = false,
  height,
  bgColor = 'black',
  alignment = 'left',
  mediaOpacity = 0.5,
  children = null,
}) => {
  const style = {
    opacity: mediaOpacity,
  } as React.CSSProperties;

  return (
    vignette ? (
      <div className={classNames({ 'banner-rounded': !vignette })}>
        <div className={classNames('ui-kit-banner', `bg-${bgColor}`, alignment, { [`${height}`]: height }, className)}>
          <If truthy={!!media?.src && media?.type === 'img'}>
            <img src={media?.src} alt="" style={style} className="banner-bg" />
          </If>
          <If truthy={!!media?.src && media?.type === 'video'}>
            <Suspense fallback={<span />}>
              <LazyVideo
                isBackground
                mediaUrl={media?.src}
                autoPlay
                style={style}
                loop
                controls={false}
                muted
                play
                fallbackImage={media?.fallbackImg}
              />
            </Suspense>
          </If>
          <If truthy={!!React.Children.count(children)}>
            <div className="banner-body">
              <Suspense fallback={<span />}>
                {children}
              </Suspense>
            </div>
          </If>
        </div>
      </div>
    ) : (
      <div className={classNames('ui-kit-banner', `bg-${bgColor}`, alignment, { [`${height}`]: height }, className)}>
        <If truthy={!!media?.src && media?.type === 'img'}>
          <img src={media?.src} alt="" style={style} className="banner-bg" />
        </If>
        <If truthy={!!media?.src && media?.type === 'video'}>
          <Suspense fallback={<span />}>
            <LazyVideo
              isBackground
              mediaUrl={media?.src}
              autoPlay
              style={style}
              loop
              controls={false}
              muted
              play
              fallbackImage={media?.fallbackImg}
            />
          </Suspense>
        </If>
        <If truthy={!!React.Children.count(children)}>
          <div className="banner-body">
            <Suspense fallback={<span />}>
              {children}
            </Suspense>
          </div>
        </If>
      </div>
    )
  );
};

export default Banner;
