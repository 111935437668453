import React, { Suspense } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconProperties } from './types';
import './iconography.less';
import classNames from 'classnames';

const CustomIcon: React.FC<CustomIconProperties> = ({
  className = '',
  component,
  style,
  variant,
  size,
  onClick,
}) => {
  return (
    <Suspense
      fallback={
        <span style={{
          ...(size && { fontSize: size }),
          ...style,
        }}
        />
    }
    >
      <Icon
        component={component}
        onClick={onClick}
        style={{
          ...(size && { fontSize: size }),
          ...style,
        }}
        className={classNames('ui-kit-icon', className, variant)}
      />
    </Suspense>
  );
};

export default CustomIcon;
