import { StrapiBanner, StrapiContent } from '@/const/cmsInterface';
import Title from '@/ui-kit/Base/Typography/Title';
import Text from '@/ui-kit/Base/Typography/Text';
import Heading from '@/ui-kit/Base/Typography/Heading';
import If from '@/ui-kit/Base/If/If';
import { TitleProps } from 'antd/es/typography/Title';
import React from 'react';
import Banner from '@/ui-kit/Components/Banner/Banner';
import CardContent from '@/ui-kit/Components/Section/CardContent/CardContent';

import StrapiButtonComponent from '@/components/Cms/StrapiButtonComponent';
import classNames from 'classnames';
import List from '../../ui-kit/Base/Typography/List';
import StrapiCompetitionFilterWrapperComponent
, {
  StrapiCompetitionFilterWrapperComponentProperties,
} from '@/components/Cms/StrapiCompetitionFilterWrapperComponent';
import StrapiMessagesAnnounceComponent from '@/components/Cms/StrapiMessagesAnnounceComponent';

interface StrapiBannerComponentProperties {
  block: StrapiBanner;
  CompetitionFilter?: React.ElementType<StrapiCompetitionFilterWrapperComponentProperties>;
}

const StrapiBannerComponent: React.FC<StrapiBannerComponentProperties> = ({
  block,
  CompetitionFilter = StrapiCompetitionFilterWrapperComponent,
}) => {
  const renderList = (card: StrapiContent) => {
    if (!card?.list) {
      return null;
    }
    return (
      <List list={card.list} />
    );
  };

  const renderContent = (content: StrapiBanner['content']) => {
    if (!content?.length) {
      return null;
    }

    return content?.map((card) => {
      return (
        <CardContent
          key={card.id}
          alt={card?.image?.alternativeText || ''}
          img={card?.image?.url || ''}
          controls={
            card?.button?.map((button) => {
              return (
                <StrapiButtonComponent button={button} key={button?.id} CompetitionFilter={CompetitionFilter} />
              );
            })
          }
        >
          <If truthy={!!card.heading?.title}>
            <Heading
              title={card.heading?.title?.text ?? ''}
              subtitle={card.heading?.subtitle?.text ?? ''}
              subtitleClass={card.heading?.subtitle?.subtitleClass ?? ''}
              size={card.heading?.title?.size as TitleProps['level']}
              titleClass={card.heading?.title?.titleClass}
              titleVariant={card.heading?.title?.titleVariant}
              titleFontWeight={card.heading?.title?.titleFontWeight}
              color={card.heading?.title?.color}
              emphasize={card.heading?.title?.emphasize ?? 'lime'}
              emphasizeNewRow={card.heading?.title?.emphasizeNewRow ?? false}
            />

            <If truthy={!!card?.title}>
              <Title level={card?.size as any || 2} color={card?.theme || 'secondary'}>
                {typeof card.title === 'string' ? card.title : ''}
              </Title>
            </If>
            {renderList(card)}

            <If truthy={!!card?.content}>
              <Text className={classNames(card?.theme || 'secondary', card.class)}>
                <span dangerouslySetInnerHTML={{ __html: card.content || '' }} />
              </Text>
            </If>
          </If>
        </CardContent>
      );
    });
  };

  return (
    <CompetitionFilter
      isVisibleForCompetition={block?.isVisibleForCompetition}
      isHiddenForCompetition={block?.isHiddenForCompetition}
      isVisibleIfNoActiveCompetitionOnly={block?.isVisibleIfNoActiveCompetitionOnly}
      // name={`StrapiBannerComponent-${block.id}`}
    >
      {() => (
        <Banner
          key={`banner-${block.id}`}
          media={block.media ?? {
            src: '',
            type: 'img',
          }}
          alignment={block.align ?? 'left-bottom'}
          bgColor={block.theme ?? 'light'}
          mediaOpacity={block.opacity ?? 1}
          vignette={!!block.displayImageAsCover}
          height={block.height}
          className={block.class ?? ''}
        >
          {renderContent(block.content)}
          <StrapiMessagesAnnounceComponent
            messagesAnnounce={block?.messageAnnounce}
          />
        </Banner>
      )}
    </CompetitionFilter>
  );
};

export default StrapiBannerComponent;
