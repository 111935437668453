const SignOut = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3701_2445)">
      <rect width="100%" height="100%" transform="translate(0.5)" fill="white" fillOpacity="0.01" />
      <g clipPath="url(#clip1_3701_2445)">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.9694 9.43086C13.1334 9.0918 13.5436 8.94688 13.8827 9.11094C14.2245 9.275 14.3666 9.68242 14.2053 10.0242C13.642 11.1945 12.7643 12.1871 11.6678 12.8926C10.5413 13.6172 9.23423 14 7.88891 14C6.0186 14 4.26313 13.2727 2.9397 11.9492C1.61626 10.6285 0.888916 8.87031 0.888916 7C0.888916 5.12969 1.61626 3.37148 2.9397 2.05078C4.2604 0.727344 6.0186 0 7.88891 0C9.23423 0 10.5413 0.382813 11.6678 1.10469C12.767 1.80742 13.642 2.8 14.2053 3.97305C14.3694 4.31484 14.2245 4.72227 13.8854 4.88633C13.5436 5.05039 13.1362 4.90547 12.9721 4.56641C12.5182 3.62305 11.8127 2.82187 10.9295 2.25586C10.0217 1.67617 8.97173 1.36719 7.88891 1.36719C4.78267 1.36719 2.2561 3.89375 2.2561 7C2.2561 10.1062 4.78267 12.6328 7.88891 12.6328C8.97173 12.6328 10.0217 12.3238 10.9268 11.7414C11.81 11.1727 12.5155 10.3742 12.9694 9.43086ZM8.73638 9.86016C8.56138 9.86016 8.38638 9.79454 8.25239 9.66055C7.98716 9.39258 7.98716 8.96055 8.25513 8.69805L9.26411 7.68907H5.37583C4.99849 7.68907 4.69224 7.38282 4.69224 7.00548C4.69224 6.62813 4.99849 6.32188 5.37583 6.32188H9.26138L8.25239 5.3129C7.98442 5.04493 7.98442 4.6129 8.25239 4.34493C8.52036 4.07696 8.95239 4.07696 9.22036 4.34493L10.9403 6.06485C11.1891 6.31368 11.3286 6.65001 11.3286 7.00274C11.3286 7.35548 11.1918 7.68907 10.9403 7.94063L9.22036 9.66055C9.08638 9.79454 8.91138 9.86016 8.73638 9.86016Z" fill="currentColor" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3701_2445">
        <rect width="100%" height="100%" fill="currentColor" transform="translate(0.5)" />
      </clipPath>
      <clipPath id="clip1_3701_2445">
        <rect width="100%" height="100%" fill="currentColor" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>

);

export default SignOut;
