import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { ReactQueryProvider } from '@/api/ReactQueryProvider';
import If from '@/ui-kit/Base/If/If';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from '@/App';
import DeferScriptLoader from '@/components/DeferScriptLoader';
import './style/main.less';
import { setupSentry } from '@/utils/setupSentry';

const container = document.querySelector('#root');

const logError = (error: Error) => {
  console.error('Application caught an error:', error);
};

setupSentry();

const ErrorFallback = () => {
  return null;
};

if (container) {
  const root = createRoot(container);
  root.render((
    <ReactQueryProvider>
      <If truthy={import.meta.env?.VITE_REACT_QUERY_DEV_TOOLS}>
        <ReactQueryDevtools />
      </If>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
        <App />
        <DeferScriptLoader />
      </ErrorBoundary>
    </ReactQueryProvider>
  ));
}
