import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';
import { Public } from '@/api/generated/Public';
import { options } from '@/api/http/helper';

import { Payment } from '@/api/generated/Payment';
import { Script } from '@/api/generated/Script';
import { ScriptReview } from '@/api/generated/ScriptReview';
import { ScriptPaidReviewFilter } from '@/api/generated/ScriptPaidReviewFilter';
import {
  KlPaymentCheckoutSessionDto,
  KlScriptAvailableReviewFilterAvailableReviewsUsingGetParams,
  KlScriptReviewScheduleVideoCallScheduleVideoCallUsingPostParams,
} from '@/api/generated/data-contracts';
import { User } from '@/api/generated/User';

export const useGetStripeProducts = () => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/public/payment/product'],
    queryFn: async () => new Public(httpClient).paymentProductGetProductsUsingGet({}),
  });
};

export const useCreatePaymentSession = () => {
  const httpClient = useApi();

  const mutation = useMutation({
    mutationKey: ['post:/api/v1/payment/checkout/session'],
    mutationFn: async (params: KlPaymentCheckoutSessionDto) => {
      return new Payment(httpClient).paymentCheckoutSessionCreateCheckoutSessionUsingPost(params);
    },
    ...options,
  });

  return mutation;
};

export const useMyScriptReviewById = (params: KlScriptAvailableReviewFilterAvailableReviewsUsingGetParams) => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/script/review', params],
    queryFn: async () => {
      return new Script(httpClient).scriptAvailableReviewFilterAvailableReviewsUsingGet(params);
    },
    enabled: !!params?.scriptId,
  });
};

export const useMyScriptReviews = () => {
  const httpClient = useApi();
  return useQuery({
    queryKey: ['get:/api/v1/script-paid-review-filter'],
    queryFn: async () => {
      return new ScriptPaidReviewFilter(httpClient).scriptPaidReviewFilterGetScriptPaidReviewsUsingGet({});
    },
  });
};

export const useScheduleVideoCall = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationKey: ['post:/api/v1/script-review/schedule-video-call'],
    mutationFn: async (params: KlScriptReviewScheduleVideoCallScheduleVideoCallUsingPostParams) => {
      return new ScriptReview(httpClient).scriptReviewScheduleVideoCallScheduleVideoCallUsingPost(params);
    },
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v1/script/review'],
      });
    },
  });

  return mutation;
};

export const useCancelSubscription = () => {
  const httpClient = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['delete:/api/v1/user/subscription'],
    mutationFn: async () => {
      return new User(httpClient).subscriptionCancelSubscriptionUsingDelete();
    },
    ...options,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['get:/api/v1/user/me'],
      });
    },
  });
};
