const ArrowCircleUpFilled = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_180976_439)">
      <path fillRule="evenodd" clipRule="evenodd" d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36ZM18.878 13.5819L18.878 26.0487H16.8292V13.5819L11.1028 19.298L9.65845 17.8536L17.8536 9.65846L26.0487 17.8536L24.6043 19.3082L18.878 13.5819Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_180976_439">
        <rect width="36" height="36" fill="none" />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowCircleUpFilled;
