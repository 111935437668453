/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	KlScriptReviewDto,
	KlScriptReviewGetScriptReviewUsingGetData,
	KlScriptReviewGetScriptReviewUsingGetError,
	KlScriptReviewGetScriptReviewUsingGetParams,
	KlScriptReviewScheduleVideoCallScheduleVideoCallUsingPostData,
	KlScriptReviewScheduleVideoCallScheduleVideoCallUsingPostError,
	KlScriptReviewScheduleVideoCallScheduleVideoCallUsingPostParams,
	KlScriptReviewUpdateScriptReviewUsingPutData,
	KlScriptReviewUpdateScriptReviewUsingPutError,
	KlScriptReviewUpdateScriptReviewUsingPutParams
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class ScriptReview<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http
	}

	/**
	 * No description
	 *
	 * @tags script-review-controller
	 * @name ScriptReviewGetScriptReviewUsingGet
	 * @summary getScriptReview
	 * @request GET:/api/v1/script-review
	 * @secure
	 * @response `200` `KlScriptReviewGetScriptReviewUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptReviewGetScriptReviewUsingGet = (
		query: KlScriptReviewGetScriptReviewUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptReviewGetScriptReviewUsingGetData,
			KlScriptReviewGetScriptReviewUsingGetError
		>({
			path: `/api/v1/script-review`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-review-controller
	 * @name ScriptReviewUpdateScriptReviewUsingPut
	 * @summary updateScriptReview
	 * @request PUT:/api/v1/script-review
	 * @secure
	 * @response `200` `KlScriptReviewUpdateScriptReviewUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptReviewUpdateScriptReviewUsingPut = (
		query: KlScriptReviewUpdateScriptReviewUsingPutParams,
		scriptReviewRequest: KlScriptReviewDto,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptReviewUpdateScriptReviewUsingPutData,
			KlScriptReviewUpdateScriptReviewUsingPutError
		>({
			path: `/api/v1/script-review`,
			method: 'PUT',
			query: query,
			body: scriptReviewRequest,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags script-review-schedule-video-call-controller
	 * @name ScriptReviewScheduleVideoCallScheduleVideoCallUsingPost
	 * @summary scheduleVideoCall
	 * @request POST:/api/v1/script-review/schedule-video-call
	 * @secure
	 * @response `200` `KlScriptReviewScheduleVideoCallScheduleVideoCallUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	scriptReviewScheduleVideoCallScheduleVideoCallUsingPost = (
		query: KlScriptReviewScheduleVideoCallScheduleVideoCallUsingPostParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlScriptReviewScheduleVideoCallScheduleVideoCallUsingPostData,
			KlScriptReviewScheduleVideoCallScheduleVideoCallUsingPostError
		>({
			path: `/api/v1/script-review/schedule-video-call`,
			method: 'POST',
			query: query,
			secure: true,
			type: ContentType.Json,
			...params
		})
}
