import React, { useEffect, useRef } from 'react';
import { MediaPlayer } from 'dashjs';
import { usePlatformInfo } from '@/hooks/usePlatformInfo';
import './video.less';
import If from '@/ui-kit/Base/If/If';
import { VideoSettings } from '@/ui-kit/Components/VideoComponentWithProgress/config';

export interface VideoProps {
  mediaUrl: string;
  autoPlay?: boolean;
  startTime?: number;
}

const Video: React.FC<VideoProps> = ({
  mediaUrl,
  autoPlay = false,
  startTime = 0,
}) => {
  const playerRef = useRef<HTMLVideoElement | null>(null);
  const { isIOS } = usePlatformInfo();

  let pathToMedia = `${window.location.origin}/${mediaUrl}`;
  if (isIOS) {
    pathToMedia = pathToMedia.replace('.mpd', '.m3u8');
  }

  useEffect(() => {
    if (isIOS) {
      return;
    }
    try {
      const player = MediaPlayer().create();
      player.initialize(
        document.querySelector('#kinolime-video-general') as HTMLMediaElement,
        pathToMedia,
        autoPlay,
        startTime,
      );

      player.updateSettings({
        streaming: VideoSettings.streaming,
      });
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
    }
  }, []);

  return (
    <div className="ui-kit-video">
      <video
        id="kinolime-video-general"
        controls
        preload="yes"
        autoPlay={autoPlay}
        disablePictureInPicture
        controlsList="nodownload noplaybackrate volume"
        width="100%"
        height="100%"
        ref={playerRef}
        onLoadedMetadata={() => {
          if (playerRef?.current?.muted === true) {
            playerRef.current.muted = false;
            playerRef.current.volume = 0.5;
            playerRef.current?.play();
          }
        }}
        muted={!!autoPlay}
      >
        <track kind="captions" src="" />
        <If truthy={isIOS}>
          <source
            src={pathToMedia}
          />
        </If>
      </video>
    </div>
  );
};

export default Video;
