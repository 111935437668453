import React, { PropsWithChildren, Suspense } from 'react';
import './button.less';
import { IUKitButtonProperties } from './types';
import { If } from '../If';
import classNames from 'classnames';

const AntButton = React.lazy(async () => import('antd/es/button'));

const Button: React.FC<PropsWithChildren<IUKitButtonProperties>> = ({
  children,
  variant,
  className,
  iconRight = null,
  iconLeft = null,
  ...rest
}) => {
  return (
    <Suspense fallback={<span />}>
      {children ? (
        <AntButton
          className={classNames(
            'ui-kit-btn',
            `variant-${variant}`,
            className,
          )}
          {...rest}
        >
          <If truthy={!!iconLeft}>
            {iconLeft}
          </If>
          {children}
          <If truthy={!!iconRight}>
            {iconRight}
          </If>
        </AntButton>
      ) : (
        <AntButton
          className={classNames(
            'ui-kit-btn',
            `variant-${variant}`,
            className,
          )}
          {...rest}
        />
      )}
    </Suspense>
  );
};

export default Button;
