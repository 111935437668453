import React from 'react';
import { useCurrencyEventRateGetRatesUsingGet } from '@/api/http/public';
import { KlCurrencyEventRateGetRatesUsingGetData } from '@/api/generated/data-contracts';

interface LimesDictionaryWrapperProperties {
  children: (currency: KlCurrencyEventRateGetRatesUsingGetData) => React.ReactNode | null;
}

const LimesDictionaryWrapper: React.FC<LimesDictionaryWrapperProperties> = ({
  children,
}) => {
  const { data: currency, isLoading: isCurrencyLoading } = useCurrencyEventRateGetRatesUsingGet();

  if (isCurrencyLoading) {
    return null;
  }

  if (!currency) {
    return null;
  }

  return (
    // KINDEV-3885:
    // that could work
    // <div className='screenplay-inner-page-actions-container'>
    <>
      {children(currency)}
    </>
  );
};

export default LimesDictionaryWrapper;
