/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	KlCommentReportRequest,
	KlCommentRequest,
	KlPreSignedConfirmRequest,
	KlReelCommentCreateReportUsingPostData,
	KlReelCommentCreateReportUsingPostError,
	KlReelCommentPageUsingGetData,
	KlReelCommentPageUsingGetError,
	KlReelCommentPageUsingGetParams,
	KlReelCommentRemoveCommentUsingDeleteData,
	KlReelCommentRemoveCommentUsingDeleteError,
	KlReelCommentSaveUsingPostData,
	KlReelCommentSaveUsingPostError,
	KlReelCommentUpdateCommentUsingPutData,
	KlReelCommentUpdateCommentUsingPutError,
	KlReelDto,
	KlReelFavoriteAddToFavoriteUsingPostData,
	KlReelFavoriteAddToFavoriteUsingPostError,
	KlReelFavoriteRemoveFromFavoriteUsingDeleteData,
	KlReelFavoriteRemoveFromFavoriteUsingDeleteError,
	KlReelsConfirmUploadFileUsingPostData,
	KlReelsConfirmUploadFileUsingPostError,
	KlReelsConfirmUploadImageFileUsingPostData,
	KlReelsConfirmUploadImageFileUsingPostError,
	KlReelsConfirmUploadReelFileUsingPostData,
	KlReelsConfirmUploadReelFileUsingPostError,
	KlReelsDislikeDislikeUsingPutData,
	KlReelsDislikeDislikeUsingPutError,
	KlReelsDislikeRemoveDislikeUsingDeleteData,
	KlReelsDislikeRemoveDislikeUsingDeleteError,
	KlReelsGetByIdUsingGetData,
	KlReelsGetByIdUsingGetError,
	KlReelsLikeLikeUsingPutData,
	KlReelsLikeLikeUsingPutError,
	KlReelsLikeUnlikeUsingDeleteData,
	KlReelsLikeUnlikeUsingDeleteError,
	KlReelsPreSignedUrlUsingGetData,
	KlReelsPreSignedUrlUsingGetError,
	KlReelsSaveUsingPostData,
	KlReelsSaveUsingPostError,
	KlReelsUploadFileUsingPostData,
	KlReelsUploadFileUsingPostError
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Reels<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http
	}

	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelsSaveUsingPost
	 * @summary save
	 * @request POST:/api/v1/reels
	 * @secure
	 * @response `200` `KlReelsSaveUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelsSaveUsingPost = (reel: KlReelDto, params: RequestParams = {}) =>
		this.http.request<KlReelsSaveUsingPostData, KlReelsSaveUsingPostError>({
			path: `/api/v1/reels`,
			method: 'POST',
			body: reel,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelsUploadFileUsingPost
	 * @summary uploadFile
	 * @request POST:/api/v1/reels/file/upload
	 * @secure
	 * @response `200` `KlReelsUploadFileUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelsUploadFileUsingPost = (data: any, params: RequestParams = {}) =>
		this.http.request<
			KlReelsUploadFileUsingPostData,
			KlReelsUploadFileUsingPostError
		>({
			path: `/api/v1/reels/file/upload`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelsConfirmUploadFileUsingPost
	 * @summary confirmUploadFile
	 * @request POST:/api/v1/reels/file/upload/confirm
	 * @deprecated
	 * @secure
	 * @response `200` `KlReelsConfirmUploadFileUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelsConfirmUploadFileUsingPost = (
		request: KlPreSignedConfirmRequest,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlReelsConfirmUploadFileUsingPostData,
			KlReelsConfirmUploadFileUsingPostError
		>({
			path: `/api/v1/reels/file/upload/confirm`,
			method: 'POST',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelsConfirmUploadImageFileUsingPost
	 * @summary confirmUploadImageFile
	 * @request POST:/api/v1/reels/file/upload/image/confirm
	 * @secure
	 * @response `200` `KlReelsConfirmUploadImageFileUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelsConfirmUploadImageFileUsingPost = (
		request: KlPreSignedConfirmRequest,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlReelsConfirmUploadImageFileUsingPostData,
			KlReelsConfirmUploadImageFileUsingPostError
		>({
			path: `/api/v1/reels/file/upload/image/confirm`,
			method: 'POST',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelsPreSignedUrlUsingGet
	 * @summary preSignedUrl
	 * @request GET:/api/v1/reels/file/upload/pre-signed/{fileName}
	 * @secure
	 * @response `200` `KlReelsPreSignedUrlUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelsPreSignedUrlUsingGet = (fileName: string, params: RequestParams = {}) =>
		this.http.request<
			KlReelsPreSignedUrlUsingGetData,
			KlReelsPreSignedUrlUsingGetError
		>({
			path: `/api/v1/reels/file/upload/pre-signed/${fileName}`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelsConfirmUploadReelFileUsingPost
	 * @summary confirmUploadReelFile
	 * @request POST:/api/v1/reels/file/upload/video/confirm
	 * @secure
	 * @response `200` `KlReelsConfirmUploadReelFileUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelsConfirmUploadReelFileUsingPost = (
		request: KlPreSignedConfirmRequest,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlReelsConfirmUploadReelFileUsingPostData,
			KlReelsConfirmUploadReelFileUsingPostError
		>({
			path: `/api/v1/reels/file/upload/video/confirm`,
			method: 'POST',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelsGetByIdUsingGet
	 * @summary getById
	 * @request GET:/api/v1/reels/{id}
	 * @secure
	 * @response `200` `KlReelsGetByIdUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelsGetByIdUsingGet = (id: number, params: RequestParams = {}) =>
		this.http.request<KlReelsGetByIdUsingGetData, KlReelsGetByIdUsingGetError>({
			path: `/api/v1/reels/${id}`,
			method: 'GET',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelCommentPageUsingGet
	 * @summary page
	 * @request GET:/api/v1/reels/{id}/comment
	 * @secure
	 * @response `200` `KlReelCommentPageUsingGetData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelCommentPageUsingGet = (
		{ id, ...query }: KlReelCommentPageUsingGetParams,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlReelCommentPageUsingGetData,
			KlReelCommentPageUsingGetError
		>({
			path: `/api/v1/reels/${id}/comment`,
			method: 'GET',
			query: query,
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelCommentSaveUsingPost
	 * @summary save
	 * @request POST:/api/v1/reels/{id}/comment
	 * @secure
	 * @response `200` `KlReelCommentSaveUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelCommentSaveUsingPost = (
		id: number,
		request: KlCommentRequest,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlReelCommentSaveUsingPostData,
			KlReelCommentSaveUsingPostError
		>({
			path: `/api/v1/reels/${id}/comment`,
			method: 'POST',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelCommentUpdateCommentUsingPut
	 * @summary updateComment
	 * @request PUT:/api/v1/reels/{id}/comment
	 * @secure
	 * @response `200` `KlReelCommentUpdateCommentUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelCommentUpdateCommentUsingPut = (
		id: number,
		request: KlCommentRequest,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlReelCommentUpdateCommentUsingPutData,
			KlReelCommentUpdateCommentUsingPutError
		>({
			path: `/api/v1/reels/${id}/comment`,
			method: 'PUT',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelCommentRemoveCommentUsingDelete
	 * @summary removeComment
	 * @request DELETE:/api/v1/reels/{id}/comment/{commentId}
	 * @secure
	 * @response `200` `KlReelCommentRemoveCommentUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelCommentRemoveCommentUsingDelete = (
		commentId: number,
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlReelCommentRemoveCommentUsingDeleteData,
			KlReelCommentRemoveCommentUsingDeleteError
		>({
			path: `/api/v1/reels/${id}/comment/${commentId}`,
			method: 'DELETE',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelCommentCreateReportUsingPost
	 * @summary createReport
	 * @request POST:/api/v1/reels/{id}/comment/{commentId}/report
	 * @secure
	 * @response `200` `KlReelCommentCreateReportUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelCommentCreateReportUsingPost = (
		commentId: number,
		id: number,
		request: KlCommentReportRequest,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlReelCommentCreateReportUsingPostData,
			KlReelCommentCreateReportUsingPostError
		>({
			path: `/api/v1/reels/${id}/comment/${commentId}/report`,
			method: 'POST',
			body: request,
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelsDislikeDislikeUsingPut
	 * @summary dislike
	 * @request PUT:/api/v1/reels/{id}/dislike
	 * @secure
	 * @response `200` `KlReelsDislikeDislikeUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelsDislikeDislikeUsingPut = (id: number, params: RequestParams = {}) =>
		this.http.request<
			KlReelsDislikeDislikeUsingPutData,
			KlReelsDislikeDislikeUsingPutError
		>({
			path: `/api/v1/reels/${id}/dislike`,
			method: 'PUT',
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelsDislikeRemoveDislikeUsingDelete
	 * @summary removeDislike
	 * @request DELETE:/api/v1/reels/{id}/dislike
	 * @secure
	 * @response `200` `KlReelsDislikeRemoveDislikeUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelsDislikeRemoveDislikeUsingDelete = (
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlReelsDislikeRemoveDislikeUsingDeleteData,
			KlReelsDislikeRemoveDislikeUsingDeleteError
		>({
			path: `/api/v1/reels/${id}/dislike`,
			method: 'DELETE',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelFavoriteAddToFavoriteUsingPost
	 * @summary addToFavorite
	 * @request POST:/api/v1/reels/{id}/favorite
	 * @secure
	 * @response `200` `KlReelFavoriteAddToFavoriteUsingPostData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelFavoriteAddToFavoriteUsingPost = (
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlReelFavoriteAddToFavoriteUsingPostData,
			KlReelFavoriteAddToFavoriteUsingPostError
		>({
			path: `/api/v1/reels/${id}/favorite`,
			method: 'POST',
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelFavoriteRemoveFromFavoriteUsingDelete
	 * @summary removeFromFavorite
	 * @request DELETE:/api/v1/reels/{id}/favorite
	 * @secure
	 * @response `200` `KlReelFavoriteRemoveFromFavoriteUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelFavoriteRemoveFromFavoriteUsingDelete = (
		id: number,
		params: RequestParams = {}
	) =>
		this.http.request<
			KlReelFavoriteRemoveFromFavoriteUsingDeleteData,
			KlReelFavoriteRemoveFromFavoriteUsingDeleteError
		>({
			path: `/api/v1/reels/${id}/favorite`,
			method: 'DELETE',
			secure: true,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelsLikeLikeUsingPut
	 * @summary like
	 * @request PUT:/api/v1/reels/{id}/like
	 * @secure
	 * @response `200` `KlReelsLikeLikeUsingPutData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelsLikeLikeUsingPut = (id: number, params: RequestParams = {}) =>
		this.http.request<
			KlReelsLikeLikeUsingPutData,
			KlReelsLikeLikeUsingPutError
		>({
			path: `/api/v1/reels/${id}/like`,
			method: 'PUT',
			secure: true,
			type: ContentType.Json,
			...params
		})
	/**
	 * No description
	 *
	 * @tags Reel
	 * @name ReelsLikeUnlikeUsingDelete
	 * @summary unlike
	 * @request DELETE:/api/v1/reels/{id}/like
	 * @secure
	 * @response `200` `KlReelsLikeUnlikeUsingDeleteData` OK
	 * @response `400` `KlApiError` Bad request
	 * @response `401` `KlApiError` Unauthorized
	 * @response `404` `KlApiError` Not found
	 * @response `409` `KlApiError` Conflict
	 * @response `500` `KlApiError` Internal server error
	 */
	reelsLikeUnlikeUsingDelete = (id: number, params: RequestParams = {}) =>
		this.http.request<
			KlReelsLikeUnlikeUsingDeleteData,
			KlReelsLikeUnlikeUsingDeleteError
		>({
			path: `/api/v1/reels/${id}/like`,
			method: 'DELETE',
			secure: true,
			...params
		})
}
