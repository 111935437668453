import type { ReactElement } from 'react';
import { getPages } from '@/const/pages';
import { StrapiPage } from '@/const/cmsInterface';
import { renderPage } from '@/ui-kit/Components/Layout/renderPage';
import NotFound from '@/pages/NotFound';

export default function CompetitionByReference(): ReactElement {
  const pages = getPages() as StrapiPage[];
  const competitionPage = pages?.find((page) => page.slug === '/competition');

  if (!competitionPage) {
    return <NotFound />;
  }

  return renderPage(competitionPage);
}
