const Circle = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_180490_51661)">
      <path fillRule="evenodd" clipRule="evenodd" d="M18 34.2C26.947 34.2 34.2 26.947 34.2 18C34.2 9.05299 26.947 1.8 18 1.8C9.05299 1.8 1.8 9.05299 1.8 18C1.8 26.947 9.05299 34.2 18 34.2ZM18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_180490_51661">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default Circle;
