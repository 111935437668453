const LinkedIn = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_12012_37502)">
      <path
        d="M13.0909 14.7273C13.9947 14.7273 14.7273 13.9947 14.7273 13.0909C14.7273 12.1872 13.9947 11.4545 13.0909 11.4545C12.1872 11.4545 11.4545 12.1872 11.4545 13.0909C11.4545 13.9947 12.1872 14.7273 13.0909 14.7273Z"
        fill="currentColor"
      />
      <path d="M11.4545 15.5455H14.7272V24.5455H11.4545V15.5455Z" fill="currentColor" />
      <path
        d="M18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0ZM18 34.3636C8.96259 34.3636 1.63638 27.0374 1.63638 18C1.63638 8.96259 8.96259 1.63638 18 1.63638C27.0374 1.63638 34.3636 8.96259 34.3636 18C34.3636 27.0374 27.0374 34.3636 18 34.3636Z"
        fill="currentColor"
      />
      <path
        d="M22.3855 15.5455C19.9309 15.5455 19.6364 17.0018 19.6364 17.0018V15.5455H16.3637V24.5455H19.6364V19.6364C19.6364 19.6364 19.6364 18 21.0273 18C21.8455 18 22.0909 18.7282 22.0909 19.6364V24.5455H25.3636V19.6364C25.3636 17.0755 24.2509 15.5455 22.3855 15.5455Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_12012_37502">
        <rect width="100%" height="100%" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LinkedIn;
