import {
  KlCompetitionData,
  KlCompetitionRoundActionData,
  KlCompetitionRoundData,
  KlCompetitionRoundStepData,
} from '@/api/generated/data-contracts';
import orderBy from 'lodash/orderBy';
import dayjs from 'dayjs';
import first from 'lodash/first';
import { useSearchParams } from 'react-router-dom';
import { useGetCompetitionsUsingGet } from '@/api/http/public';

export interface CompetitionHook {
  getSteps: () => KlCompetitionRoundStepData[];
  getActiveStep: () => KlCompetitionRoundStepData | undefined;
  getActiveRound: () => KlCompetitionRoundData | undefined;
  getNextActionOfActiveRound: () => KlCompetitionRoundActionData | undefined;
  getActiveStepIndex: () => number;
  isFirstRound: () => boolean;
  isVoteForTop10: () => boolean;
  isVoteForTop30: () => boolean;
  isVoteForTop3: () => boolean;
  isVoteForWinner: () => boolean;
  isFinalVote: () => boolean;
  currentCompetition: KlCompetitionData;
  competitionPodcastsEnabled: () => boolean;
}

export const useCompetition = (currentCompetition: KlCompetitionData | undefined) => {
  const getSteps = () => {
    let rounds = [] as KlCompetitionRoundData[];
    if (currentCompetition?.rounds?.length) {
      rounds = orderBy(currentCompetition?.rounds, ['sequence'], ['asc']);
    }

    // @todo add useMemo
    const steps: KlCompetitionRoundStepData[] = [];
    for (const round of rounds) {
      const orderedSteps = orderBy(round?.steps, ['sequence'], ['asc']);

      for (const step of orderedSteps) {
        steps.push(step);
      }
    }
    return steps;
  };

  const getActiveStep = () => {
    const stepsData = getSteps();
    const activeStepIndex = stepsData?.findIndex((step) => {
      return step?.isActive;
    });

    return stepsData[activeStepIndex];
  };

  const getActiveRound = () => {
    const round = currentCompetition?.rounds?.find((roundItem) => {
      return roundItem?.isActive;
    });

    return round;
  };

  const getNextActionOfActiveRound = () => {
    const round = getActiveRound();

    const nextAction = round?.actions?.find((actionItem) => {
      if (actionItem?.isActive) {
        return false;
      }
      return dayjs(actionItem?.activeTo as string).isAfter(dayjs());
    });

    return nextAction;
  };

  const getActiveStepIndex = () => {
    const stepsData = getSteps();
    const activeStepIndex = stepsData?.findIndex((step) => {
      return step?.isActive;
    });

    return activeStepIndex;
  };

  const isFirstRound = (): boolean => {
    const activeStepIndex = getActiveStepIndex();
    return activeStepIndex === 0;
  };

  const isVoteForWinner = (): boolean => {
    const activeStepIndex = getActiveStepIndex();
    return activeStepIndex === 4;
  };

  const isVoteForTop3 = (): boolean => {
    const activeStepIndex = getActiveStepIndex();
    return activeStepIndex === 3;
  };

  const isVoteForTop10 = (): boolean => {
    const activeStepIndex = getActiveStepIndex();
    // @todo we take voting stage from the index, better to do that from the backend part
    return activeStepIndex === 2;
  };

  const isVoteForTop30 = (): boolean => {
    const activeStepIndex = getActiveStepIndex();
    // @todo we take voting stage from the index, better to do that from the backend part
    return activeStepIndex === 1;
  };

  // @todo refactor
  const competitionPodcastsEnabled = (): boolean => {
    const podcastRound = currentCompetition?.rounds?.find((roundItem: KlCompetitionRoundStepData) => {
      return roundItem?.sequence === 1;
    });
    const competitionPodcastsAction = first(podcastRound?.actions);
    if (competitionPodcastsAction) {
      return dayjs(competitionPodcastsAction?.activeFrom as string).isBefore(dayjs());
    }
    return false;
  };

  const isFinalVote = (): boolean => {
    const lastRound = currentCompetition?.rounds?.find((roundItem: KlCompetitionRoundStepData) => {
      return roundItem?.sequence === 3;
    });
    const competitionAction = first(lastRound?.actions);
    return !!competitionAction?.isActive;
  };

  return {
    getSteps,
    getActiveStep,
    getActiveRound,
    getNextActionOfActiveRound,
    getActiveStepIndex,
    isFirstRound,
    isVoteForTop3,
    isVoteForTop10,
    isVoteForTop30,
    isVoteForWinner,
    isFinalVote,
    currentCompetition,
    competitionPodcastsEnabled,
  } as CompetitionHook;
};

export const useCurrentCompetition = (isCompetitionFetchEnabled = true) => {
  const [searchParams] = useSearchParams();
  const activeCompetitionIdSearchParams = searchParams.get('activeCompetitionId');
  const { data } = useGetCompetitionsUsingGet(isCompetitionFetchEnabled);

  const getCurrentCompetition = () => {
    if (activeCompetitionIdSearchParams) {
      const competitionId = Number.parseInt(activeCompetitionIdSearchParams, 10);
      return data?.content
        ?.find((competition) => competition?.id === competitionId);
    }
    // @todo move to hook, duplicated code
    const activeCompetition = data?.content?.find((item) => item.isActive);
    const inactiveCompetition = data?.content?.find((item) => !item.isActive);
    const currentCompetition = activeCompetition || inactiveCompetition;

    return currentCompetition;
  };

  return getCurrentCompetition();
};
