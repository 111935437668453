const History = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <path
      d="M17.8376 19.3424L24.5873 24.4047C24.7055 24.4934 24.84 24.5578 24.9831 24.5945C25.1262 24.6312 25.2751 24.6393 25.4214 24.6184C25.5676 24.5975 25.7083 24.548 25.8354 24.4727C25.9626 24.3975 26.0736 24.2979 26.1623 24.1797C26.2509 24.0615 26.3154 23.9271 26.3521 23.7839C26.3887 23.6408 26.3968 23.4919 26.3759 23.3457C26.3551 23.1994 26.3056 23.0587 26.2303 22.9316C26.155 22.8045 26.0555 22.6934 25.9373 22.6048L19.6881 17.8743V8.31222C19.6881 8.01387 19.5696 7.72773 19.3586 7.51676C19.1477 7.30579 18.8615 7.18727 18.5632 7.18727C18.2648 7.18727 17.9787 7.30579 17.7677 7.51676C17.5567 7.72773 17.4382 8.01387 17.4382 8.31222V18.4368C17.4382 18.8103 17.5946 19.1467 17.8376 19.3424Z"
      fill="currentColor"
    />
    <path
      d="M19.1257 1C10.8915 1 3.86001 6.94314 2.48756 15.0619L2.06008 14.4263C1.893 14.1787 1.63439 14.0076 1.34114 13.9506C1.04789 13.8937 0.744027 13.9555 0.496391 14.1226C0.248754 14.2897 0.0776329 14.5483 0.0206709 14.8415C-0.036291 15.1348 0.0255726 15.4387 0.192652 15.6863L2.44256 19.0612C2.53393 19.2001 2.65498 19.3171 2.79698 19.4037C2.93898 19.4902 3.09841 19.5442 3.26378 19.5618H3.37628C3.52379 19.5613 3.66976 19.5317 3.80585 19.4748C3.94193 19.4178 4.06546 19.3346 4.16937 19.2299L6.98176 16.4175C7.18258 16.2044 7.29246 15.9214 7.2881 15.6286C7.28374 15.3357 7.16548 15.0561 6.9584 14.8491C6.75133 14.642 6.47173 14.5237 6.17891 14.5194C5.8861 14.515 5.6031 14.6249 5.38995 14.8257L4.69248 15.5288C5.98786 7.55624 13.5009 2.14408 21.4729 3.43947C29.4449 4.73485 34.8582 12.2473 33.5628 20.2199C32.4125 27.2992 26.2978 32.4999 19.1257 32.4988C16.8968 32.5392 14.6912 32.0407 12.6964 31.0457C10.7015 30.0507 8.97651 28.5887 7.66798 26.784C7.5823 26.6636 7.47374 26.5612 7.3485 26.4828C7.22327 26.4044 7.0838 26.3513 6.93808 26.3268C6.79235 26.3022 6.64322 26.3066 6.4992 26.3397C6.35517 26.3728 6.21907 26.4339 6.09867 26.5196C5.97827 26.6053 5.87592 26.7139 5.79748 26.8391C5.71903 26.9643 5.66602 27.1038 5.64147 27.2495C5.61692 27.3953 5.62132 27.5444 5.6544 27.6884C5.68749 27.8324 5.74862 27.9685 5.83431 28.0889C7.34965 30.1874 9.34945 31.8887 11.6636 33.0482C13.9777 34.2077 16.5376 34.7911 19.1257 34.7487C28.4454 34.7487 36 27.194 36 17.8743C36 8.55464 28.4454 1 19.1257 1Z"
      fill="currentColor"
    />
  </svg>
);

export default History;
