import * as Sentry from '@sentry/react';

const release = APP_VERSION?.replace('v', '');

export const sentryOption = {
  dsn: import.meta.env.VITE_SENTRY_DSN_REACT,
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
  ],
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
    }),
  ],
  release,
  environment: import.meta.env.MODE,
  // Tracing
  tracesSampleRate: 1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [import.meta.env.VITE_REACT_PUBLIC_SITE_URL],
  // Session Replay
  replaysSessionSampleRate: 0.3, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate: 1,
} as Sentry.BrowserOptions;
