const EnvelopeDollar = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
    <g clipPath="url(#clip0_11934_215958)">
      <path
        d="M17.948 11.3126L17.9156 11.3048C15.8372 10.8302 15.8372 9.93444 15.8372 9.63983C15.8372 8.51483 16.8089 7.99803 17.7117 7.99803C18.0814 7.99752 18.443 8.1065 18.7508 8.31122C19.0586 8.51593 19.2989 8.80722 19.4414 9.14834C19.6368 9.6143 20.1729 9.83375 20.6388 9.63842C21.1048 9.44309 21.3243 8.90696 21.1289 8.441C20.9078 7.9118 20.5665 7.44136 20.132 7.06694C19.6975 6.69252 19.1818 6.4244 18.6258 6.28381V5.50264C18.6258 4.9978 18.2166 4.58858 17.7117 4.58858C17.2069 4.58858 16.7977 4.9978 16.7977 5.50264V6.26764C15.158 6.62975 14.0091 7.95866 14.0091 9.63912C14.0105 10.6242 14.4612 12.375 17.4748 13.0781L17.5071 13.0858C19.5856 13.5605 19.5856 14.4562 19.5856 14.7508C19.5856 15.8758 18.6138 16.3926 17.711 16.3926C17.3413 16.3932 16.9798 16.2842 16.672 16.0795C16.3641 15.8747 16.1238 15.5835 15.9813 15.2423C15.786 14.7764 15.2499 14.5569 14.7839 14.7522C14.318 14.9476 14.0985 15.4837 14.2938 15.9497C14.5149 16.4792 14.8562 16.95 15.2908 17.3247C15.7254 17.6994 16.2414 17.9676 16.7977 18.1083V18.8887C16.7977 19.3936 17.2069 19.8028 17.7117 19.8028C18.2166 19.8028 18.6258 19.3936 18.6258 18.8887V18.123C20.2641 17.7602 21.4123 16.432 21.4123 14.7522C21.4123 13.7679 20.9609 12.0143 17.948 11.3126Z"
        fill="currentColor"
      />
      <path
        d="M35.1021 13.7053L30.3272 9.67289V2.64586C30.3252 1.1854 29.1418 0.00196875 27.6813 0H7.93828C6.47641 0 5.29088 1.18399 5.28891 2.64586V9.50906L4.11961 10.4934L0.322734 13.7011C0.298055 13.7232 0.27457 13.7467 0.252422 13.7714L0.238359 13.7876C0.221484 13.8066 0.204609 13.8255 0.189141 13.8452L0.175078 13.8649C0.161016 13.8846 0.146953 13.9043 0.135 13.924L0.121641 13.9479C0.110391 13.9676 0.0998438 13.9873 0.09 14.0077L0.0773438 14.0358C0.0646146 14.0655 0.0531204 14.0958 0.0428906 14.1265C0.039375 14.137 0.0316406 14.1645 0.0267188 14.1834C0.0217969 14.2024 0.0210938 14.2073 0.0182813 14.2193C0.0154688 14.2312 0.01125 14.258 0.00843753 14.277C0.00562503 14.2959 0.00492189 14.3009 0.00351564 14.3128C0.00210939 14.3248 0.00351563 14.3564 0 14.3782V32.6433C0.00196875 34.495 1.50152 35.9961 3.3532 36H32.0702C33.9233 35.998 35.425 34.4964 35.427 32.6433V14.4035C35.4269 14.2705 35.3977 14.1391 35.3416 14.0185C35.2855 13.898 35.2038 13.7911 35.1021 13.7053ZM30.3279 12.0684L33.0933 14.4014L30.3279 16.3575V12.0684ZM7.11703 2.64586C7.1178 2.19347 7.48519 1.82735 7.93758 1.82813H27.6813C28.1327 1.8289 28.4983 2.19452 28.4991 2.64586V17.6484L17.808 25.212L7.11633 17.6484L7.11703 2.64586ZM4.23281 12.7969L5.2875 11.9053V16.3603L2.41805 14.3269L4.23281 12.7969ZM33.5988 32.6433C33.5981 33.4872 32.9141 34.1711 32.0702 34.1719H3.3532C2.50931 34.1711 1.82538 33.4872 1.82461 32.6433V16.1466L12.9705 24.0293L5.10328 30.0537C4.70039 30.3578 4.62031 30.931 4.92441 31.3339C5.22858 31.7368 5.8017 31.8169 6.20466 31.5128L14.5343 25.1346L17.2807 27.0773C17.435 27.1863 17.6192 27.2448 17.808 27.2448C17.9969 27.2448 18.1811 27.1863 18.3354 27.0773L21.0818 25.1346L29.4012 31.5049C29.7998 31.8147 30.374 31.7428 30.6838 31.3442C30.9936 30.9456 30.9217 30.3713 30.5231 30.0615L22.6448 24.0293L33.5988 16.283V32.6433Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_11934_215958">
        <rect width="100%" height="100%" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EnvelopeDollar;
