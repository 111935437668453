const ArrowLeft = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_179722_86832)">
      <path d="M36 15.7499H8.6175L21.195 3.17253L18 6.10352e-05L0 17.9999L18 35.9997L21.1725 32.8272L8.6175 20.2499H36V15.7499Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_179722_86832">
        <rect width="36" height="36" fill="none" />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowLeft;
