const Billing = (): JSX.Element => (
  <svg width="100%" height="100%" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2376_34770)">
      <path d="M9.90977 11.2459L13.6596 14.0583C13.7253 14.1075 13.8 14.1434 13.8795 14.1637C13.959 14.1841 14.0417 14.1886 14.123 14.177C14.2042 14.1654 14.2824 14.1379 14.353 14.0961C14.4236 14.0543 14.4853 13.9989 14.5346 13.9333C14.5838 13.8676 14.6197 13.7929 14.64 13.7134C14.6604 13.6339 14.6649 13.5512 14.6533 13.4699C14.6417 13.3887 14.6142 13.3105 14.5724 13.2399C14.5306 13.1693 14.4753 13.1076 14.4096 13.0583L10.9379 10.4303V5.11801C10.9379 4.95226 10.872 4.79329 10.7548 4.67609C10.6376 4.55888 10.4786 4.49304 10.3129 4.49304C10.1471 4.49304 9.98816 4.55888 9.87095 4.67609C9.75375 4.79329 9.6879 4.95226 9.6879 5.11801V10.7428C9.6879 10.9503 9.77477 11.1371 9.90977 11.2459Z" fill="currentColor" />
      <path d="M10.6254 1.05566C6.05086 1.05566 2.14445 4.35741 1.38198 8.86786L1.14449 8.51475C1.05167 8.37717 0.907994 8.2821 0.745078 8.25046C0.582163 8.21881 0.413348 8.25318 0.275773 8.346C0.138197 8.43883 0.0431294 8.5825 0.0114839 8.74541C-0.0201617 8.90833 0.014207 9.07714 0.107029 9.21472L1.35698 11.0896C1.40774 11.1668 1.47499 11.2318 1.55388 11.2799C1.63277 11.328 1.72134 11.358 1.81321 11.3678H1.87571C1.95766 11.3675 2.03876 11.3511 2.11436 11.3194C2.18996 11.2878 2.25859 11.2416 2.31632 11.1834L3.87876 9.62095C3.99032 9.50254 4.05137 9.34532 4.04894 9.18264C4.04652 9.01996 3.98082 8.86463 3.86578 8.74959C3.75074 8.63455 3.5954 8.56885 3.43273 8.56642C3.27005 8.564 3.11283 8.62505 2.99442 8.73661L2.60693 9.12722C3.32659 4.69802 7.50049 1.69126 11.9294 2.41092C16.3583 3.13058 19.3656 7.30417 18.646 11.7334C18.007 15.6663 14.6099 18.5556 10.6254 18.555C9.38713 18.5774 8.16178 18.3005 7.05354 17.7477C5.9453 17.195 4.98695 16.3827 4.25999 15.3801C4.21239 15.3132 4.15208 15.2564 4.0825 15.2128C4.01293 15.1692 3.93545 15.1397 3.85449 15.1261C3.77353 15.1125 3.69068 15.1149 3.61066 15.1333C3.53065 15.1517 3.45504 15.1856 3.38815 15.2332C3.32126 15.2808 3.2644 15.3411 3.22082 15.4107C3.17724 15.4803 3.14779 15.5578 3.13415 15.6387C3.12051 15.7197 3.12295 15.8025 3.14134 15.8826C3.15972 15.9626 3.19368 16.0382 3.24128 16.1051C4.08314 17.2709 5.19414 18.216 6.47976 18.8602C7.76538 19.5044 9.18758 19.8285 10.6254 19.8049C15.803 19.8049 20 15.6079 20 10.4303C20 5.25269 15.803 1.05566 10.6254 1.05566Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_2376_34770">
        <rect width="100%" height="100%" fill="none" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>

);

export default Billing;
